import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { toast } from "@/components/ui/use-toast";
import { acceptGroupInvitation } from "@/features/groups/api/group.api";
import {
  GroupUserStatusEnum,
  GroupUserTypeEnum,
} from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo } from "@/features/groups/group";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance, remainingTime } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";
import { COMMON_MESSAGES, getCustomMessage } from "@/utils/message";

import AcceptGroupModal from "./AcceptGroupModal";
import AcceptOrgModal from "./AcceptOrgModal";
import CancelModal from "./CancelConfirmModal";
import RejectIsConfirmModal from "./RejectIsConfirmModal";
import RejectOrgModal from "./RejectOrgModal";

function CardInvites() {
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const { user } = useAppSelector((state) => state.auth);

  return (
    <>
      {groupInfo?.userGroupRelation &&
        groupInfo.userGroupRelation.sender.id !== user?.id &&
        (groupInfo.userGroupRelation.groupUserStatus ===
          GroupUserStatusEnum.REQUESTED ||
          groupInfo.userGroupRelation.groupUserStatus ===
            GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE) && (
          <div className="mt-4 container">
            <div className=" p-6 bg-white rounded border border-secondary-200 justify-between items-start  flex flex-wrap">
              <div className="justify-start items-start gap-24 flex">
                <div className="justify-start items-center gap-2 flex">
                  <TGNAvatar
                    userName={
                      groupInfo.userGroupRelation.sender.name ||
                      extractUsername(
                        groupInfo.userGroupRelation.sender.email || ""
                      )
                    }
                    userPhoto={groupInfo.userGroupRelation.sender.photoUrl}
                    size="40"
                  />
                  <div className="flex-col justify-start items-start gap-1 inline-flex">
                    <div className="self-stretch justify-start items-start gap-1 inline-flex">
                      <div className="text-secondary-900 text-sm font-medium">
                        {groupInfo.userGroupRelation.sender.name ||
                          extractUsername(
                            groupInfo.userGroupRelation.sender.email || ""
                          )}
                      </div>
                      <div className="text-secondary-600 text-sm font-normal">
                        đã gửi cho bạn một lời mời tham gia{" "}
                        {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} này
                      </div>
                    </div>
                    <div className="text-secondary-600 text-xs font-normal">
                      Lời mời{" "}
                      {remainingTime(groupInfo.userGroupRelation.invitedAt)}.
                    </div>
                  </div>
                </div>
              </div>

              <div className="justify-end items-start gap-3 flex">
                {groupInfo.userGroupRelation.groupUserStatus ===
                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ? (
                  <CancelModal
                    isDetail
                    groupInfo={groupInfo}
                    button={<Button variant={"outline"}>Hủy bỏ</Button>}
                  />
                ) : (
                  <RejectOrgModal
                    isDetail
                    inviteId={groupInfo.userGroupRelation.id}
                    groupName={groupInfo.name}
                    groupId={groupInfo.id}
                    groupLock={groupInfo.groupLock}
                    rootGroupId={groupInfo.rootGroupId ?? groupInfo.id}
                    button={<Button variant={"outline"}>Từ chối</Button>}
                  />
                )}
                {groupInfo.userGroupRelation.groupUserStatus ===
                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ? (
                  <>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            className="w-full me-2.5"
                            disabled
                          >
                            Đang xử lý
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            Vui lòng chờ! Tổ chức của nhóm đang xử lý lời mời
                            này.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                ) : (
                  <>
                    {!groupInfo.rootGroupId ? (
                      <AcceptOrgModal
                        isDetail={true}
                        rootGroupId={groupInfo.id}
                        groupId={groupInfo.id}
                        inviteId={groupInfo.userGroupRelation.id}
                        groupName={groupInfo.name}
                        groupLock={groupInfo.groupLock}
                        button={<Button className="w-full">Chấp nhận </Button>}
                      />
                    ) : (
                      <AcceptGroupModal
                        isDetail={true}
                        rootGroupName={groupInfo.rootGroupName}
                        rootGroupId={groupInfo.rootGroupId}
                        groupId={groupInfo.id}
                        inviteId={groupInfo.userGroupRelation.id}
                        groupName={groupInfo.name}
                        groupLock={groupInfo.groupLock}
                        button={<Button className="w-full">Chấp nhận </Button>}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default memo(CardInvites);
