import React, { FC, Fragment, memo, useMemo, useState } from "react";

import {
  Close,
  Download,
  Edit,
  FolderMoveTo,
  Information,
  Maximize,
  ReplyAll,
  TrashCan,
} from "@carbon/icons-react";
import clsx from "clsx";

import Divider from "@/components/Divider/Divider";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { cn } from "@/lib/utils";
import { formatBytes, getContentName } from "@/utils/file";

import FileIcon from "./FileIcon";
import {
  ContentTypeEnums,
  FolderMenuActions,
} from "../constants/lib.constants";
// eslint-disable-next-line import/namespace
import { IContent } from "../types/lib.types";

type ContentItemProps = {
  content: IContent;
  active: boolean;
  disableRightClick?: boolean;
  isShare?: boolean;
  disable?: boolean;
  onClick?: () => void;
  onMenuClick: (action: FolderMenuActions) => void;
};

const ContentItem: FC<ContentItemProps> = ({
  content,
  active,
  disableRightClick = false,
  isShare = false,
  disable = false,
  onMenuClick,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const actionBarList = useMemo(() => {
    return [
      {
        label: "Mở thư mục",
        icon: (
          <div className="flex relative">
            <Maximize className="rotate-90" />
            <Maximize className="rotate-180 absolute top-0 left-0" />
          </div>
        ),
        action: FolderMenuActions.Open,
        show: content.type === ContentTypeEnums.FOLDER,
      },
      {
        label: "Đổi tên",
        icon: <Edit />,
        action: FolderMenuActions.Rename,
        show: !isShare,
      },
      {
        label: "Chia sẻ",
        icon: <ReplyAll />,
        action: FolderMenuActions.Share,
        show: !isShare,
      },
      {
        label: "Chuyển đến",
        icon: <FolderMoveTo />,
        action: FolderMenuActions.Move,
        show: !isShare,
      },
      {
        label: "Tải xuống",
        icon: <Download />,
        action: FolderMenuActions.Download,
        show: true,
      },
      {
        label: "Thông tin",
        icon: <Information />,
        action: FolderMenuActions.Detail,
        show: true,
      },
      {
        label: "Xoá",
        icon: <TrashCan />,
        action: FolderMenuActions.Delete,
        show: !isShare,
      },
      {
        label: "Gỡ truy cập",
        icon: <Close />,
        action: FolderMenuActions.RemoveShare,
        show: isShare,
      },
    ];
  }, []);

  return (
    <ContextMenu onOpenChange={(o) => setOpen(o)}>
      <ContextMenuTrigger
        disabled={disableRightClick || disable}
        className="w-[calc(100%/6)] 2xl:w-[calc(100%/8)]"
      >
        <div
          className={clsx(
            "p-2 rounded h-full",
            open || active ? "bg-secondary-100" : "",
            disable ? "opacity-50 cursor-default" : "cursor-pointer"
          )}
          onDoubleClick={() => {
            if (content.type === ContentTypeEnums.FOLDER && !disable) {
              onMenuClick(FolderMenuActions.Open);
            }
          }}
          onClick={() => {
            if (onClick && !disable) {
              onClick();
            }
          }}
        >
          <FileIcon
            extension={content.extension}
            size={64}
          />
          <p className="text-xs font-semibold mt-2 break-words">
            {getContentName(content.name)}
          </p>
          {content.type === ContentTypeEnums.FILE && (
            <p className="text-xs text-secondary-600">
              {formatBytes(content.size)}
            </p>
          )}
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="bg-white p-1">
        {actionBarList.map((item) =>
          item.show ? (
            <Fragment key={item.label}>
              <ContextMenuItem className="p-0 min-w-[240px]">
                <div
                  className={cn(
                    "flex gap-1 items-center hover:bg-secondary-100 w-full px-2 py-[10px] rounded-sm cursor-pointer",
                    [
                      FolderMenuActions.Delete,
                      FolderMenuActions.RemoveShare,
                    ].includes(item.action)
                      ? "text-error-600"
                      : ""
                  )}
                  onClick={() => onMenuClick(item.action)}
                >
                  <div className="mr-1">{item.icon}</div>
                  {item.label}
                </div>
              </ContextMenuItem>
              {["Tải xuống", "Thông tin"].includes(item.label) && (
                <Divider
                  className="my-1 !w-[calc(100%-16px)] mx-auto"
                  height={1}
                />
              )}
            </Fragment>
          ) : null
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default memo(ContentItem);
