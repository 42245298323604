import React, { FC, useEffect, useState } from "react";

import {
  ArrowUpRight,
  CheckmarkFilled,
  DecisionTree,
  InformationFilled,
} from "@carbon/icons-react";
import cx from "classnames";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { GroupDeletedDef } from "@/features/groups/types/group.type";

type RestoreGroupConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  group?: GroupDeletedDef;
  success: boolean;
  onOk: () => void;
};

enum StepEnum {
  ONE = "1",
  TWO = "2",
}

const RestoreGroupConfirmDialog: FC<RestoreGroupConfirmDialogProps> = ({
  open,
  group,
  success,
  onClose,
  onOk,
}) => {
  const [step, setStep] = useState<StepEnum>(StepEnum.ONE);
  useEffect(() => {
    setStep(StepEnum.ONE);
  }, [open]);

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent
        onInteractOutside={onClose}
        className={cx(
          "bg-white text-sm",
          success ? "sm:max-w-[468px]" : "sm:max-w-[650px]"
        )}
      >
        {success ? (
          <>
            <DialogHeader>
              <span className="flex items-center justify-center border-[6px] p-1 text-success-600 border-success-50 bg-success-100 rounded-full mx-auto">
                <CheckmarkFilled
                  size={20}
                  className="text-success-500"
                />
              </span>
            </DialogHeader>
            <div className="text-center">
              <p className="text-lg font-semibold">
                Khôi phục Nhóm thành công!
              </p>
              <p className="text-[#667085] mt-2">
                Đã khôi phục Nhóm “
                <span className="font-semibold">{group?.name}</span>” thành
                công. Bạn đã có thể xem và thực hiện cập nhật Cơ cấu tổ chức của
                mình.
              </p>
              <div className="flex justify-center items-center gap-2 mt-2">
                <Button variant="text">
                  <DecisionTree
                    className="text-primary rotate-90 mr-2"
                    size={16}
                  />
                  <span className="text-primary font-medium">
                    Cơ cấu tổ chức
                  </span>
                </Button>
              </div>
              <div className="text-center mt-6">
                <Button
                  size="sm"
                  variant="text"
                  onClick={onClose}
                >
                  Để sau
                </Button>
              </div>
            </div>
          </>
        ) : step === StepEnum.ONE ? (
          <>
            <DialogTitle className="mb-2">
              Khôi phục nhóm {group?.name}?
            </DialogTitle>
            <div className="text-secondary-600">
              <div className="flex gap-5 bg-primary-25 rounded-sm p-4">
                <div>
                  <span className="border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full block">
                    <InformationFilled size={20} />
                  </span>
                </div>
                <div>
                  Thao tác này sẽ khôi phục Nhóm của bạn, bạn có thể lựa chọn
                  phương thức khôi phục sau đây. Quá trình này có thể diễn ra
                  trong một vài phút.
                  <p className="font-medium">Tìm hiểu thêm↗</p>
                </div>
              </div>
              <div className="text-end mt-6">
                <Button
                  size="sm"
                  variant="outline"
                  className="mr-3"
                  onClick={onClose}
                >
                  Huỷ bỏ
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setStep(StepEnum.TWO)}
                >
                  Tiếp tục
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Phương thức khôi phục</DialogTitle>
              <DialogDescription className="font-xs text-secondary-600">
                Lựa chọn phương thức khôi phục Nhóm của bạn{" "}
                <span className="font-medium inline-flex items-center text-secondary-900">
                  Tìm hiểu thêm <ArrowUpRight size={14} />
                </span>
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-6">
              <div className="grid gap-4">
                <div className="items-center grid gap-2">
                  <RadioGroup defaultValue="1">
                    <Label
                      className="flex items-center border border-primary rounded-lg p-4 bg-primary-25 items-start ring-4 ring-primary-50"
                      htmlFor="r1"
                    >
                      <RadioGroupItem
                        value="1"
                        id="r1"
                        className="mt-1"
                      />
                      <div className="grid grap-2">
                        <span className="text-sm font-medium text-secondary-900">
                          Khôi phục thường
                        </span>
                        <span className="text-xs text-secondary-600 font-normal mt-1">
                          Hệ thống tiến hành khôi phục Nhóm của bạn với thông
                          tin cơ bản, bao gồm: ảnh đại diện, mô tả nhóm, hệ
                          thống nhóm trực thuộc và các thiết lập cơ bản kể từ
                          lần cập nhật cuối cùng.
                        </span>
                      </div>
                    </Label>
                    <Label
                      className="flex items-center border border-primary border-white rounded-lg p-4 items-start"
                      htmlFor="r2"
                    >
                      <RadioGroupItem
                        value="2"
                        id="r2"
                        className="mt-1"
                        disabled
                      />
                      <div className="grid grap-2 cursor-not-allowed">
                        <span className="text-sm font-medium text-secondary-900">
                          Khôi phục nâng cao
                        </span>
                        <span className="text-xs text-secondary-600 font-normal mt-1">
                          Tải lên bản ghi dữ liệu của Nhóm đã được sao lưu trước
                          đó. TAGA sẽ tiến hành xác minh và khôi phục tất cả dữ
                          liệu, tài nguyên cùng các thiết lập của hệ thống Nhóm
                          của bạn tại thời điểm bản sao lưu được cập nhật.
                        </span>
                      </div>
                    </Label>
                  </RadioGroup>
                </div>
              </div>
              <DialogFooter>
                <Button
                  size="sm"
                  variant={"ghost"}
                  className="mr-2"
                  onClick={onClose}
                >
                  Huỷ bỏ
                </Button>
                <Button
                  size="sm"
                  onClick={onOk}
                >
                  Tiến hành Khôi phục
                </Button>
              </DialogFooter>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RestoreGroupConfirmDialog;
