import React, { useEffect, useRef, useState } from "react";

import cx from "classnames";

import d3Minmap, { D3MindmapDef } from "@/features/mindmap/lib/d3-mindmap";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import styles from "./MindmapScreen.module.scss";
import BottomBar from "../../components/BottomBar/BottomBar";
import MenuBar from "../../components/MenuBar/MenuBar";
import MindmapHeader from "../../components/MindmapHeader/MindmapHeader";
import PeekRenderer from "../../components/PeekRenderer/PeekRenderer";
import StyleSetting from "../../components/StyleSetting/StyleSetting";
import TaskUpdate from "../../components/TaskUpdate/TaskUpdate";
import { socketMindmap } from "../../lib/socket.mindmap";
import {
  addSubnode,
  getChats,
  handleChatRelease,
  handleRefreshTask,
  setMindmapIO,
} from "../../mindmap";

let d3Mm: D3MindmapDef;

const MindmapScreen = () => {
  const {
    currentSheet: { root, selectedNode, scale },
    showSearchBar,
  } = useAppSelector((state) => state.mindmap);
  const { mindmapIO } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isFirstTimeRenderDiagram, setIsFirstTimeRenderDiagram] =
    useState(true);
  const diagramRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const bodyEl = document.querySelector("body");
    if (bodyEl) {
      bodyEl.style.overflow = "hidden";
    }
    if (!diagramRef.current) {
      return;
    }
    d3Mm = d3Minmap(diagramRef.current);
    dispatch(
      setMindmapIO(socketMindmap((params.get("token") || "").replace(" ", "+")))
    );
    return () => {
      if (bodyEl) {
        bodyEl.style.removeProperty("overflow");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (showSearchBar) {
      setIsFirstTimeRenderDiagram(false);
    }
  }, [showSearchBar]);

  useEffect(() => {
    if (!mindmapIO) {
      return;
    }
    mindmapIO.connect();
    mindmapIO.listener((data) => {
      switch (data.command) {
        case "REFRESH_TASK": {
          dispatch(handleRefreshTask(data));
          break;
        }
        case "RETURN_DOWNLOAD_RESULT": {
          window.open(data.response.downloadURL, "_blank");
          break;
        }
        case "CHATS_RELEASE": {
          dispatch(handleChatRelease(data));
          break;
        }
        case "HAS_CHAT_UPDATED": {
          dispatch(getChats());
          break;
        }
        default:
          break;
      }
    });
    setTimeout(() => {
      mindmapIO.getData();
    }, 100);
  }, [mindmapIO, dispatch]);
  useEffect(() => {
    return () => {
      if (mindmapIO) {
        mindmapIO.removeAllListener();
      }
    };
  }, [mindmapIO]);

  useEffect(() => {
    if (root) {
      d3Mm.render({
        isFirstRender: isFirstRender,
        durationTime: 0,
      });
      setIsFirstRender(false);
    }
  }, [root, selectedNode, isFirstRender, scale]);

  const handleAddSubNode = () => {
    dispatch(addSubnode());
  };

  return (
    <div>
      <MindmapHeader />
      <TaskUpdate />
      <StyleSetting />
      <BottomBar />
      <PeekRenderer />
      <div
        className={cx(
          styles.diagramContainer,
          showSearchBar
            ? styles["show-search-bar"]
            : !isFirstTimeRenderDiagram
            ? styles["hide-search-bar"]
            : ""
        )}
      >
        <div
          id="mindmap-container"
          className={cx(
            styles.diagramWrapper,
            showSearchBar
              ? styles["show-search-bar"]
              : !isFirstTimeRenderDiagram
              ? styles["hide-search-bar"]
              : ""
          )}
          ref={diagramRef}
        >
          <svg
            ref={svgRef}
            id="mindmap"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              overflow: "scroll",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          ></svg>
        </div>
        <MenuBar />
        <button
          id="btn-add-node"
          className={styles["btn-add"]}
          onClick={handleAddSubNode}
        >
          <i className="tgn-outline-plus fs-18"></i>
        </button>
        {/* <button
          id="btn-add-node"
          className={styles["btn-add"]}
          onClick={() => {
            if (!loading) {
              handleAddNode();
            }
          }}
        >
          {loading ? "..." : "+"}
        </button>
        <button
          id="btn-toggle-node"
          className={styles["btn-toggle"]}
          onClick={() => dispatch(toggleChildren())}
        >
          {selectedNodeInRealTime?._children.length
            ? selectedNodeInRealTime?._children.length
            : "—"}
        </button> */}
        <div className={cx("skeleton", styles.skeleton)}></div>
      </div>
    </div>
  );
};

export default MindmapScreen;
