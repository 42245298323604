import { RouteItemDef } from "@/types/app.types";

import { TaskPathsEnum } from "../constants/task.paths";
import TaskLayout from "../layouts/TaskLayout";
import TaskDiscover from "../screens/TaskDiscover";
import TaskGroup from "../screens/TaskGroup";
import TaskScreen from "../screens/TaskScreen";

export const TASK_ROUTES: RouteItemDef[] = [
  {
    id: TaskPathsEnum.TASK,
    path: TaskPathsEnum.TASK,
    component: TaskLayout,
    children: [
      {
        id: TaskPathsEnum.TASK,
        path: TaskPathsEnum.TASK,
        component: TaskScreen,
      },
      {
        id: TaskPathsEnum.TASK_ORG,
        path: TaskPathsEnum.TASK_ORG,
        component: TaskGroup,
      },
      {
        id: TaskPathsEnum.TASK_DISCOVER,
        path: TaskPathsEnum.TASK_DISCOVER,
        component: TaskDiscover,
      },
    ],
  },
];
