import React, { FC, memo } from "react";

import Typography from "@/components/Typography/Typography";
import { TEXT_SETTINGS } from "@/features/mindmap/mindmap";

import styles from "./TextSetting.module.scss";

type TextSettingProps = {
  close: () => void;
  onChangeText: (fontSize: number) => void;
};

const TextSetting: FC<TextSettingProps> = ({ close, onChangeText }) => {
  return (
    <div
      className={styles.wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex align-items-center mb-4">
        <Typography className="flex-1 fs-12 fw-600">Font</Typography>
        <i
          className="tgn-outline-chat-close fs-18 cursor-pointer"
          onClick={close}
        ></i>
      </div>
      {TEXT_SETTINGS.map((item) => (
        <div
          key={item.fontSize}
          className={styles.item}
          onClick={() => onChangeText(item.fontSize)}
        >
          <span className="fs-14 flex-1">{item.label}</span>
          <span className="fs-12">{item.fontSize}px</span>
        </div>
      ))}
    </div>
  );
};

export default memo(TextSetting);
