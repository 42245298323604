import React, { useMemo } from "react";

import { InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";

import { ContentTypeEnums } from "../../constants/lib.constants";
import { IContent } from "../../types/lib.types";

interface IDialogConfirmMoveDuplicateNameProps {
  isShow: boolean;
  sourceContent: IContent | undefined;
  newName: string;
  loading: boolean;
  onOpenChange?: (val: boolean) => void;
  onRejectContent?: () => void;
  onAcceptContent?: () => void;
}
export default function DialogConfirmMoveDuplicateName({
  isShow,
  sourceContent,
  newName,
  loading,
  onOpenChange,
  onRejectContent,
  onAcceptContent,
}: IDialogConfirmMoveDuplicateNameProps) {
  const isFolder = useMemo(
    () => sourceContent?.type === ContentTypeEnums.FILE,
    [sourceContent]
  );
  return (
    <Dialog
      open={isShow}
      onOpenChange={onOpenChange}
      onEnterKeyPressWhenOpen={onAcceptContent}
    >
      <DialogContent className="flex flex-col w-fit max-w-full h-fit bg-white">
        <button />
        <div className="flex flex-row space-x-4">
          <div className="p-1 rounded-full bg-[#FFFBFA] h-fit">
            <div className="p-1 rounded-full bg-[#FEE4E2]">
              <InformationFilled className="w-6 h-6 text-[#D92D20]" />
            </div>
          </div>
          <div>
            <div className="text-lg font-semibold">
              Tên {isFolder ? "thư mục" : "nội dung"} đã tồn tại
            </div>
            <div className="mt-2 text-sm text-secondary-600">
              Nội dung có tên này đã tồn tại. Hệ thống sẽ di chuyển nội dung với
              tên “<span className="font-medium">{newName}</span>”, bạn có thể
              thực hiện đổi tên sau đó.
              <p className="mt-2">
                Bạn có muốn tiếp tục di chuyển{" "}
                {isFolder ? "thư mục" : "nội dung"}?
              </p>
            </div>
            <div className="space-x-2 flex justify-end pt-4">
              <Button
                variant="outline"
                onClick={onRejectContent}
              >
                Hủy bỏ
              </Button>
              <Button
                onClick={onAcceptContent}
                loading={loading}
                variant="secondary"
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
