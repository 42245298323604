import React, { FC, memo } from "react";

import { TrashCan } from "@carbon/icons-react";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import { GroupDeletedDef } from "@/features/groups/types/group.type";
import { formatDistance } from "@/utils/date";

type GroupDeletedCardProps = {
  group: GroupDeletedDef;
  onOk: () => void;
};

const GroupDeletedCard: FC<GroupDeletedCardProps> = ({ group, onOk }) => {
  return (
    <div className="py-4 border-secondary-200 border-b last:border-b-0 first:border-t flex justify-between items-center">
      <div className="flex gap-2 items-center">
        <TGNAvatar
          size="40"
          src={group.photoUrl || GroupCover}
          round="12px"
        />
        <div>
          <p className="text-secondary-900 font-medium mb-0 text-sm -mb-1">
            {group.name}
          </p>
          <div className="text-secondary-600  flex items-center gap-1">
            <span>Đã xoá</span>
            <span>{formatDistance(group.deletedAt)} </span>trước
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <Button
          className="w-full"
          variant="ghost"
          onClick={onOk}
        >
          Khôi phục
        </Button>
      </div>
    </div>
  );
};

export default memo(GroupDeletedCard);
