import React, { FC } from "react";

import { Checkbox } from "@/components/ui/checkbox";

type TGNCheckboxProps = {
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  label?: string;
  htmlFor?: string;
  onChange?: () => void;
  description?: string;
};

const TGNCheckbox: FC<TGNCheckboxProps> = ({
  label,
  description,
  checked,
  defaultChecked = false,
  disabled = false,
  htmlFor,
  onChange,
}) => {
  return (
    <div className="items-top inline-flex space-x-2">
      <Checkbox
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        id={htmlFor}
        onCheckedChange={onChange}
      />
      <div className="grid gap-0 leading-none">
        {label && (
          <label
            htmlFor={htmlFor}
            className="text-xs text-secondary-600 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {label}
          </label>
        )}
        {description && (
          <p className="text-xs text-secondary-400 font-normal text-muted-foreground">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default TGNCheckbox;
