export const COMMON_MESSAGES: any = {
  MSG_ERROR: "Đã có lỗi xảy ra",
  MSG1: "${0} không được phép để trống.",
  MSG2: "${0} đã tồn tại.",
  MSG3: "Tạo mới ${0} thành công.",
  MSG4: "Cập nhật thông tin thành công.",
  MSG5: "Xoá ${0} thành công.",
  MSG6: "Khôi phục nhóm thành công.",
  MSG7: "Không thể tải ảnh lên. Ảnh phải có kích thước nhỏ hơn 2 MB và được lưu dưới định dạng JPG, PNG, WebP.",
  MSG8: "Thiết bị được chọn không đáp ứng cấu hình yêu cầu. Vui lòng chọn lại thiết bị khác.",
  MSG9: "Vui lòng nhập Email theo định dạng sau: abc@domain.",
  MSG10: "Không thể tải lên tệp tin ${0}.Tệp tin phải có định dạng XLS, CSV.",
  MSG11: "Đồng ý yêu cầu thành công.",
  MSG12: "Đã xảy ra lỗi. Xin vui lòng thử lại.",
  MSG13: "Đồng ý thành công các yêu cầu có trạng thái “Chờ xác nhận”.",
  MSG14: "Từ chối yêu cầu thành công.",
  MSG15: "Từ chối thành công các yêu cầu có trạng thái “Chờ xác nhận”.",
  MSG16: "Từ chối thành công các yêu cầu có trạng thái “Chờ xác nhận”.",
  MSG17: "Huỷ lời mời thành công.",
  MSG18: "Huỷ yêu cầu thành công.",
  MSG19: "Mã OTP không đúng hoặc đã hết hạn. Xin vui lòng thử lại.",
  MSG20: "Gửi yêu cầu thành công.",
  MSG21: "Đã gửi %amount lời mời. ",
  MSG22: "Gửi lời mời tham gia thành công.",
  MSG23: "Đồng ý lời mời thành công.",
  MSG24: "Từ chối lời mời thành công.",
  MSG25: "Tạo thư mục ${0} thành công.",
  MSG26: "Gửi yêu cầu thành công.",
  MSG27: "Tham chiếu dữ liệu thành công.",
  MSG28: "Đã xoá thành viên ${0}.",
  MSG29: "Sai mật khẩu, vui lòng nhập lại.",
  MSG30: "Tham chiếu thư mục thành công.",
  MSG31: "Di chuyển thư mục thành công.",
  MSG32: "Di chuyển tệp tin thành công.",
  MSG33: "Tham chiếu tệp tin thành công.",
  MSG34: "Gỡ thư mục/tệp tin tham chiếu thành công.",
  MSG35: "Chia sẻ thành công",
  MSG36: "Đã gỡ ${0}.",
  MSG37: "Đã gỡ ${0}.",
  MSG38: "Đã chuyển nhượng quyền sở hữu nhóm.",
  MSG39: "Đã chuyển nhượng quyền sở hữu tổ chức.",
  MSG40: "Email này đã có trong danh sách email mời.",
  MSG41: "Không thể tải tệp tin lên. Tệp tin phải có kích thước nhỏ hơn 10MB",
  MSG42: "Đính kèm tệp tin thành công.",
  MSG43: "Tên thư mục / tệp tin đã tồn tại. Vui lòng nhập lại tên khác.",
  MSG44: "Tên trạng thái đã tồn tại. Vui lòng nhập lại tên khác.",
  MSG45: "Gỡ thiết bị thành công.",
  MSG53: "${0} không được phép vượt quá 255 ký tự.",
  MSG61: "Khóa ${0} thành công.",
  MSG62: "Mở khóa ${0} thành công.",
  MSG63: "Bạn đã là thành viên của ${0}",
  MSG64: "Bạn đã là thành viên của ${0}",
  MSG65: "Đã chấp nhận lời mời của ${0}",
  MSG66: "Đã từ chối lời mời của ${0}",
  MSG67: "Đã từ chối lời mời của ${0}",
  MSG68: "Gán chức danh thành công",
  MSG69: "Gỡ chức danh thành công",
  MSG70: "Đã yêu cầu tham gia ${0}",
  MSG71: "Đã từ chối yêu cầu của ${0}",
  MSG72: "Đã chấp nhận yêu cầu của ${0}",
  MSG73: "Đã hủy yêu cầu tham gia ${0}",
  MSG74: "Đã chấp nhận yêu cầu của ${0}",
  MSG75: "Đã từ chối yêu cầu của ${0}",
  MSG76: "Đã phê duyệt thiết bị ${0}",
  MSG77: "Đã từ chối thiết bị ${0}",
  MSG79: "Đã hủy lời mời tới ${0}",
  MSG80: "Nhóm đang bị khóa. Xin vui lòng thử lại sau.",
  MSG81: "Cập nhật thông tin thành viên thành công.",
  MSG83: "Gửi yêu cầu rời tổ chức thành công.",
  MSG84: "Đã đồng ý yêu cầu của ${0}",
  MSG85: "Đã gỡ yêu cầu rời khỏi ${0}",
  MSG86: "Cập nhật thông tin thành viên thành công",
  MSG99: "Đã hủy tham gia ${0}",
  MSGTV35: "Đã chia sẻ ${0}",
  MSGTV36: "Đã xóa ${0}",
  MSGTV37: "Đã khôi phục ${0}",
  MSG_TK1: "${0} không được bỏ trống",
  MSG_TK2: "${0} không được vượt quá ${1} ký tự",
  MSG_TK3: "${0} không đúng định dạng",
  MSG_TK4: "${0} Đã tồn tại",
  MSG_TK5:
    "Mật khẩu cần có ít nhất 8 ký tự, trong đó phải có ít nhất một chữ cái viết hoa và một ký tự đặc biệt",
  MSG_TK6: "Mật khẩu không đúng",
  MSG_TK7: "Mã OTP không hợp lệ",
  MSG_TK8: "Đăng ký tài khoản thành công",
  MSG_TK9: "Tên đăng nhập hoặc mật khẩu của bạn không đúng. Vui lòng thử lại",
  MSG_TK10: "Email hoặc số điện thoại không tồn tại. Vui lòng nhập lại",
  MSG_TK11: "Đăng nhập thành công",
  MSG_TK12: "Đặt lại mật khẩu thành công",
  MSG_TK13: "Đã kích hoạt xác thực 2 yếu tố",
  MSG_TK14: "${0} không đúng định dạng. Vui lòng nhập lại",
  MSG_TK15: "Cập nhật thông tin thành công",
  MSG_TK16: "Lưu mã QR về thiết bị thành công",
  MSG_TK17: "Hủy bảo mật 2 lớp thành công",
  MSG_TK18: "Mật khẩu hiện tại không đúng",
  MSG_TK19: "Đổi mật khẩu thành công",
  MSG_TK20: "Ảnh tải lên phải đúng một trong các định dạng sau: PNG, JPEG, JPG",
  MSG_TB21: "Bật thông báo từ ${0} ${1} thành công",
  MSG_TB22: "Tắt thông báo ${0} ${1} thành công",
  MSG_TB23: "Xóa thông báo thành công",
  MSG_TK24: "Cập nhật số điện thoại thành công",
  MSG_TK25:
    "Không thể xóa vì đây là phương thức liên hệ duy nhất của bạn trên nền tảng TAGA",
  MSG_TK26: "Đã xóa số điện thoại thành công",
  MSG_TK27: "Mã QR để đăng nhập đã hết hiệu lực",
  MSG_TK28: "Địa chỉ email đã được sử dụng",
  MSG_TK29: "Số điện thoại đã được sử dụng",
  MSG_TK30: "Cập nhật địa chỉ email thành công",
  MSG_TK31: "Đã xóa địa chỉ email thành công",
  MSG_TK32: "Đã tắt xác thực 2 yếu tố",
  MSG_TK33: "Email đã tồn tại trong hệ thống",
};

export const getMessage = (msgCode: string, ...args: string[]): string => {
  let result = COMMON_MESSAGES[msgCode] || COMMON_MESSAGES.MSG12;
  if (args?.length) {
    args.forEach((a, index) => {
      result = result.replaceAll("${" + index + "}", a);
    });
  }
  return result;
};
export const getCustomMessage = (
  msgCode: string,
  msgString: string
): string => {
  const result = COMMON_MESSAGES[msgCode].replaceAll(
    "${0}",
    ` <span class='font-semibold'>${msgString}</span>`
  );

  return result;
};
