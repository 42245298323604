import { RouteItemDef } from "@/types/app.types";

import { LibPathsEnum } from "../constants/lib.paths";
import DefaultLayout from "../layouts/DefaultLayout";
import BlankScreen from "../screens/BlankScreen/BlankScreen";
import GroupLibrary from "../screens/GroupLibrary/GroupLibrary";
import LibraryList from "../screens/LibraryList/LibraryList";
import OrgLibrary from "../screens/OrgLibrary/OrgLibrary";
import ShareWithMe from "../screens/ShareWithMe/ShareWithMe";
import TrashList from "../screens/TrashList/TrashList";

export const CLOUD_ROUTES: RouteItemDef[] = [
  {
    id: LibPathsEnum.CLOUD,
    path: LibPathsEnum.CLOUD,
    component: DefaultLayout,
    children: [
      {
        id: LibPathsEnum.CLOUD,
        path: "",
        component: LibraryList,
      },
      {
        id: LibPathsEnum.SHARE_WITH_ME,
        path: LibPathsEnum.SHARE_WITH_ME,
        component: ShareWithMe,
      },
      {
        id: LibPathsEnum.FAVORITE,
        path: LibPathsEnum.FAVORITE,
        component: LibraryList,
      },
      {
        id: LibPathsEnum.RECENTLY,
        path: LibPathsEnum.RECENTLY,
        component: LibraryList,
      },
      {
        id: LibPathsEnum.BLANK,
        path: LibPathsEnum.BLANK,
        component: BlankScreen,
      },
      {
        id: LibPathsEnum.TRASH,
        path: LibPathsEnum.TRASH,
        component: TrashList,
      },
      {
        id: LibPathsEnum.ORG_LIBRARY,
        path: LibPathsEnum.ORG_LIBRARY,
        component: OrgLibrary,
      },
      {
        id: LibPathsEnum.GROUP_LIBRARY,
        path: LibPathsEnum.GROUP_LIBRARY,
        component: GroupLibrary,
      },
    ],
  },
];
