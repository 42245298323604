import { FilterTimeTabEnums, MemberTabEnums } from "./group.menu";

export enum GroupUserRoleEnums {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
}

export enum DeviceStatusEnums {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum DeviceGroupRequestStatusEnums {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum GroupSortEnum {
  CREATED_DATE = "CREATED_DATE",
  DEFAULT = "DEFAULT",
  NAME = "NAME",
  UPDATED_DATE = "UPDATED_DATE",
}
export enum GroupSortDateEnum {
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
}
export enum GroupUserStatusEnum {
  DEFAULT = "DEFAULT",
  WAIT_ORGANIZATION_APPROVE = "WAIT_ORGANIZATION_APPROVE",
  APPROVED = "APPROVED",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
  REQUESTED = "REQUESTED",
}

export enum GroupUserTypeEnum {
  DIRECT = "DIRECT",
  INVITE = "INVITE",
  INVITE_EMAIL = "INVITE_EMAIL",
  REQUEST = "REQUEST",
}

export enum GroupTypeEnum {
  GROUP = "GROUP",
  ROOT = "ROOT",
}

export enum GroupRoleEnum {
  MANAGER = "MANAGER",
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
}

export const FILTER_TIME_TABS = [
  {
    label: "Tất cả",
    value: FilterTimeTabEnums.ALL,
  },
  {
    label: "1 tuần",
    value: FilterTimeTabEnums.ONE_WEEK,
  },
  {
    label: "1 tháng",
    value: FilterTimeTabEnums.ONE_MONTH,
  },
];

export const FILTER_ROLE_TABS = [
  {
    label: "Tất cả",
    value: MemberTabEnums.ALL,
  },
  {
    label: "Quản trị viên",
    value: `${MemberTabEnums.ADMIN},${MemberTabEnums.OWNER}`,
  },
  {
    label: "Thành viên",
    value: MemberTabEnums.MEMBER,
  },
];

export enum GroupModeEnums {
  PRIVATE = 1,
  PUBLIC = 0,
}

export enum GroupRequestStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
}

export enum GroupRequestInvite {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
}

export const FILE_CSV_EXTENSIONS = ["csv", "xls", "xlsx"];

export const SAMPLE_FILE_CSV_UPLOAD_EMAIL = [
  ["Địa chỉ email"],
  ["test_email1@gmail.com"],
  ["test_email2@gmail.com"],
];

export enum BottomActionBarValue {
  SHARE = 1,
  REMOVE = 2,
  MOVE_TO = 3,
  COPY = 4,
  DOWNLOAD = 5,
  INFO = 6,
}

export enum GroupUserRequestStatusEnum {
  WAITING = "WAITING",
}

export enum GroupUserRequestTypeEnum {
  LEAVE_REQUEST = "LEAVE_REQUEST",
}

export enum GroupSlide {
  upgrade = "upgrade",
  deviceAccepted = "deviceAccepted",
  lock = "lock",
}

export enum GroupDeviceStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
