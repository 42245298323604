import React, { Fragment, memo, useMemo } from "react";

import Divider from "@/components/Divider/Divider";
import TGNButton from "@/components/TGNButton/TGNButton";
import TGNSelect from "@/components/TGNSelect/TGNSelect";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { SelectItem } from "@/types/app.types";

import styles from "./BottomBar.module.scss";
import { ZOOM_OPTIONS, setScale } from "../../mindmap";

const BottomBar = () => {
  const {
    currentSheet: { scale },
    sheets,
  } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();
  const scaleZoom = useMemo(() => {
    return ZOOM_OPTIONS.find((option) => option.value === scale);
  }, [scale]);
  const handleZoom = (item: SelectItem) => {
    dispatch(setScale(Number(item.value)));
  };
  return (
    <div className={styles["bottom-bar"]}>
      <div className="d-flex align-items-center">
        {sheets.map((sheet) => {
          return (
            <Fragment key={sheet.id}>
              <div className={styles.sheet}>
                <span className="fs-12">Mindmap Workspace 1</span>
              </div>
              <Divider
                width={1}
                height={16}
              />
            </Fragment>
          );
        })}
        <TGNButton
          variant="ghost"
          height={40}
        >
          <i className="tgn-outline-plus fs-16"></i>
          <span className="fs-12">Add Workspace</span>
        </TGNButton>
      </div>
      <div className="d-flex align-items-center">
        <span className="fs-12 fw-semibold text-gray-900">
          Sibling-nodes: 0
        </span>
        <Divider
          className="mx-8"
          width={1}
          height={12}
        />
        <TGNSelect
          onChange={handleZoom}
          value={scaleZoom}
          itemList={ZOOM_OPTIONS}
        />
      </div>
    </div>
  );
};

export default memo(BottomBar);
