import { RouteItemDef } from "@/types/app.types";

import { NotificationPathEnum } from "../constants/notification.path";
import Notification from "../screens/Notification/Notification";

export const NOTIFICATION_ROUTES: RouteItemDef[] = [
  {
    id: NotificationPathEnum.HOME,
    path: NotificationPathEnum.HOME,
    component: Notification,
  },
];
