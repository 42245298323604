import { combineReducers } from "@reduxjs/toolkit";

import { accountReducer } from "@/features/account/account";
import authReducer from "@/features/auth/redux/auth.slice";
import { groupReducer } from "@/features/groups/group";
import { libraryReducer } from "@/features/lib/redux/lib.slice";
import { mindmapReducer } from "@/features/mindmap/mindmap";
import { notificationReducer } from "@/features/notification/redux/notification.slice";
import { workflowReducer } from "@/features/operation/redux/workflow.slice";

import { loadingReducer } from "./common.slice";

export const rootReducer = combineReducers({
  library: libraryReducer,
  auth: authReducer,
  account: accountReducer,
  mindmap: mindmapReducer,
  group: groupReducer,
  common: loadingReducer,
  notification: notificationReducer,
  workflow: workflowReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
