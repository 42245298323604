import React, { useState, useMemo } from "react";

import { Close, InformationFilled, ReplyAll } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

import FileIcon from "./FileIcon";
import { AccessType, ContentTypeEnums } from "../constants/lib.constants";
// eslint-disable-next-line import/namespace
import { IContent } from "../types/lib.types";

interface IContentDetailsProps extends React.HTMLAttributes<HTMLElement> {
  isHiddenPermissionButton?: boolean;
  isHiddenCloseButton?: boolean;
  contentDetail: IContent;
  onClose?: () => void;
  onShowChangeContentType?: () => void;
  onShowAccessRequest?: () => void;
}

export default function ContentDetails({
  className,
  isHiddenPermissionButton,
  isHiddenCloseButton,
  contentDetail,
  onClose,
  onShowChangeContentType,
  onShowAccessRequest,
}: IContentDetailsProps) {
  const [user, setUser] = useState({
    isOwner: true,
    isGuest: false,
  });
  const isRootContent = useMemo(
    () => contentDetail.parent?.type === ContentTypeEnums.LIBRARY,
    [contentDetail]
  );

  return (
    <>
      <div
        className={cn(
          "divide-y bg-white rounded-md flex flex-col flex-1  border border-gray-100 w-full min-h-[calc(100vh-180px)]",
          className
        )}
      >
        <div className="relative">
          {!isHiddenCloseButton && (
            <Button
              variant="ghost"
              className="absolute top-3 right-2 rounded-full"
              aria-label="Close"
              onClick={onClose}
            >
              <Close size={22} />
            </Button>
          )}
          <div className="flex flex-col pl-4 pr-10 py-5">
            <div className="flex flex-grow items-start space-x-2">
              <FileIcon extension={contentDetail.extension} />
              <div className="font-medium">{contentDetail.name}</div>
            </div>
          </div>
          {user.isGuest &&
          contentDetail.access_permission === AccessType.Private ? (
            <>
              {contentDetail.has_permission ? (
                <div className="border-l-[2px] border-l-[#1589EE] mx-4 my-3">
                  <div className="text-[#1589EE] bg-[#ECF5FE] py-4 px-2 text-xs">
                    Tệp tin này khả dụng với bạn.
                  </div>
                </div>
              ) : (
                <div className="border-l-[2px] border-l-[#D92D20] mx-4 my-3">
                  <div className="text-danger bg-[#FEF3F2] py-4 px-2 text-xs">
                    Tệp tin này hiện không khả dụng với bạn.
                  </div>
                </div>
              )}
            </>
          ) : null}
          {!isHiddenPermissionButton && (
            <div className="flex flex-col p-4">
              <div className="text-gray-500 text-tg-text-secondary">
                Quyền truy cập
              </div>
              Member
              {user?.isGuest ? (
                <>
                  {contentDetail.access_permission === AccessType.Private &&
                  !contentDetail.has_permission ? (
                    <Button
                      disabled={contentDetail.is_requested}
                      variant="ghost"
                      className="mt-6 bg-[#F1F2F5] font-medium"
                      onClick={onShowAccessRequest}
                    >
                      <ReplyAll className="mr-2 h-4 w-4" />{" "}
                      {contentDetail.is_requested
                        ? "Đã gửi yêu cầu"
                        : "Yêu cầu truy cập"}
                    </Button>
                  ) : null}
                </>
              ) : (
                <Button
                  variant="ghost"
                  className="mt-6 bg-[#F1F2F5] font-medium"
                  onClick={onShowChangeContentType}
                >
                  <ReplyAll className="mr-2 h-4 w-4" /> Quản lý truy cập
                </Button>
              )}
            </div>
          )}
        </div>
        <div>
          <div className="flex flex-row items-center p-4 space-x-2 text-tg-text-secondary">
            <InformationFilled className="h-4 w-4" />{" "}
            <span className="text-sm font-medium">Chi tiết</span>
          </div>
          <div className="grid grid-cols-5 px-4 pb-4 gap-2">
            <div className="col-span-2 text-xs text-tg-text-secondary">
              Lưu tại
            </div>
            <div className="col-span-3 text-xs">
              {isRootContent ? "Thư viện" : contentDetail.parent?.name}
            </div>
            <div className="col-span-2 text-xs text-tg-text-secondary">
              Tạo bởi
            </div>
            <div className="col-span-3">
              <div className="flex items-center space-x-1">
                <div className="w-[16px] min-w-[16px] h-[16px] min-h-[16px] rounded-full bg-blue-200"></div>
                <div className="text-xs truncate font-medium">
                  {/* {contentDetail.creator.email} */}
                </div>
              </div>
            </div>
            <div className="col-span-2 text-xs text-tg-text-secondary">
              Kích thước
            </div>
            <div className="col-span-3 text-xs">fomat content</div>
            <div className="col-span-2 text-xs text-tg-text-secondary">
              Loại
            </div>
            <div className="col-span-3 text-xs">
              {contentDetail.type === ContentTypeEnums.FOLDER
                ? "Thư mục"
                : "Tài liệu"}
            </div>
            {contentDetail.type === ContentTypeEnums.FOLDER && (
              <>
                <div className="col-span-2 text-xs text-tg-text-secondary">
                  Số lượng
                </div>
                <div className="col-span-3 text-xs">
                  {contentDetail.child_content_quantity} tệp tin
                </div>
              </>
            )}
            <div className="col-span-2 text-xs text-tg-text-secondary">
              Chỉnh sửa
            </div>
            <div className="col-span-3 text-xs whitespace-nowrap">
              fomat dâte
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
