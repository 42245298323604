import React, { memo, useCallback, useRef, useState } from "react";

import cx from "classnames";
import { toast, useToasterStore } from "react-hot-toast";

import Dropzone from "@/components/Dropzone/Dropzone";
import Typography from "@/components/Typography/Typography";
import { setIsCancelUpload } from "@/features/mindmap/mindmap";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import AttachFilePopup from "./AttachFilePopup/AttachFilePopup";
import CancelAction from "./AttachFilePopup/CancelAction";
import styles from "./FileManagement.module.scss";
import UploadToast from "./UploadToast";
import Attachments from "../../Attachments/Attachments";

const FileManagement = () => {
  const { mindmapIO } = useAppSelector((state) => state.mindmap);
  const dispatch = useAppDispatch();
  const { toasts } = useToasterStore();
  const inputRef = useRef<HTMLInputElement>(null);
  const [hightlight, setHightlight] = useState(false);

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!mindmapIO) {
      return;
    }
    try {
      if (e?.target.files?.length) {
        const files = e.target.files;
        await handleUploadFile(files);
      }
      e.target.value = "";
    } catch (err) {
      console.warn("err", err);
    }
  };

  const handleUploadFile = async (files: FileList) => {
    try {
      if (!mindmapIO) {
        return;
      }
      const filesPayload = Array.from(files).map((f) => ({
        name: f.name,
        type: f.type,
        size: f.size,
      }));
      const relayFilesRes = await mindmapIO.requestUpload(filesPayload);
      if (!relayFilesRes?.response?.length) return;
      const relayFiles = relayFilesRes.response;
      if (!toasts.length) {
        toast.custom(<CancelAction />, {
          position: "top-right",
          duration: 60 * 1000,
        });
      }
      for (const index of relayFiles.keys()) {
        const toastId = toast.custom(<div />, {
          position: "top-right",
          duration: 60 * 1000,
        });
        toast.custom(
          <UploadToast
            id={toastId}
            relay={relayFiles[index]}
            file={files[index]}
          />,
          {
            position: "top-right",
            duration: 60 * 1000,
            id: toastId,
          }
        );
      }
      dispatch(setIsCancelUpload(false));
    } catch (err) {
      toast.error("Đã có lỗi xảy ra");
    }
  };

  const handleClickFile = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  return (
    <div className="mt-4">
      <input
        className="d-none"
        ref={inputRef}
        type="file"
        multiple
        onChange={onSelectFile}
      />
      <div className="d-flex justify-content-between align-items-center">
        <Typography className="fs-18 fw-600">Tài liệu</Typography>
        <div className="d-flex gap-4 align-items-center">
          <div className={styles.search}>
            <i className="tgn-outline-search-02 fs-16"></i>
          </div>
          <AttachFilePopup onSelectFile={handleClickFile} />
        </div>
      </div>
      <Dropzone
        multiple
        onChange={handleUploadFile}
        startDrag={() => setHightlight(true)}
        endDrag={() => setHightlight(false)}
      >
        <div
          className={cx(styles.dropzone, hightlight && styles["drag-active"])}
        >
          <div className="d-flex justify-content-center">
            <i className="tgn-outline-upload text-primary-color-gray-10"></i>
          </div>
          <Typography className="text-primary-color-gray-10 fs-14 font-italic mt-2 text-center">
            Drop files here to attach or{" "}
            <span className="fw-600 text-decoration-underline">browse</span>
          </Typography>
          <Typography className="fs-12 mt-2 text-secondary-color-gray-7 text-center">
            Max file size is 50MB. Supported file types are file-name.json,
            .doc, .xlsx or image.
          </Typography>
        </div>
      </Dropzone>
      <Attachments />
    </div>
  );
};

export default memo(FileManagement);
