import React, { FC, memo } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { CommentDef } from "@/features/mindmap/mindmap";
import { formatDistanceToNowByDate } from "@/utils/date";

type CommentLineProps = {
  comment: CommentDef;
};

const CommentLine: FC<CommentLineProps> = ({ comment }) => {
  return (
    <div className="d-flex gap-4">
      <TGNAvatar
        size="20"
        name={comment.user.name}
        round
        src={comment.user.photo}
      />
      <div>
        <span className="fs-14 fw-semibold me-2">{comment.user.name}</span>
        <span className="fs-12 text-gray-600">
          {formatDistanceToNowByDate(Number(comment.issueAt), {
            addSuffix: true,
          })}
        </span>
        <br />
        <span className="fs-14 text-gray-900">{comment.content}</span>
      </div>
    </div>
  );
};

export default memo(CommentLine);
