import React, { memo, useRef, DragEvent, FC, ReactNode } from "react";

type DropzoneProps = {
  multiple?: boolean;
  children: ReactNode;
  onChange: (files: FileList) => void;
  startDrag?: () => void;
  endDrag?: () => void;
  accept?: string;
};

const Dropzone: FC<DropzoneProps> = ({
  multiple = false,
  children,
  accept = "",
  onChange,
  startDrag,
  endDrag,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (startDrag) {
      startDrag();
    }
  };

  const handleLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (endDrag) {
      endDrag();
    }
  };
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (endDrag) {
      endDrag();
    }
    if (e.target.files?.length) {
      onChange(e.target.files);
    }
  };
  const handleDrop = (
    e: React.ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>
  ) => {
    if (endDrag) {
      endDrag();
    }
    e.preventDefault();
    if ("files" in e.target) {
      if (e.target.files) {
        onChange(e.target.files);
      }
    } else if ("dataTransfer" in e) {
      onChange(e.dataTransfer.files);
    }
  };
  const handleClickFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  return (
    <div
      onDragEnter={handleEnter}
      onDragOver={handleEnter}
      onDragLeave={handleLeave}
      onDrop={handleDrop}
    >
      <div
        className="cursor-pointer"
        onClick={handleClickFile}
      >
        {children}
      </div>
      <div>
        <input
          ref={inputRef}
          type="file"
          className="d-none"
          onChange={(e) => handleUpload(e)}
          multiple={multiple}
          accept={accept}
        />
      </div>
    </div>
  );
};

export default memo(Dropzone);
