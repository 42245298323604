import React, { memo, useMemo } from "react";

import Avatar from "react-avatar";

import TGNTable from "@/components/TGNTable/TGNTable";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";

import styles from "./Attachments.module.scss";
import { FileType, requestDownloadFile } from "../../mindmap";

const Attachments = () => {
  const { revisions, selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const dispatch = useAppDispatch();

  const dataTable = useMemo(() => {
    return revisions
      .filter(
        (revision) =>
          revision.stepId === selectedNode?.data.id &&
          revision.extensionData &&
          revision.extensionData.type === FileType.FILE
      )
      .sort((a, b) => Number(b.issuedAt) - Number(a.issuedAt))
      .map((revision) => {
        return {
          id: revision.issuedAt,
          name: (
            <span
              role="presentation"
              className="cursor-pointer"
              onClick={() => dispatch(requestDownloadFile(revision))}
            >
              {revision.fileName}
            </span>
          ),
          updated_at: formatDistance(Number(revision.issuedAt)),
          created_by: (
            <Avatar
              className={styles.avatar}
              size="24"
              name={revision.userId}
              round
            />
          ),
          delete: (
            <div className="d-flex align-items-center cursor-pointer">
              <i className="tgn-outline-trash text-gray-c4 fs-18"></i>
            </div>
          ),
        };
      });
  }, [revisions, selectedNode, dispatch]);

  return (
    <div className="mt-4">
      <TGNTable
        columns={[
          {
            label: "Name",
            value: "name",
            isSort: true,
          },
          {
            label: "Modified",
            value: "updated_at",
            isSort: true,
          },
          {
            label: "By",
            value: "created_by",
            isSort: true,
          },
          {
            label: "",
            value: "delete",
          },
        ]}
        data={dataTable}
      />
    </div>
  );
};

export default memo(Attachments);
