import { RouteItemDef } from "@/types/app.types";

import { MindmapPathsEnum } from "../constants/mindmap.paths";
import MindmapScreen from "../screens/mindmap/MindmapScreen";

export const MINDMAP_SCREEN: RouteItemDef[] = [
  {
    id: "mindmap_index",
    path: MindmapPathsEnum.MINDMAP,
    component: MindmapScreen,
    permissions: ["ADMIN", "OWNER"],
  },
];

export const MINDMAP_ROUTES = [...MINDMAP_SCREEN];
