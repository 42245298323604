"use client";

import * as React from "react";
import { useEffect } from "react";

import { Close } from "@carbon/icons-react";
import { Command as CommandPrimitive } from "cmdk";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Badge } from "@/components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";

// eslint-disable-next-line import/namespace
import { ICreator } from "../types/lib.types";

export interface IComboboxMemberItem extends ICreator {
  shared?: boolean;
}

function ComboboxMembersItemCreator({
  creator,
}: {
  creator: IComboboxMemberItem;
}) {
  if (!creator) return null;
  return (
    <div className="flex flex-row items-center space-x-2">
      <TGNAvatar
        size="32"
        src={creator.photo || creator.email || ""}
        userName={creator.name ?? creator.email}
      />
      <div className="flex flex-col">
        <div className="font-medium">
          {creator.name || creator.username || creator.email || "Taga User"}
        </div>
        <div className="text-xs text-tg-text-secondary">
          {creator.shared
            ? "Đã được thêm vào danh sách thành viên truy cập"
            : creator.email}
        </div>
      </div>
    </div>
  );
}

interface IComboboxMembersProps {
  placeholder: string;
  options: IComboboxMemberItem[];
  onSelected: (val: IComboboxMemberItem[]) => void;
}

export function ComboboxMembers({
  options,
  placeholder,
  onSelected,
}: IComboboxMembersProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<IComboboxMemberItem[]>([]);
  const [inputValue, setInputValue] = React.useState("");

  const setLowerCase = (email: string) => {
    setInputValue(email.toLowerCase());
  };
  const handleUnselect = React.useCallback((creator: IComboboxMemberItem) => {
    setSelected((prev) => prev.filter((s) => s.email !== creator.email));
  }, []);
  useEffect(() => {
    onSelected(selected);
  }, [selected]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if (e.key === "Delete" || e.key === "Backspace") {
          if (input.value === "") {
            setSelected((prev) => {
              const newSelected = [...prev];
              newSelected.pop();
              return newSelected;
            });
          }
        }
        // This is not a default behaviour of the <input /> field
        if (e.key === "Escape") {
          input.blur();
        }
      }
    },
    []
  );

  const selectables = options.filter((content) => !selected.includes(content));
  return (
    <Command
      onKeyDown={handleKeyDown}
      className="overflow-visible bg-transparent"
      filter={(value, search) => {
        if (value.includes(search)) return 1;
        return 0;
      }}
    >
      <div className="group border border-input px-3 py-2 text-sm ring-offset-background rounded-md">
        <div className="flex gap-1 flex-wrap">
          {selected.map((creator) => {
            return (
              <Badge
                key={creator.id}
                variant="ghost"
                className="rounded-sm px-1 py-0"
              >
                {creator.email}
                <button
                  className="ml-1 ring-offset-background outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUnselect(creator);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => handleUnselect(creator)}
                >
                  <Close className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            );
          })}
          {/* Avoid having the "Search" Icon */}
          <CommandPrimitive.Input
            placeholder={placeholder}
            ref={inputRef}
            value={inputValue}
            onValueChange={setLowerCase}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            className="ml-2 bg-transparent outline-none placeholder:text-secondary-400 flex-1"
          />
        </div>
      </div>
      <div className="relative mt-2">
        {open && selectables.length > 0 ? (
          <div className="absolute w-full z-10 top-0 rounded-md border bg-white text-popover-foreground shadow-md outline-none animate-in">
            <CommandGroup className="h-full overflow-auto  max-h-[300px]  ">
              {selectables.map((creator, index) => {
                return (
                  <CommandItem
                    disabled={creator.shared}
                    key={creator.id}
                    value={creator.email}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onSelect={(value) => {
                      setLowerCase("");
                      setSelected((prev) => [...prev, creator]);
                    }}
                    className={
                      creator.shared
                        ? "opacity-60"
                        : "cursor-pointer  hover:bg-secondary-200"
                    }
                  >
                    <ComboboxMembersItemCreator creator={creator} />
                  </CommandItem>
                );
              })}
            </CommandGroup>
            <CommandEmpty>
              <div>Không có kết quả tìm kiếm phù hợp</div>
            </CommandEmpty>
          </div>
        ) : null}
      </div>
    </Command>
  );
}
