import React, { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";

import { Empty } from "@/assets/icons";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { useToast } from "@/components/ui/use-toast";
import { useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import CardItem from "./components/CardItem";
import { searchGroup } from "../../api/group.api";
import SearchModal from "../../components/SearchModal/SearchModal";
import { GroupDef } from "../../types/group.type";

const GroupsScreen = () => {
  const { toast } = useToast();

  const { keyword } = useParams();
  const [myGroups, setMyGroups] = useState<GroupDef[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const loading = useAppSelector((state) => state.group.loading);

  useEffect(() => {
    fetchMyGroups(page);
  }, [keyword, loading]);

  const fetchMyGroups = async (page: number) => {
    setMyGroups([]);
    try {
      const result = await searchGroup({
        keyword: keyword,
        pageNumber: page,
        pageSize: pageSize,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
    //  finally {
    //   setIsLoadMore(false);
    // }
  };
  const fetchMyGroupsScroll = async (page: number) => {
    try {
      setIsLoadMore(true);
      const result = await searchGroup({
        keyword: keyword,
        pageNumber: page,
        pageSize: pageSize,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroups(() => [...myGroups, ...result.data]);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  return (
    <>
      <div className="container sticky top-[70px] bg-white">
        <div className="py-3 flex items-center justify-between">
          <p className="text-xs font-medium text-secondary-600">
            <span className="text-secondary-400">
              Nhóm / Kết quả tìm kiếm cho
            </span>{" "}
            <span className="text-secondary-600">{keyword}</span>
          </p>
          <SearchModal />
        </div>
      </div>
      <div className="h-px w-full bg-secondary-100 sticky top-[70px]"></div>
      <div className="container pb-4">
        <div className="flex justify-between">
          <div className="py-5 flex">
            <p className="font-semibold text-secondary-900 mr-2">
              Kết quả tìm kiếm
            </p>
          </div>
        </div>
      </div>
      <div className="container pb-4">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-end-11">
            <InfiniteScroll
              style={{
                height: "unset",
                overflow: "unset",
              }}
              className="px-0 mb-12"
              dataLength={myGroups.length}
              next={() => {
                setPage((prev) => prev + 1);
                fetchMyGroupsScroll(page + 1);
              }}
              hasMore={isLoadMore}
              loader={
                <div className="flex items-center justify-center mt-6">
                  <TGNLoading size="large" />
                </div>
              }
            >
              {myGroups.length === 0 ? (
                <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
                  <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                    <Empty />
                    <span className="text-secondary-600 text-xs mt-4 font-semibold">
                      0 kết quả phù hợp
                    </span>
                  </div>
                </div>
              ) : (
                <div className="grid gap-4">
                  <div className="grid gap-4">
                    {myGroups.map((group, index) => {
                      return (
                        <CardItem
                          group={group}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupsScreen;
