import { RouteItemDef } from "@/types/app.types";

import { OperationPathsEnum } from "../constants/operation.paths";
import OperationLayout from "../layouts/Layout";
import TaskGroup from "../screens/OperationGroup";
import TaskScreen from "../screens/OperationScreen";

export const OPERATION_ROUTES: RouteItemDef[] = [
  {
    id: OperationPathsEnum.OPERATION,
    path: OperationPathsEnum.OPERATION,
    component: OperationLayout,
    children: [
      {
        id: OperationPathsEnum.OPERATION,
        path: OperationPathsEnum.OPERATION,
        component: TaskScreen,
      },
      {
        id: OperationPathsEnum.OPERATION_ORG,
        path: OperationPathsEnum.OPERATION_ORG,
        component: TaskGroup,
      },
    ],
  },
];
