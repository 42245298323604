import React, { FC, memo, useEffect, useState } from "react";

import { ViewFilled, ViewOffFilled } from "@carbon/icons-react";
import { isAxiosError } from "axios";

import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { getMessage } from "@/utils/message";

import FileIcon, { FileTypes, getFileTypeByExtension } from "./FileIcon";
import RecentContentItem from "./RecentContentItem";
import { getRecentContent } from "../api/lib.api";
import { FolderMenuActions } from "../constants/lib.constants";
import { IContent, RecentContentDef } from "../types/lib.types";

type RecentContentProps = {
  onMenuClick: (action: FolderMenuActions, content: IContent) => void;
};

const RecentContent: FC<RecentContentProps> = ({ onMenuClick }) => {
  const [open, setOpen] = useState(false);
  const [recentContents, setRecentContents] = useState<RecentContentDef[]>([]);
  const { libraryId } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const { toast } = useToast();
  useEffect(() => {
    if (libraryId) {
      fetchData();
    }
  }, [libraryId]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getRecentContent(libraryId);
      setRecentContents(result.data);
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoading(false);
    }
  };
  if (recentContents.length === 0) {
    return null;
  }
  return (
    <div className="">
      <p className="flex items-center font-medium text-xs my-4 gap-1 text-secondary-600">
        Được đề xuất{" "}
        {show ? (
          <ViewFilled
            size={16}
            onClick={() => setShow(!show)}
            className="cursor-pointer"
          />
        ) : (
          <ViewOffFilled
            size={16}
            onClick={() => setShow(!show)}
            className="cursor-pointer"
          />
        )}
      </p>
      {show && (
        <div className="flex flex-1 overflow-x-auto">
          <div className="flex flex-row gap-4">
            {recentContents.map((item) => (
              <RecentContentItem
                key={item.content.id}
                recentContent={item}
                onMenuClick={(action) => onMenuClick(action, item.content)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(RecentContent);
