import React, { FC, memo, useMemo } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNCheckbox from "@/components/TGNCheckboxRadio/TGNCheckbox";
import TGNSelect from "@/components/TGNSelect/TGNSelect";
import Typography from "@/components/Typography/Typography";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  AssignRolesEnum,
  AssigneeRolesOptions,
  UpdateDescriptionType,
  removeAssignee,
  updateAssignee,
  updateStyleNode,
} from "@/features/mindmap/mindmap";
import { useAppDispatch } from "@/redux/store";
import { SelectItem } from "@/types/app.types";
import { getUrlImage } from "@/utils/image";

type AssigneeItemProps = {
  assignee: {
    user: UserDef;
    role: AssignRolesEnum | null;
  };
};

const AssigneeItem: FC<AssigneeItemProps> = ({ assignee }) => {
  const dispatch = useAppDispatch();
  const assignRoleSelected = useMemo(() => {
    return AssigneeRolesOptions.find((item) => item.value === assignee.role);
  }, [assignee]);
  const checked = useMemo(() => {
    return Boolean(assignee.role);
  }, [assignee]);
  const onChangeCheckbox = () => {
    if (checked) {
      dispatch(removeAssignee({ username: assignee.user.username }));
    } else {
      dispatch(
        updateAssignee({
          username: assignee.user.username,
          role: AssignRolesEnum.MEMBER,
        })
      );
    }
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.ASSIGNEES,
      })
    );
  };
  const onChangeRole = (e: SelectItem) => {
    dispatch(
      updateAssignee({
        username: assignee.user.username,
        role: e?.value as AssignRolesEnum | null,
      })
    );
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.ASSIGNEES,
      })
    );
  };

  return (
    <div
      className="d-flex align-items-center gap-4 mt-12 mb-8"
      key={assignee.user.id}
    >
      <TGNCheckbox
        checked={checked}
        label=""
        onChange={onChangeCheckbox}
      />
      <TGNAvatar
        name={assignee.user.name}
        round
        size="40"
        src={getUrlImage(assignee.user.photo)}
      />
      <div className="flex-1">
        <Typography
          className="text-primary-color-gray-10 fs-14 text-truncate"
          style={{
            maxWidth: 170,
          }}
        >
          {assignee.user.name || assignee.user.username}
        </Typography>
        <span
          className="fs-12 text-tertiary-color-gray-5 text-truncate"
          style={{
            maxWidth: 170,
          }}
        >
          {assignee.user.username}
        </span>
      </div>
      <TGNSelect
        itemList={AssigneeRolesOptions}
        variant="text"
        placeholder="-"
        value={assignRoleSelected}
        disabled={!checked}
        onChange={(e) => onChangeRole(e)}
      />
    </div>
  );
};

export default memo(AssigneeItem);
