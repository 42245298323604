import React, { memo, useState } from "react";

import { DateRangePicker, RangeKeyDict } from "react-date-range";
import * as locales from "react-date-range/dist/locale";

import Divider from "@/components/Divider/Divider";
import { FormatDateEnums } from "@/constants/date.constants";
import {
  UpdateDescriptionType,
  updateStyleNode,
} from "@/features/mindmap/mindmap";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDate } from "@/utils/date";

const DatePopup = () => {
  const { selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    selection: {
      startDate: selectedNode?.data.description.intendTime?.start
        ? new Date(selectedNode?.data.description.intendTime?.start || "")
        : new Date(),
      endDate: selectedNode?.data.description.intendTime?.end
        ? new Date(selectedNode?.data.description.intendTime?.end || "")
        : new Date(),
      key: "selection",
    },
  });
  const handleChange = (item: RangeKeyDict) => {
    const newDate = { ...state, ...item };
    setState(newDate);
    dispatch(
      updateStyleNode({
        type: UpdateDescriptionType.INTEND_TIME,
        intendTime: {
          start: formatDate(
            newDate.selection.startDate,
            FormatDateEnums.MONTH_DATE
          ),
          end: formatDate(
            newDate.selection.endDate,
            FormatDateEnums.MONTH_DATE
          ),
        },
      })
    );
  };

  return (
    <div>
      <div className="d-flex align-items-center gap-2">
        <div
          className="d-flex align-items-center gap-1"
          style={{
            width: 131,
          }}
        >
          <i className="tgn-outline-calendar-minus fs-20 text-tertiary-color-gray-5"></i>
          <span className="fs-14 text-placeholder-color-gray-4">
            {formatDate(state.selection.startDate)}
          </span>
        </div>
        <Divider
          height={20}
          width={1}
        />
        <div className="d-flex align-items-center gap-1">
          <i className="tgn-outline-calendar-minus fs-20 text-tertiary-color-gray-5"></i>
          <span className="fs-14 text-placeholder-color-gray-4">
            {formatDate(state.selection.endDate)}
          </span>
        </div>
      </div>
      {/* <DateRangePicker
        className="date"
        onChange={(item) => handleChange(item)}
        months={1}
        ranges={[state.selection]}
        moveRangeOnFirstSelection={false}
        inputRanges={[]}
        showDateDisplay={false}
        weekStartsOn={1}
        locale={locales.vi}
      /> */}
    </div>
  );
};

export default memo(DatePopup);
