import React, { memo, useEffect, useState } from "react";

import TGNButton from "@/components/TGNButton/TGNButton";
import { useAppSelector } from "@/redux/store";

import styles from "./OverviewTab.module.scss";
import Section from "../Section/Section";

const OverviewTab = () => {
  const { sections } = useAppSelector((state) => state.mindmap);
  const [isCreateSection, setIsCreateSection] = useState(false);

  useEffect(() => {
    setIsCreateSection(false);
  }, [sections.length]);

  return (
    <>
      <div>
        {sections.length === 0 && <Section />}
        {sections.map((section) => (
          <Section
            key={section.id}
            section={section}
          />
        ))}
        {isCreateSection && <Section />}
        <div className="d-flex align-items-center">
          <TGNButton
            variant="ghost"
            onClick={() => setIsCreateSection(true)}
          >
            <i className="tgn-outline-plus text-tertiary-color-gray-5"></i>
            <span className="fs-14 text-tertiary-color-gray-5">
              New Section
            </span>
          </TGNButton>
          <div className={styles.dashed}></div>
        </div>
      </div>
    </>
  );
};

export default memo(OverviewTab);
