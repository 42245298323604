import React, { memo, useCallback, useEffect, useState } from "react";

import {
  ArrowLeft,
  Close,
  DataStructured,
  Download,
  Folders,
  HelpFilled,
  InformationFilled,
  UserMultiple,
  WarningFilled,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import NoConnection from "@/assets/images/slide/NoConnection.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
  DialogDescription,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import {
  deleteGroup,
  deleteSubGroup,
  getConfirmInfoDelete,
  getMemberTransferOwner,
  memberTransferOwnerRequest,
} from "@/features/groups/api/group.api";
import { GroupUserRoleEnums } from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import {
  getGroupInfo,
  getSuggestTransformMembersThunk,
  setGroup,
} from "@/features/groups/group";
import {
  GroupInfoDelete,
  IMemberGroup,
} from "@/features/groups/types/group.type";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import AssignOwnerRoleVerify from "./AssignOwnerRoleVerify";

const AdvanceSetting = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { data: groupInfo, suggestTransformMembers } = useAppSelector(
    (state) => state.group
  );
  const [enableDeletedButton, setEnableDeletedButton] = useState(false);
  const [groupDeleteInfo, setGroupDeleteInfo] =
    useState<null | GroupInfoDelete>(null);
  const [openPopupAssign, setOpenPopupAssign] = useState(false);
  const [assignStep, setAssignStep] = useState(1);
  const [suggestMembers, setSuggestMembers] = useState<IMemberGroup[] | []>(
    suggestTransformMembers
  );
  const [selectedMember, setSelectedMember] = useState<IMemberGroup | null>(
    null
  );
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [openPopupVerify, setOpenPopupVerify] = useState(false);
  const [uid, setUid] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchInfiniteScroll = async (pageScroll?: number) => {
    if (groupInfo) {
      const result = await getMemberTransferOwner({
        groupId: groupInfo.id,
        pageNumber: pageScroll,
        pageSize: 9,
      });
      setSuggestMembers(() => [...suggestMembers, ...result.data]);
      if (result.data.length < 9) {
        setIsLoadMore(false);
      }
    }
  };

  const fetchSuggestMembers = useCallback(() => {
    if (groupInfo) {
      dispatch(
        getSuggestTransformMembersThunk({
          pageNumber: 1,
          pageSize: 9,
          groupId: groupInfo.id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          setSuggestMembers(result);
          if (!result.length || result.length < 9) {
            setIsLoadMore(false);
          }
        });
    }
  }, [dispatch, groupInfo]);

  useEffect(() => {
    fetchSuggestMembers();
  }, [fetchSuggestMembers]);

  const handleGetInfoGroup = async () => {
    setGroupDeleteInfo(null);
    try {
      if (groupInfo) {
        const info = await getConfirmInfoDelete(groupInfo.id);
        setGroupDeleteInfo(info.data);
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  const handleDeleteGroup = async () => {
    try {
      if (groupInfo) {
        dispatch(
          loadingAction.show({
            title: `Đang xoá ${groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}`,
            description:
              "Quá trình này có thể mất một vài phút, vui lòng chờ tới thiết lập hoàn tất.",
          })
        );
        if (groupInfo.rootGroupId) {
          await deleteSubGroup(groupInfo.id);
        } else {
          await deleteGroup(groupInfo.id);
        }
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <p>
              Đã xoá {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
              {groupInfo.name}
            </p>
          ),
        });
        dispatch(setGroup(null));
        if (groupInfo.rootGroupId) {
          navigate(GroupPathsEnum.REMOVE_GROUP_SUCCESS);
        } else {
          navigate(GroupPathsEnum.GROUP);
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };
  const handleAssignOwner = async () => {
    if (groupInfo?.rootGroupId) {
      dispatch(
        loadingAction.show({
          title: "Đang chuyển giao...",
          description:
            "Quá trình này có thể mất tới vài phút. Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
    }
    setLoading(true);
    try {
      if (!groupInfo || !selectedMember) {
        return;
      }
      const result = await memberTransferOwnerRequest({
        groupId: groupInfo.id,
        userId: selectedMember.userId,
      });
      if (result.data.uid) {
        setUid(result.data.uid);
        setOpenPopupVerify(true);
      } else {
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG38"),
        });
        navigate(
          GroupPathsEnum.GROUP_DETAIL.replace(":id", String(groupInfo.id))
        );
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      setLoading(false);
      setOpenPopupAssign(false);
      setAssignStep(1);
      dispatch(loadingAction.hide());
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue === groupInfo?.name) {
      setEnableDeletedButton(true);
    } else {
      setEnableDeletedButton(false);
    }
  };
  return (
    <>
      {groupInfo && (
        <div className="mt-6">
          <p className="text-base font-semibold text-secondary-900">
            Thiết lập nâng cao
          </p>
          <p className="text-xs text-secondary-600 flex items-center">
            <InformationFilled className="mr-1 inline-block" /> Tìm hiểu thêm về
            những tính năng thiết lập nâng cao dành cho{" "}
            {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}.↗
          </p>
          {groupInfo.role?.code === GroupUserRoleEnums.OWNER && (
            <div>
              <p className="text-secondary-600 uppercase font-medium text-xs mt-6">
                Quyền sở hữu
              </p>
              <div className="mt-4 rounded-lg p-6 bg-white">
                <p className="text-sm font-medium text-secondary-600 mb-2">
                  Chuyển quyền sở hữu{" "}
                  {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}
                </p>
                <p className="text-xs text-secondary-600 mb-6">
                  {groupInfo.rootGroupId
                    ? "Hệ thống sẽ tiến hành chuyển quyền sở hữu nhóm cho thành viên được chọn. Bạn sẽ trở thành Quản trị viên của tổ chức này. Tìm hiểu thêm↗"
                    : "Chuyển quyền sở hữu cho một thành viên thuộc Tổ chức của bạn, mọi dữ liệu sẽ được truyền tải sang thiết bị lưu trữ của chủ sở hữu mới. Vui lòng chuẩn bị một tài khoản còn trống ít nhất 15 GB không gian bộ nhớ."}{" "}
                </p>
                <div>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenPopupAssign(true)}
                  >
                    Chuyển nhượng
                  </Button>
                </div>
              </div>
              <Dialog open={openPopupAssign}>
                <DialogContent>
                  <DialogHeader className="relative">
                    {assignStep === 3 && (
                      <Button
                        onClick={() => {
                          setAssignStep((pre) => pre - 1);
                        }}
                        variant="ghost"
                        size="sm"
                        className="absolute top-0 left-0 rounded-full w-[36px]"
                      >
                        <ArrowLeft />
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        setOpenPopupAssign(false);
                        setAssignStep(1);
                        setSelectedMember(null);
                      }}
                      variant="ghost"
                      size="sm"
                      className="absolute top-0 right-0 rounded-full w-[36px]"
                    >
                      <Close />
                    </Button>
                    <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
                      Chuyển quyền sở hữu{" "}
                      {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}
                    </DialogTitle>
                  </DialogHeader>
                  {assignStep === 1 && (
                    <>
                      <div className="flex justify-center mt-10">
                        <img
                          className="block w-[100px]"
                          src={NoConnection}
                          alt=""
                        />
                      </div>
                      <DialogDescription className="text-xl font-semibold text-center">
                        Bạn chắc chắn chuyển quyền sở hữu{" "}
                        {groupInfo.rootGroupId ? "nhóm" : "tổ chức"} của mình
                        cho người khác?
                      </DialogDescription>
                      {!groupInfo.rootGroupId ? (
                        <>
                          <p className="text-base text-center">
                            TAGA sẽ tiến hành chuyển giao toàn bộ dữ liệu tổ
                            chức của bạn sang cho chủ sở hữu tổ chức mới. Hệ
                            thống yêu cầu thiết bị của chủ sở hữu mới còn trống
                            ít nhất 15GB không gian bộ nhớ.{" "}
                            <span className="font-semibold">
                              Tìm hiểu thêm↗
                            </span>
                          </p>
                          <p className="text-base text-center">
                            Bạn sẽ trở thành Quản trị viên của tổ chức này.
                          </p>
                        </>
                      ) : (
                        <p>
                          Hệ thống sẽ tiến hành chuyển quyền sở hữu nhóm cho
                          thành viên được chọn. Bạn sẽ trở thành Quản trị viên
                          của nhóm này.{" "}
                          <span className="font-semibold">Tìm hiểu thêm↗</span>
                        </p>
                      )}
                    </>
                  )}
                  {assignStep === 2 && (
                    <div>
                      <Input placeholder="Nhập tên thành viên" />
                      <p className="text-xs text-secondary-600 my-2">
                        Gợi ý・{suggestMembers.length}
                      </p>
                      <InfiniteScroll
                        style={{
                          height: "unset",
                          overflow: "unset",
                        }}
                        className="px-0"
                        dataLength={suggestMembers.length}
                        next={() => {
                          setPage((prev) => prev + 1);
                          fetchInfiniteScroll(page);
                        }}
                        hasMore={isLoadMore}
                        loader={
                          <div className="flex items-center justify-center mt-6">
                            <TGNLoading size="large" />
                          </div>
                        }
                      >
                        <div>
                          {suggestMembers.map((member, index) => {
                            return (
                              <div
                                key={index}
                                className="flex gap-2 items-center px-3 py-2 -mx-3 hover:bg-secondary-50 group"
                              >
                                <TGNAvatar
                                  size="40"
                                  userName={
                                    member.alias !== ""
                                      ? member.alias
                                      : member.name
                                  }
                                  userPhoto={member.photoUrl}
                                />
                                <div>
                                  <p className="font-medium text-sm">
                                    {member.alias
                                      ? member.alias
                                      : member.name ?? member.email}
                                  </p>
                                  <p className="text-sm text-secondary-600">
                                    {member.email}
                                  </p>
                                </div>
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  className="ml-auto hidden group-hover:block"
                                  onClick={() => {
                                    setSelectedMember(member);
                                    setAssignStep(3);
                                  }}
                                >
                                  Nhượng quyền
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      </InfiniteScroll>
                    </div>
                  )}
                  {assignStep === 3 && (
                    <p className="text-center">
                      Bạn chắc chắn chuyển quyền sở hữu{" "}
                      {groupInfo.rootGroupId ? "nhóm " : "tổ chức "}
                      <span className="font-semibold">
                        “{groupInfo.name}”
                      </span>{" "}
                      cho thành viên{" "}
                      <span className="font-semibold">
                        {selectedMember?.alias ?? selectedMember?.name}
                      </span>
                      ?
                    </p>
                  )}
                  {assignStep !== 2 && (
                    <DialogFooter>
                      <Button
                        className="w-full mt-6"
                        size="sm"
                        loading={loading}
                        onClick={() => {
                          if (assignStep === 1) {
                            setAssignStep((pre) => pre + 1);
                          } else {
                            handleAssignOwner();
                          }
                        }}
                      >
                        {assignStep === 3
                          ? groupInfo.rootGroupId
                            ? "Xác nhận"
                            : "Tiếp tục"
                          : "Tiếp theo"}
                      </Button>
                    </DialogFooter>
                  )}
                </DialogContent>
              </Dialog>
              {selectedMember && (
                <AssignOwnerRoleVerify
                  openFn={setOpenPopupVerify}
                  user={selectedMember}
                  uid={uid}
                  open={openPopupVerify}
                />
              )}
            </div>
          )}
          <div className="mt-10">
            <p className="text-secondary-600 uppercase font-medium text-xs mt-6">
              Sao lưu dữ liệu
            </p>
            <div className="mt-4 rounded-lg p-6 bg-white">
              <p className="text-sm font-medium text-secondary-600 mb-2">
                Tải xuống dữ liệu của{" "}
                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}
              </p>
              <p className="text-xs text-secondary-600 mb-6">
                TAGA không quản lý và lưu trữ dữ liệu Tổ chức/Doanh nghiệp, bạn
                vui lòng tải xuống thông tin Tổ chức phục vụ mục đích sao lưu và
                khôi phục. <span className="font-medium">Tìm hiểu thêm↗</span>
              </p>
              <div>
                <Button
                  variant="secondary"
                  iconBefore={<Download />}
                >
                  Tải xuống
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <p className="text-secondary-600 font-medium text-xs uppercase mb-4">
              Vùng nguy hiểm
            </p>
            <div className="p-6 bg-error-25 rounded-lg border border-error-200">
              <div className="flex gap-4">
                <div>
                  <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                    <InformationFilled size={20} />
                  </span>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-secondary-600">
                    Hành động này sẽ xoá{" "}
                    {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                    <span className="font-semibold text-primary-600">
                      {groupInfo.name}
                    </span>{" "}
                    và mọi dữ liệu liên quan khỏi nền tảng!
                  </p>
                  <p className="text-xs text-secondary-600 mb-6">
                    Hãy chắc chắn rằng bạn đã tạo một bản sao lưu trong trường
                    hợp muốn giữ lại dữ liệu tổ chức của mình.
                  </p>
                  <div className="flex items-center gap-3">
                    <Dialog
                      onOpenChange={() => {
                        setEnableDeletedButton(false);
                      }}
                    >
                      <DialogTrigger>
                        <div>
                          <Button
                            variant="destructive"
                            onClick={handleGetInfoGroup}
                          >
                            Xóa {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                          </Button>
                        </div>
                      </DialogTrigger>
                      <DialogContent className="gap-6">
                        <DialogHeader>
                          <DialogTitle>
                            Xoá {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                            {groupInfo.name}?
                          </DialogTitle>
                        </DialogHeader>
                        <div className="p-4 rounded bg-warning-25">
                          <div className="flex gap-5">
                            <div>
                              <span className="border-[6px] p-1 text-warning-500 border-warning-50 bg-warning-100 rounded-full block">
                                <WarningFilled size={20} />
                              </span>
                            </div>
                            <div>
                              <p className="text-sm text-secondary-900 font-medium mb-2">
                                Bạn sắp xóa{" "}
                                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}{" "}
                                này, bao gồm:
                              </p>
                              {groupDeleteInfo ? (
                                <>
                                  <p className="text-secondary-600 mb-2 text-sm">
                                    <DataStructured className="mr-2 inline" />
                                    {groupDeleteInfo.subGroupCounter} Nhóm trực
                                    thuộc
                                  </p>
                                  <p className="text-secondary-600 mb-2 text-sm">
                                    <UserMultiple className="mr-2 inline" />
                                    {groupDeleteInfo.memberCounter} Thành viên
                                  </p>
                                  <p className="text-secondary-600 mb-2 text-sm">
                                    <Folders className="mr-2 inline" />
                                    {groupDeleteInfo.contentCounter} Tệp tin
                                  </p>
                                </>
                              ) : (
                                <TGNLoading />
                              )}
                            </div>
                          </div>
                          <p className="text-secondary-900 text-sm mt-6">
                            Thao tác này sẽ xoá vĩnh viễn tài nguyên của{" "}
                            {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} và tất
                            cả dữ liệu liên quan. Hãy chắc chắn rằng bạn đã tạo
                            một bản sao lưu mới nhất trong trường hợp muốn giữ
                            lại dữ liệu, thiết đặt{" "}
                            {groupInfo.rootGroupId ? "nhóm" : "tổ chức"} của
                            mình.
                            <span className="font-medium">Tìm hiểu thêm↗</span>
                          </p>
                        </div>
                        <div>
                          <p className="text-secondary-900 text-sm font-medium mb-2">
                            Để xác minh hành động, vui lòng nhập tên của{" "}
                            {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}:
                          </p>
                          <Input onChange={(e) => handleInputChange(e)} />
                        </div>
                        <DialogFooter>
                          <DialogClose asChild>
                            <div>
                              <Button variant="ghost">
                                Hủy, giữ{" "}
                                {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
                              </Button>
                            </div>
                          </DialogClose>
                          {groupDeleteInfo && (
                            <Button
                              variant="destructive"
                              disabled={!enableDeletedButton}
                              onClick={handleDeleteGroup}
                            >
                              Đồng ý, xóa{" "}
                              {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
                            </Button>
                          )}
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                    <p className="text-xs text-secondary-600 font-medium flex">
                      <HelpFilled className="mr-1" /> Tìm hiểu thêm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(AdvanceSetting);
