import React, { useState } from "react";

const BlankScreen = () => {
  return (
    <div>
      <h1>BlankScreen</h1>
    </div>
  );
};

export default BlankScreen;
