import React, {
  FC,
  FocusEvent,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

import { Add, ArrowLeft, Close, Renew, Warning } from "@carbon/icons-react";
import { isAxiosError } from "axios";
import { Field, Form, Formik } from "formik";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import COUNTRY_CODES from "@/constants/CountryCodes.json";
import { COUNT_DOWN } from "@/constants/app.constants";
import { useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { UpdateEmailOrPhoneNumberParams } from "../account";
import {
  checkExistForEmailOrPhoneNumber,
  sendOtp,
  sendOtpToPhoneNumber,
} from "../api/account.api";
import {
  updateEmailSchema,
  updatePhoneNumberSchema,
} from "../helpers/form.helper";

interface UpdatePhoneNumberModalProps {
  open: boolean;
  onSubmit: (params: UpdateEmailOrPhoneNumberParams) => void;
  onClose: () => void;
  onDelete: () => void;
}

enum UpdatePhoneNumberSteps {
  NO_PHONE_NUMBER,
  DEFAULT,
  UPDATE,
  REMOVE,
  OTP,
}

const COUNTRY_CODES_MAP: Record<
  string,
  {
    name: string;
    dial_code: string;
    code: string;
  }
> = COUNTRY_CODES.reduce((prev, current) => {
  return {
    ...prev,
    [current.code]: current,
    [current.dial_code]: current,
  };
}, {});

const UpdatePhoneNumberModal: FC<UpdatePhoneNumberModalProps> = ({
  open,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const [step, setStep] = useState(
    user?.phoneNumber
      ? UpdatePhoneNumberSteps.DEFAULT
      : UpdatePhoneNumberSteps.NO_PHONE_NUMBER
  );
  const [countDown, setCountDown] = useState(COUNT_DOWN);
  const inputRef = useRef<HTMLInputElement>(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [exist, setExist] = useState(false);
  const [uid, setUid] = useState("");
  const { toast } = useToast();
  const reSendOtp = () => {
    if (countDown === 0) {
      setCountDown(COUNT_DOWN);
      handleSendOtp(newPhoneNumber);
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1, value.length);
    setActiveOtpIndex(value ? index + 1 : index - 1);
    setOtp(newOtp);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [activeOtpIndex]);
  useEffect(() => {
    setStep(
      user?.phoneNumber
        ? UpdatePhoneNumberSteps.DEFAULT
        : UpdatePhoneNumberSteps.NO_PHONE_NUMBER
    );
  }, [open]);
  const handleSendOtp = async (phone: string) => {
    try {
      const isExist = await checkExistForEmailOrPhoneNumber({
        phoneNumber: phone,
      });
      setExist(isExist.data);
      if (isExist.data) {
        return;
      }
      const result = await sendOtpToPhoneNumber(phone);
      if (result.data.uid) {
        setUid(result.data.uid);
      }
      setStep(UpdatePhoneNumberSteps.OTP);
      setNewPhoneNumber(phone);
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    }
  };
  const handleSubmit = () => {
    onSubmit({
      uid,
      phoneNumber: newPhoneNumber,
      otp: otp.join(""),
    });
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className={
          step === UpdatePhoneNumberSteps.REMOVE
            ? "max-w-[364px]"
            : "max-w-[600px]"
        }
      >
        {step !== UpdatePhoneNumberSteps.REMOVE && (
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute rounded-full right-0 top-0"
              onClick={onClose}
            >
              <Close />
            </Button>
          </DialogHeader>
        )}
        {step === UpdatePhoneNumberSteps.NO_PHONE_NUMBER && (
          <div>
            <DialogTitle>Bạn chưa thêm số điện thoại nào</DialogTitle>
            <p className="text-xs text-secondary-600 mt-2">
              Bạn có sử dụng số điện thoại để đăng nhập vào tài khoản{" "}
              <span className="text-primary font-medium">Tìm hiểu thêm</span>
            </p>
            <div className="text-center mt-10 flex justify-center">
              <Button
                className="flex items-center"
                onClick={() => setStep(UpdatePhoneNumberSteps.UPDATE)}
              >
                <Add className="mr-2 text-white" />
                <span>Thêm số điện thoại</span>
              </Button>
            </div>
          </div>
        )}
        {step === UpdatePhoneNumberSteps.DEFAULT && (
          <>
            <DialogTitle>{user?.phoneNumber}</DialogTitle>
            <DialogDescription>
              <p className="text-xs text-secondary-600">
                Bạn có thể sử dụng số điện thoại để đăng nhập vào tài khoản.{" "}
                <span className="text-primary-600">Tìm hiểu thêm</span>
              </p>
            </DialogDescription>
            <div className="mt-10">
              <Button
                className="block w-full"
                onClick={() => setStep(UpdatePhoneNumberSteps.UPDATE)}
              >
                Cập nhật số điện thoại
              </Button>
              <Button
                className="block w-full mt-2 text-error-600 bg-white hover:bg-error-50"
                onClick={() => setStep(UpdatePhoneNumberSteps.REMOVE)}
              >
                Xoá
              </Button>
            </div>
          </>
        )}
        {step === UpdatePhoneNumberSteps.UPDATE && (
          <Formik
            initialValues={{
              phoneNumber: user?.phoneNumber || "",
              code: "VN",
              dial_code: "+84",
            }}
            validationSchema={updatePhoneNumberSchema}
            onSubmit={(values) =>
              handleSendOtp(values.dial_code + values.phoneNumber)
            }
            validateOnBlur
          >
            {({
              isSubmitting,
              handleChange,
              values,
              errors,
              touched,
              isValid,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <DialogTitle className="mb-2 text-lg">
                    Cập nhật số điện thoại
                  </DialogTitle>
                  <DialogDescription>
                    <p className="text-xs text-secondary-600">
                      Nhập số điện thoại bạn muốn liên kết với tài khoản Taga
                      của mình. Bạn sẽ nhận được mã xác minh được gửi tại đây.
                    </p>
                  </DialogDescription>
                  <p className="mt-10 text-secondary-900 text-xs font-medium mb-1">
                    Mã quốc gia
                  </p>
                  <div className="flex items-center rounded border border-solid border-secondary-200">
                    <Select
                      name="code"
                      value={values.code}
                      onValueChange={(e) => {
                        setExist(false);
                        setFieldValue("code", e);
                        setFieldValue(
                          "dial_code",
                          COUNTRY_CODES_MAP[e].dial_code
                        );
                      }}
                    >
                      <SelectTrigger className="min-w-[68px] w-[68px] border-0 shadow-none h-[40px]">
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <ScrollArea>
                            {COUNTRY_CODES.map((item) => (
                              <SelectItem
                                key={item.code}
                                value={item.code}
                              >
                                {item.code}
                              </SelectItem>
                            ))}
                          </ScrollArea>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Field
                      name="dial_code"
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        setExist(false);
                        handleChange(e);
                        console.warn(
                          "COUNTRY_CODES_MAP[e.target.value]",
                          COUNTRY_CODES_MAP[e.target.value]
                        );
                        if (COUNTRY_CODES_MAP[e.target.value]) {
                          setFieldValue(
                            "code",
                            COUNTRY_CODES_MAP[e.target.value].code
                          );
                        } else {
                          setFieldValue("code", "");
                        }
                      }}
                      value={values.dial_code}
                      error={errors.dial_code}
                      as={Input}
                      className="border-0 rounded-l-0 -translate-x-3 shadow-none focus:shadow-none"
                    ></Field>
                  </div>
                  <p className="mt-4 text-secondary-900 text-xs font-medium mb-1">
                    Số điện thoại
                  </p>
                  <Field
                    name="phoneNumber"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                    error={errors.phoneNumber}
                    as={Input}
                    className="Nhập số điện thoại"
                  ></Field>
                  {touched.phoneNumber && errors.phoneNumber && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {errors.phoneNumber}
                    </span>
                  )}
                  {exist && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {getMessage("MSG_TK29")}
                    </span>
                  )}
                  <div className="flex gap-2 mt-6">
                    <Checkbox />
                    <p className="text-xs text-secondary-600 font-medium">
                      Cho phép mọi người trên Taga có thể tìm kiếm và kết nối
                      với bạn thông qua số điện thoại này.{" "}
                      <span className="text-primary font-medium">
                        Tìm hiểu thêm
                      </span>
                    </p>
                  </div>
                  <div className="text-center mt-10">
                    <Button
                      type="submit"
                      loading={isSubmitting}
                      disabled={
                        !isValid || isSubmitting || !touched.phoneNumber
                      }
                    >
                      Tiếp theo
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {step === UpdatePhoneNumberSteps.OTP && (
          <div>
            <div
              className="text-secondary-600 inline-flex gap-2 items-center cursor-pointer"
              onClick={() => setStep(UpdatePhoneNumberSteps.UPDATE)}
            >
              <ArrowLeft />
              <span className="text-sm">Trở lại</span>
            </div>
            <div className="text-sm text-secondary-600">
              <p className="text-lg font-semibold text-secondary-900 mt-4">
                Xác minh
              </p>
              <p className="mt-1 mb-4">
                Nhập mã xác nhận bao gồm 6 chữ số chúng tôi vừa gửi đến địa chỉ
                email của bạn{" "}
                <span className="text-secondary-900 font-medium">
                  atomux@viktor-pd.com
                </span>
              </p>
            </div>
            <div className="w-full h-40 px-4 py-12 bg-secondary-50 rounded justify-center items-center gap-2 inline-flex">
              {otp.map((_, index) => {
                return (
                  <React.Fragment key={index}>
                    <Input
                      ref={index === activeOtpIndex ? inputRef : null}
                      type="number"
                      className="w-16 h-16 p-1 bg-white rounded-lg shadow border border-secondary-200 text-5xl font-medium text-center text-primary spin-button-none active:border-primary-600 placeholder:text-secondary-300"
                      onChange={(e) => handleChange(e, index)}
                      value={otp[index]}
                      onKeyDown={(event) => {
                        if ([event.code, event.key].includes("Backspace")) {
                          event.preventDefault();
                          const newOtp = [...otp];
                          newOtp[index] = "";
                          setOtp(newOtp);
                          setActiveOtpIndex(activeOtpIndex - 1);
                        }
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="0"
                    />
                    {index === 2 && (
                      <span className="w-2 py-0.5 bg-secondary-400" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="flex justify-between items-center mt-7">
              <div className="flex-col justify-center items-start gap-1 inline-flex mr-auto">
                <div className="text-gray-900 text-sm font-normal ">
                  Không nhận được mã xác nhận?
                </div>

                <span className="flex gap-1 items-center text-primary font-medium text-sm ">
                  <span
                    onClick={reSendOtp}
                    className={
                      countDown > 0
                        ? "flex opacity-50 items-center gap-1"
                        : "flex items-center gap-1 cursor-pointer"
                    }
                  >
                    <Renew />
                    Gửi lại
                  </span>
                  {countDown > 0 && <span>sau {countDown}s</span>}
                </span>
              </div>
              <div>
                <Button
                  type="button"
                  variant="outline"
                  className="mr-3"
                  onClick={onClose}
                >
                  Hủy bỏ
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={otp.some((item) => !item)}
                >
                  Xác nhận
                </Button>
              </div>
            </div>
          </div>
        )}
        {step === UpdatePhoneNumberSteps.REMOVE && (
          <div>
            <DialogTitle>Xoá số điện thoại</DialogTitle>
            <p className="text-xs text-secondary-600 mt-1">
              Thao tác này sẽ xóa số điện thoại{" "}
              <span className="text-secondary-900 font-medium">
                {user?.phoneNumber}
              </span>{" "}
              khỏi tài khoản của bạn. Bạn sẽ không thể sử dụng số điện thoại để
              đăng nhập nữa.
            </p>
            <div className="mt-6">
              <Button
                variant="destructive"
                className="w-full"
                onClick={onDelete}
              >
                Xoá
              </Button>
            </div>
            <div className="mt-3">
              <Button
                variant="ghost"
                className="w-full"
                onClick={onClose}
              >
                Huỷ bỏ
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdatePhoneNumberModal);
