import React, { FC, memo } from "react";

import { InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getDeviceName } from "@/features/groups/helpers/group.helper";
import { DeviceDef } from "@/features/groups/types/group.type";

type RemoveMyDeviceDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  device: DeviceDef | null;
};

const RemoveMyDeviceDialog: FC<RemoveMyDeviceDialogProps> = ({
  open,
  onClose,
  onSubmit,
  loading,
  device,
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent
        className="sm:max-w-[552px] bg-white gap-6 text-center"
        onInteractOutside={() => onClose()}
      >
        <DialogHeader className="flex flex-row gap-4">
          <div>
            <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
              <InformationFilled size={20} />
            </span>
          </div>
          <div className="flex-1">
            <DialogTitle className="mb-2">Gỡ thiết bị</DialogTitle>
            <DialogDescription className="text-secondary-600">
              Bạn chắc chắn gỡ{" "}
              <span className="font-semibold">{getDeviceName(device)}</span>{" "}
              khỏi danh sách thiết bị truy cập?
              <p className="mt-3 text-secondary-600">
                Điều này có nghĩa khi sử dụng thiết bị này bạn sẽ cần thực hiện
                yêu cầu xác thực thiết bị để mở khoá tính năng nâng cao và truy
                cập vào tài sản của Tổ chức này.
              </p>
            </DialogDescription>
            <div className="text-right mt-10">
              <Button
                variant="outline"
                className="mr-2"
                size="sm"
                onClick={onClose}
              >
                Hủy bỏ
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={onSubmit}
                loading={loading}
              >
                Tiếp tục
              </Button>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default memo(RemoveMyDeviceDialog);
