import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  Add,
  CloudUpload,
  FolderAdd,
  FolderParent,
  Group,
  Search,
  StarReview,
  Thumbnail_2,
  Time,
  TrashCan,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import SubNav from "@/components/layout/SubNav/SubNav";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import useToastError from "@/hooks/useToastError";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatBytes } from "@/utils/file";

import {
  checkExistName,
  getCapacityDetails,
  getLibraryList,
  getUrlPersonalUpload,
  getUrlUploadFolder,
  uploadFile,
} from "../api/lib.api";
import CapacityDetails from "../components/CapacityDetails";
import CreateFolderModal from "../components/CreateFolderModal";
import DownloadPopup from "../components/DownloadPopup";
import ExistNameModal from "../components/ExistNameModal";
import OverCapacityModal from "../components/OverCapacityModal";
import ProgressBar from "../components/ProgressBar";
import UploadList from "../components/UploadList";
import {
  ContentTypeEnums,
  IUploadDetailsStatus,
  ResourceType,
} from "../constants/lib.constants";
import { LibPathsEnum } from "../constants/lib.paths";
import { libraryActions } from "../redux/lib.slice";
import {
  CapacityDetail,
  IContentUpload,
  IUploadDetailsItem,
  LibraryGroup,
} from "../types/lib.types";

const navLinks = [
  {
    url: LibPathsEnum.CLOUD,
    end: true,
    icon: (
      <Thumbnail_2
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Tất cả",
  },
  {
    url: LibPathsEnum.RECENTLY,
    end: true,
    icon: (
      <Time
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Gần đây",
  },
  {
    url: LibPathsEnum.SHARE_WITH_ME,
    end: true,
    icon: (
      <Group
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Chia sẻ với tôi",
  },
  {
    url: LibPathsEnum.FAVORITE,
    end: true,
    icon: (
      <StarReview
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Yêu thích",
  },
  {
    url: LibPathsEnum.TRASH,
    end: true,
    icon: (
      <TrashCan
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Lưu trữ",
  },
];

const DefaultLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { showToastError } = useToastError();
  const inputFileEl = useRef<HTMLInputElement>(null);
  const inputFolderEl = useRef<HTMLInputElement>(null);
  const { parentContentDetail } = useAppSelector((state) => state.library);
  const { libraryId } = useAppSelector((state) => state.auth);
  const [uploadList, setUploadList] = useState<IUploadDetailsItem[]>([]);
  const [openUploadList, setOpenUploadList] = useState(false);
  const [openExistName, setOpenExistName] = useState(false);
  const [newName, setNewName] = useState<string | null>("");
  const [openOverCapacity, setOpenOverCapacity] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | FileList | null>(
    null
  );
  const [orgList, setOrgList] = useState<LibraryGroup[]>([]);

  const fetchMyGroupsInfiniteScroll = async () => {
    try {
      const result = await getLibraryList();
      setOrgList(result.data);
    } catch (error) {
      showToastError(error);
    }
  };
  useEffect(() => {
    fetchMyGroupsInfiniteScroll();
  }, []);

  useEffect(() => {
    fetchCapacityDetails();
  }, []);

  const onAddFile = () => {
    inputFileEl.current?.click();
  };

  const onAddFolder = () => {
    inputFolderEl.current?.click();
  };

  const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files?.length) {
      const file = e.target.files[0];
      try {
        if (parentContentDetail) {
          const result = await checkExistName(
            file.name,
            file.size,
            ContentTypeEnums.FILE,
            parentContentDetail.id
          );
          if (result.data.duplicate) {
            setOpenExistName(true);
            setNewName(result.data.new_name);
            setSelectedFile(file);
          } else {
            onSubmitFile(file);
          }
        }
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.data?.errors?.[0] === "MSG_OVER_CAPACITY") {
            setOpenOverCapacity(true);
          }
        }
      }
    }
  };

  const onCancelUploadFile = () => {
    setSelectedFile(null);
    setOpenExistName(false);
    if (inputFileEl.current) {
      inputFileEl.current.value = "";
    }
    if (inputFolderEl.current) {
      inputFolderEl.current.value = "";
    }
  };

  const onFolderSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files || !parentContentDetail) return;

    const folderName = e.target?.files[0].webkitRelativePath.split("/")[0];
    const folderSize = Array.from(e.target?.files).reduce(
      (accumulator, item) => {
        return accumulator + item.size;
      },
      0
    );
    try {
      if (parentContentDetail) {
        const result = await checkExistName(
          folderName,
          folderSize,
          ContentTypeEnums.FOLDER,
          parentContentDetail.id
        );
        if (result.data.duplicate) {
          setOpenExistName(true);
          setNewName(result.data.new_name);
          setSelectedFile(e.target.files);
        } else {
          onSubmitFolder(e.target.files);
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.data?.errors?.[0] === "MSG_OVER_CAPACITY") {
          setOpenOverCapacity(true);
        }
      }
    }
  };

  const onSubmitFolder = async (files: FileList) => {
    if (!files || !parentContentDetail) return;

    try {
      setOpenExistName(false);
      const payload = getPayloadUploadFolder(files);
      if (!payload) return;
      const res = await getUrlUploadFolder({
        parent_id: parentContentDetail?.id,
        contents: payload?.contents,
      });
      if (!res.data) return;
      setOpenUploadList(true);
      addUploadDetailFolderToList(payload.contents, res.data);
      uploadMultipleFiles(payload.contents, payload.filesObj, res.data)
        .then(() => {
          updateFileUploadSuccess(payload.contents[0].id);
        })
        .catch(() => {
          updateFileUploadError(payload.contents[0].id);
        });
    } catch (error) {
      showToastError(error);
    } finally {
      setNewName(null);
      if (inputFolderEl.current) {
        inputFolderEl.current.value = "";
      }
    }
  };

  const addUploadDetailFolderToList = (contents: any[], data: any) => {
    const fileIds = contents
      .filter((c) => c.content_type === ResourceType.FILE)
      .map((c: any) => data[c.id]?.content?.id);
    const firstContent = contents[0];
    const uploadDetail: IUploadDetailsItem = {
      contentId: firstContent.id,
      contentFileIds: fileIds,
      contentFileCloudUploadSuccessIds: [],
      isSingleFile: false,
      name: newName ?? firstContent.content_name,
      extension: null,
      status: IUploadDetailsStatus.Uploading,
      process: 0,
      parentId: parentContentDetail?.id,
      parentName: parentContentDetail?.name,
      // parentIsRoot: parentContentDetail,
    };
    setUploadList((prev) => [uploadDetail, ...prev]);
  };

  const generateString = (length: number) =>
    Array(length)
      .fill("")
      .map(() => Math.random().toString(36).charAt(2))
      .join("");

  const getPayloadUploadFolder = (files: FileList) => {
    if (!files) return null;
    if (files) {
      const filesSelect = files;
      const foldersObj: any = {};
      const filesObj: any = {};
      const contents: any = [];
      let contentSize = 0;
      Array.from(filesSelect).forEach((file) => {
        const materialPath: string[] = [];
        let paths = file.webkitRelativePath.split("/");
        paths = paths.slice(0, paths.length - 1);
        paths.forEach((path, index) => {
          const key = paths.slice(0, index + 1).join("/");
          if (!foldersObj[key]) {
            const newFolderId = generateString(6);
            materialPath.push(newFolderId);
            foldersObj[key] = newFolderId;
            contents.push({
              id: newFolderId,
              content_name: path,
              content_size: 0,
              content_type: ResourceType.FOLDER,
              material_path: materialPath.join("."),
            });
          } else {
            materialPath.push(foldersObj[key]);
          }
        });
        const newFileId = generateString(6);
        materialPath.push(newFileId);
        contents.push({
          id: newFileId,
          content_name: file.name,
          content_size: file.size,
          content_type: ResourceType.FILE,
          material_path: materialPath.join("."),
        });
        contentSize += file.size;
        filesObj[newFileId] = file;
      });
      return {
        contents,
        filesObj,
        contentSize,
      };
    }
  };

  const uploadMultipleFiles = (
    contents: any[],
    filesObj: any,
    dataUrl: any
  ) => {
    const pms: any[] = [];
    contents.forEach((content: any) => {
      const dataContent = dataUrl[content.id];
      const uploadUrl = dataContent.upload_url;
      if (uploadUrl) {
        pms.push(uploadFile(filesObj[content.id], uploadUrl));
      }
    });
    return Promise.all(pms);
  };

  const updateFileUploadSuccess = (contentId: number) => {
    setUploadList((prevState) => {
      const cloneUploadList = JSON.parse(JSON.stringify(prevState));
      const updateItem = cloneUploadList.find(
        (i: IUploadDetailsItem) => i.contentId === contentId
      );
      updateItem.status = IUploadDetailsStatus.Success;
      return cloneUploadList;
    });
  };

  const updateFileUploadError = (contentId: number) => {
    setUploadList((prevState) => {
      const cloneUploadList = JSON.parse(JSON.stringify(prevState));
      const updateItem = cloneUploadList.find(
        (i: IUploadDetailsItem) => i.contentId === contentId
      );
      updateItem.status = IUploadDetailsStatus.Error;
      return cloneUploadList;
    });
  };

  const onSubmitFile = async (file: File) => {
    setOpenExistName(false);
    try {
      if (!parentContentDetail) {
        return;
      }
      const res = await getUrlPersonalUpload(
        file.name,
        file.size,
        parentContentDetail.id
      );
      if (res.data) {
        const contentUpload: IContentUpload = res.data;
        const contentId = contentUpload.content.id;
        const urlUpload = contentUpload.upload_url;
        setOpenUploadList(true);
        uploadFile(file, urlUpload)
          .then(() => {
            if (contentId) {
              updateFileUploadSuccess(contentId);
            }
          })
          .catch(() => {
            updateFileUploadError(contentId);
          });
        if (inputFileEl.current) {
          inputFileEl.current.value = "";
        }
        const uploadDetail: IUploadDetailsItem = {
          contentId: contentId,
          isSingleFile: true,
          name: newName ?? file.name,
          extension: file.type,
          status: IUploadDetailsStatus.Uploading,
          process: 0,
          parentId: parentContentDetail?.id,
          parentName: parentContentDetail?.name,
        };
        setUploadList((prev) => [uploadDetail, ...prev]);
      }
    } catch (error) {
      showToastError(error);
    } finally {
      setNewName(null);
    }
  };
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!keyword) {
      searchParams.delete("keyword");
    } else {
      searchParams.set("keyword", keyword.trim());
      searchParams.set("content_id", libraryId.toString());
    }
    if (location.pathname === LibPathsEnum.CLOUD) {
      navigate(LibPathsEnum.CLOUD + "?" + searchParams.toString());
    }
  };
  useEffect(() => {
    setKeyword(searchParams.get("keyword") || "");
  }, [searchParams]);

  const onCloseUploadList = () => {
    setOpenUploadList(false);
    setUploadList([]);
  };

  const [usedCapacity, setUsedCapacity] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [capacityDetailsData, setCapacityDetailsData] =
    useState<null | CapacityDetail>(null);
  const [openCapacityDetail, setOpenCapacityDetail] = useState(false);

  const fetchCapacityDetails = async () => {
    try {
      const capacityDetailsResult = await getCapacityDetails();
      const personalCapacity =
        capacityDetailsResult.data.personalLibraryCapacity;
      const groupCapacity = capacityDetailsResult.data.groupsCapacities.reduce(
        (accumulator: number, item: any) => {
          return accumulator + item.capacity;
        },
        0
      );
      console.log(capacityDetailsResult);
      setCapacityDetailsData(capacityDetailsResult);
      setUsedCapacity(personalCapacity + groupCapacity);
      setTotalCapacity(capacityDetailsResult.data.maxCapacity);
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <div>
      <div className="">
        <SubNav>
          <div className="w-full flex flex-col h-full overflow-y-auto">
            <div className="p-4">
              <p className="text-xl font-semibold text-secondary-900 mb-0">
                Thư viện của tôi
              </p>
            </div>
            <div className="w-full bg-secondary-100 h-px"></div>
            <div className="p-4">
              <p className="text-xs">Cá nhân</p>
              {navLinks.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    to={item.url}
                    end
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "pending"
                        : isActive
                        ? "bg-secondary-100 flex items-center py-2.5 px-2 rounded font-medium"
                        : "flex items-center py-2.5 px-2 rounded text-secondary-600"
                    }
                  >
                    {item.icon} {item.label}
                  </NavLink>
                );
              })}
            </div>
            <div className="w-full bg-secondary-100 h-px"></div>
            <div className="p-4 font-medium flex-grow flex flex-col">
              <p className="text-xs font-medium mb-2">
                Quản lý dữ liệu Tổ chức ({orgList.length})
              </p>
              {/* <div className="p-3 flex flex-col items-center justify-center flex-grow">
                <p className="font-semibold">Chưa có dữ liệu</p>
                <p className="text-xs text-center">
                  Danh sách dữ liệu của Tổ chức bạn sở hữu sẽ hiển thị ở đây.
                </p>
              </div> */}
              {orgList.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    to={LibPathsEnum.ORG_LIBRARY.replace(
                      ":id",
                      String(item.group.groupId)
                    )}
                    className="flex gap-2 px-2 py-2.5 items-center hover:bg-secondary-100"
                  >
                    <img
                      className="object-cover w-4 h-4 rounded"
                      src={item.group.photoUrl ?? GroupDetailCover}
                      alt=""
                    />
                    <span className="text-sm">{item.group.name}</span>
                  </NavLink>
                );
              })}
            </div>
            <div className="px-4 pb-6">
              <div
                className={
                  "bg-[#F5FAFE] p-4 flex-col justify-start gap-2 inline-flex rounded-lg relative overflow-hidden"
                }
              >
                <>
                  <div className="w-[360px] h-[360px] left-[-136px] top-[-136px] absolute bg-[#EEF7FF] rounded-full" />
                  <div className="w-[220px] h-[220px] left-[-66px] top-[-66px] absolute bg-[#E7F3FF] rounded-full" />
                  <div className="w-[120px] h-[120px] left-[-16px] top-[-16px] absolute bg-[#DEEDFF] rounded-full" />
                </>
                <ProgressBar
                  total={totalCapacity}
                  current={usedCapacity}
                />
                <div className="text-gray-900 text-sm font-semibold z-10">
                  Premium Business plan
                </div>
                <p className="z-10 text-xs font-medium text-secondary-600 mb-8">
                  {formatBytes(usedCapacity)} trên {formatBytes(totalCapacity)}{" "}
                  đã được sử dụng.
                </p>
                <Button
                  className="z-10"
                  onClick={() => setOpenCapacityDetail(true)}
                >
                  Quản lý lưu trữ
                </Button>
                {capacityDetailsData && (
                  <CapacityDetails
                    capacityDetails={capacityDetailsData}
                    open={openCapacityDetail}
                    onClose={() => setOpenCapacityDetail(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </SubNav>
        <div className="content pl-[280px] pt-[70px]">
          <CreateFolderModal />
          {selectedFile && (
            <ExistNameModal
              open={openExistName}
              newName={newName}
              onSubmit={() => {
                if (selectedFile instanceof FileList) {
                  onSubmitFolder(selectedFile);
                } else {
                  onSubmitFile(selectedFile);
                }
              }}
              type="Upload"
              onCancel={onCancelUploadFile}
            />
          )}
          <OverCapacityModal
            open={openOverCapacity}
            onClose={() => {
              onCancelUploadFile();
              setOpenOverCapacity(false);
            }}
          />
          <div className="container py-3 flex gap-3">
            <form
              className="relative flex-1"
              onSubmit={onSubmit}
            >
              <Search className="absolute left-3 top-3 text-secondary-600" />
              <Input
                className="bg-secondary-100 rounded-[384px] pl-9"
                placeholder="Tìm kiếm tệp tin, thư mục"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </form>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="secondary"
                  className="rounded-[100%] p-3"
                >
                  <Add />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem className="p-0">
                  <Button
                    className="bg-transparent w-full text-left justify-start"
                    variant={"ghost"}
                    iconBefore={<FolderAdd />}
                    onClick={() => {
                      dispatch(libraryActions.showCreateModal());
                    }}
                  >
                    Tạo mới Thư mục
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuSeparator className="bg-secondary-100 mx-2" />
                <DropdownMenuItem className="p-0">
                  <Button
                    className="bg-transparent w-full text-left justify-start"
                    variant={"ghost"}
                    iconBefore={<CloudUpload />}
                    onClick={onAddFile}
                  >
                    Tải lên Tệp tin
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuItem className="p-0">
                  <Button
                    className="bg-transparent w-full justify-start"
                    variant={"ghost"}
                    iconBefore={<FolderParent />}
                    onClick={onAddFolder}
                  >
                    Tải lên Thư mục
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <input
              type="file"
              ref={inputFileEl}
              style={{ display: "none" }}
              onChange={onFileSelect}
            />
            {
              <input
                type="file"
                ref={inputFolderEl}
                style={{ display: "none" }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                webkitdirectory=""
                multiple
                onChange={onFolderSelect}
              />
            }
          </div>
          {openUploadList && (
            <UploadList
              uploadList={uploadList}
              onClose={onCloseUploadList}
            />
          )}
          <DownloadPopup />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
