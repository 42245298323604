import React, { FC, memo, useEffect, useState } from "react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Switch } from "@/components/ui/switch";
import { toggleNotification } from "@/features/groups/api/group.api";
import { SubGroupDef } from "@/features/groups/types/group.type";

type ToggleNotifySubGroupProps = {
  subGroup: SubGroupDef;
  disabled: boolean;
  onChangeSwitch: () => void;
};

const ToggleNotifySubGroup: FC<ToggleNotifySubGroupProps> = ({
  subGroup,
  disabled,
  onChangeSwitch,
}) => {
  const [mode, setMode] = useState(subGroup.isNotified);

  return (
    <div className="flex gap-2 items-center p-3">
      <TGNAvatar
        size="24"
        round="8px"
      />
      <div className="flex-1">
        <p className="text-sm text-secondary-900 font-medium">
          {subGroup.name}
        </p>
      </div>
      <Switch
        checked={mode}
        onCheckedChange={() => {
          setMode(!mode);
          toggleNotification(subGroup.id, !mode);
          onChangeSwitch();
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default memo(ToggleNotifySubGroup);
