import React, { FC, useEffect, useRef, useState, useCallback } from "react";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import { TaskDetailRenderer } from "./TaskDetailRenderer";
import { getTaskDetail } from "../api/task.api";
import { TaskDetailDef } from "../types/task";

type Props = {
  taskId: number | null;
  close?: () => void;
};
export const TaskDetail: FC<Props> = ({ taskId, close }) => {
  const dispatch = useAppDispatch();
  const [openDetail, setOpenDetail] = useState(false);
  const [taskDetail, setTaskDetail] = useState<TaskDetailDef | null>(null);
  console.log(taskDetail);
  const [loading, setLoading] = useState(false);
  const fetchContent = useCallback(async () => {
    if (taskId) {
      try {
        const result = await getTaskDetail(taskId);
        setTaskDetail(result.data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [taskId]);
  useEffect(() => {
    if (taskId) {
      setOpenDetail(true);
      fetchContent();
    }
  }, [taskId, fetchContent]);
  return (
    <Dialog
      open={openDetail}
      onOpenChange={() => (setOpenDetail(false), close && close())}
    >
      <DialogContent className="min-w-[90%] h-[90%] p-0 overflow-hidden flex flex-col">
        {loading ? (
          <div className="flex justify-center items-center p-2">
            <TGNLoading size="medium" />
          </div>
        ) : (
          <div className="rounded grid grid-rows-[auto,1fr] h-full">
            {/* Header Section */}
            <div className="font-bold text-2xl p-5">
              {taskDetail?.taskName || "Task Details"}
            </div>

            {/* Scrollable Content */}
            <div className="overflow-auto bg-secondary-100 w-full p-10">
              <TaskDetailRenderer taskData={taskDetail} />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
