import React, { useState } from "react";

import { useAppSelector } from "@/redux/store";

const ProfileSetting = () => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-end-11">
            <p className="text-lg font-semibold text-secondary-900">
              Dung lượng
            </p>
            <p className="text-xs text-secondary-600">
              Bạn đang tìm cách mua hoặc quản lý đăng ký cho nhóm của mình? Điều
              hướng đến nhóm của bạn và đi tới Cài đặt Thanh toán. Sau đó, di
              chuyển bất kỳ dự án nào bạn muốn sử dụng cùng với đăng ký của mình
              vào nhóm đó.
            </p>
            <div className="mt-6">{user?.email}Free</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
