import React, { FC, memo } from "react";

import { ArrowUpRight, Close, WarningFilled } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo, groupActions } from "@/features/groups/group";
import { GroupDef } from "@/features/groups/types/group.type";
import { useAppDispatch } from "@/redux/store";

import ListQuickAction from "./ListQuickAction";

type ModalProps = {
  isDetail?: boolean;
  groupInfo: number;
  handelModal: () => void;
  openModal: boolean;
  root: boolean;
};
const SuggestModal: FC<ModalProps> = ({
  openModal,
  handelModal,
  groupInfo,
  isDetail = false,
  root,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <>
      <Dialog open={openModal}>
        <DialogContent className={"sm:max-w-[600px] bg-white"}>
          <DialogHeader className="relative">
            <DialogTitle className="text-center flex justify-between text-lg gap-2">
              <div className="w-[36px]"></div>
              <div>Lời mời tham gia Nhóm</div>
              <Button
                variant="ghost"
                size="sm"
                className="rounded-full w-[36px]"
                onClick={() => {
                  handelModal();
                  if (isDetail) {
                    dispatch(
                      getGroupInfo({
                        group_id: groupInfo,
                      })
                    )
                      .then(unwrapResult)
                      .catch(() => {
                        navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                      });
                  }
                }}
              >
                <Close />
              </Button>
            </DialogTitle>
          </DialogHeader>
          <div className="flex gap-2 grid-cols-2 bg-primary-25 p-4 rounded-md">
            <div>
              <span className="block border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full">
                <WarningFilled size={24} />
              </span>
            </div>
            <span className="w-[calc(100%_-_48px)] text-secondary-900 text-sm">
              {root
                ? "Trước đó, bạn đã nhận được một số lời mời từ các nhóm thuộc Tổ chức này. Chúng tôi đề xuất bạn thực hiện xử lý các lời mời này để tránh bỏ lỡ."
                : "Trước đó, bạn đã nhận được một số lời mời từ các nhóm thuộc cùng Tổ chức mà nhóm này thuộc về. Chúng tôi đề xuất bạn thực hiện xử lý các lời mời này để tránh bỏ lỡ."}

              <span className="font-medium inline-flex items-center">
                Tìm hiểu thêm <ArrowUpRight size={14} />
              </span>
            </span>
          </div>{" "}
          <ListQuickAction rootGroupId={groupInfo} />
          <DialogFooter>
            <Button
              className="w-full"
              variant={"text"}
              onClick={() => {
                if (isDetail) {
                  dispatch(
                    getGroupInfo({
                      group_id: groupInfo,
                    })
                  )
                    .then(unwrapResult)
                    .catch(() => {
                      navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                    });
                }
                dispatch(groupActions.setFlagLoading(false));
                handelModal();
              }}
            >
              Để sau
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default memo(SuggestModal);
