import React, { FC, memo, useState } from "react";

import {
  InformationFilled,
  MapCenter,
  OverflowMenuVertical,
  TrashCan,
  UserAdmin,
  UserAvatar,
  UserIdentification,
  UserMilitary,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";
import { toast } from "sonner";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { DisplayMode } from "@/constants/app.constants";
import { assignRole } from "@/features/groups/api/group.api";
import { GroupUserRoleEnums } from "@/features/groups/constants/group.constant";
import { getGroupMembersListThunk } from "@/features/groups/group";
import { IMemberGroup } from "@/features/groups/types/group.type";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import DeleteConfirmModal from "./DeleteConfirmModal";
import AdminConfirmModal from "./UserDetail/AdminConfirmModal";

type Props = {
  groupId: number;
  setId: (id: number) => void;
  displayMode: DisplayMode;
  member: IMemberGroup;
};
const CardUser: FC<Props> = ({ setId, groupId, displayMode, member }) => {
  const { toast } = useToast();
  const { user } = useAppSelector((state) => state.auth);
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const dispatch = useAppDispatch();
  const [openAssignRole, setOpenAssignRole] = useState(false);

  const handleAssignRole = async () => {
    if (!groupInfo || !member) {
      return;
    }
    try {
      await assignRole(
        groupInfo?.id,
        member?.userId,
        member?.role === GroupUserRoleEnums.ADMIN
          ? GroupUserRoleEnums.MEMBER
          : GroupUserRoleEnums.ADMIN
      );
      dispatch(
        getGroupMembersListThunk({
          pageNumber: 1,
          pageSize: 9,
          groupId: groupInfo.id,
        })
      );
      setOpenAssignRole(false);
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG86"),
      });
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };
  return (
    <>
      {displayMode === DisplayMode.GRID && (
        <div className=" min-h-60 px-4 pt-6 pb-4 bg-white rounded hover:shadow border border-secondary-200 flex-col justify-start items-center gap-4 inline-flex relative overflow-hidden">
          <div className="absolute top-0 right-0">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="p-3 cursor-pointer">
                  <OverflowMenuVertical />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <AdminConfirmModal
                  groupInfo={groupId}
                  button={
                    <Button
                      variant="text"
                      className="justify-start"
                    >
                      <UserAdmin className="me-1" />
                      Chỉ định làm quản trị viên
                    </Button>
                  }
                />
                {/* <DeleteConfirmModal
                  userId={groupId}
                  button={
                    <Button
                      variant="text"
                      className="w-full justify-start"
                    >
                      <TrashCan className="me-1" />
                      Xóa thành viên
                    </Button>
                  }
                /> */}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="flex-col justify-start items-center gap-3 flex w-full">
            <div className="justify-start items-center inline-flex flex-col">
              <div onClick={() => setId(groupId)}>
                <img
                  className="w-24 h-24 rounded-full cursor-pointer"
                  src={`https://unsplash.it/150/200?image=${groupId}`}
                  alt=""
                />
              </div>
              {!Math.round(Math.random()) ? (
                <Badge className="bg-orange-25 border border-orange-200 text-orange-500 font-medium text-[10px] leading-[14px]  -mt-4">
                  <UserAvatar
                    className="mr-1"
                    size={12}
                  />
                  Quản trị viên
                </Badge>
              ) : (
                <></>
              )}
            </div>
            <div className="flex-col justify-start items-center gap-1 flex w-full">
              <div
                className="w-full text-secondary-900 text-sm text-center font-semibold  hover:text-primary-600 inline cursor-pointer truncate  text-ellipsis overflow-hidden"
                onClick={() => setId(groupId)}
              >
                Jerome Bell Bell Bell Bell Bell(You)
              </div>
              <p className="text-secondary-600 text-xs font-normal ">
                CEO & Founder
              </p>
            </div>
          </div>
          {!Math.round(Math.random()) ? (
            <Button className="w-full">Kết bạn</Button>
          ) : (
            <Button
              variant="secondaryColor"
              className="w-full"
            >
              Nhắn tin
            </Button>
          )}
        </div>
      )}
      {displayMode === DisplayMode.LIST && (
        <div className="py-4 border-secondary-200 border-b last:border-b-0 first:border-t flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <TGNAvatar
              size="40"
              userName={member.alias ?? member.name}
              src={member.photoUrl}
            />
            <div>
              <span className="font-semibold text-sm mb-0.5">
                <span
                  className="cursor-pointer"
                  onClick={() => setId(member.userId)}
                >
                  {member.alias ? member.alias : member.name ?? member.email}{" "}
                </span>
                {user && member.userId === user.id ? "(Bạn)" : ""}
                {member.role !== GroupUserRoleEnums.MEMBER && member.role && (
                  <Badge
                    className="ml-1 font-medium text-[10px] leading-[14px]"
                    variant={
                      member.role === GroupUserRoleEnums.OWNER
                        ? "default"
                        : "orange"
                    }
                  >
                    <UserAvatar
                      className="mr-1"
                      size={12}
                    />
                    {member.role === GroupUserRoleEnums.OWNER
                      ? "Chủ sở hữu"
                      : "Quản trị viên"}
                  </Badge>
                )}
              </span>
              <p className="text-xs text-secondary-600">Chức danh</p>
            </div>
          </div>
          <div className="flex gap-2">
            {user && member.userId !== user.id ? (
              member.relationship ? (
                <Button className="bg-primary-25 px-4 py-2.5 block hover:bg-primary-50 text-primary-600 rounded flex-1 mr-3 text-center text-sm">
                  Nhắn tin
                </Button>
              ) : (
                <Button>Thêm bạn bè</Button>
              )
            ) : (
              ""
            )}

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="p-3 cursor-pointer">
                  <OverflowMenuVertical />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem className="p-0">
                  <Button
                    onClick={() => setId(member.userId)}
                    variant="text"
                    className="w-full justify-start"
                  >
                    <UserIdentification className="me-1" />
                    Chi tiết
                  </Button>
                </DropdownMenuItem>
                {groupInfo?.role?.code === GroupUserRoleEnums.OWNER &&
                  user?.id !== member.userId && (
                    <Dialog open={openAssignRole}>
                      <DialogTrigger asChild>
                        <Button
                          variant="text"
                          className="w-full justify-start"
                          onClick={() => setOpenAssignRole(true)}
                        >
                          <UserMilitary className="me-1" />
                          {member?.role !== GroupUserRoleEnums.ADMIN
                            ? "Trao quyền Quản trị viên"
                            : "Tước quyền Quản trị viên"}
                        </Button>
                      </DialogTrigger>
                      <DialogContent className={"sm:max-w-[600px] bg-white"}>
                        <DialogHeader className="flex flex-row gap-4">
                          <div>
                            <span className="border-[6px] p-1 text-warning-600 border-warning-50 bg-warning-100 rounded-full block">
                              <InformationFilled size={20} />
                            </span>
                          </div>
                          <div className="flex-1">
                            <DialogTitle className="text-lg">
                              {member?.role !== GroupUserRoleEnums.ADMIN
                                ? "Trao quyền Quản trị viên"
                                : "Tước quyền Quản trị viên"}
                            </DialogTitle>
                            <DialogDescription className="text-secondary-600 mt-2">
                              {member?.role !== GroupUserRoleEnums.ADMIN ? (
                                <>
                                  <p className="mt-2">
                                    Bạn chắc chắn muốn thêm{" "}
                                    {member?.alias ?? member?.name} trở thành
                                    Quản trị viên tổ chức của bạn?
                                  </p>
                                  <p className="mt-5">
                                    Quản trị viên có quyền thực hiện các tính
                                    năng nâng cao (tạo nhóm, quản lý thành
                                    viên), xây dựng và quản lý các bộ phận trong
                                    tổ chức. Tìm hiểu thêm↗
                                  </p>
                                </>
                              ) : (
                                <p>
                                  Bạn chắc chắn muốn gỡ{" "}
                                  {member?.alias ?? member?.name} khỏi danh sách
                                  Quản trị viên tổ chức của bạn?
                                </p>
                              )}
                            </DialogDescription>
                          </div>
                        </DialogHeader>
                        <DialogFooter className="justify-end gap-2">
                          <DialogClose asChild>
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={() => setOpenAssignRole(false)}
                            >
                              Huỷ bỏ
                            </Button>
                          </DialogClose>
                          <Button
                            onClick={handleAssignRole}
                            size="sm"
                          >
                            Xác nhận
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  )}
                <DropdownMenuItem className="p-0">
                  <Button
                    variant="text"
                    className="w-full justify-start"
                  >
                    <MapCenter className="me-1" />
                    Xem trong Sơ đồ tổ chức
                  </Button>
                </DropdownMenuItem>
                {(groupInfo?.role?.code === GroupUserRoleEnums.OWNER ||
                  (groupInfo?.role?.code === GroupUserRoleEnums.ADMIN &&
                    member.role === GroupUserRoleEnums.MEMBER)) &&
                  user?.id !== member.userId && (
                    <DeleteConfirmModal
                      user={member}
                      button={
                        <Button
                          variant="text"
                          className="w-full justify-start"
                        >
                          <TrashCan className="me-1" />
                          Xóa thành viên
                        </Button>
                      }
                    />
                  )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(CardUser);
