import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  UserMultiple,
  Thumbnail_2,
  ListNumberedMirror,
  UserProfileAlt,
  Collaborate,
  UserFollow,
} from "@carbon/icons-react";
import { NavLink, Outlet } from "react-router-dom";

import SubNav from "@/components/layout/SubNav/SubNav";

import { FriendsPathsEnum } from "../constants/friends";

const navLinks = [
  {
    url: FriendsPathsEnum.FRIENDS,
    end: true,
    icon: (
      <UserMultiple
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Trang chủ",
  },
  {
    url: FriendsPathsEnum.MY_FRIENDS,
    end: true,
    icon: (
      <UserProfileAlt
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Tất cả bạn bè",
  },
  {
    url: "/invite",
    end: true,
    icon: (
      <Collaborate
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Gợi ý bạn bè",
  },

  {
    url: FriendsPathsEnum.REQUEST_FRIENDS,
    end: true,
    icon: (
      <UserFollow
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Lới mời kết bạn",
  },
];

const DefaultLayout = () => {
  return (
    <div>
      <div className="">
        <SubNav>
          <div className="w-full flex flex-col h-full overflow-y-auto">
            <div className="p-4">
              <p className="text-xl font-semibold text-secondary-900 mb-0">
                Bạn bè
              </p>
            </div>
            <div className="w-full bg-secondary-100 h-px"></div>
            <div className="p-4">
              {navLinks.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    to={item.url}
                    end
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "pending"
                        : isActive
                        ? "bg-secondary-100 flex items-center py-2.5 px-2 rounded font-medium"
                        : "flex items-center py-2.5 px-2 rounded text-secondary-600"
                    }
                  >
                    {item.icon} {item.label}
                  </NavLink>
                );
              })}
            </div>
            <div className="w-full bg-secondary-100 h-px"></div>
          </div>
        </SubNav>
        <div className="content pl-[280px] pt-[70px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
