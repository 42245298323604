import React, { Dispatch, FC, SetStateAction } from "react";

import { Close, Upload } from "@carbon/icons-react";

import { getContentName } from "@/utils/file";

import FileIcon from "./FileIcon";
import { IUploadDetailsStatus } from "../constants/lib.constants";
import { IUploadDetailsItem } from "../types/lib.types";

interface IUploadListProps {
  onClose: () => void;
  uploadList: IUploadDetailsItem[];
}

const UploadList: FC<IUploadListProps> = ({ onClose, uploadList }) => {
  const countSuccess = uploadList.filter(
    (item) => item.status === IUploadDetailsStatus.Success
  ).length;
  return (
    <div
      className={`${
        countSuccess === uploadList.length ? "h-auto" : "h-[400px]"
      } w-[344px] fixed bottom-4 right-4 bg-white rounded border border-secondary-200 shadow-lg p-4`}
    >
      <div className="relative px-2 flex flex-col">
        <p className="text-sm">Danh sách tải lên</p>
        <Close
          size="20"
          className="absolute top-0 right-0 cursor-pointer"
          onClick={() => onClose()}
        />
      </div>
      <div
        className={`${
          countSuccess === uploadList.length ? "h-auto" : "h-[273px]"
        } mt-4`}
      >
        {uploadList?.map((item, index) => {
          return (
            <div
              key={index}
              className="p-2 overflow-hidden"
            >
              <div className="flex gap-2">
                <FileIcon
                  extension={item.extension}
                  size={20}
                />
                <div>
                  <p className="text-xs text-secondary-600 font-semibold break-words">
                    {getContentName(item.name)}
                  </p>
                  {item.status === IUploadDetailsStatus.Success && (
                    <p className="text-xs text-secondary-600 font-medium mt-1">
                      Thêm vào{" "}
                      <span className="text-secondary-900 underline">
                        {item.parentName}
                      </span>
                    </p>
                  )}
                  {item.status === IUploadDetailsStatus.Uploading && (
                    <p className="text-xs text-secondary-600 mt-1">
                      Đang tải lên ...
                    </p>
                  )}
                </div>
              </div>
              {item.status === IUploadDetailsStatus.Uploading && (
                <div className="h-1 bg-secondary-100 relative mt-4">
                  <div className="h-1 w-[200px] absolute top-0 left-0 animate-uploading bg-gradient-to-r from-transparent via-blue-500 to-transparent rounded-[100px]"></div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {countSuccess < uploadList.length && (
        <div className="px-6 py-4 -mx-4 flex items-center gap-2 border-secondary-100 border-t">
          <span className="border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full block">
            <Upload size={20} />
          </span>
          <span className="text-sm font-semibold">
            Đã tải lên {countSuccess} trên {uploadList.length} tệp tin
          </span>
        </div>
      )}
    </div>
  );
};

export default UploadList;
