import React, { FC, memo, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import { InformationFilled } from "@carbon/icons-react";
import { Formik, Form } from "formik";
import { ArrowUpRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useToast } from "@/components/ui/use-toast";
import { getMessage } from "@/utils/message";

import { restoreGroup } from "../../api/group.api";
import { RestoreGroup } from "../../types/group.type";

type RestoreGroupModalProps = {
  group: RestoreGroup;
};
type Initial = {
  mode: number;
  id: number;
};
const RestoreGroupModal: FC<RestoreGroupModalProps> = ({ group }) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={setOpenModal}
      >
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            className="w-full"
            onClick={() => setOpenModal(true)}
          >
            Khôi phục
          </Button>
        </DialogTrigger>
        <DialogContent className={"sm:max-w-[552px] bg-white gap-6"}>
          <DialogHeader>
            <DialogTitle>Khôi phục tổ chức {group.name}?</DialogTitle>
            <DialogDescription className="text-sm text-secondary-600">
              <div className="flex gap-2  grid-cols-2 bg-primary-25 p-4 rounded-md mt-6">
                <div>
                  <span className="block border-[6px] p-1 text-primary-600 border-primary-50 bg-primary-100 rounded-full">
                    <InformationFilled size={24} />
                  </span>
                </div>
                <span className="w-[calc(100%_-_48px)] text-secondary-900">
                  Thao tác này sẽ khôi phục Tổ chức của bạn, bạn có thể lựa chọn
                  phương thức khôi phục sau đây. Quá trình này có thể diễn ra
                  trong một vài phút.{" "}
                  <span className="font-medium inline-flex items-center">
                    Tìm hiểu thêm <ArrowUpRight size={14} />
                  </span>
                </span>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant={"ghost"}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Huỷ bỏ
            </Button>
            <Button
              onClick={() => {
                setOpenModal(false);
                setOpenConfirmModal(true);
              }}
            >
              Tiếp tục
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmModal}>
        <DialogContent className="max-w-[650px]">
          <DialogHeader>
            <DialogTitle>Phương thức khôi phục</DialogTitle>
            <DialogDescription className="font-xs text-secondary-600">
              Lựa chọn phương thức khôi phục Tổ chức của bạn.{" "}
              <span className="font-medium inline-flex items-center text-secondary-900">
                Tìm hiểu thêm <ArrowUpRight size={14} />
              </span>
            </DialogDescription>
          </DialogHeader>
          <Formik
            initialValues={{
              mode: 1,
              id: group.id,
            }}
            onSubmit={(values: Initial, { setSubmitting }) => {
              const restoreGroupFn = async () => {
                try {
                  setLoading(true);
                  await restoreGroup(values.id);
                  setTimeout(() => {
                    navigate(`/group/${values.id}?res=true`);
                  }, 2000);
                } catch (error: any) {
                  setTimeout(() => {
                    setLoading(false);
                  }, 2000);
                  toast({
                    variant: "error",
                    title: "Thất bại!",
                    description: getMessage(error.response?.data.errors[0]),
                  });
                } finally {
                  setOpenModal(false);
                  setTimeout(() => {
                    setLoading(false);
                  }, 2000);
                  setSubmitting(false);
                }
              };
              restoreGroupFn();
            }}
          >
            {({ setFieldValue, isSubmitting, values }) => (
              <Form className="grid gap-6">
                <div className="grid gap-4">
                  <div className="items-center grid gap-2">
                    <RadioGroup
                      defaultValue="1"
                      onValueChange={(value) => setFieldValue("mode", value)}
                    >
                      <Label
                        className={
                          values.mode === 1
                            ? "flex items-center border border-primary rounded-lg p-4 bg-primary-25 items-start ring-4 ring-primary-50"
                            : "flex items-center border border-primary border-white rounded-lg p-4 items-start"
                        }
                        htmlFor="r1"
                      >
                        <RadioGroupItem
                          value="1"
                          id="r1"
                          className="mt-1"
                        />
                        <div className="grid grap-2">
                          <span className="text-sm font-medium text-secondary-900">
                            Khôi phục thường
                          </span>
                          <span className="text-xs text-secondary-600 font-normal mt-1">
                            Hệ thống tiến hành khôi phục Tổ chức của bạn với
                            thông tin cơ bản, bao gồm: ảnh đại diện, mô tả tổ
                            chức, hệ thống nhóm và các thiết lập cơ bản kể từ
                            lần cập nhật cuối cùng.
                          </span>
                        </div>
                      </Label>
                      <Label
                        className={
                          values.mode === 2
                            ? "flex items-center border border-primaryrounded-lg p-4 bg-primary-25 items-start"
                            : "flex items-center border border-primary border-white rounded-lg p-4 items-start"
                        }
                        htmlFor="r2"
                      >
                        <RadioGroupItem
                          value="2"
                          id="r2"
                          className="mt-1"
                          disabled
                        />
                        <div className="grid grap-2">
                          <span className="text-sm font-medium text-secondary-900">
                            Khôi phục nâng cao
                          </span>
                          <span className="text-xs text-secondary-600 font-normal mt-1">
                            Tải lên bản ghi dữ liệu của Tổ chức đã được sao lưu
                            trước đó. TAGA sẽ tiến hành xác minh và khôi phục
                            tất cả dữ liệu, tài nguyên cùng các thiết lập của hệ
                            thống Tổ chức của bạn tại thời điểm bản sao lưu được
                            cập nhật.
                          </span>
                        </div>
                      </Label>
                    </RadioGroup>
                  </div>
                </div>
                <DialogFooter>
                  <Button
                    variant={"ghost"}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenModal(false);
                      setOpenConfirmModal(false);
                    }}
                  >
                    Huỷ bỏ
                  </Button>
                  <Button
                    loading={isSubmitting}
                    type="submit"
                    onClick={() => {
                      setOpenConfirmModal(false);
                    }}
                  >
                    Tiến hành Khôi phục
                  </Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog open={loading}>
        <DialogContent className="p-6 text-center max-w-[400px] gap-2">
          <LoadingIcon className="animate-spin duration-[3000ms] mx-auto" />
          <p className="text-secondary-900 text-lg font-semibold mt-2">
            Đang khôi phục Tổ chức
          </p>

          <p className="text-secondary-600 text-sm">
            Quá trình này có thể mất một vài phút. Vui lòng chờ tới khi Tổ chức
            được khôi phục thành công.
          </p>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(RestoreGroupModal);
