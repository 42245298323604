import React, { useState, memo, FC, useEffect, useCallback } from "react";

import { AxiosError } from "axios";
import cx from "classnames";
import { Circle } from "rc-progress";
import toast, { useToaster } from "react-hot-toast";

import api from "@/api/api_instance";
import { Excel, LoadingHover } from "@/assets/icons";
import Typography from "@/components/Typography/Typography";
import { FileType, RelayFile } from "@/features/mindmap/mindmap";
import { useAppSelector } from "@/redux/store";
import { getFileSize } from "@/utils/file";

import styles from "./FileManagement.module.scss";
import UploadToastResult from "./UploadToastResult";

type UploadToastProps = {
  id: string;
  file: File;
  relay: RelayFile;
};

const UploadToast: FC<UploadToastProps> = ({ id, file, relay }) => {
  const {
    mindmapIO,
    currentSheet: { selectedNode },
    isCancelUpload,
  } = useAppSelector((state) => state.mindmap);
  const [showBtnClose, setShowBtnClose] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [percent, setPercent] = useState(0);
  const [controllerApi, setControllerApi] = useState<AbortController>();
  const { toasts } = useToaster();

  const cancelUpload = useCallback(() => {
    if (controllerApi) {
      controllerApi.abort();
      toast.custom(
        <UploadToastResult
          file={file}
          id={id}
          type="cancel"
        />,
        {
          position: "top-right",
          duration: 60 * 1000,
          id: id,
        }
      );
    }
  }, [controllerApi, file, id]);
  useEffect(() => {
    if (isCancelUpload) {
      cancelUpload();
    }
  }, [isCancelUpload, cancelUpload]);
  const showToast = useCallback(() => {
    toast.custom(
      <UploadToastResult
        file={file}
        id={id}
        type="success"
      />,
      {
        position: "top-right",
        duration: 60 * 1000,
        id: id,
      }
    );
  }, [id, file]);
  const uploadFile = useCallback(
    async (uploadUrl: string, file: File) => {
      try {
        const controller = new AbortController();
        setControllerApi(controller);
        await api.put(uploadUrl, file, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          signal: controller.signal,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.bytes) {
              const currentPercent = Math.round(
                (progressEvent.loaded / (progressEvent?.total || 1)) * 100
              );
              setPercent(currentPercent);
            }
          },
        });
        showToast();
      } catch (e) {
        const err = e as AxiosError;
        if (err.code !== "ERR_CANCELED") {
          toast.custom(
            <UploadToastResult
              file={file}
              id={id}
              type="error"
            />,
            {
              position: "top-right",
              duration: 60 * 1000,
              id: id,
            }
          );
        }
      }
    },
    [id, showToast]
  );
  const upload = useCallback(async () => {
    try {
      await uploadFile(relay.upload, file);
      if (mindmapIO) {
        mindmapIO.updateData({
          stepId: `${selectedNode?.data.id}`,
          name: file.name,
          cloudFileUrl: relay.download,
          maskedFileName: relay.maskedFileName,
          extensionData: {
            type: FileType.FILE,
            chatId: null,
          },
        });
      }
    } catch (err) {
      console.warn("err");
    }
  }, [
    file,
    mindmapIO,
    relay.download,
    relay.maskedFileName,
    relay.upload,
    selectedNode?.data.id,
    uploadFile,
  ]);
  useEffect(() => {
    upload();
  }, [upload]);

  return (
    <div
      className={cx(styles.toast)}
      onClick={(e) => e.stopPropagation()}
      onMouseOver={() => setShowBtnClose(true)}
      onMouseOut={() => setShowBtnClose(false)}
    >
      <div
        className={cx(styles.close, showBtnClose && styles.show)}
        onClick={() => {
          toast.dismiss(id);
          if (toasts.length === 2) {
            toast.remove();
          }
        }}
      >
        <i className="tgn-outline-remove fs-18"></i>
      </div>
      <div className={styles["thumbnail"]}>
        <Excel />
      </div>
      <div className="flex-1">
        <Typography
          style={{
            maxWidth: 210,
          }}
          className="text-truncate text-primary-color-gray-10 fs-12 fw-semibold"
        >
          {file.name}
        </Typography>
        <Typography className="fs-12 text-secondary-color-gray-7">
          {getFileSize(file.size)}
        </Typography>
      </div>
      <div className="d-flex justify-content-end">
        <div
          className={styles.load}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {isHover ? (
            <span onClick={cancelUpload}>
              <LoadingHover />
            </span>
          ) : (
            <Circle
              percent={percent}
              strokeWidth={16}
              trailWidth={16}
              strokeLinecap="round"
              trailColor="#fff"
              strokeColor="#1589ee"
              style={{
                width: 16,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(UploadToast);
