import React, { FC, ReactNode, memo, useEffect, useState } from "react";

import {
  Close,
  Devices,
  DotMark,
  Edit,
  InformationFilled,
  MapCenter,
  OverflowMenuVertical,
  TrashCan,
  UserAdmin,
  UserAvatar,
  UserMilitary,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";

import GroupAvatarDefault from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { RelationshipStatusEnums } from "@/constants/app.constants";
import {
  assignRole,
  getDetailGroupMember,
  updateGroupMember,
} from "@/features/groups/api/group.api";
import { GroupUserRoleEnums } from "@/features/groups/constants/group.constant";
import { getGroupMembersListThunk } from "@/features/groups/group";
import { MemberDetailDef } from "@/features/groups/types/group.type";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { getMessage } from "@/utils/message";

import AdminConfirmModal from "./AdminConfirmModal";
import DeleteConfirmModal from "../DeleteConfirmModal";
type Props = {
  user?: number;
  userId: number | null;
  close?: () => void;
};
export const UserDetail: FC<Props> = ({ userId, close }) => {
  const [openConfirmSetRole, setOpenConfirmSetRole] = useState(false);
  const groupInfo = useAppSelector((state) => state.group.data);
  const user = useAppSelector((state) => state.auth.user);
  const [openDetail, setOpenDetail] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(10);
  const [currentDevice, setCurrentDevice] = useState(10);
  const [loading, setLoading] = useState(false);
  const [memberDetail, setMemberDetail] = useState<MemberDetailDef | null>(
    null
  );
  const [openAssignRole, setOpenAssignRole] = useState(false);
  const [changeAlias, setChangeAlias] = useState(memberDetail?.alias ?? "");
  const [role, setRole] = useState(memberDetail?.role);
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const getMemberDetail = async () => {
    setLoading(true);
    try {
      if (groupInfo && userId) {
        const result = await getDetailGroupMember({
          groupId: groupInfo?.id,
          userId: userId,
        });
        setMemberDetail(result.data);
        setChangeAlias(result.data.alias ?? "");
        setRole(result.data.role);
      }
    } catch (error: any) {
      setOpenDetail(false);
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      setChangeAlias("");
      setOpenDetail(true);
      getMemberDetail();
    }
  }, [userId]);

  const handleUpdateAlias = async () => {
    try {
      if (!groupInfo || !memberDetail) return;
      await updateGroupMember(groupInfo.id, memberDetail.userId, changeAlias);
      await getMemberDetail();
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG81"),
      });
      dispatch(
        getGroupMembersListThunk({
          pageNumber: 1,
          pageSize: 9,
          groupId: groupInfo.id,
        })
      );
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    }
  };

  const handleAssignRole = async () => {
    if (!groupInfo || !memberDetail) {
      return;
    }
    try {
      await assignRole(
        groupInfo?.id,
        memberDetail?.userId,
        memberDetail?.role === GroupUserRoleEnums.ADMIN
          ? GroupUserRoleEnums.MEMBER
          : GroupUserRoleEnums.ADMIN
      );
      dispatch(
        getGroupMembersListThunk({
          pageNumber: 1,
          pageSize: 9,
          groupId: groupInfo.id,
        })
      );
      setOpenConfirmSetRole(false);
      getMemberDetail();
      toast({
        title: "Thành công!",
        variant: "success",
        description: getMessage("MSG86"),
      });
    } catch (error) {
      setRole(memberDetail.role);
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };

  const checkDisableInput = (member: MemberDetailDef) => {
    const userRole = groupInfo?.role?.code;
    if (userRole === GroupUserRoleEnums.OWNER) {
      return false;
    }
    if (userRole === GroupUserRoleEnums.ADMIN) {
      if (user?.id === member.userId) {
        return false;
      } else {
        if (member.role === GroupUserRoleEnums.MEMBER) {
          return false;
        }
      }
    }
    if (userRole === GroupUserRoleEnums.MEMBER) {
      if (user?.id === member.userId) {
        return false;
      }
    }
    return true;
  };

  return (
    <Sheet
      open={openDetail}
      onOpenChange={() => (setOpenDetail(false), close && close())}
    >
      <SheetContent className="sm:max-w-[500px] p-0 ">
        {loading ? (
          <div className="flex justify-center items-center p-2">
            <TGNLoading size="medium" />
          </div>
        ) : (
          <>
            <div className="p-6 gap-4 w-full max-w-[500px] bg-white flex-col justify-start items-start inline-flex border-b border-secondary-100 fixed top-0">
              <Button
                className="absolute right-4 top-4 z-50"
                variant={"text"}
                onClick={() => (close && close(), setOpenDetail(false))}
              >
                <Close />
              </Button>
              {memberDetail && (
                <div className="flex w-full items-center gap-2">
                  <TGNAvatar
                    size="64"
                    userName={
                      memberDetail.name ??
                      memberDetail.email ??
                      memberDetail.phone
                    }
                    src={memberDetail.photoUrl}
                  />
                  <div className="">
                    <p className="flex items-center gap-2 font-medium ">
                      {memberDetail.name ?? memberDetail.email}
                      {memberDetail.role !== GroupUserRoleEnums.MEMBER && (
                        <Badge
                          className="ml-1 font-medium text-[10px] leading-[14px]"
                          variant={
                            memberDetail.role === GroupUserRoleEnums.OWNER
                              ? "default"
                              : "orange"
                          }
                        >
                          <UserAvatar
                            className="mr-1"
                            size={12}
                          />
                          {memberDetail.role === GroupUserRoleEnums.OWNER
                            ? "Chủ sở hữu"
                            : "Quản trị viên"}
                        </Badge>
                      )}
                    </p>
                    <p className="text-secondary-600 text-sm">
                      {memberDetail.email ?? memberDetail.phone}
                    </p>
                  </div>
                </div>
              )}
              {memberDetail && (
                <div className="flex w-full gap-3">
                  {memberDetail.userId !== user?.id &&
                    memberDetail.relationshipStatus !==
                      RelationshipStatusEnums.ACCEPTED && (
                      <Button className="flex-1">Thêm bạn bè</Button>
                    )}
                  <Button
                    variant={"ghost"}
                    className="flex-1"
                  >
                    Trang cá nhân
                  </Button>
                  {userId && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div className="p-3 cursor-pointer">
                          <OverflowMenuVertical />
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <Button
                          variant="text"
                          className="w-full justify-start"
                        >
                          <MapCenter className="me-1" />
                          Xem trong Sơ đồ tổ chức
                        </Button>
                        {groupInfo?.role?.code === GroupUserRoleEnums.OWNER &&
                          user?.id !== memberDetail.userId && (
                            <Dialog open={openAssignRole}>
                              <DialogTrigger asChild>
                                <Button
                                  variant="text"
                                  className="w-full justify-start"
                                  onClick={() => setOpenAssignRole(true)}
                                >
                                  <UserMilitary className="me-1" />
                                  {memberDetail?.role !==
                                  GroupUserRoleEnums.ADMIN
                                    ? "Trao quyền Quản trị viên"
                                    : "Tước quyền Quản trị viên"}
                                </Button>
                              </DialogTrigger>
                              <DialogContent
                                className={"sm:max-w-[600px] bg-white"}
                              >
                                <DialogHeader className="flex flex-row gap-4">
                                  <div>
                                    <span className="border-[6px] p-1 text-warning-600 border-warning-50 bg-warning-100 rounded-full block">
                                      <InformationFilled size={20} />
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <DialogTitle className="text-lg">
                                      {memberDetail?.role !==
                                      GroupUserRoleEnums.ADMIN
                                        ? "Trao quyền Quản trị viên"
                                        : "Tước quyền Quản trị viên"}
                                    </DialogTitle>
                                    <DialogDescription className="text-secondary-600 mt-2">
                                      {memberDetail?.role !==
                                      GroupUserRoleEnums.ADMIN ? (
                                        <>
                                          <p className="mt-2">
                                            Bạn chắc chắn muốn thêm{" "}
                                            {memberDetail?.alias ??
                                              memberDetail?.name}{" "}
                                            trở thành Quản trị viên tổ chức của
                                            bạn?
                                          </p>
                                          <p className="mt-5">
                                            Quản trị viên có quyền thực hiện các
                                            tính năng nâng cao (tạo nhóm, quản
                                            lý thành viên), xây dựng và quản lý
                                            các bộ phận trong tổ chức. Tìm hiểu
                                            thêm↗
                                          </p>
                                        </>
                                      ) : (
                                        <p>
                                          Bạn chắc chắn muốn gỡ{" "}
                                          {memberDetail?.alias ??
                                            memberDetail?.name}{" "}
                                          khỏi danh sách Quản trị viên tổ chức
                                          của bạn?
                                        </p>
                                      )}
                                    </DialogDescription>
                                  </div>
                                </DialogHeader>
                                <DialogFooter className="justify-end gap-2">
                                  <DialogClose asChild>
                                    <Button
                                      size="sm"
                                      variant="outline"
                                      onClick={() => setOpenAssignRole(false)}
                                    >
                                      Huỷ bỏ
                                    </Button>
                                  </DialogClose>
                                  <Button
                                    onClick={handleAssignRole}
                                    size="sm"
                                  >
                                    Xác nhận
                                  </Button>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          )}
                        {(groupInfo?.role?.code === GroupUserRoleEnums.OWNER ||
                          (groupInfo?.role?.code === GroupUserRoleEnums.ADMIN &&
                            memberDetail.role === GroupUserRoleEnums.MEMBER)) &&
                          user?.id !== memberDetail.userId && (
                            <DeleteConfirmModal
                              user={memberDetail}
                              button={
                                <Button
                                  variant="text"
                                  className="w-full justify-start"
                                >
                                  <TrashCan className="me-1" />
                                  Xoá thành viên
                                </Button>
                              }
                            />
                          )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              )}
            </div>
            <div className="overflow-hidden lg:overflow-auto mt-[170px] h-[calc(100%-170px)]">
              <div className="grid py-4 p-6 border-b border-secondary-100">
                <p className="font-medium text-secondary-900 mb-3">
                  Thông tin thành viên
                </p>
                <div className="grid grid-cols-3 items-center gap-4">
                  <Label
                    htmlFor="name"
                    className="text-gray-600 text-sm font-normal"
                  >
                    Tên
                  </Label>
                  {memberDetail && (
                    <p className="col-span-2 text-sm font-medium px-3 py-2.5">
                      {memberDetail.name ??
                        memberDetail.email ??
                        memberDetail.phone}
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-3 items-start gap-4">
                  <p className="text-gray-600 text-sm font-normal mt-3">
                    Tên hiển thị{" "}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <InformationFilled className="inline" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            Tuỳ chỉnh hiển thị thông tin của người dùng trong{" "}
                            {groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </p>
                  {memberDetail && (
                    <div className="relative col-span-2 group">
                      <Input
                        disabled={checkDisableInput(memberDetail)}
                        placeholder="Tên hiển thị"
                        className="pr-9 border-none shadow-none hover:border hover:shadow hover:bg-secondary-100 read-only:hover:bg-transparent read-only:hover:shadow-none disabled:bg-transparent read-only:bg-transparent"
                        defaultValue={memberDetail.alias}
                        onChange={(e) => {
                          if (changeAlias !== e.target.value) {
                            setChangeAlias(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          if (memberDetail.alias !== e.target.value) {
                            handleUpdateAlias();
                          }
                        }}
                      />
                      {!!groupInfo?.moderatorInfos.find(
                        (item) =>
                          item.id === user?.id &&
                          item.role.code === GroupUserRoleEnums.OWNER
                      ) && (
                        <Edit className="hidden group-hover:block absolute top-1/2 right-3 transform -translate-y-1/2" />
                      )}
                    </div>
                  )}
                </div>
                {/* <Button
                  variant="text"
                  className="w-full justify-start pl-0"
                  onClick={() => setOpenContact(!openContact)}
                >
                  <ChevronDown className="me-1" />
                  Thông tin lên hệ
                </Button>
                {openContact && (
                  <div>
                    <div className="grid grid-cols-3 items-start gap-4">
                      <p className="text-gray-600 text-sm font-normal mt-3">
                        Họ và tên
                      </p>
                      <span className="col-span-2 text-sm px-4 font-medium py-3 min-h-[40px] flex gap-1 flex-wrap text-secondary-900">
                        Guy Hawkins
                      </span>
                    </div>
                    <div className="grid grid-cols-3 items-start gap-4">
                      <p className="text-gray-600 text-sm font-normal mt-3">
                        Họ và tên
                      </p>
                      <span className="col-span-2 text-sm px-4 font-medium py-3 min-h-[40px] flex gap-1 flex-wrap text-secondary-900">
                        Guy Hawkins
                      </span>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="p-3 border-b border-secondary-100">
                <p className="p-3 text-sm">
                  Vai trò trong {!groupInfo?.rootGroupId ? "Tổ chức" : "Nhóm"}
                </p>
                <RadioGroup
                  value={role}
                  onValueChange={() => setOpenConfirmSetRole(true)}
                >
                  {memberDetail?.role === GroupUserRoleEnums.OWNER ? (
                    <Label
                      className={"flex px-4 py-2 items-start"}
                      htmlFor="r1"
                    >
                      <RadioGroupItem
                        disabled={
                          groupInfo?.role?.code !== GroupUserRoleEnums.OWNER
                        }
                        value={GroupUserRoleEnums.OWNER}
                        id="r1"
                        className="mt-1"
                      />
                      <div className="grid grap-2">
                        <span className="text-sm font-medium text-secondary-900">
                          Chủ sở hữu
                        </span>
                        <span className="text-xs text-secondary-600 font-normal mt-1">
                          Chủ sở hữu của{" "}
                          {!groupInfo?.rootGroupId ? "Tổ chức" : "Nhóm"}
                        </span>
                      </div>
                    </Label>
                  ) : (
                    <>
                      <Label
                        className={"flex px-4 py-2 items-start"}
                        htmlFor="r1"
                      >
                        <RadioGroupItem
                          disabled={
                            groupInfo?.role?.code !== GroupUserRoleEnums.OWNER
                          }
                          value={GroupUserRoleEnums.ADMIN}
                          className="mt-1"
                        />
                        <div className="grid grap-2">
                          <span className="text-sm font-medium text-secondary-900">
                            Quản trị viên
                          </span>
                          <span className="text-xs text-secondary-600 font-normal mt-1">
                            Quản trị viên có thể thực hiện các tính năng nâng
                            cao (tạo nhóm, quản lý thành viên), xây dựng và quản
                            lý các bộ phận trong{" "}
                            {!groupInfo?.rootGroupId ? "tổ chức" : "nhóm"}.
                          </span>
                        </div>
                      </Label>
                      <Label
                        className={"flex px-4 py-2 items-start"}
                        htmlFor="r1"
                      >
                        <RadioGroupItem
                          disabled={
                            groupInfo?.role?.code !== GroupUserRoleEnums.OWNER
                          }
                          value={GroupUserRoleEnums.MEMBER}
                          className="mt-1"
                        />
                        <div className="grid grap-2">
                          <span className="text-sm font-medium text-secondary-900">
                            Thành viên
                          </span>
                          <span className="text-xs text-secondary-600 font-normal mt-1">
                            Có thể truy cập và sử dụng tài nguyên được cấp quyền
                            trong {!groupInfo?.rootGroupId ? "tổ chức" : "nhóm"}
                            .
                          </span>
                        </div>
                      </Label>
                    </>
                  )}
                </RadioGroup>
              </div>
              <div className="py-3 border-b border-secondary-100">
                <div className="flex items-center gap-1 text-secondary-600 p-3 px-6">
                  <span className="font-medium text-secondary-900">
                    Nhóm đã tham gia
                  </span>
                  <DotMark
                    size={5}
                    color="#4B5563"
                    className="ml-1 mr-1"
                  ></DotMark>
                  <span className="font-semibold">
                    {memberDetail?.numberOfGroupsParticipated}
                  </span>
                </div>
                <div className="flex-none min-w-full px-0 ">
                  {memberDetail?.groupsParticipated &&
                    memberDetail?.groupsParticipated
                      .slice(0, currentGroup)
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full h-16 p-3 px-6 bg-white justify-between items-center inline-flex hover:bg-secondary-50"
                          >
                            <div className="justify-start items-center gap-2 flex">
                              <TGNAvatar
                                size="40"
                                src={item.photoUrl || GroupAvatarDefault}
                                userName={item.groupName}
                              />
                              <div className="flex-col justify-start items-start inline-flex">
                                <div className="self-stretch text-secondary-900 text-sm font-medium ">
                                  {item.groupName}
                                </div>
                                <div className="justify-start items-start gap-1 inline-flex">
                                  <div className="text-secondary-600 text-xs">
                                    Đã tham gia
                                  </div>

                                  <div className="text-secondary-600 text-xs">
                                    {formatDistance(item.joinedAt)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
                {memberDetail?.groupsParticipated &&
                  memberDetail?.groupsParticipated.length > currentGroup && (
                    <div className="px-6">
                      <Button
                        variant={"ghost"}
                        className="block w-full"
                        onClick={() => setCurrentGroup(currentGroup + 10)}
                      >
                        Xem thêm
                      </Button>
                    </div>
                  )}
              </div>
              <div className="py-3 border-b border-secondary-100">
                <div className="flex items-center gap-1 text-secondary-600 p-3 px-6">
                  <span className="font-medium text-secondary-900">
                    Thiết bị truy cập
                  </span>
                  <DotMark
                    size={5}
                    color="#4B5563"
                    className="ml-1 mr-1"
                  ></DotMark>
                  <span className="font-semibold">
                    {memberDetail?.numberOfDevice}
                  </span>
                </div>
                {memberDetail?.userGroupDeviceInfo &&
                  memberDetail?.userGroupDeviceInfo
                    .slice(0, currentDevice)
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex-none min-w-full px-0 "
                        >
                          <div className="w-full h-16 p-3 px-6 bg-white justify-between items-center inline-flex hover:bg-secondary-50">
                            <div className="justify-start items-center gap-2 flex">
                              <div className="w-10 h-10 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex ">
                                <Devices
                                  className="text-secondary-600 "
                                  size={20}
                                />
                              </div>
                              <div className="flex-col justify-start items-start inline-flex">
                                <div className="self-stretch text-secondary-900 text-sm font-medium ">
                                  {item.deviceUniqueId}
                                </div>
                                <div className="justify-start items-start gap-1 inline-flex">
                                  <div className="text-secondary-600 text-xs">
                                    Hoạt động
                                  </div>
                                  <div className="text-secondary-600 text-xs">
                                    4 tháng trước
                                  </div>
                                </div>
                              </div>
                            </div>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  className="px-3 py-2"
                                  variant={"text"}
                                >
                                  <OverflowMenuVertical />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <Button
                                  className="w-full me-2.5"
                                  variant={"text"}
                                  size={"sm"}
                                >
                                  <TrashCan className="me-1" />
                                  Gỡ thiết bị
                                </Button>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      );
                    })}
                {memberDetail?.userGroupDeviceInfo &&
                  memberDetail?.userGroupDeviceInfo.length > currentDevice && (
                    <div className="px-6">
                      <Button
                        variant={"ghost"}
                        className="block w-full"
                        onClick={() => setCurrentDevice(currentDevice + 10)}
                      >
                        Xem thêm
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
      </SheetContent>
      <Dialog open={openConfirmSetRole}>
        <DialogContent className={"sm:max-w-[600px] bg-white"}>
          <DialogHeader className="flex flex-row gap-4">
            <div>
              <span className="border-[6px] p-1 text-warning-600 border-warning-50 bg-warning-100 rounded-full block">
                <InformationFilled size={20} />
              </span>
            </div>
            <div className="flex-1">
              <DialogTitle className="text-lg">
                {memberDetail?.role !== GroupUserRoleEnums.ADMIN
                  ? "Trao quyền Quản trị viên"
                  : "Tước quyền Quản trị viên"}
              </DialogTitle>
              <DialogDescription className="text-secondary-600 mt-2">
                {memberDetail?.role !== GroupUserRoleEnums.ADMIN ? (
                  <>
                    <p className="mt-2">
                      Bạn chắc chắn muốn thêm{" "}
                      {memberDetail?.alias ?? memberDetail?.name} trở thành Quản
                      trị viên tổ chức của bạn?
                    </p>
                    <p className="mt-5">
                      Quản trị viên có quyền thực hiện các tính năng nâng cao
                      (tạo nhóm, quản lý thành viên), xây dựng và quản lý các bộ
                      phận trong tổ chức. Tìm hiểu thêm↗
                    </p>
                  </>
                ) : (
                  <p>
                    Bạn chắc chắn muốn gỡ{" "}
                    {memberDetail?.alias ?? memberDetail?.name} khỏi danh sách
                    Quản trị viên tổ chức của bạn?
                  </p>
                )}
              </DialogDescription>
            </div>
          </DialogHeader>
          <DialogFooter className="justify-end gap-2">
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                setRole(memberDetail?.role);
                setOpenConfirmSetRole(false);
              }}
            >
              Huỷ bỏ
            </Button>
            <Button
              onClick={handleAssignRole}
              size="sm"
            >
              Xác nhận
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Sheet>
  );
};
