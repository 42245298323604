import React, { memo, useMemo, useEffect, useCallback } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import TGNSelect from "@/components/TGNSelect/TGNSelect";
import TGNTable from "@/components/TGNTable/TGNTable";
import Typography from "@/components/Typography/Typography";
import {
  AssignRolesEnum,
  AssigneeRolesOptions,
  UpdateDescriptionType,
  getAssignees,
  removeAssignee,
  updateAssignee,
  updateStyleNode,
} from "@/features/mindmap/mindmap";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { SelectItem } from "@/types/app.types";

import styles from "./MemberManagement.module.scss";

const MemberManagement = () => {
  const dispatch = useAppDispatch();
  const { selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatch(
      getAssignees({
        groupId: Number(searchParams.get("group_id")) || 0,
        pageNum: 0,
        pageSize: 50,
      })
    ).then(unwrapResult);
  }, [selectedNode, dispatch, searchParams]);

  const assigneeOptions = useMemo(
    () =>
      (selectedNode?.data.description.assignees || []).filter(
        (assignee) => assignee.role !== AssignRolesEnum.OWNER
      ),
    [selectedNode?.data.description.assignees]
  );
  const onChangeRole = useCallback(
    (e: SelectItem, username: string) => {
      dispatch(
        updateAssignee({
          username,
          role: e?.value as AssignRolesEnum | null,
        })
      );
      dispatch(
        updateStyleNode({
          type: UpdateDescriptionType.ASSIGNEES,
        })
      );
    },
    [dispatch]
  );
  const handleRemoveAssignee = useCallback(
    (username: string) => {
      dispatch(removeAssignee({ username }));
      dispatch(
        updateStyleNode({
          type: UpdateDescriptionType.ASSIGNEES,
        })
      );
    },
    [dispatch]
  );
  const dataTable = useMemo(() => {
    return assigneeOptions.map((assignee) => {
      const assignRoleSelected = AssigneeRolesOptions.find(
        (item) => item.value === assignee.role
      );
      return {
        name: (
          <div className="d-flex align-items-center gap-4">
            <TGNAvatar
              src={assignee.user.photo}
              name={assignee.user.name}
              size="32"
            />
            <div className="d-flex">
              <Typography className="fs-14 fw-semibold">
                {assignee.user.name}
              </Typography>
              <span className="text-tertiary-color-gray-5 fs-12">
                @{assignee.user.username}
              </span>
            </div>
          </div>
        ),
        role: (
          <TGNSelect
            itemList={AssigneeRolesOptions}
            value={assignRoleSelected}
            variant="text"
            placeholder="-"
            onChange={(value) => onChangeRole(value, assignee.user.username)}
          />
        ),
        delete: (
          <OverlayTrigger
            placement="bottom-start"
            overlay={<Tooltip>Xóa thành viên</Tooltip>}
          >
            <i
              className="tgn-outline-user-circle-minus text-tertiary-color-gray-5 fs-20 cursor-pointer"
              onClick={() => handleRemoveAssignee(assignee.user.username)}
            ></i>
          </OverlayTrigger>
        ),
      };
    });
  }, [assigneeOptions, handleRemoveAssignee, onChangeRole]);

  return (
    <div className="mt-12">
      <div className="d-flex justify-content-between align-items-center">
        <Typography className="fs-18 fw-600">Thành viên</Typography>
        <div className="d-flex gap-4 align-items-center">
          <div className={styles.search}>
            <i className="tgn-outline-search-02 fs-16"></i>
          </div>
        </div>
      </div>
      <Container className="mt-12">
        <TGNTable
          columns={[
            {
              label: "Tên thành viên",
              value: "name",
              isSort: true,
            },
            {
              label: "Vai trò",
              value: "role",
              isSort: true,
            },
            {
              label: "",
              value: "delete",
            },
          ]}
          data={dataTable}
        />
      </Container>
    </div>
  );
};

export default memo(MemberManagement);
