import React, { FC, memo } from "react";

import cx from "classnames";

import style from "./Loading.module.scss";

type TGNLoadingProps = {
  isFullScreen?: boolean;
  size?: "small" | "medium" | "large";
};

const TGNLoading: FC<TGNLoadingProps> = ({
  size = "small",
  isFullScreen = false,
}) => {
  const border = {
    small: 24,
    medium: 32,
    large: 64,
  };
  return (
    <div className={isFullScreen ? style.fullscreen : "inline-flex"}>
      <div
        className={cx(style["loader"], style[`${size}`])}
        style={{
          width: border[size],
          height: border[size],
        }}
      ></div>
    </div>
  );
};

export default memo(TGNLoading);
