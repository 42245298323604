import React, { FC } from "react";

interface ProgressBarProps {
  total: number;
  current: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ total, current }) => {
  const circumference = 2 * Math.PI * 26;

  const progress = (current / total) * circumference;
  const offset = circumference - progress;
  const percent = ((current / total) * 100).toFixed(2);

  return (
    <span className="z-10 relative inline-flex">
      <span className="text-xs font-semibold text-primary-600 absolute top-1/2 left-[13px] -translate-y-1/2">
        {percent}%
      </span>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
      >
        <circle
          cx="28"
          cy="28"
          r="26"
          fill="none"
          stroke="#f0f2f5"
          strokeWidth="3"
        />
        <circle
          cx="28"
          cy="28"
          r="26"
          fill="none"
          stroke="#4d7cfe"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </span>
  );
};

export default ProgressBar;
