import React, { useRef, useEffect, ReactNode } from "react";

import { WorkflowStatus } from "../constants/operation.constant";
import { IWorkflowContent } from "../types/operation";

interface FiMenuContentProps {
  actions: Action[];
  handleAction: (key: string | number, file: IWorkflowContent) => void;
}

interface Action {
  key: string | number;
  label?: string;
  icon?: ReactNode;
}

interface FiMenuContentProps {
  actions: Action[];
  onClose: () => void;
  file: IWorkflowContent;
}

export const FiMenuContent: React.FC<FiMenuContentProps> = ({
  actions,
  file,
  handleAction,
  onClose,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const renderFileMenu = (action: Action, index: number) => {
    if (action.key === "line") {
      return (
        <div
          key={index}
          className="border-t border-secondary-200 mb-1 mt-1 "
        ></div>
      );
    } else {
      return (
        <div
          key={index}
          onClick={() => handleAction(action.key, file)}
          className={
            "flex items-center gap-2 px-2 py-2.5 cursor-pointer text-sm hover:bg-secondary-100 rounded-md text-black"
          }
        >
          {action.icon}
          <div>{action.label}</div>
        </div>
      );
    }
  };

  return (
    <div
      ref={menuRef}
      className="bg-white rounded-md shadow-2xl p-1 w-[200px] border border-secondary-200"
    >
      {actions
        .filter((action) => {
          if (action.key === "stop" && file.status === WorkflowStatus.STOPPED) {
            return false;
          }
          if (
            action.key === "stop" &&
            file.status === WorkflowStatus.CONNECTING
          ) {
            return false;
          }
          return true;
        })
        .map((action, index) => renderFileMenu(action, index))}
    </div>
  );
};
