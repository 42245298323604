import React, { memo } from "react";

import TGNButton from "@/components/TGNButton/TGNButton";
import TGNChip from "@/components/TGNChip/TGNChip";
import TGNInputField from "@/components/TGNInput/TGNInputField";

const HastagPopup = () => {
  return (
    <div>
      <div className="d-flex align-items-center gap-1 mb-3">
        <span className="fs-14 fw-600">Add Hashtags</span>
        <i className="tgn-solid-information-circle fs-16 text-tertiary-color-gray-5"></i>
      </div>
      <TGNInputField placeholder="Add or paste any hashtag here" />
      <div className="mt-3 d-flex gap-1 flex-wrap">
        <TGNChip
          label="Food & Beverage"
          removable
        />
        <TGNChip
          label="Clothing"
          removable
        />
        <TGNChip
          label="Manufacturing"
          removable
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <TGNButton
          className="rounded-2"
          height={40}
        >
          <span className="fs-14">Submit</span>
        </TGNButton>
      </div>
    </div>
  );
};

export default memo(HastagPopup);
