import React, { useState } from "react";

const ProfileSetting = () => {
  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-end-11">
            <p className="text-lg font-semibold text-secondary-900">
              Giao diện & Ngôn ngữ
            </p>
            <p className="text-xs text-secondary-600">
              Quản lý thông tin cá nhân của bạn, đồng thời kiểm soát thông tin
              nào người khác xem được và ứng dụng nào có thể truy cập.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
