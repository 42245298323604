export enum AccountEndpointsEnum {
  UPDATE_PROFILE = "/user/profile",
  UPLOAD_PHOTO = "/user/photo",
  OTP = "/user/send-otp",
  LOGIN = "/login",
  MFA = "/user/mfa",
  USER_OTP = "/user/otp",
  CHANGE_PASSWORD = "/user/change-password",
  UPDATE_PHONE_NUMBER_OR_EMAIL = "/user/email-phonenumber",
  CHECK_EXIST_EMAIL_OR_PHONENUMBER = "/user/check-exist",
  SEND_OTP_TO_PHONE_NUMBER = "/user/send-otp/phonenumber",
  REMOVE_EMAIL_OR_PHONE_NUMBER = "/user/remove",
}
