import * as Yup from "yup";

import { getMessage } from "@/utils/message";
import { PASSWORD_REGEX } from "@/utils/regex";

export type UpdateProfileFormType = {
  name: string;
  biography: string;
  company: string;
  location: string;
};

export const UPDATE_PROFILE_INITIAL_VALUES: UpdateProfileFormType = {
  name: "",
  biography: "",
  company: "",
  location: "",
};

export const updateProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required(getMessage("MSG_TK1", "Tên đầy đủ"))
    .max(255, getMessage("MSG_TK2", "Tên đầy đủ", "255")),
  biography: Yup.string().max(1000, getMessage("MSG_TK2", "Tiểu sử", "1000")),
  company: Yup.string().max(255, getMessage("MSG_TK2", "Công ty", "255")),
  location: Yup.string().max(255, getMessage("MSG_TK2", "Vị trí", "255")),
});

export const updateEmailSchema = Yup.object().shape({
  oldEmail: Yup.string(),
  email: Yup.string()
    .email(getMessage("MSG_TK14", "Email"))
    .required(getMessage("MSG_TK1", "Email"))
    .when("oldEmail", (oldEmail, field) =>
      oldEmail
        ? field
            .required(getMessage("MSG_TK1", "Email"))
            .notOneOf([Yup.ref("oldEmail")], getMessage("MSG_TK28"))
        : field
    ),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const updatePhoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required(getMessage("MSG_TK1", "Số điện thoại"))
    .matches(phoneRegExp, getMessage("MSG_TK14")),
});

export const changePasswordSchema = Yup.object().shape({
  pwd: Yup.string()
    .required(getMessage("MSG_TK1", "Mật khẩu"))
    .max(53, getMessage("MSG_TK2", "Mật khẩu", "53"))
    .matches(PASSWORD_REGEX, getMessage("MSG_TK5")),
  confirmPwd: Yup.string().when("pwd", (pwd, field) =>
    pwd
      ? field
          .required(getMessage("MSG_TK1", "Mật khẩu"))
          .oneOf([Yup.ref("pwd")], getMessage("MSG_TK6"))
      : field
  ),
});
