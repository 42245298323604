import React, { FC, useEffect, useRef, useState } from "react";

import { CaretDown, DocumentTasks } from "@carbon/icons-react";

import { DataForm } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { useToast } from "@/components/ui/use-toast";
import { useAppSelector } from "@/redux/store";
import { extractUsername } from "@/utils/extractUsername";

import { getInstanceBlockContent } from "../api/operation.api";
import {
  BlockInstanceContent,
  BlockResponse,
  BlockSection,
} from "../types/operation";

export const BlockDetail: React.FC<{ groupId: number }> = ({ groupId }) => {
  const { toast } = useToast();
  const currentBlock = useAppSelector((state) => state.workflow.currentBlock);
  const [blockDetail, setBlockDetail] = useState<BlockInstanceContent>();

  const [groupedSections, setGroupedSections] =
    useState<Record<string, BlockSection[]>>();

  useEffect(() => {
    if (currentBlock?.data.id) {
      fetchContent(currentBlock?.data.id, groupId);
    }
  }, [currentBlock]);
  const fetchContent = async (blockId: number, groupId: number) => {
    try {
      const result = await getInstanceBlockContent({
        blockId: blockId,
        groupId: groupId,
      });
      setBlockDetail(result.data);
      setGroupedSections(groupSectionsByRole(result.data.sections));
    } catch (error) {
      console.log(error);
      // toast({
      //   title: "Thất bại!",
      //   variant: "error",
      //   description: "Tải dữ liệu thất bại",
      // });
    }
  };
  function groupSectionsByRole(
    sections: BlockSection[]
  ): Record<string, BlockSection[]> {
    return sections.reduce((acc, section) => {
      const role = section.assignRole;
      if (!acc[role]) {
        acc[role] = [];
      }
      acc[role].push(section);
      return acc;
    }, {} as Record<string, BlockSection[]>);
  }
  const renderSection = ({
    role,
    sections,
  }: {
    role: string;
    sections: BlockSection[];
  }) => {
    return (
      <>
        <div className="flex-col justify-start items-start flex">
          <div className="py-2 justify-start items-center gap-2 inline-flex">
            <CaretDown />
            <div className="text-xs font-medium uppercase leading-none">
              {role}
            </div>
          </div>
        </div>
        {sections.map((s, i) => (
          <div
            key={s.id}
            className="flex flex-col gap-2"
          >
            <div className="text-secondary-600 text-sm ">
              {s.roleAlias || `${s.assignRole} ${i + 1}`}
            </div>
            <div className="flex gap-2 items-center">
              <TGNAvatar
                size="32"
                src={s.user?.photo}
                userName={s.user?.name || s.user?.email || "NULL"}
              />
              <div>
                <p className="font-medium text-sm">
                  {s.user?.name ?? extractUsername(s.user?.email || "NULL")}
                </p>
                <p className="text-secondary-600 text-xs">
                  {s.user?.email || s.user?.name || "NULL"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="h-10 p-3 border-b border-[#e8e8e8] w-full">
        <div className="grow shrink basis-0 text-xs font-normal leading-none">
          Bước {currentBlock?.data?.id}
        </div>
      </div>
      <div className="w-full px-3 py-4 border-b border-[#e8e8e8] flex-col justify-start items-start gap-3 flex">
        <div className="flex-col justify-center items-start gap-1 flex">
          <div className="text-secondary text-xs font-normal ">Tên bước</div>
          <div className="py-1 rounded justify-start items-center gap-2 inline-flex overflow-hidden">
            <div className="grow shrink basis-0 justify-start items-center gap-2 flex">
              <div className="text-xs font-medium ">{blockDetail?.name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-3 border-b border-[#e8e8e8] flex-col justify-start items-start gap-3 flex">
        <div className="h-5 flex-col justify-start items-start gap-1 flex">
          <div className="justify-between items-center inline-flex">
            <div className="text-sm font-medium leading-tight">Tác vụ</div>
          </div>
        </div>
        <div className="px-2 py-2.5 w-full bg-[#e8e8e8] rounded justify-start items-center gap-2 inline-flex">
          <DocumentTasks />
          <div className="text-sm font-normal leading-tight">
            {blockDetail?.taskName}
          </div>
        </div>
      </div>
      <div className="w-full p-3 border-b border-[#e8e8e8] flex-col justify-start items-start gap-3 flex">
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="justify-between items-center inline-flex">
            <div className="text-sm font-medium leading-tight">
              Danh sách thực thi
            </div>
          </div>
        </div>
        <div className="flex-col justify-start items-start flex gap-2">
          {groupedSections?.REPORTER && (
            <>
              {renderSection({
                role: "REPORTER",
                sections: groupedSections.REPORTER || [],
              })}
            </>
          )}
          {groupedSections?.EDITOR && (
            <>
              {renderSection({
                role: "EDITOR",
                sections: groupedSections.EDITOR || [],
              })}
            </>
          )}
          {groupedSections?.VIEWER && (
            <>
              {renderSection({
                role: "VIEWER",
                sections: groupedSections.VIEWER || [],
              })}
            </>
          )}
        </div>
      </div>
      <div className="w-full p-3 border-b border-secondary-200 flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-medium leading-tight">
              Tệp tin liên quan
            </h2>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          {blockDetail?.dataformInfos.map((d) => (
            <div
              key={d.id}
              className="flex items-center gap-2 w-full px-2 py-2.5 bg-secondary-200 rounded overflow-hidden"
            >
              <DataForm />
              <span
                className="text-sm truncate"
                title={d.name}
              >
                {d.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
