import React, { FC, memo, useEffect, useState } from "react";

import {
  CheckmarkFilled,
  Close,
  View,
  ViewOff,
  Warning,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";
import { Field, Form, Formik } from "formik";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";
import {
  MIN_EIGHT_CHARACTER_REGEX,
  MIN_ONE_CHARACTER_UPPERCASE_REGEX,
  SPECIAL_CHARACTER_REGEX,
} from "@/utils/regex";

import VerifyChangePwdOTP from "./VerifyChangePwdOTP";
import { changePassword, getUserOtp } from "../api/account.api";
import { changePasswordSchema } from "../helpers/form.helper";

type ChangePasswordDialogProps = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: {
    currentPwd: string;
    pwd: string;
    otp: string;
    uid: string;
  }) => void;
};

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const { pwd: currentPwd } = useAppSelector((state) => state.account);
  const { toast } = useToast();
  const [showPwd, setShowPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [openVerifyOTP, setOpenVerifyOTP] = useState(false);
  const [pwd, setPwd] = useState("");
  const [uid, setUid] = useState("");
  useEffect(() => {
    setOpenVerifyOTP(false);
    setUid("");
    setPwd("");
  }, [open]);

  const sendOtp = async (values: { pwd: string; confirmPwd: string }) => {
    try {
      if (!user || !user.userId) {
        return;
      }
      const result = await getUserOtp(user.userId, currentPwd);
      setUid(result.uid);
      setPwd(values.pwd);
      setOpenVerifyOTP(true);
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent className="pt-4 pb-10 rounded-xl border border-solid border-secondary-200 max-w-[600px] text-sm">
        <Formik
          initialValues={{
            pwd: "",
            confirmPwd: "",
          }}
          validationSchema={changePasswordSchema}
          onSubmit={sendOtp}
        >
          {({
            errors,
            handleChange,
            touched,
            isSubmitting,
            isValid,
            values,
          }) => (
            <Form>
              {openVerifyOTP ? (
                <VerifyChangePwdOTP
                  reFetchUid={() => sendOtp(values)}
                  loading={loading}
                  open={openVerifyOTP}
                  onClose={() => setOpenVerifyOTP(false)}
                  onSubmit={(otp) =>
                    onSubmit({
                      currentPwd,
                      pwd,
                      otp,
                      uid,
                    })
                  }
                />
              ) : (
                <>
                  {" "}
                  <div>
                    <Close
                      size={20}
                      className="absolute top-4 right-4 text-end cursor-pointer"
                      onClick={onClose}
                    />
                  </div>
                  <p className="text-lg font-semibold">Thay đổi mật khẩu</p>
                  <div className="mt-7">
                    <p className="text-xs text-secondary-900 font-medium mb-2">
                      Mật khẩu mới
                    </p>
                    <div className="relative">
                      <Field
                        name="pwd"
                        as={Input}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                        }}
                        type={showPwd ? "text" : "password"}
                        error={errors.pwd}
                        placeholder="Nhập mật khẩu"
                      ></Field>
                      {showPwd ? (
                        <View
                          className="absolute right-3 top-3 cursor-pointer"
                          onClick={() => setShowPwd(false)}
                        />
                      ) : (
                        <ViewOff
                          className="absolute right-3 top-3 cursor-pointer"
                          onClick={() => setShowPwd(true)}
                        />
                      )}
                    </div>
                    {touched.pwd && errors.pwd && (
                      <span className="text-xs text-red-600 flex gap-1 mt-1">
                        <Warning />
                        {errors.pwd}
                      </span>
                    )}
                    <div className="mt-7">
                      <p className="text-xs text-secondary-900 font-medium mb-2">
                        Xác nhận mật khẩu mới
                      </p>
                      <div className="relative">
                        <Field
                          name="confirmPwd"
                          as={Input}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChange(e);
                          }}
                          type={showNewPwd ? "text" : "password"}
                          error={errors.confirmPwd}
                          placeholder="Nhập mật khẩu"
                        ></Field>
                        {showNewPwd ? (
                          <View
                            className="absolute right-3 top-3 cursor-pointer"
                            onClick={() => setShowNewPwd(false)}
                          />
                        ) : (
                          <ViewOff
                            className="absolute right-3 top-3 cursor-pointer"
                            onClick={() => setShowNewPwd(true)}
                          />
                        )}
                      </div>
                      {touched.confirmPwd && errors.confirmPwd && (
                        <span className="text-xs text-red-600 flex gap-1 mt-1">
                          <Warning />
                          {errors.confirmPwd}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="p-3 bg-secondary-100 rounded mt-4">
                    <p className="text-secondary-900 font-medium mb-3">
                      Mật khẩu mới cần:
                    </p>
                    <div className="flex items-center gap-2 mb-2">
                      <CheckmarkFilled
                        className={cn(
                          MIN_EIGHT_CHARACTER_REGEX.test(values.pwd)
                            ? "text-success-500"
                            : "text-secondary-600"
                        )}
                      />
                      <span className="text-secondary-600">
                        Tối thiểu 8 ký tự chữ hoặc số
                      </span>
                    </div>
                    <div className="flex items-center gap-2 mb-2">
                      <CheckmarkFilled
                        className={cn(
                          MIN_ONE_CHARACTER_UPPERCASE_REGEX.test(values.pwd)
                            ? "text-success-500"
                            : "text-secondary-600"
                        )}
                      />
                      <span className="text-secondary-600">
                        Chứa tối thiểu 1 ký tự viết hoa
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <CheckmarkFilled
                        className={cn(
                          SPECIAL_CHARACTER_REGEX.test(values.pwd)
                            ? "text-success-500"
                            : "text-secondary-600"
                        )}
                      />
                      <span className="text-secondary-600">
                        Chứa tối thiểu 1 ký tự đặc biệt !@#$%^&*()~
                      </span>
                    </div>
                  </div>
                  <div className="mt-10">
                    <Button
                      className="font-medium text-sm w-full"
                      variant="primary"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        !touched.pwd ||
                        !touched.confirmPwd
                      }
                      loading={isSubmitting}
                    >
                      Xác nhận
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ChangePasswordDialog);
