import React from "react";

import { useNavigate } from "react-router-dom";

import Lock from "@/assets/images/lockorg.png";
import { Button } from "@/components/ui/button";

import { GroupPathsEnum } from "../../constants/group.paths";

const NotAvailableScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="p-6 flex flex-col items-center max-w-[416px]">
        <img
          className="w-[60px] h-[60px]"
          src={Lock}
          alt=""
        />
        <p className="text-lg font-semibold mb-2 mt-4 text-center">
          Bạn hiện không xem được nội dung này!
        </p>
        <p className="text-sm text-secondary-600 mb-6 text-center">
          Bạn không phải là thành viên của tổ chức/nhóm này nên không thể thực
          hiện tìm kiếm, truy cập hay xem trước các thông tin đã chia sẻ.
        </p>
        <Button
          onClick={() => {
            navigate(GroupPathsEnum.GROUP);
          }}
        >
          Nhóm của tôi
        </Button>
      </div>
    </div>
  );
};

export default NotAvailableScreen;
