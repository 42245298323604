import React, { useCallback, useEffect, useState } from "react";

import {
  InformationFilled,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import { Empty } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_DEFAULT } from "@/constants/app.constants";
import usePagination from "@/hooks/usePagination";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";
import { getCustomMessage, getMessage } from "@/utils/message";

import { updateStatusLeaveRequest } from "../../api/group.api";
import { getLeaveRequestsThunk } from "../../group";
import { LeaveRequestDef } from "../../types/group.type";
const GroupLeaveRequestsScreen = () => {
  const dispatch = useAppDispatch();
  const { data: groupInfo, leaveRequests } = useAppSelector(
    (state) => state.group
  );
  const { toast } = useToast();
  const [isLoadMore, setIsLoadMore] = useState(true);
  const { pagination, setPagination } = usePagination();
  const [totalRequest, setTotalRequest] = useState(0);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedRequest, setSelectedRequest] =
    useState<LeaveRequestDef | null>(null);

  const fetchLeaveRequests = useCallback(() => {
    if (groupInfo) {
      dispatch(
        getLeaveRequestsThunk({
          pageNumber: PAGE_DEFAULT,
          pageSize: pagination.size,
          groupId: groupInfo.id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          setTotalRequest(result.totalElements);
          if (!result.data.length || result.data.length < 9) {
            setIsLoadMore(false);
          }
        });
    }
  }, [dispatch, groupInfo]);

  const fetchInfiniteScroll = (page: number) => {
    if (groupInfo) {
      dispatch(
        getLeaveRequestsThunk({
          pageNumber: page,
          pageSize: pagination.size,
          groupId: groupInfo.id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          setTotalRequest(result.totalElements);
          if (!result.data.length || result.data.length < 9) {
            setIsLoadMore(false);
          }
        });
    }
  };

  const handleUpdateRequest = async (status: boolean) => {
    if (!selectedRequest || !groupInfo) {
      return;
    }
    dispatch(
      loadingAction.show({
        title: "Đang xử lý...",
        description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      await updateStatusLeaveRequest({
        userId: selectedRequest.user.id,
        groupId: groupInfo.id,
        accept: status,
      });
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage(
                status ? "MSG84" : "MSG71",
                selectedRequest.user.name
              ),
            }}
          ></div>
        ),
      });
      setSelectedRequest(null);
      dispatch(
        getLeaveRequestsThunk({
          pageNumber: 1,
          pageSize: pagination.size,
          groupId: groupInfo.id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          setTotalRequest(result.totalElements);
          if (!result.data.length || result.data.length < 9) {
            setIsLoadMore(false);
          }
        });
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  useEffect(() => {
    fetchLeaveRequests();
  }, [fetchLeaveRequests]);

  return (
    <div
      className={cx(
        "pb-6 min-h-[100vh]",
        leaveRequests.length === 0 && !isLoadMore ? "bg-white" : ""
      )}
    >
      {groupInfo && (
        <>
          <div className="bg-white pt-5 pb-6 z-10 sticky top-0 border-b border-secondary-100">
            <div className="container">
              <p className="text-lg font-semibold text-secondary-900">
                Yêu cầu rời {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}
              </p>
              <p className="text-xs text-secondary-600">
                Danh sách yêu cầu rời{" "}
                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-start-3 col-end-11">
              {leaveRequests.length === 0 && !isLoadMore ? (
                <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
                  <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                    <Empty />
                    <span className="text-secondary-600 text-xs mt-4 font-semibold">
                      Danh sách yêu cầu trống!
                    </span>
                  </div>
                </div>
              ) : (
                <div className="mt-6">
                  <p className="text-base font-semibold text-secondary-900">
                    Danh sách ・
                    <span className="text-secondary-600">{totalRequest}</span>
                  </p>
                  <p className="text-xs text-secondary-600 flex items-center">
                    Người dùng nhận được lời mời tham gia có quyền xem trước
                    thông tin của {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}.
                  </p>
                  <div className="mt-4 rounded-lg border border-secondary-200 bg-white p-3">
                    <InfiniteScroll
                      className="px-0"
                      dataLength={leaveRequests.length}
                      next={() => {
                        setPagination((prev) => ({
                          ...prev,
                          current: prev.current + 1,
                        }));
                        fetchInfiniteScroll(pagination.current + 1);
                      }}
                      hasMore={isLoadMore}
                      loader={
                        <div className="flex items-center justify-center mt-6">
                          <Loading3Dot
                            setWidth="30"
                            setHeight="30"
                          />
                        </div>
                      }
                    >
                      {leaveRequests.length > 0 &&
                        leaveRequests.map((request, index) => {
                          return (
                            <div
                              key={index}
                              className="p-3 flex gap-3 items-center"
                            >
                              <TGNAvatar
                                userName={
                                  request.user.alias ||
                                  request.user.alias ||
                                  extractUsername(request.user.email)
                                }
                                userPhoto={request.user.photoUrl}
                              />
                              <div>
                                <p
                                  className={`text-sm text-secondary-900 font-medium`}
                                >
                                  {request.user.alias ||
                                    request.user.alias ||
                                    extractUsername(request.user.email)}
                                </p>
                                <p className={`text-xs text-secondary-600`}>
                                  {request.user.userId}
                                </p>
                                <p
                                  className={`text-xs text-secondary-600 mt-2`}
                                >
                                  Đã gửi {formatDistance(request.createdAt)}{" "}
                                  trước
                                </p>
                              </div>
                              <Button
                                className="ml-auto"
                                type={"button"}
                                onClick={() => {
                                  setSelectedRequest(request);
                                  setOpenConfirmModal(true);
                                }}
                              >
                                Chấp nhận
                              </Button>
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button
                                    className="px-3 py-2"
                                    variant={"text"}
                                  >
                                    <OverflowMenuVertical />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <Button
                                    variant="ghost"
                                    className="w-full"
                                    onClick={() => {
                                      setSelectedRequest(request);
                                      handleUpdateRequest(false);
                                    }}
                                  >
                                    <TrashCan className="me-1" />
                                    Từ chối yêu cầu
                                  </Button>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedRequest && (
            <Dialog open={openConfirmModal}>
              <DialogContent>
                <DialogHeader className="flex flex-row gap-4">
                  <div>
                    <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                      <InformationFilled size={20} />
                    </span>
                  </div>
                  <div className="flex-1">
                    <DialogTitle className="text-lg">
                      Xác nhận yêu cầu rời{" "}
                      {groupInfo?.rootGroupId ? "nhóm" : "tổ chức"}
                    </DialogTitle>
                    <DialogDescription className="text-secondary-600 mt-2">
                      <p>
                        {groupInfo?.rootGroupId ? (
                          <span>
                            <span className="font-semibold">
                              {selectedRequest.user.name}
                            </span>{" "}
                            sẽ không còn là thành viên nhóm của bạn.
                          </span>
                        ) : (
                          <span>
                            <span className="font-semibold">
                              {selectedRequest.user.name}
                            </span>{" "}
                            sẽ không còn là thành viên Tổ chức và các nhóm đã
                            tham gia trước đó nữa.
                          </span>
                        )}
                      </p>
                    </DialogDescription>
                  </div>
                </DialogHeader>
                <DialogFooter className="justify-end gap-2">
                  <Button
                    size="sm"
                    variant="text"
                    onClick={() => setOpenConfirmModal(false)}
                  >
                    Huỷ bỏ
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => handleUpdateRequest(true)}
                  >
                    Xác nhận
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

export default GroupLeaveRequestsScreen;
