import React, { memo, useEffect, useRef, useState } from "react";

import {
  Camera,
  CloseFilled,
  Information,
  Upload,
  Warning,
  WarningFilled,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";
import { Field, Form, Formik, useFormikContext } from "formik";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { getCurrentUsername } from "@/features/auth/api/auth";
import { authAction } from "@/features/auth/redux/auth.slice";
import { cn } from "@/lib/utils";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import {
  getPresignedUrlS3,
  updateProfile,
  uploadPhoto,
} from "../api/account.api";
import { PhotoTypeEnum } from "../constants/account.constants";
import {
  UpdateProfileFormType,
  updateProfileSchema,
} from "../helpers/form.helper";

const Profile = () => {
  const { user } = useAppSelector((state) => state.auth);
  const avatarRef = useRef<HTMLInputElement>(null);
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  const [urlImage, setUrlImage] = useState<string | null>(null);
  const [isErrorImage, seIisErrorImage] = useState(false);
  const [isRemoveAvatar, setIsRemoveAvatar] = useState(false);
  const [render, setRender] = useState(false);
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  function isImageFile(file: File) {
    const validExtensions = ["png", "jpeg", "jpg"];
    const extension = file.name.split(".").pop()?.toLocaleLowerCase() || "";

    return validExtensions.includes(extension);
  }

  const onFilesChange = (file: File) => {
    setIsRemoveAvatar(false);
    const isImage = isImageFile(file);
    seIisErrorImage(!isImage);
    if (file && isImage) {
      const url = URL.createObjectURL(file);
      setFileUpload(file);
      setUrlImage(url);
    } else {
      setFileUpload(null);
      setUrlImage("");
    }
  };
  const removePreviewAvatar = () => {
    setUrlImage("");
    setFileUpload(null);
    if (avatarRef.current) {
      avatarRef.current.value = "";
    }
  };
  const onSubmit = async (values: UpdateProfileFormType) => {
    try {
      let photoId;
      if (fileUpload) {
        const urlResponse = await getPresignedUrlS3({
          fileName: fileUpload.name,
          size: fileUpload.size,
          type: PhotoTypeEnum.USER,
        });
        if (urlResponse.data.url) {
          await uploadPhoto(
            urlResponse.data.url,
            fileUpload,
            urlResponse.data.type,
            urlResponse.data.len
          );
          photoId = urlResponse.data.id;
        }
      }
      const result = await updateProfile({
        ...values,
        photoId: isRemoveAvatar ? -1 : photoId,
      });
      if (result.data) {
        toast({
          title: "Thành công!",
          variant: "success",
          description: getMessage("MSG_TK15"),
        });
      }
      reset();
      const userInfo = await getCurrentUsername();
      if (userInfo) {
        dispatch(authAction.setUserData(userInfo));
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    }
  };
  const reset = () => {
    setFileUpload(null);
    setUrlImage("");
    setIsRemoveAvatar(false);
  };

  const isChangeForm = (values: UpdateProfileFormType) => {
    if (!user) {
      return false;
    }
    return (
      JSON.stringify(values) !==
        JSON.stringify({
          name: user.name || "",
          biography: user.biography || "",
          location: user.location || "",
          company: user.company || "",
        }) ||
      fileUpload ||
      isRemoveAvatar
    );
  };
  const removeAvatar = () => {
    setIsRemoveAvatar(true);
    setFileUpload(null);
    setUrlImage("");
  };

  if (!user) {
    return null;
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: user.name || "",
          biography: user.biography || "",
          location: user.location || "",
          company: user.company || "",
        }}
        validationSchema={updateProfileSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          handleChange,
          errors,
          touched,
          isValid,
          values,
          resetForm,
        }) => (
          <Form>
            <div
              className={cn(
                "container mt-6 w-[696px] text-sm",
                isChangeForm(values)
                  ? "h-[calc(100vh-10px)]"
                  : "h-[calc(100vh)]"
              )}
            >
              <p className="text-base font-semibold text-secondary-900">
                Hồ sơ
              </p>
              <p className="text-secondary-600 text-xs">
                Chỉnh sửa thông tin hiển thị của bạn trên Mạng xã hội TAGA
              </p>
              <div className="flex justify-between mt-10">
                <div>
                  <p className="font-semibold text-secondary-900 mb-1">
                    Ảnh đại diện
                  </p>
                  <p className="text-xs text-secondary-600">
                    Kích thước đề xuất 300x300 (px)
                  </p>
                  <div className="flex items-center gap-2 mt-3">
                    <Button
                      variant={"outline"}
                      size="sm"
                      type="button"
                    >
                      <Label
                        htmlFor="file"
                        className="cursor-pointer flex items-center gap-2"
                      >
                        <Upload />
                        <span>Tải lên</span>
                      </Label>
                      <Input
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        type="file"
                        id="file"
                        name="avatar"
                        onChange={(e) => {
                          const fileList = e.target.files;
                          if (fileList) {
                            const file = fileList[0];
                            onFilesChange(file);
                          }
                        }}
                        hidden
                        ref={avatarRef}
                        className="hidden"
                      />
                    </Button>
                    {fileUpload ? (
                      <Button
                        variant="ghost"
                        size="sm"
                        className="flex items-center gap-1"
                        type="button"
                      >
                        <Camera />
                        <span className="overflow-hidden truncate d-inline-block max-w-[200px]">
                          {fileUpload.name}
                        </span>
                        <CloseFilled onClick={removePreviewAvatar} />
                      </Button>
                    ) : (
                      <Button
                        variant={"outline"}
                        size="sm"
                        type="button"
                        onClick={removeAvatar}
                        disabled={!user.photo}
                      >
                        <span>Xoá ảnh đại diện</span>
                      </Button>
                    )}
                  </div>
                  {isErrorImage && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {getMessage("MSG_TK20")}
                    </span>
                  )}
                </div>
                <TGNAvatar
                  name={user.name}
                  size="96"
                  src={isRemoveAvatar ? undefined : urlImage || user?.photo}
                />
              </div>

              <div className="mt-10">
                <p className="text-xs text-secondary-900 font-medium mb-2">
                  Tên đầy đủ <span className="text-error-600">*</span>
                </p>
                <Field
                  name="name"
                  as={Input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  error={errors.name}
                  placeholder="Tên đầy đủ"
                ></Field>
                {touched.name && errors.name && (
                  <span className="text-xs text-red-600 flex gap-1 mt-1">
                    <Warning />
                    {errors.name}
                  </span>
                )}
              </div>
              <div className="mt-4">
                <p className="text-xs text-secondary-900 font-medium mb-2">
                  Tiểu sử
                </p>
                <Field
                  name="biography"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  error={errors.biography}
                  as={Textarea}
                  className="h-[172px]"
                  placeholder="Thêm thông tin về bạn..."
                ></Field>
                {touched.biography && errors.biography && (
                  <span className="text-xs text-red-600 flex gap-1 mt-1">
                    <Warning />
                    {errors.biography}
                  </span>
                )}
              </div>
              {/* <div className="mt-4">
          <p className="text-xs text-secondary-900 font-medium mb-2">
            Liên kết xã hội
          </p>
          <Select>
            <SelectTrigger className="!max-w-[148px] min-w-0">
              <SelectValue placeholder="Linked In" />
            </SelectTrigger>
          </Select>
        </div> */}

              <div className="mt-4">
                <p className="text-xs text-secondary-900 font-medium mb-2 flex items-center gap-1">
                  Vị trí <Information />
                </p>
                <Field
                  name="location"
                  as={Input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  error={errors.location}
                  placeholder="Vị trí"
                ></Field>
                {touched.location && errors.location && (
                  <span className="text-xs text-red-600 flex gap-1 mt-1">
                    <Warning />
                    {errors.location}
                  </span>
                )}
              </div>
              <div className="mt-4">
                <p className="text-xs text-secondary-900 font-medium mb-2 flex items-center gap-1">
                  Công ty
                </p>
                <Field
                  name="company"
                  as={Input}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  error={errors.location}
                  placeholder="Công ty"
                ></Field>
                {touched.company && errors.company && (
                  <span className="text-xs text-red-600 flex gap-1 mt-1">
                    <Warning />
                    {errors.company}
                  </span>
                )}
              </div>
            </div>

            <div className={cn("left-0 bottom-0 fixed w-full transition")}>
              <div className="bg-secondary-100 h-[1px]"></div>
              <div className="w-[696px] mx-auto flex justify-between items-center bg-white py-3">
                <div className="flex items-center gap-1">
                  <WarningFilled />
                  <span className="text-secondary-900 font-medium text-xs">
                    Bạn có thay đổi chưa lưu
                  </span>
                </div>
                <div>
                  <Button
                    variant="outline"
                    className="mr-3"
                    type="button"
                    onClick={() => {
                      resetForm();
                      setRender(!render);
                      reset();
                    }}
                    disabled={!isChangeForm(values)}
                  >
                    Huỷ
                  </Button>
                  <Button
                    variant="primary"
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting || !isChangeForm(values)}
                    type="submit"
                  >
                    Lưu thay đổi
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(Profile);
