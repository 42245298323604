import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ZoomBehavior } from "d3-zoom";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

import { UserDef } from "@/features/auth/types/auth.types";
import { getUsersOfGroup } from "@/features/groups/api/group.api";
import { GetUsersOfGroupParams } from "@/features/groups/types/group.type";

import {
  AssignRolesEnum,
  ChatSocketTypeEnums,
  DecorationEnums,
  OWNER_EXAMPLE,
  ROOT_ID,
  ROOT_SHEET,
  UpdateDescriptionType,
} from "../constants/mindmap.constants";
import {
  filterRoot,
  findNodeById,
  findParentNodeByChildId,
  getExampleNode,
  searchNodesByName,
} from "../helpers/mindmap.helpers";
import { MindmapIOType } from "../lib/socket.mindmap";
import {
  ChatSocketType,
  MessageDef,
  MindmapItemDef,
  MindmapNodeDef,
  MindmapSocketResponse,
  MindmapState,
  RevisionsType,
  SectionDef,
  SheetType,
  TaskDef,
  UpdateStylePayload,
  ZoomTransform,
} from "../types/mindmap.type";

const initialState: MindmapState = {
  mindmapIO: null,
  createSubnodeToastId: "",
  createNodeToastId: "",
  sheets: [ROOT_SHEET],
  currentSheet: ROOT_SHEET,
  chats: [],
  sections: [],
  isCancelUpload: false,
  zoomBehavior: null,
  showSearchBar: false,
  previewByHtml: "",
};

export const getAssignees = createAsyncThunk<UserDef[], GetUsersOfGroupParams>(
  "mindmap/assginees",
  async ({ groupId, pageNum, pageSize }, { rejectWithValue }) => {
    try {
      const response = await getUsersOfGroup({
        groupId,
        pageNum,
        pageSize,
      });
      return response.content;
    } catch (error) {
      if (!error) {
        throw error;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const mindmapSlice = createSlice({
  name: "mindmap",
  initialState,
  reducers: {
    setMindmapIO: (state, action: PayloadAction<MindmapIOType>) => {
      state.mindmapIO = action.payload;
    },
    setSelectedNode: (state, action: PayloadAction<MindmapNodeDef | null>) => {
      state.currentSheet.assignees = [];
      state.currentSheet.selectedNode = action.payload;
    },
    setRoot: (
      state,
      action: PayloadAction<{ data: MindmapItemDef; isMainRoot?: boolean }>
    ) => {
      const {
        currentSheet: { root, selectedNode, assignees, isDraft },
      } = state;
      const { isMainRoot, data } = action.payload;
      if (isMainRoot && isDraft) {
        const sheet = state.sheets.find((sheet) => !sheet.isDraft);
        if (sheet) {
          sheet.root = data;
          return;
        }
      }
      if (!isEqual({ ...root }, action.payload)) {
        state.currentSheet.root = { ...action.payload.data };
        if (selectedNode && !isDraft) {
          const currentRoot = findNodeById(
            action.payload.data,
            selectedNode?.data.id
          );
          if (
            currentRoot &&
            state.currentSheet.selectedNode &&
            state.currentSheet.selectedNode.data
          ) {
            state.currentSheet.selectedNode.data = currentRoot;
            currentRoot.description.assignees.forEach((assignee) => {
              const indexAssignee = assignees.findIndex(
                (item) => item.user.id === assignee.user.id
              );
              assignees[indexAssignee] = assignee;
            });
          }
        }
      }
    },
    setScale: (state, action: PayloadAction<number>) => {
      state.currentSheet.scale = action.payload;
    },
    setCurrentZoomTransform: (state, action: PayloadAction<ZoomTransform>) => {
      state.currentSheet.currentZoomTransform = action.payload;
    },
    setCurrentSheet: (state, action: PayloadAction<SheetType>) => {
      if (state.currentSheet.id !== action.payload.id) {
        const sheetIndex = state.sheets.findIndex(
          (sheet) => sheet.id === state.currentSheet.id
        );
        state.sheets[sheetIndex] = state.currentSheet;
        state.currentSheet = action.payload;
      }
    },
    setIsCancelUpload: (state, action: PayloadAction<boolean>) => {
      state.isCancelUpload = action.payload;
    },
    setZoomBehavior: (
      state,
      action: PayloadAction<ZoomBehavior<Element, unknown>>
    ) => {
      state.zoomBehavior = action.payload;
    },
    setShowSearchBar: (state, action: PayloadAction<boolean>) => {
      state.showSearchBar = action.payload;
    },
    setPreviewByHtml: (state, action: PayloadAction<string>) => {
      state.previewByHtml = action.payload;
    },
    addNewSheet: (state) => {
      const newSheet = {
        id: uuidv4(),
        name: "Sheet 1",
        mindmapIO: null,
        root: {
          id: uuidv4(),
          name: "Man node",
          description: {
            index: 0,
            color: "#2d3282",
            fontSize: 16,
            decoration: DecorationEnums.INITIAL,
            fill: "#ffffff",
            lineColor: "#101828",
            owner: OWNER_EXAMPLE,
            assignees: [],
            descriptionTask: "",
            hashtags: [],
            tasks: [],
            intendTime: null,
          },
          parentId: null,
          children: [],
          _children: [],
        },
        selectedNode: null,
        searchNode: [],
        scale: 1,
        currentZoomTransform: null,
        isShowPeekRenderer: false,
        assignees: [],
        revisions: [],
        isDraft: true,
      };
      const sheetIndex = state.sheets.findIndex(
        (sheet) => sheet.id === state.currentSheet.id
      );
      state.sheets[sheetIndex] = state.currentSheet;
      state.sheets.push(newSheet);
      state.currentSheet = newSheet;
    },
    addNode: (state) => {
      if (state.createNodeToastId) {
        return;
      }
      const { selectedNode, root, isDraft } = state.currentSheet;
      if (!isDraft) {
        state.createNodeToastId = toast.loading("Đang tạo node ...");
      }
      if (!selectedNode) {
        return;
      }
      const parentNode = findParentNodeByChildId(root, selectedNode.data.id);
      if (!parentNode) {
        return;
      }
      const newNode = getExampleNode({
        id: uuidv4(),
        index: parentNode.children.length,
        owner: OWNER_EXAMPLE,
        parentId: parentNode.id,
        permissions: [[OWNER_EXAMPLE.username, AssignRolesEnum.OWNER]],
      });
      if (state.mindmapIO && !state.currentSheet.isDraft) {
        state.mindmapIO.addTask(newNode);
      } else {
        if (parentNode._children.length) {
          parentNode.children = parentNode._children;
        }
        parentNode.children.push(newNode);
      }
    },
    addSubnode: (state) => {
      if (state.createSubnodeToastId) {
        return;
      }
      const { selectedNode, root, isDraft } = state.currentSheet;
      if (!isDraft) {
        state.createSubnodeToastId = toast.loading("Đang tạo node con...");
      }
      if (!selectedNode) {
        return;
      }
      const node = findNodeById(root, selectedNode.data.id);
      if (!node) {
        return;
      }
      if (!node?.children) {
        node.children = [];
      }
      const newNode = getExampleNode({
        id: uuidv4(),
        index: node.children.length,
        owner: OWNER_EXAMPLE,
        parentId: selectedNode.data.id,
        permissions: [[OWNER_EXAMPLE.username, AssignRolesEnum.OWNER]],
      });
      if (isDraft) {
        if (node._children.length) {
          node.children = node._children;
        }
        node.children.push(newNode);
      }
      if (state.mindmapIO && !isDraft) {
        state.mindmapIO.addTask(newNode);
      }
    },
    addChat: (state, action: PayloadAction<MessageDef>) => {
      state.chats.push(action.payload);
    },
    removeSheet: (state, action: PayloadAction<string>) => {
      const index = state.sheets.findIndex(
        (sheet) => sheet.id === action.payload
      );
      if (index > -1) {
        mindmapSlice.caseReducers.setCurrentSheet(state, {
          payload: state.sheets[0],
          type: action.type,
        });
        state.sheets.splice(index, 1);
      }
    },
    openPeekRenderer: (state) => {
      state.currentSheet.isShowPeekRenderer = true;
    },
    closePeekRenderer: (state) => {
      state.currentSheet.isShowPeekRenderer = false;
    },
    removeNode: (state) => {
      const { selectedNode, root } = state.currentSheet;
      if (!selectedNode) {
        return;
      }
      const parentNode = findParentNodeByChildId(root, selectedNode.data.id);
      if (!parentNode) {
        return;
      }
      const index = parentNode.children.findIndex(
        (node) => node.id === selectedNode.data.id
      );
      parentNode.children.splice(index, 1);
    },
    updateSheetName: (state, action: PayloadAction<string>) => {
      state.currentSheet.name = action.payload;
      const index = state.sheets.findIndex(
        (sheet) => sheet.id === state.currentSheet.id
      );
      state.sheets[index].name = action.payload;
    },
    updateTasks: (state, action: PayloadAction<TaskDef>) => {
      const {
        mindmapIO,
        currentSheet: { selectedNode },
      } = state;
      if (!mindmapIO || !selectedNode) {
        return;
      }
      const {
        id,
        name,
        description,
        parentId,
        permissions = [],
      } = selectedNode.data;
      const dummyDescription = cloneDeep(description);
      dummyDescription.tasks.push(action.payload);
      mindmapIO.updateTask({
        id,
        name,
        description: dummyDescription,
        parentId,
        permissions,
      });
    },
    updateStyleNode: (state, action: PayloadAction<UpdateStylePayload>) => {
      const {
        mindmapIO,
        currentSheet: { selectedNode },
      } = state;
      if (!selectedNode || !mindmapIO) {
        return;
      }

      const {
        id,
        name,
        description,
        parentId,
        permissions = [],
      } = selectedNode.data;
      const dummyDescription = cloneDeep(description);
      let dummyName = name;
      switch (action.payload.type) {
        case UpdateDescriptionType.NAME:
          dummyName = action.payload.name;
          break;
        case UpdateDescriptionType.HASHTAG:
          dummyDescription.hashtags = action.payload.hastag
            .split(" ")
            .filter((character) => character);
          break;
        case UpdateDescriptionType.INTEND_TIME: {
          const { start, end } = action.payload.intendTime;
          dummyDescription.intendTime = {
            start,
            end,
          };
          break;
        }
        case UpdateDescriptionType.TEXT:
          dummyDescription.fontSize = action.payload.fontSize;
          break;
        case UpdateDescriptionType.COLOR:
          dummyDescription.color = action.payload.color;
          break;
        case UpdateDescriptionType.FILL:
          dummyDescription.fill = action.payload.color;
          break;
        case UpdateDescriptionType.CONNECTOR_COLOR:
          dummyDescription.lineColor = action.payload.color;
          break;
        case UpdateDescriptionType.DECORATION:
          dummyDescription.decoration = action.payload.decoration;
          break;
        case UpdateDescriptionType.DESCRIPTION:
          dummyDescription.descriptionTask = action.payload.content;
          break;
        case UpdateDescriptionType.ASSIGNEES: {
          const { assignees } = state.currentSheet;
          if (!assignees.length) {
            return;
          }
          assignees.forEach((assignee) => {
            if (assignee.role) {
              permissions.push([assignee.user.username, assignee.role]);
            }
          });
          const filterAssigness = assignees.filter(
            (assignee) =>
              assignee.role && assignee.role !== AssignRolesEnum.OWNER
          );
          dummyDescription.assignees = filterAssigness;
          break;
        }
        default:
          break;
      }
      mindmapIO.updateTask({
        id,
        name: dummyName,
        description: dummyDescription,
        parentId,
        permissions,
      });
    },
    searchNode: (state, action: PayloadAction<string>) => {
      state.currentSheet.searchNode = [];
      state.currentSheet.searchNode = searchNodesByName(
        state.currentSheet.root,
        action.payload
      );
    },
    toggleChildren: (state) => {
      const { selectedNode, root } = state.currentSheet;
      if (!selectedNode) {
        return;
      }
      const node = findNodeById(root, selectedNode.data.id);
      if (!node) {
        return;
      }
      [node.children, node._children] = [node._children, node.children];
    },
    updateAssignee: (
      state,
      action: PayloadAction<{ username: string; role: AssignRolesEnum | null }>
    ) => {
      const { assignees } = state.currentSheet;
      if (!assignees.length) {
        return;
      }
      const assignee = assignees.find(
        (item) => item.user.username === action.payload.username
      );
      if (assignee) {
        assignee.role = action.payload.role;
      }
    },
    removeAssignee: (state, action: PayloadAction<{ username: string }>) => {
      const assignee = state.currentSheet.assignees.find(
        (item) => item.user.username === action.payload.username
      );
      if (assignee) {
        assignee.role = null;
      }
    },
    requestDownloadFile: (state, action: PayloadAction<RevisionsType>) => {
      if (state.mindmapIO) {
        state.mindmapIO.requestDownload(action.payload);
      }
    },
    removeCreateNodeToast: (state) => {
      state.createNodeToastId = "";
    },
    removeCreateSubNodeToast: (state) => {
      state.createSubnodeToastId = "";
    },
    handleRefreshTask: (
      state,
      action: PayloadAction<MindmapSocketResponse>
    ) => {
      const { createNodeToastId, createSubnodeToastId } = state;
      if (createNodeToastId) {
        toast.dismiss(createNodeToastId);
        toast.success("Thao tác thành công", { duration: 2000 });
        state.createNodeToastId = "";
      }
      if (createSubnodeToastId) {
        toast.dismiss(createSubnodeToastId);
        toast.success("Thao tác thành công", { duration: 2000 });
        state.createSubnodeToastId = "";
      }
      const _root = {
        id: ROOT_ID,
        name: "Product Release",
        description: {
          index: 0,
          color: "#2d3282",
          fontSize: 16,
          decoration: DecorationEnums.INITIAL,
          fill: "#ffffff",
          lineColor: "#101828",
          owner: OWNER_EXAMPLE,
          assignees: [],
          tasks: [],
          descriptionTask: "",
          hashtags: [],
          intendTime: null,
        },
        parentId: null,
        children: [],
        _children: [],
      };
      action.payload.response.tasks.forEach((task) => {
        const parent = findNodeById(_root, task.parentStep);
        if (parent) {
          const sumary = JSON.parse(task.data);
          const index = sumary?.description?.index;
          if (!parent.children) {
            parent.children = [];
          }
          parent.children[index] = {
            id: task.id,
            name: task.stepName,
            _children: [],
            children: [],
            parentId: task.parentStep,
            description: sumary.description,
            permissions: sumary.permissions,
          };
        }
      });
      filterRoot(_root);
      const {
        currentSheet: { root, selectedNode, assignees, isDraft },
      } = state;
      if (isDraft) {
        const sheet = state.sheets.find((sheet) => !sheet.isDraft);
        if (sheet) {
          sheet.root = _root;
          return;
        }
      }
      if (!isEqual({ ...root }, _root)) {
        state.currentSheet.root = { ..._root };
        if (selectedNode && !isDraft) {
          const currentRoot = findNodeById(_root, selectedNode?.data.id);
          if (
            currentRoot &&
            state.currentSheet.selectedNode &&
            state.currentSheet.selectedNode.data
          ) {
            state.currentSheet.selectedNode.data = currentRoot;
            currentRoot.description.assignees.forEach((assignee) => {
              const indexAssignee = assignees.findIndex(
                (item) => item.user.id === assignee.user.id
              );
              assignees[indexAssignee] = assignee;
            });
          }
        }
      }
      state.currentSheet.revisions = action.payload.response.revisions.map(
        (item) => ({ ...item, extensionData: JSON.parse(item.extensionData) })
      );
    },
    handleChatRelease: (
      state,
      action: PayloadAction<MindmapSocketResponse>
    ) => {
      const { chats, stepId } = action.payload.response;
      const sections: SectionDef[] = [];
      const chatStore: MessageDef[] = [];
      chats
        .sort((a, b) => Number(a.issueAt) - Number(b.issueAt))
        .forEach((chat) => {
          const value = JSON.parse(chat.value) as ChatSocketType;
          switch (value.type) {
            case ChatSocketTypeEnums.SECTION: {
              sections.push({
                id: value.id,
                title: value.title,
                lines: [],
                stepId: value.stepId,
              });
              break;
            }
            case ChatSocketTypeEnums.LINE: {
              const section = sections.find(
                (item) => item.id === value.sectionId
              );
              if (section) {
                const { id, sectionId, content } = value;
                section.lines.push({
                  id,
                  sectionId,
                  comments: [],
                  content,
                });
              }
              break;
            }
            case ChatSocketTypeEnums.COMMENT: {
              const section = sections.find(
                (item) => item.id === value.sectionId
              );
              if (section) {
                const line = section.lines.find(
                  (item) => item.id === value.lineId
                );
                if (line) {
                  line.comments.push({
                    ...value,
                    issueAt: Number(chat.issueAt),
                  });
                }
              }
              break;
            }
            case ChatSocketTypeEnums.CHAT: {
              const { issueAt, stepId, userId } = chat;
              chatStore.push({
                issueAt,
                stepId,
                userId,
                value: {
                  id: value.id,
                  message: value.message,
                  user: value.user,
                },
                files: [],
              });
              break;
            }
            default:
              break;
          }
        });
      if (state.currentSheet.selectedNode?.data.id === stepId) {
        if (state.chats.length <= chatStore.length) {
          state.chats = chatStore;
        }
      }
      state.sections = sections;
    },
    getChats: (state) => {
      const {
        mindmapIO,
        currentSheet: { selectedNode },
      } = state;
      if (mindmapIO && selectedNode) {
        mindmapIO.getChats(selectedNode.data.id);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAssignees.fulfilled,
      (state, action: PayloadAction<UserDef[]>) => {
        state.currentSheet.assignees = action.payload.map((user) => {
          const assignee =
            state.currentSheet.selectedNode?.data.description.assignees.find(
              (assignee) => assignee.user.id === user.id
            );
          return {
            user,
            role:
              state.currentSheet.selectedNode?.data.description.owner.id ===
              user.id
                ? AssignRolesEnum.OWNER
                : assignee
                ? assignee.role
                : null,
          };
        });
      }
    );
    builder.addCase(getAssignees.rejected, (state) => {
      state.currentSheet.assignees = [];
    });
  },
});

export const {
  setMindmapIO,
  setSelectedNode,
  setRoot,
  setScale,
  setCurrentSheet,
  setCurrentZoomTransform,
  setIsCancelUpload,
  setZoomBehavior,
  setShowSearchBar,
  setPreviewByHtml,
  addNode,
  addSubnode,
  addChat,
  removeNode,
  updateSheetName,
  updateTasks,
  updateStyleNode,
  addNewSheet,
  searchNode,
  toggleChildren,
  openPeekRenderer,
  closePeekRenderer,
  updateAssignee,
  removeAssignee,
  requestDownloadFile,
  removeSheet,
  removeCreateNodeToast,
  removeCreateSubNodeToast,
  handleRefreshTask,
  handleChatRelease,
  getChats,
} = mindmapSlice.actions;

export const mindmapReducer = mindmapSlice.reducer;
