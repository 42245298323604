import React, { FC } from "react";

import { Search } from "@carbon/icons-react";
import { CarbonIconType } from "@carbon/icons-react/lib/CarbonIcon";
import cx from "classnames";
import { FieldProps } from "formik";
import { FormControlProps } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import style from "./Input.module.scss";

type TGNInputProps = {
  label?: string;
  state?: "error" | "warning";
  type?: "text" | "password" | "email";
  placeholder?: string;
  optionalText?: string;
  name?: string;
  readOnly?: boolean;
  className?: string;
  isSearch?: boolean;
  classNameInput?: string;
  icon?: CarbonIconType;
} & FormControlProps &
  Partial<FieldProps>;

const TGNInputField: FC<TGNInputProps> = ({
  state = "normal",
  label,
  optionalText,
  className,
  classNameInput,
  name,
  isSearch,
  icon,
  ...props
}) => {
  const Icon = icon;
  return (
    <Form.Group
      className={cx(
        "tgn-input",
        `${state}`,
        "position-relative",
        isSearch || icon ? style.search : "",
        className
      )}
    >
      {label && <Form.Label>{label}</Form.Label>}
      {isSearch && (
        <Search
          size={20}
          className={cx(" ", style.icon)}
          color="#98a2b3"
        ></Search>
      )}
      {Icon && (
        <Icon
          className={cx("fs-20 text-tertiary-color-gray-5", style.icon, icon)}
        />
      )}
      <Form.Control
        {...props}
        className={cx(style["input-container"], classNameInput)}
        name={name}
      />
      {optionalText && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-error">{optionalText}</div>
        </div>
      )}
    </Form.Group>
  );
};

export default TGNInputField;
