import React, { useEffect, useState } from "react";

import { Download } from "@carbon/icons-react";

import Demo from "@/assets/images/Demo.png";
import { Button } from "@/components/ui/button";

import Footer from "./Footer";
import Header from "./Header";

const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  if (/Android/.test(userAgent)) return "Android";
  if (/iPhone|iPad|iPod/.test(userAgent)) return "iOS";
  if (platform.startsWith("Win")) return "Windows";
  if (platform.startsWith("Mac")) return "MacOS";
  if (platform.startsWith("Linux") || userAgent.indexOf("Ubuntu") !== -1)
    return "Linux";

  return "Unknown";
};
const DownloadPage = () => {
  const [link, setLink] = useState("");
  const os = detectOS();
  const determineLink = (osName: string) => {
    switch (osName) {
      case "Windows":
        return "#*";
      case "MacOS":
        return "#*";
      case "Linux":
        return "#*";
      case "Android":
        return "https://drive.google.com/file/d/1lMrknwbCMlJBcxxEjVBiiiNmxgSkTLEv/view?usp=sharing";
      case "iOS":
        return "#*";
      default:
        return "#*";
    }
  };
  useEffect(() => {
    setLink(determineLink(os));
  }, [os]);

  return (
    <>
      <Header />
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto min-h-[60vh]">
          <div className="flex flex-col items-center justify-center h-full">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold  md:text-5xl xl:text-6xl  text-center bg-gradient-to-r from-green-600 !leading-tight to-brand-500 text-transparent bg-clip-text">
              Tải xuống TagaNow
              <p>cho {os}</p>
            </h1>

            <div className="flex gap-4 mt-10">
              {os == "Android" ? (
                <a
                  href={link}
                  className="px-[20px] py-[20px] text-2xl bg-brand-600 text-white rounded-lg"
                >
                  Tải xuống cho Android
                </a>
              ) : (
                <Button
                  className="-h-fit px-[20px] py-[20px] text-2xl bg-brand-600 text-white rounded-lg "
                  disabled
                >
                  Chưa hỗ trợ cho {os !== "Unknown" ? os : "Hệ điều hành"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className=" bg-secondary-100 rounded-t-[50px]">
        <div className="p-6 pt-10 container max-w-[1000px]">
          <div className="grid p-4 md:p-10 bg-white grid-cols-1 md:grid-cols-3 rounded-lg">
            <div className="col-span-1">
              <img
                src={Demo}
                alt="Logo taga"
                className="mb-4 mx-auto h-[200px] w-auto"
              />
            </div>
            <div className="col-span-2 grid gap-1 ">
              <h2 className="text-2xl font-bold">
                Hãy thử TagaNow cho thiết bị di động
              </h2>
              <p className="text-secondary-600">
                Tận hưởng tính năng đồng bộ hóa an toàn và truy cập máy chủ của
                bạn khi đang di chuyển với ứng dụng di động Taganow dành cho iOS
                và Android.
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mt-4">
                <a href={`${determineLink("iOS")}`}>
                  <img
                    src="https://framerusercontent.com/images/1ALkV1Eckh03AD1MTmcoyxlGPw.png"
                    alt=""
                  />
                </a>
                <a href={`${determineLink("Android")}`}>
                  <img
                    src="https://framerusercontent.com/images/Ech0madLMiImF0Zw2dNeN7AWU.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3 mt-5 gap-5">
            <div className="flex flex-col gap-5 px-10 bg-white p-5 rounded-lg">
              <div className="flex justify-center text-2xl font-bold">
                MacOS
              </div>
              <Button variant="outline">
                <Download className="mr-2" />
                Tải xuống{" "}
              </Button>
            </div>
            <div className="flex flex-col gap-5 px-10 bg-white p-5 rounded-lg">
              <div className="flex justify-center text-2xl font-bold">
                Window
              </div>
              <Button variant="outline">
                <Download className="mr-2" />
                Tải xuống{" "}
              </Button>
            </div>
            <div className="flex flex-col gap-5 px-10 bg-white p-5 rounded-lg">
              <div className="flex justify-center text-2xl font-bold">
                Linux
              </div>
              <Button variant="outline">
                <Download className="mr-2" />
                Tải xuống{" "}
              </Button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DownloadPage;
