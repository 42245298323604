import React, { memo, useEffect, useState } from "react";

import { ChevronRight } from "@carbon/icons-react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import { getMyGroups } from "@/features/groups/api/group.api";
import { GroupTypeEnum } from "@/features/groups/constants/group.constant";
import {
  GroupsListResponse,
  GroupDef,
} from "@/features/groups/types/group.type";
import { cn } from "@/lib/utils";

import EnableNotificationSettingScreen from "../EnableNotificationSettingScreen/EnableNotificationSettingScreen";

const ProfileSetting = () => {
  const [subGroups, setSubGroups] = useState<GroupsListResponse>();
  const [
    showEnableNotificationSettingDialog,
    setShowEnableNotificationSettingDialog,
  ] = useState(false);
  const fetchGroup = async () => {
    const res = await getMyGroups({
      pageNumber: PAGE_DEFAULT,
      pageSize: 10,
      typeFilter: GroupTypeEnum.ROOT,
    });
    setSubGroups(res);
  };
  useEffect(() => {
    fetchGroup();
  }, []);

  return (
    <div>
      <EnableNotificationSettingScreen
        open={showEnableNotificationSettingDialog}
        onClose={() => setShowEnableNotificationSettingDialog(false)}
        rootGroups={subGroups?.data || []}
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-end-11">
            <p className="text-lg font-semibold text-secondary-900">
              Thông báo
            </p>
            <p className="text-xs text-secondary-600">
              Thiết lập và quản lý thông báo đối với hoạt động của bạn và Taga
            </p>
            <div className="mt-10">
              <p className="text-secondary-600 text-xs font-medium uppercase">
                Phương thức nhận thông báo
              </p>
              <div className="p-3 bg-white border border-secondary-200 rounded-lg mt-4">
                <div className="flex items-center gap-3 p-3 transition hover:bg-secondary-50 cursor-pointer w-full rounded-md justify-between">
                  <div>
                    <p className="text-secondary-900 font-medium text-sm">
                      Thông báo đẩy
                    </p>
                    <p className="text-sm text-secondary-600">
                      Nhận thông báo đẩy về hoạt động mới trên TAGA. Bạn có thể
                      tắt chúng bất cứ lúc nào.
                    </p>
                    <p className="font-semibold text-secondary-400 text-sm mt-1">
                      Tắt
                    </p>
                  </div>
                  <ChevronRight />
                </div>
                <div className="flex items-center gap-3 p-3 transition hover:bg-secondary-50 cursor-pointer w-full rounded-md justify-between">
                  <div>
                    <p className="text-secondary-900 font-medium text-sm">
                      Thông báo qua email
                    </p>
                    <p className="text-sm text-secondary-600">
                      Nhận thông báo mới qua địa chỉ email. Bạn có thể tắt chúng
                      bất cứ lúc nào.{" "}
                      <span className="text-primary font-medium">
                        Tìm hiểu thêm
                      </span>
                    </p>
                    <p className="font-semibold text-secondary-400 text-sm mt-1">
                      Tắt
                    </p>
                  </div>
                  <ChevronRight />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <p className="text-secondary-600 text-xs font-medium uppercase">
                thông báo tổ chức
              </p>
              <div className="p-3 bg-white border border-secondary-200 rounded-lg mt-4">
                <div
                  className="flex items-center gap-3 p-3 transition hover:bg-secondary-50 cursor-pointer w-full rounded-md justify-between"
                  onClick={() => setShowEnableNotificationSettingDialog(true)}
                >
                  <div>
                    <p className="text-secondary-900 font-medium text-sm">
                      Quản lý thông báo tổ chức của bạn
                    </p>
                    {subGroups && (
                      <div className="flex items-center gap-1 mt-3">
                        <div className="h-6">
                          {subGroups.data.slice(0, 5).map((item, index) => (
                            <TGNAvatar
                              className={cn(
                                "border border-white border-solid",
                                index > 0 ? "-ml-1" : ""
                              )}
                              size="24"
                              src={item.photoUrl}
                              key={item.groupId}
                            />
                          ))}
                        </div>
                        {subGroups?.totalElements - 5 > 0 && (
                          <span className="text-sm">
                            +{subGroups.totalElements - 5} tổ chức khác
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <ChevronRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
