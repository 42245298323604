import React, { useEffect, useState } from "react";

import { SearchLocate, CaretDown } from "@carbon/icons-react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";

import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import SubNav from "@/components/layout/SubNav/SubNav";
import { ScrollArea } from "@/components/ui/scroll-area";
import useToastError from "@/hooks/useToastError";

import { getGroups } from "../api/operation.api";
import { OperationPathsEnum } from "../constants/operation.paths";
import { workflowSlice } from "../redux/workflow.slice";
import { IRootGroup } from "../types/operation";

const navLinks = [
  {
    url: OperationPathsEnum.OPERATION,
    end: true,
    icon: (
      <SearchLocate
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Quy trình của bạn",
  },
];

const OperationLayout = () => {
  const dispatch = useDispatch();

  const [rootGroups, setRootGroups] = useState<IRootGroup[]>([]);
  const { showToastError } = useToastError();

  const fetchMyGroupsInfiniteScroll = async () => {
    try {
      const result = await getGroups();
      setRootGroups(result.data);
    } catch (error) {
      showToastError(error);
    }
  };
  useEffect(() => {
    fetchMyGroupsInfiniteScroll();
  }, []);
  return (
    <div className="">
      <SubNav>
        <div className="w-full">
          <div className="p-4">
            <p className="text-xl font-semibold text-secondary-900 mb-0">
              Quản lý Vận hành
            </p>
          </div>
          <div className="w-full bg-secondary-100 h-px"></div>
          <div className="py-4 px-2">
            <p className="mb-1 text-sm text-secondary-700">Cá nhân</p>
            <ul>
              {navLinks.map((navLink, index) => {
                return (
                  <li
                    className="mb-0"
                    key={index}
                  >
                    <NavLink
                      to={navLink.url}
                      end={navLink.end}
                      className={({ isActive }) =>
                        isActive
                          ? "px-2 py-2.5 rounded bg-secondary-100 text-secondary-900 block hover:bg-secondary-100"
                          : "px-2 py-2.5 rounded block hover:bg-secondary-50 text-secondary-600"
                      }
                    >
                      {({ isActive }) => (
                        <div
                          className={cx(
                            "flex items-center justify-start text-secondary-600",
                            isActive
                              ? "bg-secondary-100 text-secondary-900"
                              : ""
                          )}
                        >
                          {navLink.icon}
                          {navLink.label}
                        </div>
                      )}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="py-4 ">
            <p className="mb-1 text-sm text-secondary-700 px-2">Tổ chức</p>
            <div className="pt-2 pl-2 space-y-3">
              <div className="h-[calc(100vh-280px)] w-full  overflow-auto">
                {rootGroups?.length > 0 &&
                  rootGroups.map((rootGroup) => (
                    <div
                      key={rootGroup.id}
                      className="w-full"
                    >
                      <div className="flex items-center justify-start gap-2 p-2">
                        <CaretDown className="h-[20px] w-[20px]" />
                        <p className="text-xs font-medium uppercase text-ellipsis truncate">
                          {rootGroup.name}
                        </p>
                      </div>
                      {rootGroup?.groups?.length > 0 && (
                        <>
                          {rootGroup.groups.map((item, index) => (
                            <NavLink
                              key={index}
                              to={OperationPathsEnum.OPERATION_ORG.replace(
                                ":id",
                                String(item.id)
                              )}
                              className={({ isActive }) =>
                                isActive
                                  ? "flex items-center  gap-2 px-2 py-2.5 rounded bg-secondary-100 text-secondary-900  hover:bg-secondary-100"
                                  : "flex items-center  gap-2 px-2 py-2.5 rounded  hover:bg-secondary-50 text-secondary-600"
                              }
                            >
                              <img
                                className="object-cover w-4 h-4 rounded"
                                src={item.photoUrl ?? GroupDetailCover}
                                alt=""
                              />
                              <span className="text-sm text-ellipsis truncate ">
                                {item.name}
                              </span>
                            </NavLink>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </SubNav>
      <div className="content pl-[280px] pt-[70px] ">
        <Outlet />
      </div>
    </div>
  );
};

export default OperationLayout;
