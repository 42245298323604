import React, { FC, ReactNode, memo, useState } from "react";

import { InformationFilled } from "@carbon/icons-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { rejectGroupInvitation } from "@/features/groups/api/group.api";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getCustomMessage } from "@/utils/message";

type ConfirmModal = {
  groupInfo: number;
  button: ReactNode;
};

const ConfirmModal: FC<ConfirmModal> = ({ groupInfo, button }) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [haveInvites, setHaveInvites] = useState(true);

  const dispatch = useAppDispatch();

  const handleReject = async (groupId: number) => {
    setOpenModal(false);
    dispatch(
      loadingAction.show({
        title: "Đang xử lý...",
        description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      if (groupId) {
        await rejectGroupInvitation(true, groupId);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG66", String(groupInfo)),
              }}
            ></div>
          ),
        });
      }
    } catch (error: any) {
      dispatch(loadingAction.hide());

      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG12,
      });
    } finally {
      dispatch(loadingAction.hide());
    }
  };
  return (
    <>
      {groupInfo && (
        <>
          <Dialog open={openModal}>
            <DialogTrigger asChild>
              <div
                className="w-full"
                onClick={() =>
                  haveInvites ? setOpenModal(true) : setOpenModal(true)
                }
              >
                {button}
              </div>
            </DialogTrigger>
            <DialogContent className="max-w-[600px]">
              <DialogHeader>
                <div className="flex gap-4 grid-cols-2">
                  <div>
                    <span className="block border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full">
                      <InformationFilled size={24} />
                    </span>
                  </div>
                  <span className="w-[calc(100%_-_48px)]  text-sm">
                    <p className="text-secondary-900 text-lg font-semibold mb-2">
                      Xoá thành viên
                    </p>
                    <span className="font-normal text-secondary-600 leading-tight">
                      Bạn chắc chắn muốn xoá{" "}
                      <span className="font-semibold">NSO Orientation</span>?
                      Người này sẽ không còn là thành viên Tổ chức và các nhóm
                      đã tham gia trước đó nữa.
                    </span>
                  </span>
                </div>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant={"outline"}
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  size="sm"
                >
                  Hủy bỏ
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                >
                  Xác nhận
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default memo(ConfirmModal);
