import React, { memo, useEffect, useMemo, useState } from "react";

import {
  Devices,
  OverflowMenuVertical,
  Add,
  Subtract,
  TrashCan,
} from "@carbon/icons-react";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import { Empty } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  getGroupDeviceByStatus,
  removeGroupDeviceByManager,
} from "@/features/groups/api/group.api";
import {
  GroupDeviceStatus,
  GroupUserRoleEnums,
} from "@/features/groups/constants/group.constant";
import { DeviceDef } from "@/features/groups/types/group.type";
import { GetGroupDeviceByManagerResponse } from "@/features/groups/types/groupDevice.type";
import usePagination from "@/hooks/usePagination";
import { useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { getMessage } from "@/utils/message";

import RemoveDeviceModal from "./RemoveDeviceModal";

const Approved = () => {
  const { toast } = useToast();
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [openRemoveDeviceModal, setOpenRemoveDeviceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupDeviceSelected, setGroupDeviceSelected] = useState<{
    user: UserDef | null;
    device: DeviceDef | null;
  }>({
    user: null,
    device: null,
  });
  const [groupDeviceList, setGroupDeviceList] = useState<
    GetGroupDeviceByManagerResponse[]
  >([]);
  const groupInfo = useAppSelector((state) => state.group.data);
  const user = useAppSelector((state) => state.auth.user);

  const { pagination, setPagination } = usePagination();
  const [open, setOpen] = useState(false);

  const fetchInfiniteScroll = async (pageScroll: number) => {
    if (!groupInfo) {
      return;
    }
    try {
      setIsLoadMore(true);
      const result = await getGroupDeviceByStatus({
        pageNumber: pageScroll,
        pageSize: pagination.size,
        groupId: groupInfo.id,
        status: GroupDeviceStatus.APPROVED,
      });
      if (!result.data.length || pagination.current <= result.totalPage) {
        setIsLoadMore(false);
      }
      setPagination({
        current: result.pageIndex,
        size: pagination.size,
        total: result.totalPage,
      });
      setGroupDeviceList((prev) => [...prev, ...result.data]);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };

  const fetchDeviceList = async () => {
    if (!groupInfo) {
      return;
    }
    try {
      setIsLoadMore(true);
      const result = await getGroupDeviceByStatus({
        pageNumber: 1,
        pageSize: pagination.size,
        groupId: groupInfo.id,
        status: GroupDeviceStatus.APPROVED,
      });
      if (!result.data.length || pagination.current <= result.totalPage) {
        setIsLoadMore(false);
      }
      setPagination({
        current: 1,
        size: pagination.size,
        total: result.totalPage,
      });
      setGroupDeviceList(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };

  useEffect(() => {
    fetchInfiniteScroll(pagination.current);
  }, []);

  const handleOpenRemoveDeviceModal = (user: UserDef, device: DeviceDef) => {
    setGroupDeviceSelected({
      user,
      device,
    });
    setOpenRemoveDeviceModal(true);
  };
  const submitRemoveDevice = async () => {
    if (
      !groupDeviceSelected.device ||
      !groupDeviceSelected.user ||
      !groupInfo
    ) {
      return;
    }
    try {
      setLoading(true);
      await removeGroupDeviceByManager({
        deviceUniqueId: groupDeviceSelected.device.deviceUniqueId,
        userId: groupDeviceSelected.user.id,
        groupId: groupInfo.id,
      });
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <p>
            Đã gỡ thiết bị{" "}
            <span className="font-semibold">
              {groupDeviceSelected.device.name || "Thiết bị không xác định"}
            </span>
          </p>
        ),
      });
      setOpenRemoveDeviceModal(false);
      await fetchDeviceList();
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setLoading(false);
    }
  };
  const countDevice = useMemo(() => {
    return groupDeviceList.reduce((prev, current) => {
      return prev + current.devices.length;
    }, 0);
  }, [groupDeviceList]);

  return (
    <div>
      <div className="mt-6">
        <p className="text-base font-semibold text-secondary-900">
          Danh sách thiết bị ・
          <span className="text-secondary-600">{countDevice}</span>
        </p>
        <p className="text-xs text-secondary-600 flex items-center">
          Danh sách các thiết bị đã được phê duyệt quyền truy cập và sử dụng tài
          sản Tổ chức.
        </p>

        <div className="mt-4 rounded-lg border border-secondary-200 bg-white py-2">
          {groupDeviceList.length === 0 && !isLoadMore && (
            <div className="flex flex-col items-center justify-center text-center">
              <Empty />
              <span className="text-secondary-600 text-xs mt-4 font-semibold">
                Không có thiết bị nào đang chờ phê duyệt
              </span>
            </div>
          )}
          <InfiniteScroll
            className="px-0"
            dataLength={groupDeviceList.length}
            next={() => {
              setPagination((prev) => ({ ...prev, current: prev.current + 1 }));
              fetchInfiniteScroll(pagination.current + 1);
            }}
            hasMore={isLoadMore}
            loader={
              <div className="flex items-center justify-center mt-6">
                <Loading3Dot
                  setWidth="30"
                  setHeight="30"
                />
              </div>
            }
          >
            {groupDeviceList.map((groupDevice, index) => {
              return (
                <div key={index}>
                  <Collapsible>
                    <CollapsibleTrigger
                      className="w-full"
                      onClick={() => setOpen(!open)}
                    >
                      <div
                        className={cx(
                          open ? "border-b border-secondary-100" : "",
                          "w-full px-6 py-3  justify-between items-center inline-flex hover:bg-secondary-100"
                        )}
                      >
                        <div className="h-16 justify-between items-center flex">
                          <div className="justify-start items-center gap-3 flex">
                            <div className="w-16 h-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex">
                              <TGNAvatar
                                className="w-16 h-16 rounded-full cursor-pointer"
                                src={groupDevice.user.photoUrl}
                                userName={
                                  groupDevice.user.alias ??
                                  groupDevice.user.name
                                }
                                alt=""
                              />
                            </div>
                            <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                              <div className="flex-col justify-start items-start gap-0.5 flex">
                                <div className="justify-start items-start gap-1 inline-flex">
                                  <div className="text-secondary-900 text-sm font-medium">
                                    {groupDevice.user.alias ??
                                      groupDevice.user.name}{" "}
                                    {user?.id == groupDevice.user.id &&
                                      "( Bạn )"}
                                  </div>
                                </div>
                                <div className="text-secondary-600 text-xs font-normal">
                                  {groupDevice.user.email}
                                </div>
                                <span className="text-xs text-secondary-600 mt-2 font-semibold">
                                  {groupDevice.devices.length} thiết bị
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {open ? <Subtract /> : <Add />}
                      </div>
                    </CollapsibleTrigger>
                    <CollapsibleContent
                      className={cx(
                        open ? "border-b border-secondary-100" : ""
                      )}
                    >
                      {groupDevice.devices.map((item, index) => {
                        return (
                          <div
                            className="w-full px-6 py-3 bg-secondary-50 justify-between items-center inline-flex hover:bg-secondary-100"
                            key={index}
                          >
                            <div className="h-16 justify-between items-center flex">
                              <div className="justify-start items-center gap-3 flex">
                                <div className="w-16 h-16 bg-secondary-50 rounded-full flex-col justify-center items-center inline-flex ">
                                  <Devices
                                    className="text-secondary-600 "
                                    size={24}
                                  />
                                </div>
                                <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                                  <div className="flex-col justify-start items-start gap-0.5 flex">
                                    <div className="justify-start items-start gap-1 inline-flex">
                                      <div className="text-secondary-900 text-sm font-medium">
                                        {item.device.name ||
                                          "Thiết bị không xác định"}
                                      </div>
                                    </div>
                                    <div className="text-secondary-400 text-xs font-normal">
                                      ID: {item.device.deviceUniqueId}
                                    </div>
                                  </div>
                                  <div className="h-4 justify-start items-start gap-1 inline-flex">
                                    <div className="text-secondary-600 text-xs ">
                                      Phê duyệt bởi
                                    </div>
                                    <div className="text-gray-600 text-xs font-medium underline">
                                      {item.approvedBy
                                        ? item.approvedBy.name
                                        : groupDevice.user.name}
                                    </div>
                                    <div className="text-secondary-600 text-xs ">
                                      {formatDistance(item.approvedAt)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(groupInfo?.role?.code ===
                              GroupUserRoleEnums.OWNER ||
                              (groupInfo?.role?.code ===
                                GroupUserRoleEnums.ADMIN &&
                                groupDevice.user.role.code ===
                                  GroupUserRoleEnums.MEMBER)) &&
                              item.device.deviceUniqueId !==
                                groupInfo.deviceName && (
                                <div className="flex items-center gap-2">
                                  <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                      <Button
                                        className="px-3 py-2"
                                        variant={"text"}
                                      >
                                        <OverflowMenuVertical />
                                      </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                      <Button
                                        variant="ghost"
                                        className="w-full"
                                        onClick={() =>
                                          handleOpenRemoveDeviceModal(
                                            groupDevice.user,
                                            item.device
                                          )
                                        }
                                      >
                                        <TrashCan className="me-1" />
                                        Gỡ thiết bị này
                                      </Button>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </div>
                              )}
                          </div>
                        );
                      })}
                    </CollapsibleContent>
                  </Collapsible>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
      <RemoveDeviceModal
        open={openRemoveDeviceModal}
        onClose={() => setOpenRemoveDeviceModal(false)}
        onSubmit={submitRemoveDevice}
        loading={loading}
        device={groupDeviceSelected.device}
      />
    </div>
  );
};

export default memo(Approved);
