import React, {
  FC,
  memo,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import cx from "classnames";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Typography from "@/components/Typography/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import styles from "./ChatBox.module.scss";
import Comment from "./Comment";
import { uploadFile } from "../../api/mindmap.api";
import {
  ChatSocketTypeEnums,
  ChatType,
  FileType,
  OWNER_EXAMPLE,
  RevisionsFileType,
  addChat,
} from "../../mindmap";
import CommentInput from "../CommentInput/CommentInput";

type ChatBoxProps = {
  onClose: () => void;
};

const ChatBox: FC<ChatBoxProps> = ({ onClose }) => {
  const { chats, currentSheet, mindmapIO } = useAppSelector(
    (state) => state.mindmap
  );
  const dispatch = useAppDispatch();
  const boxChatRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    if (boxChatRef.current) {
      boxChatRef.current.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }
  }, [chats]);
  const revisionFileChat: Record<string, RevisionsFileType[]> = useMemo(() => {
    const revisions = currentSheet.revisions.filter(
      (revision) =>
        revision.extensionData &&
        revision.extensionData.chatId &&
        revision.extensionData.type === FileType.CHAT &&
        revision.stepId === currentSheet.selectedNode?.data.id
    );
    return revisions.reduce((prev, current) => {
      if (!current.extensionData.chatId) {
        return prev;
      }
      if (!prev[current.extensionData.chatId]) {
        prev[current.extensionData.chatId] = [];
        prev[current.extensionData.chatId].push(current);
      } else {
        prev[current.extensionData.chatId].push(current);
      }
      return prev;
    }, {} as Record<string, RevisionsFileType[]>);
  }, [currentSheet.revisions, currentSheet.selectedNode?.data.id]);

  const pushMessage = async (message: string, files: File[]) => {
    if (mindmapIO && currentSheet.selectedNode) {
      const id = uuidv4();
      const newChat: ChatType = {
        id,
        message,
        type: ChatSocketTypeEnums.CHAT,
        user: OWNER_EXAMPLE,
      };
      if (files.length) {
        setLoading(true);
        const filesPayload = Array.from(files).map((f) => ({
          name: f.name,
          type: f.type,
          size: f.size,
        }));
        const relayFilesRes = await mindmapIO.requestUpload(filesPayload);
        if (relayFilesRes?.response?.length) {
          const relayFiles = relayFilesRes.response;
          for (const index of relayFiles.keys()) {
            try {
              await uploadFile(relayFiles[index].upload, files[index]);
              if (mindmapIO) {
                mindmapIO.updateData({
                  stepId: `${currentSheet.selectedNode?.data.id}`,
                  name: files[index].name,
                  cloudFileUrl: relayFiles[index].download,
                  maskedFileName: relayFiles[index].maskedFileName,
                  extensionData: {
                    type: FileType.CHAT,
                    chatId: id,
                  },
                });
              }
            } catch {
              toast.error("Tải lên thất bại");
            }
          }
        }
      }
      setLoading(false);
      mindmapIO.addChat({
        stepId: currentSheet.selectedNode.data.id,
        value: newChat,
      });
      dispatch(
        addChat({
          userId: searchParams.get("token") || "",
          stepId: currentSheet.selectedNode.data.id,
          value: newChat,
          issueAt: String(new Date().getTime()),
          files: files.map((file) => ({
            checkSum: "",
            fileName: file.name,
            issuedAt: String(new Date().getTime()),
            maskedFileName: "",
            stepId: "",
            type: FileType.CHAT,
            userId: "",
            extensionData: {
              type: FileType.CHAT,
              chatId: id,
            },
          })),
        })
      );
    }
  };

  return (
    <div className={cx("position-relative h-100", styles.wrapper)}>
      <div className={styles.header}>
        <div className="d-flex justify-content-between w-100 px-12">
          <Typography
            color="color-black"
            className="fs-16 fw-semibold"
          >
            Thảo luận
          </Typography>
          <div className="d-flex align-items-center gap-3">
            <i className="tgn-outline-search-01 fs-16 text-secondary-color-gray-7 cursor-pointer"></i>
            <i
              aria-hidden
              className="tgn-outline-chat-close fs-16 text-secondary-color-gray-7 cursor-pointer"
              onClick={onClose}
            ></i>
          </div>
        </div>
      </div>
      <div className={cx("custom-scrollbar", styles.box)}>
        <div
          ref={boxChatRef}
          className="px-8"
        >
          {chats.map((message) => (
            <Comment
              key={message.issueAt}
              message={message}
              files={revisionFileChat?.[message.value.id] || []}
            />
          ))}
        </div>
      </div>
      <CommentInput
        pushMessage={pushMessage}
        loading={loading}
      />
    </div>
  );
};

export default memo(ChatBox);
