import React, { useEffect, useState } from "react";

import { Search, Add } from "@carbon/icons-react";
import { useNavigate, useParams } from "react-router-dom";

import { Empty } from "@/assets/icons";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { getTasks } from "../api/task.api";
import GroupTask from "../components/GroupTask";
import { TaskDetail } from "../components/TaskDetail";
import { TaskGroupDef } from "../types/task";

const TaskScreen = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const [myTasks, setMyTasks] = useState<TaskGroupDef[]>([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [idTask, setIdTask] = useState<number | null>(
    Number(urlParams.get("idTask"))
  );

  const [idRole, setIdRole] = useState<string | null>("TODO");

  useEffect(() => {
    setIdTask(Number(urlParams.get("idTask")));
  }, [urlParams.get("idTask")]);

  useEffect(() => {
    setIdRole(urlParams.get("role"));
  }, [urlParams.get("role")]);

  const fetchMyTasks = async (type: string) => {
    try {
      setLoading(true);
      const result = await getTasks({
        type: type,
      });
      setMyTasks(result.data);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyTasks(idRole || "TODO");
  }, [idRole]);

  const handelSetId = (id: number) => {
    setIdTask(id);
    urlParams.set("idTask", String(id));
    navigate(`${window.location.pathname}?${urlParams.toString()}`);
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center mt-6">
          <TGNLoading
            size="large"
            isFullScreen
          />
        </div>
      ) : (
        <div className="container relative">
          {myTasks.length === 0 ? (
            <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
              <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                <Empty />
                <h4 className="w-[350px] h-8 text-center text-[#252526] text-base font-semibold mt-4">
                  Oops! Danh sách trống.
                </h4>
                <p className="w-[350px] text-center text-[#6d6b70] text-xs ">
                  Truy cập Studio và tạo mới dự án để tiến hành thực hiện công
                  việc trong tổ chức của bạn
                </p>
                <Button className="mt-4">Truy cập Studio</Button>
              </div>
            </div>
          ) : (
            <div className="grid ">
              <div className="py-3 flex gap-3">
                <form className="relative flex-1">
                  <Search className="absolute left-3 top-3 text-secondary-600" />
                  <Input
                    className="bg-secondary-100 rounded-[384px] pl-9"
                    placeholder="Tìm công việc"
                  />
                </form>
              </div>
              <div></div>
              <div className="h-full overflow-y-auto pb-8">
                {myTasks.map((t, i) => (
                  <GroupTask
                    onSetId={handelSetId}
                    key={i}
                    myTasks={t}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {(idTask != null || idTask != 0) && (
        <TaskDetail
          taskId={idTask}
          close={() => (
            setIdTask(null),
            urlParams.delete("idTask"),
            navigate(`${window.location.pathname}?${urlParams.toString()}`)
          )}
        />
      )}
    </>
  );
};

export default TaskScreen;
