import React, { memo, useMemo } from "react";

import TGNTable from "@/components/TGNTable/TGNTable";
import Typography from "@/components/Typography/Typography";
import { useAppSelector } from "@/redux/store";

import Members from "../Members/Members";

const NodeManagement = () => {
  const { selectedNode } = useAppSelector(
    (state) => state.mindmap.currentSheet
  );
  const subNodes = useMemo(() => {
    return (selectedNode?.data.children || []).map((item) => {
      return {
        title: item.name,
        members: (
          <Members
            assignees={item.description.assignees}
            nodeId={item.id}
            isChildMember
          />
        ),
      };
    });
  }, [selectedNode]);

  return (
    <div>
      <Typography className="fs-14 fw-semibold mt-16 mb-8">
        {subNodes.length} Child-nodes
      </Typography>

      <TGNTable
        columns={[
          {
            label: "Node title",
            value: "title",
          },
          {
            label: "Members",
            value: "members",
          },
        ]}
        data={subNodes}
      />
    </div>
  );
};

export default memo(NodeManagement);
