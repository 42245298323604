import React, { FC, memo, useEffect, useState } from "react";

import { DotMark, OverflowMenuVertical, TrashCan } from "@carbon/icons-react";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import {
  acceptGroupInvitation,
  getGroupInvitationForUser,
} from "@/features/groups/api/group.api";
import { GroupUserStatusEnum } from "@/features/groups/constants/group.constant";
import { groupActions } from "@/features/groups/group";
import { IGroupInvitation } from "@/features/groups/types/group.type";
import { useAppDispatch } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { COMMON_MESSAGES, getCustomMessage } from "@/utils/message";

type Props = {
  rootGroupId?: number;
};

type ItemActionProps = {
  invite: IGroupInvitation;
};

const QuickAction: FC<Props> = ({ rootGroupId }) => {
  const [invites, setInvites] = useState<IGroupInvitation[] | []>([]);
  const { toast } = useToast();
  const getList = async () => {
    try {
      const childInvites = await getGroupInvitationForUser({
        rootGroupId: rootGroupId,
        pageNumber: 1,
        pageSize: 10,
      });

      setInvites(
        childInvites.data.filter(
          (item) =>
            item.status !== GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
        )
      );
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG12,
      });
    } finally {
      // dispatch(groupActions.setFlagLoading(false));
    }
  };
  useEffect(() => {
    getList();
  }, []);
  const ItemAction: FC<ItemActionProps> = ({ invite }) => {
    const dispatch = useAppDispatch();
    const { toast } = useToast();
    const [submitting, setSubmitting] = useState(false);

    const handleAccept = async () => {
      try {
        // dispatch(groupActions.setFlagLoading(true));
        await acceptGroupInvitation(true, invite.id);
        getList();
        toast({
          title: "Thành công",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG65", String(invite.name)),
              }}
            ></div>
          ),
        });
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(groupActions.setFlagLoading(false));
      }
    };
    const handleReject = async () => {
      try {
        // dispatch(groupActions.setFlagLoading(true));
        await acceptGroupInvitation(false, invite.id);
        getList();
        toast({
          title: "Thành công",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG66", String(invite.name)),
              }}
            ></div>
          ),
        });
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: COMMON_MESSAGES.MSG12,
        });
      } finally {
        dispatch(groupActions.setFlagLoading(false));
      }
    };
    return (
      <div>
        <div className="w-full h-16 p-3 bg-white justify-between items-center inline-flex hover:bg-secondary-50">
          <div className="justify-start items-center gap-2 flex">
            <TGNAvatar
              size="40"
              userName={invite.name}
              userPhoto={invite.photoUrl}
            />
            <div className="flex-col justify-start items-start inline-flex">
              <div className="self-stretch text-secondary-900 text-sm font-medium ">
                {invite.name}
              </div>
              <div className="justify-start items-start gap-1 inline-flex">
                <div className="text-secondary-600 text-xs font-normal leading-none">
                  Được mời bởi
                </div>
                <div className="text-secondary-600 text-xs font-medium underline leading-none">
                  {invite.sender.name}
                </div>
                <div className="text-secondary-600 text-xs font-normal leading-none">
                  {formatDistance(invite.invitedAt)}
                </div>
              </div>
            </div>
          </div>

          <div className="justify-start items-center gap-2 flex">
            <Button
              className=""
              size={"sm"}
              disabled={submitting}
              onClick={() => handleAccept()}
            >
              Chấp nhận
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  className="px-3 py-2"
                  variant={"text"}
                >
                  <OverflowMenuVertical />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <Button
                  className="w-full me-2.5"
                  variant={"text"}
                  size={"sm"}
                  type="button"
                  onClick={() => handleReject()}
                >
                  <TrashCan className="me-1" />
                  Từ chối lời mời
                </Button>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex items-center gap-1 text-secondary-600 text-xs -mb-4">
        <span className="font-medium">Danh sách</span>
        <DotMark
          size={5}
          color="#4B5563"
          className="ml-1 mr-1"
        ></DotMark>
        <span className="font-semibold">{invites.length}</span>
      </div>
      <div className="flex-none min-w-full px-0 overflow-hidden lg:overflow-auto h-[400px] mt-2">
        {invites
          .filter((item) => {
            return item.groupId !== rootGroupId;
          })
          .map((item, index) => {
            return (
              <ItemAction
                invite={item}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};
export default memo(QuickAction);
