import React, { useEffect, useState } from "react";

import {
  App,
  Application,
  BuildingInsights_3,
  Dashboard,
  Forum,
  Home,
  MediaLibrary,
  Search,
  Task,
  UserMultiple,
  WorkflowAutomation,
} from "@carbon/icons-react";
import { NavLink } from "react-router-dom";

import SubNav from "@/components/layout/SubNav/SubNav";
import { getTokenFromStorage } from "@/features/auth/api/auth";

const Nav = () => {
  const [urlStudio, setUrlStudio] = useState("");

  useEffect(() => {
    const authInfo = getTokenFromStorage();
    if (!authInfo) return;
    const params = {
      ...JSON.parse(authInfo),
      callback: "/studio",
    };
    setUrlStudio(
      `https://library.taganow.vn/token/${btoa(JSON.stringify(params))}`
    );
  }, []);
  const navLinks = [
    {
      url: "/",
      end: true,
      icon: (
        <Home
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Trang chủ",
    },
    {
      url: "/friends",
      end: true,
      icon: (
        <UserMultiple
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Bạn bè",
    },
    {
      url: "/group/discover",
      end: true,
      icon: (
        <Search
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Khám phá",
    },
    {
      url: "/group",
      end: true,
      icon: (
        <BuildingInsights_3
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Tổ chức",
    },
    {
      url: "/chats",
      end: true,
      icon: (
        <Forum
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Tin nhắn",
    },
  ];
  const workLinks = [
    {
      url: "/dashboard",
      end: true,
      icon: (
        <Dashboard
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Dashboard",
    },
    {
      url: "/task?role=TODO",
      end: true,
      icon: (
        <Task
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Công việc",
    },
    {
      url: "/operation",
      end: true,
      icon: (
        <WorkflowAutomation
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Quản lý vận hành",
    },
    {
      url: "/cloud",
      end: true,
      icon: (
        <MediaLibrary
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Thư viện",
    },
  ];
  const helpLinks = [
    {
      url: "/Announcements",
      end: true,
      icon: (
        <Home
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Announcements",
    },
    {
      url: "/Introduction",
      end: true,
      icon: (
        <UserMultiple
          className="text-base mr-2"
          size={16}
        />
      ),
      label: "Introduction",
    },
  ];
  return (
    <SubNav>
      <div className="w-full flex flex-col h-full overflow-y-auto">
        <div className="p-4">
          {navLinks.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.url}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "bg-secondary-100 flex items-center py-2.5 px-2 rounded font-medium"
                    : "flex items-center py-2.5 px-2 rounded text-secondary-600"
                }
              >
                {item.icon} {item.label}
              </NavLink>
            );
          })}
        </div>
        <div className="p-4">
          <p className="text-xs mb-2">Works</p>
          {workLinks.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.url}
                end
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "bg-secondary-100 flex items-center py-2.5 px-2 rounded font-medium"
                    : "flex items-center py-2.5 px-2 rounded text-secondary-600"
                }
              >
                {item.icon} {item.label}
              </NavLink>
            );
          })}
        </div>
        <div className="p-4">
          <p className="text-xs mb-2">Apps</p>

          <NavLink
            to={urlStudio}
            target="_blank"
            className="flex items-center py-2.5 px-2 rounded text-secondary-600 gap-2"
          >
            <Application />
            Taga Studiø
          </NavLink>
        </div>
        <div className="p-4">
          <p className="text-xs mb-2">Works</p>
          {helpLinks.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.url}
                end
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "bg-secondary-100 flex items-center py-2.5 px-2 rounded font-medium"
                    : "flex items-center py-2.5 px-2 rounded text-secondary-600"
                }
              >
                {item.icon} {item.label}
              </NavLink>
            );
          })}
        </div>
        <div className="p-4">
          <div className="bg-secondary-50 p-4 h-[200px] flex justify-center items-center text-gray-400">
            Ads
          </div>
        </div>
      </div>
    </SubNav>
  );
};
export default Nav;
