import React, { FC, ReactNode, memo } from "react";

import cx from "classnames";

import styles from "./TGNDrawer.module.scss";

type TGNDrawerProps = {
  show: boolean;
  children: ReactNode;
  onClose: () => void;
};

const TGNDrawer: FC<TGNDrawerProps> = ({ show, children, onClose }) => {
  return (
    <div
      aria-hidden
      className={cx(styles.drawer, show && styles.show)}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        aria-hidden
        className={styles.overlay}
        onClick={onClose}
      ></div>
      <div className={cx(styles.content, show && styles.active)}>
        {children}
      </div>
    </div>
  );
};

export default memo(TGNDrawer);
