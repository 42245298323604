import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";

import { Empty } from "@/assets/icons";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { COMMON_MESSAGES } from "@/utils/message";

import { getGroupRootGroupDeleted, restoreGroup } from "../../api/group.api";
import GroupCardRestore from "../../components/GroupCard/GroupCardRestore";
import SearchModal from "../../components/SearchModal/SearchModal";
import {
  GroupSortDateEnum,
  GroupSortEnum,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { groupActions } from "../../group";
import { RestoreGroup } from "../../types/group.type";

const RootGroupDeletedScreen = () => {
  const dispatch = useAppDispatch();
  const [myGroupsRestore, setMyGroupsRestore] = useState<RestoreGroup[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const [sort, setSort] = useState(urlParams.get("sort"));

  let isFiltering = false;
  if (sort) {
    isFiltering = true;
  }
  const fetchMyGroupsInfiniteScroll = async (pageScroll: number) => {
    try {
      setIsLoadMore(true);
      const result = await getGroupRootGroupDeleted({
        pageNumber: pageScroll,
        pageSize: pageSize,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroupsRestore(() => [...myGroupsRestore, ...result.data]);
    } catch (error) {
      toast.error(COMMON_MESSAGES.MSG_012);
    }
  };

  const handleRestore = async (groupId: number) => {
    const acceptFn = async () => {
      await restoreGroup(groupId);
    };
    await toast.promise(acceptFn(), {
      loading: "Đang khôi phục nhóm",
      success: () => {
        return "Khôi phục nhóm thành công";
      },
      error: () => {
        return "Khôi phục nhóm không thành công";
      },
    });
  };

  useEffect(() => {
    fetchMyGroupsInfiniteScroll(page);
  }, []);

  return (
    <>
      <div className="container   bg-white z-50">
        <div className="py-3 flex items-center justify-between">
          <p className="text-xs font-medium text-secondary-600">
            <span className="text-secondary-400">Nhóm</span> / Lưu trữ
          </p>
          <SearchModal />
        </div>
      </div>
      <div className="h-px w-full z-50 bg-secondary-100 sticky top-[69px]"></div>
      <div className=" z-50 bg-white sticky top-[70px]">
        <div className="container">
          <div className="flex justify-between">
            <div className="py-6">
              <div className=" flex ">
                <p className="font-semibold text-secondary-900 mr-2">Lưu trữ</p>
                {/* <Badge>{totalElement} nhóm</Badge> */}
              </div>
              <p className="text-xs text-secondary-600">
                Bạn có thể tìm thấy danh sách các Tổ chức/Nhóm đã xoá thuộc
                quyền sở hữu.
              </p>
            </div>

            <div className="text-xs flex items-center">
              <span className="text-secondary-600">Sắp xếp theo:</span>
              <Select
                defaultValue={sort || GroupSortDateEnum.NEWEST}
                onValueChange={(value) => {
                  if (value === GroupSortDateEnum.NEWEST) {
                    setSort(null);
                    urlParams.delete("sort");
                  } else {
                    urlParams.set("sort", value);
                    setPage(1);
                    setSort(value);
                  }
                  navigate(
                    `${window.location.pathname}?${urlParams.toString()}`
                  );
                }}
              >
                <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                  <SelectValue
                    defaultValue={sort || GroupSortDateEnum.NEWEST}
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem
                      value={GroupSortDateEnum.NEWEST}
                      className={
                        sort == null
                          ? "text-secondary-900 font-medium"
                          : "text-secondary-600  "
                      }
                    >
                      Mới nhất
                    </SelectItem>
                    <SelectItem
                      value={GroupSortDateEnum.OLDEST}
                      className={
                        sort == GroupSortDateEnum.OLDEST
                          ? "text-secondary-900 font-medium"
                          : "text-secondary-600  "
                      }
                    >
                      Cũ nhất
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-4 ">
        <InfiniteScroll
          style={{
            height: "unset",
            overflow: "unset",
          }}
          className="px-0 mb-12"
          dataLength={myGroupsRestore.length}
          next={() => {
            setPage((prev) => prev + 1);
            fetchMyGroupsInfiniteScroll(page + 1);
          }}
          hasMore={isLoadMore}
          loader={<div className="d-flex justify-content-center mt-8"></div>}
        >
          {myGroupsRestore.length === 0 ? (
            <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
              <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                <Empty />
                <span className="text-secondary-600 text-xs mt-4 font-semibold">
                  {isFiltering
                    ? "0 kết quả phù hợp"
                    : "Bạn chưa có Tổ chức nào đã xóa"}
                </span>
              </div>
            </div>
          ) : (
            <div className="grid gap-4">
              {/* <p className="text-sm font-semibold text-secondary-600">Others</p> */}
              <div className="grid gap-4 grid-cols-3">
                {myGroupsRestore.map((group) => {
                  return (
                    <GroupCardRestore
                      key={group.id}
                      group={group}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default RootGroupDeletedScreen;
