import { isAxiosError } from "axios";

import { useToast } from "@/components/ui/use-toast";
import { getMessage } from "@/utils/message";

const useToastError = () => {
  const { toast } = useToast();
  const showToastError = (error: unknown) => {
    if (isAxiosError(error)) {
      if (error.response?.data?.errorCode === "InvalidOTP") {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG_TK7"),
        });
        return;
      }
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data?.errors?.[0]),
      });
    } else {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };
  return {
    showToastError,
  };
};

export default useToastError;
