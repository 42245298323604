import React, {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";

import get from "lodash.get";
import { Table } from "react-bootstrap";

import { DirectionUp3 } from "@/assets/icons";

import TGNCheckbox from "../TGNCheckboxRadio/TGNCheckbox";
import Typography from "../Typography/Typography";

type Column = {
  label: string;
  value: string;
  isSort?: boolean;
};

type TGNTableProps = {
  columns: Column[];
  data: Record<string, ReactNode | string>[];
  disable?: boolean;
  canSelect?: boolean;
  onSelect?: (selected: number[]) => void;
};

const TGNTable: FC<TGNTableProps> = ({
  columns,
  data,
  disable = false,
  canSelect = false,
  onSelect,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const onChangeSelected = (index: number) => {
    const dummySelected = [...selected];
    const itemIndex = dummySelected.findIndex((select) => select === index);
    if (itemIndex > -1) {
      dummySelected.splice(itemIndex, 1);
    } else {
      dummySelected.push(index);
    }
    setSelected(dummySelected);
  };
  const onSelectAll = useCallback(() => {
    if (selected.length) {
      setSelected([]);
    } else {
      setSelected(Array.from({ length: data.length }, (_, i) => i));
    }
  }, [selected.length, data.length]);
  useEffect(() => {
    if (onSelect) {
      onSelect(selected);
    }
  }, [selected, onSelect]);

  return (
    <>
      <Table responsive="lg">
        <thead
          style={{
            background: "var(--bs-gray-50)",
          }}
        >
          <tr>
            {canSelect && (
              <th
                style={{
                  width: "40px",
                }}
              >
                <TGNCheckbox
                  disabled={disable}
                  checked={
                    selected.length > 0 && selected.length === data.length
                  }
                  onChange={onSelectAll}
                />
              </th>
            )}
            {columns.map((col) => {
              if (col.value != "action") {
                return (
                  <th key={col.value}>
                    <div className="d-flex align-items-center">
                      <Typography
                        color="color-gray-c4"
                        className="fs-12 fw-normal me-1"
                      >
                        {col.label}
                      </Typography>
                      {col.isSort && <DirectionUp3 />}
                    </div>
                  </th>
                );
              } else {
                return (
                  <th key={col.value}>
                    <div className="d-flex align-items-center justify-content-end">
                      <Typography
                        color="color-gray-c4"
                        className="fs-12 fw-normal me-1"
                      >
                        {col.label}
                      </Typography>
                      {col.isSort && <DirectionUp3 />}
                    </div>
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index}>
                {canSelect && (
                  <td
                    className="align-middle"
                    style={{
                      borderBottom: index === data.length - 1 ? "none" : "",
                    }}
                  >
                    <TGNCheckbox
                      disabled={disable}
                      checked={selected.includes(index)}
                      onChange={() => onChangeSelected(index)}
                    />
                  </td>
                )}
                {columns.map((col) => {
                  return (
                    <td
                      key={col.value}
                      className="align-middle"
                      style={{
                        borderBottom: index === data.length - 1 ? "none" : "",
                      }}
                    >
                      {get(row, col.value)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {data.length === 0 && (
        <div className="text-center my-12">Không có dữ liệu</div>
      )}
    </>
  );
};

export default memo(TGNTable);
