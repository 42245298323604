import { useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT,
  TOTAL_PAGE_DEFAULT,
} from "@/constants/app.constants";

const usePagination = () => {
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    current: Number(searchParams.get("page")) || PAGE_DEFAULT,
    total: TOTAL_PAGE_DEFAULT,
    size: PAGE_SIZE_DEFAULT,
  });
  return { pagination, setPagination };
};

export default usePagination;
