import React, { FC, useEffect, useRef, useState } from "react";

import { InformationFilled, Edit, PauseFilled } from "@carbon/icons-react";

import { Workflow } from "@/assets/icons";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { BlockDetail } from "./BlockDetail";
import {
  getInstanceContent,
  updateInstance,
  getTracklog,
} from "../api/operation.api";
import { WorkflowStatus } from "../constants/operation.constant";
import { renderTreemap } from "../lib/tree";
import { workflowSlice } from "../redux/workflow.slice";
import { ApiResponse, Block, WorkflowItemDef } from "../types/operation";

type Props = {
  groupId: number;
  workflowId: number | null;
  close?: () => void;
};
export const WorkflowDetail: FC<Props> = ({ groupId, workflowId, close }) => {
  const dispatch = useAppDispatch();

  const currentBlock = useAppSelector((state) => state.workflow.currentBlock);
  const [openDetail, setOpenDetail] = useState(false);
  const [openTracklog, setOpenTracklog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const { toast } = useToast();
  const [tracklog, setTracklog] = useState<any>();

  useEffect(() => {
    if (workflowId) {
      setOpenDetail(true);

      fetchContent(workflowId, groupId);
      fetchTracklog(workflowId, groupId);
    }
  }, [workflowId]);

  //handel tree
  const [treeData, setTreeData] = useState<WorkflowItemDef>();

  const fetchContent = async (fileId: number, groupId: number) => {
    try {
      const result = await getInstanceContent({
        instanceId: fileId,
        groupId: groupId,
      });
      const tree = constructTree(result);
      setFileName(result.data.instanceName);
      setTreeData(tree);
    } catch (error) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: "Tải dữ liệu thất bại",
      });
    }
  };
  const fetchTracklog = async (fileId: number, groupId: number) => {
    try {
      const result = await getTracklog({
        workflowInstanceId: fileId,
        groupId: groupId,
      });
      setTracklog(result.data);
    } catch (error) {
      console.error("Failed to fetch tracklog:", error);
    }
  };
  const constructTree = (response: ApiResponse): any => {
    const { blocks, connections } = response.data;

    const blockMap: Record<number, Block & { children: Block[] }> =
      blocks.reduce(
        (acc: Record<number, Block & { children: Block[] }>, block) => {
          acc[block.id] = { ...block, children: [] }; // Initialize each block with an empty children array
          return acc;
        },
        {}
      );

    connections.forEach(({ fromBlockId, toBlockId }) => {
      if (blockMap[fromBlockId]) {
        blockMap[fromBlockId].children.push(blockMap[toBlockId]);
      }
    });

    const childIds = new Set(connections.map((conn) => conn.toBlockId));
    const rootBlocks = blocks.filter((block) => !childIds.has(block.id));

    const appendEndBlock = (node: any) => {
      if (node.children.length === 0) {
        // If the node has no children, add the "END" block
        node.children.push({
          name: "Kết thúc",
          type: "END",
          id: generateNumber(4),
          parentId: node.id,
          parentTempId: String(node.tempId),
        });
      } else {
        node.children.forEach((child: any) => appendEndBlock(child));
      }
    };

    const tree = {
      id: generateNumber(4),
      name: "Bắt đầu",
      type: "START",
      parentId: 0,
      children: rootBlocks.map((root) => blockMap[root.id]),
    };

    appendEndBlock(tree);

    return tree;
  };
  const generateNumber = (len: number) =>
    Math.floor(
      Math.random() * (Math.pow(10, len) - Math.pow(10, len - 1)) +
        Math.pow(10, len - 1)
    );

  useEffect(() => {
    if (treeData) {
      renderTreemap("#treemap", treeData);
    }
  }, [treeData]);

  const updateInstanceFn = async () => {
    if (workflowId) {
      try {
        await updateInstance({
          groupId: groupId,
          status: WorkflowStatus.STOPPED,
          instanceId: workflowId,
        });
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };

  return (
    <>
      <Dialog
        open={openDetail}
        onOpenChange={() => (
          setOpenDetail(false),
          close && close(),
          dispatch(workflowSlice.actions.setSelectedBlock(null))
        )}
      >
        <DialogContent className="min-w-[90%] min-h-[90%] p-0 ">
          {loading ? (
            <div className="flex justify-center items-center p-2">
              <TGNLoading size="medium" />
            </div>
          ) : (
            <div className="overflow-hidden rounded">
              <div className="w-full h-[52px] p-2 bg-white flex justify-between items-center gap-2 ">
                <div className="flex items-center gap-2">
                  <div className="w-7 h-7 bg-[#e8e8e8]/40 rounded-lg flex-col justify-center items-center gap-2.5 inline-flex overflow-hidden">
                    <Workflow />
                  </div>

                  <div className="text-[#18191c] text-sm font-medium">
                    {fileName}
                  </div>
                </div>

                <div className="px-2 py-2.5 rounded justify-start items-center gap-3 flex">
                  <div className=" items-center gap-1 flex">
                    <InformationFilled className="text-[#747577] " />
                    <div className="text-[#747577] text-xs">
                      Chọn bước để xem thiết lập chi tiết
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    className="gap-1"
                    onClick={() => {
                      setOpenDetail(false), setOpenTracklog(true);
                    }}
                  >
                    Xem Tracklog
                  </Button>
                  <Button
                    variant="ghost"
                    className="gap-1"
                    disabled
                  >
                    <Edit />
                    Chỉnh sửa
                  </Button>
                  <Button
                    variant="destructive"
                    className="gap-1"
                    onClick={() => updateInstanceFn()}
                  >
                    <PauseFilled />
                    Dừng luồng việc
                  </Button>
                </div>
              </div>
              <div className="flex h-[calc(100%-52px)] p-2 gap-1">
                <div
                  id="treemap"
                  className="w-full h-[calc(100%)] bg-[#F5F5F5] border border-[#E8E8E8] rounded"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                ></div>
                {currentBlock != null && (
                  <div className="w-[24%] min-w-[240px] bg-neutral-100 rounded border border-[#e8e8e8] flex-col justify-start items-start inline-flex overflow-hidden">
                    <BlockDetail groupId={groupId} />
                  </div>
                )}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTracklog}
        onOpenChange={() => (
          setOpenTracklog(false),
          close && close(),
          dispatch(workflowSlice.actions.setSelectedBlock(null))
        )}
      >
        <DialogContent className="min-w-[90%] h-[90%] p-0 overflow-hidden flex flex-col">
          <div className="rounded grid grid-rows-[auto,1fr] h-full">
            {/* Header Section */}
            <div className="font-bold text-2xl p-5">{"tracklog Details"}</div>

            {/* Scrollable Content */}
            <div className="overflow-auto bg-secondary-100 w-full p-10">
              <h3 className="text-lg font-semibold">Details</h3>
              <p>
                <strong>Group ID:</strong> {tracklog?.groupId}
              </p>
              <p>
                <strong>Track Log ID:</strong> {tracklog?.trackLogId}
              </p>
              {JSON.stringify(tracklog)}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
