import React, { useEffect, useState } from "react";

import { Search } from "@carbon/icons-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";

import { Empty } from "@/assets/icons";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { getMyGroups } from "@/features/groups/api/group.api";
import CreateGroupModal from "@/features/groups/components/CreateGroupModal/CreateGroupModal";
import GroupCard from "@/features/groups/components/GroupCard/GroupCard";
import {
  GroupRoleEnum,
  GroupSortEnum,
  GroupTypeEnum,
} from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { groupActions } from "@/features/groups/redux/group.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";
// eslint-disable-next-line import/order
import { GroupDef } from "@/features/groups/types/group.type";
import UserCard from "../../components/UserCard";

const GroupsScreen = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const loadings = useAppSelector((state) => state.group.loading);

  const [myGroups, setMyGroups] = useState<GroupDef[]>([]);
  const [rootGroups, setRootGroups] = useState<GroupDef[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const navigate = useNavigate();

  const flagList = useAppSelector((state) => state.group.flagList);
  const urlParams = new URLSearchParams(window.location.search);
  // const roleFilter = urlParams.get("roleFilter");
  const [roleFilter, setRoleFilter] = useState(urlParams.get("roleFilter"));

  // const rootGroupId = Number(urlParams.get("rootGroupId")) || undefined;
  const [rootGroupId, setRootGroupId] = useState(
    Number(urlParams.get("rootGroupId")) || undefined
  );
  // const sort = urlParams.get("sort") as GroupSortEnum;
  const [sort, setSort] = useState(urlParams.get("sort"));

  // const typeFilter = urlParams.get("typeFilter");
  const [typeFilter, setTypeFilter] = useState(urlParams.get("typeFilter"));
  let isFiltering = false;
  if (roleFilter || rootGroupId || sort || typeFilter) {
    isFiltering = true;
  }
  const fetchMyGroupsInfiniteScroll = async (pageScroll: number) => {
    try {
      setIsLoadMore(true);
      const result = await getMyGroups({
        pageNumber: pageScroll,
        pageSize: pageSize,
        typeFilter: typeFilter as GroupTypeEnum,
        roleFilter: roleFilter as GroupRoleEnum,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroups(() => [...myGroups, ...result.data]);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  const fetchMyGroups = async (pageNumber: number) => {
    try {
      setLoading(true);
      setIsLoadMore(true);
      const result = await getMyGroups({
        pageNumber: pageNumber,
        pageSize: pageSize,
        typeFilter: typeFilter as GroupTypeEnum,
        roleFilter: roleFilter as GroupRoleEnum,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setTotalElement(result.totalElements);
      setMyGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchRootGroups = async () => {
    try {
      const result = await getMyGroups({
        typeFilter: GroupTypeEnum.ROOT,
      });
      setRootGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };
  const fetchFlagGroups = async () => {
    try {
      const result = await getMyGroups({});
      dispatch(
        groupActions.setFlagList(result.data.length == 0 ? true : false)
      );
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };
  useEffect(() => {
    fetchMyGroups(page);
    fetchRootGroups();
  }, [roleFilter, rootGroupId, sort, typeFilter, loadings]);

  useEffect(() => {
    fetchMyGroups(page);
    fetchFlagGroups();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center mt-6">
          <TGNLoading
            size="large"
            isFullScreen
          />
        </div>
      ) : (
        <div className="container py-6">
          <InfiniteScroll
            style={{
              height: "unset",
              overflow: "unset",
            }}
            className="px-0"
            dataLength={myGroups.length}
            next={() => {
              setPage((prev) => prev + 1);
              fetchMyGroupsInfiniteScroll(page + 1);
            }}
            hasMore={isLoadMore}
            loader={
              <div className="flex items-center justify-center mt-6">
                <Loading3Dot
                  setWidth="30"
                  setHeight="30"
                />
              </div>
            }
          >
            {myGroups.length === 0 ? (
              <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
                <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                  <Empty />
                  <span className="text-secondary-600 text-xs mt-4 font-semibold">
                    {isFiltering
                      ? "0 kết quả phù hợp"
                      : "Bạn chưa là thành viên của Tổ chức nào. Tạo nhóm hoặc khám phá thêm."}
                  </span>
                </div>
                {!isFiltering && (
                  <div className="mt-6 flex">
                    <CreateGroupModal haveSubGroup={true} />
                    <Link
                      to={GroupPathsEnum.GROUP_DISCOVER}
                      className="px-4 py-2.5 text-sm border border-primary-600 rounded text-primary-600 ml-3"
                    >
                      Khám phá
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className="grid gap-4">
                <p className="text-sm font-semibold text-secondary-600">
                  Others
                </p>
                <div className="flex flex-wrap gap-4 justify-start">
                  {myGroups.map((group) => {
                    return <UserCard key={group.groupId} />;
                  })}
                </div>
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default GroupsScreen;
