import LandingPage from "@/features/LandingPage";
import { RouteItemDef } from "@/types/app.types";

import { AuthPathsEnum } from "../constants/auth.paths";
import LoginScreen from "../screens/LoginScreen/LoginScreen";

export const AUTH_SCREEN: RouteItemDef[] = [
  {
    id: "home",
    path: AuthPathsEnum.HOME,
    component: LandingPage,
  },
  {
    id: "login",
    path: AuthPathsEnum.LOGIN,
    component: LoginScreen,
  },
];

export const AUTH_ROUTES = [...AUTH_SCREEN];
