import React, { FC, ReactNode, memo, useState } from "react";

import { CheckmarkFilled, Close } from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import {
  acceptGroupInvitation,
  getGroupInvitationForUser,
} from "@/features/groups/api/group.api";
import { GroupUserStatusEnum } from "@/features/groups/constants/group.constant";
import {
  getGroupInfo,
  getGroupInvitationForUserThunk,
  groupActions,
} from "@/features/groups/group";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { COMMON_MESSAGES, getCustomMessage, getMessage } from "@/utils/message";

import SuggestModal from "./SuggestModal";
import { GroupPathsEnum } from "../../../constants/group.paths";
import { IGroupInvitation } from "../../../types/group.type";

type AcceptGroupModalProps = {
  inviteId: number;
  groupId: number;
  rootGroupName: string;
  rootGroupId: number;
  groupName: string;
  button: ReactNode;
  isDetail?: boolean;
  groupLock: boolean;
};

const AcceptGroupModal: FC<AcceptGroupModalProps> = ({
  button,
  isDetail,
  inviteId,
  groupName,
  groupId,
  rootGroupName,
  rootGroupId,
  groupLock,
}) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuggest, setIsSuggest] = useState(false);
  const [rootInvite, setRootInvite] = useState<IGroupInvitation>();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleAcceptOrg = async (id: number) => {
    try {
      await acceptGroupInvitation(true, id);
      toast({
        title: "Thành công",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSG63", String(rootGroupName)),
            }}
          ></div>
        ),
      });
      dispatch(
        getGroupInvitationForUserThunk({
          pageNumber: 1,
          pageSize: 9,
        })
      );
      setOpenModal(false);
      setOpenConfirmModal(true);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG12,
      });
    }
  };
  const handleAcceptGroup = async () => {
    if (groupLock) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG80,
      });
    } else {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      try {
        const childInvites = await getGroupInvitationForUser({
          rootGroupId: rootGroupId,
        });

        const index = childInvites.data.findIndex(
          (item) => item.groupId === rootGroupId
        );
        childInvites.data.map((item) => {
          if (item.groupId === rootGroupId) {
            setRootInvite(item);
          }
        });

        if (index !== -1) {
          setOpenModal(true);
        } else {
          const res = await acceptGroupInvitation(true, inviteId);
          dispatch(groupActions.setFlagLoading(true));
          if (
            res.data.status === GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
          ) {
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG65", String(groupName)),
                  }}
                ></div>
              ),
            });
          } else {
            toast({
              title: "Thành công!",
              variant: "success",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getCustomMessage("MSG63", String(groupName)),
                  }}
                ></div>
              ),
            });
          }
          dispatch(
            getGroupInvitationForUserThunk({
              pageNumber: 1,
              pageSize: 9,
            })
          );
          setOpenConfirmModal(false);
          if (
            childInvites.data.filter(
              (item) =>
                !(
                  item.status ===
                    GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ||
                  item.groupId === groupId
                )
            ).length > 0
          ) {
            setIsSuggest(true);
          } else {
            if (isDetail) {
              dispatch(
                getGroupInfo({
                  group_id: groupId,
                })
              )
                .then(unwrapResult)
                .catch(() => {
                  navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
                });
            }
          }
        }
      } catch (error: any) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      } finally {
        dispatch(loadingAction.hide());
        dispatch(groupActions.setFlagLoading(false));
      }
    }
  };

  return (
    <>
      <Dialog open={openModal}>
        <DialogTrigger asChild>
          <div
            className="w-full"
            onClick={() => handleAcceptGroup()}
          >
            {button}
          </div>
        </DialogTrigger>
        <DialogContent className="max-w-[600px] gap-6">
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center max-w-[460px] text-lg mx-auto">
              Lời mời tham gia Tổ chức
              <p className="text-xs text-secondary-600 font-normal">
                Trước đó, bạn đã nhận được lời mời tham gia Tổ chức mà nhóm này
                trực thuộc. Vui lòng chấp nhận lời mời để trở thành thành viên
                của tổ chức, trước khi xử lý lời mời của nhóm.
              </p>
            </DialogTitle>
          </DialogHeader>
          <div className="mx-auto">
            <div className="justify-start items-center gap-2 flex">
              <img
                className="w-10 h-10 rounded-xl"
                src={rootInvite?.photoUrl ? rootInvite?.photoUrl : GroupCover}
                alt=""
              />
              <div className="flex-col justify-start items-start inline-flex">
                <div className="self-stretch text-secondary-900 text-sm font-medium ">
                  {rootInvite?.name}
                </div>
                <div className="justify-start items-start gap-1 inline-flex">
                  <div className="text-secondary-600 text-xs font-normal leading-none">
                    Được mời bởi
                  </div>
                  <div className="text-secondary-600 text-xs font-medium underline leading-none">
                    {rootInvite?.sender.name || rootInvite?.sender.email}
                  </div>
                  <div className="text-secondary-600 text-xs font-normal leading-none">
                    {rootInvite && formatDistance(rootInvite?.invitedAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter className="sm:justify-center mb-4 -mt-1">
            <Button
              size="sm"
              onClick={() => rootInvite && handleAcceptOrg(rootInvite?.id)}
            >
              Chấp nhận
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={openConfirmModal}>
        <DialogContent className="gap-6 max-w-[600px]">
          <DialogHeader>
            <DialogTitle className="text-center flex justify-between text-lg gap-2">
              <div className="w-[36px]"></div>
              <div>Xác nhận tham gia Nhóm</div>
              <Button
                variant="ghost"
                size="sm"
                className="rounded-full w-[36px]"
                onClick={() => {
                  setOpenConfirmModal(false);
                }}
              >
                <Close />
              </Button>
            </DialogTitle>
          </DialogHeader>
          <p className="text-sm text-secondary-900 font-normal text-center">
            Trở thành thành viên của nhóm{" "}
            <span className="font-semibold">{groupName}</span>?
          </p>
          <DialogFooter className="sm:justify-center mb-3 -mt-1">
            <Button
              onClick={() => inviteId && handleAcceptGroup()}
              size="sm"
              className=""
            >
              Xác nhận
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isSuccess}
        onOpenChange={setIsSuccess}
      >
        <DialogContent className="max-w-[464px]">
          <DialogHeader>
            <span className="flex items-center justify-center border-[6px] p-1 text-success-600 border-success-50 bg-success-100 rounded-full mx-auto">
              <CheckmarkFilled
                size={20}
                className="text-success-500"
              />
            </span>
          </DialogHeader>
          <div className="text-center gap-2 grid ">
            <DialogTitle className="text-center text-lg">
              Thành công!
            </DialogTitle>
            <DialogDescription className="text-center text-secondary-600">
              Bạn đã là thành viên của Nhóm{" "}
              <span className="font-semibold"> "{groupName}"</span>. Giờ đây,
              bạn đã có thể truy cập và tham gia vào các hoạt động của nhóm này.
            </DialogDescription>
            <div className="">
              <Link
                className="px-2 py-3 text-sm font-medium text-primary-600 flex items-center mx-auto justify-center"
                to={GroupPathsEnum.GROUP_DETAIL.replace(":id", String(groupId))}
              >
                Truy cập nhóm
              </Link>
            </div>
          </div>
          <DialogFooter className="sm:justify-center">
            <Button
              variant="text"
              onClick={() => {
                setIsSuccess(false);
                dispatch(groupActions.setFlagLoading(false));
              }}
            >
              Để sau
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <SuggestModal
        isDetail={isDetail}
        openModal={isSuggest}
        handelModal={() => setIsSuggest(false)}
        groupInfo={rootGroupId}
        root={false}
      />
    </>
  );
};

export default memo(AcceptGroupModal);
