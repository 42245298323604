import React, { FC } from "react";

import cx from "classnames";
import { FieldProps } from "formik";
import Form from "react-bootstrap/Form";
import { FormCheckProps } from "react-bootstrap/FormCheck";

import style from "./CheckboxRadio.module.scss";

type TGNSwitchProps = {
  disabled?: boolean;
  label?: string;
  className?: string;
} & FormCheckProps &
  Partial<FieldProps>;

const TGNSwitch: FC<TGNSwitchProps> = ({
  disabled = false,
  label,
  field,
  className,
  ...props
}) => {
  return (
    <Form.Check
      className={cx(style["tgn-switch"], className)}
      disabled={disabled}
      type="switch"
      label={label}
      {...props}
      {...field}
    />
  );
};

export default TGNSwitch;
