import React, { FC, memo, useMemo, useRef, useState } from "react";

import cx from "classnames";
import { v4 as uuid4 } from "uuid";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { UserDef } from "@/features/auth/types/auth.types";
import {
  ChatSocketTypeEnums,
  KEY_CODE,
  LineDef,
  OWNER_EXAMPLE,
} from "@/features/mindmap/mindmap";
import { useAppSelector } from "@/redux/store";

import CommentLine from "./CommentLine";
import styles from "./Section.module.scss";

export type LineProps = {
  line: LineDef;
};

const Line: FC<LineProps> = ({ line }) => {
  const { mindmapIO, currentSheet } = useAppSelector((state) => state.mindmap);
  const [isHover, setIsHover] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [commentValue, setCommentValue] = useState("");
  const onChangeComment = (e: React.FormEvent<HTMLDivElement>) => {
    setCommentValue(e.currentTarget.innerText);
  };
  const onKeydownComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.keyCode === KEY_CODE.ENTER) {
      if (mindmapIO && currentSheet.selectedNode) {
        mindmapIO.addChat({
          stepId: currentSheet.selectedNode.data.id,
          value: {
            id: uuid4(),
            type: ChatSocketTypeEnums.COMMENT,
            content: e.currentTarget.innerText,
            lineId: line.id,
            sectionId: line.sectionId,
            user: OWNER_EXAMPLE,
          },
        });
        setShowComment(true);
      }
      setTimeout(() => {
        setCommentValue("");
        if (inputRef.current) {
          inputRef.current.innerHTML = "";
        }
      }, 100);
      return;
    }
  };
  const users = useMemo(() => {
    return line.comments.reduce((prev, current) => {
      if (prev[current.user.id]) {
        return {
          ...prev,
        };
      }
      prev[current.user.id] = current.user;
      return {
        ...prev,
      };
    }, {} as Record<string, UserDef>);
  }, [line.comments]);

  return (
    <div
      className={styles.commentBox}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <div className={styles.comment}>{line.content}</div>
      <>
        <div className="d-flex align-items-center">
          {!showComment &&
            Object.values(users).map((user) => (
              <TGNAvatar
                key={user.id}
                name={user.name}
                size="20"
                src={user.photo}
              />
            ))}
          {!!line.comments.length && (
            <span
              className={cx(
                "fs-12 text-gray-900 cursor-pointer mt-2 ms-3",
                styles.toggle
              )}
              onClick={() => {
                setShowComment(!showComment);
                setShowCommentInput(!showComment);
              }}
            >
              {showComment
                ? "Ẩn bình luận"
                : line.comments.length + " bình luận"}
            </span>
          )}
        </div>
        <div className={styles["comment-box"]}>
          {showComment &&
            line.comments.map((comment) => (
              <CommentLine
                key={comment.id}
                comment={comment}
              />
            ))}
          {showCommentInput && (
            <div className="d-flex gap-4 mb-8 mt-8">
              <TGNAvatar
                name="helol"
                size="20"
                round
              />
              <div
                ref={inputRef}
                className={styles["comment-input"]}
                contentEditable
                aria-placeholder="Bình luận ..."
                onKeyDown={onKeydownComment}
                onInput={onChangeComment}
              ></div>
              <div className="mt-2">
                <i
                  className={cx(
                    "tgn-solid-sent-fast text-color-gray-1 fs-20 cursor-pointer",
                    commentValue && "text-brand-primary"
                  )}
                ></i>
              </div>
            </div>
          )}
        </div>
      </>

      {isHover && (
        <i
          className={cx("tgn-outline-chat-02 fs-16", styles.icon)}
          onClick={() => {
            setShowCommentInput(!showCommentInput);
          }}
        ></i>
      )}
    </div>
  );
};

export default memo(Line);
