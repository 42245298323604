import React, { memo } from "react";

import { Route, Routes } from "react-router-dom";

import NotFound from "@/components/layout/NotFound";
import Home from "@/features/home";

import { ROUTE_LIST } from "./routes.config";

const RoutesWrapper = () => {
  return (
    <Routes>
      {ROUTE_LIST.map((route) => {
        const Component = route.component;
        const Layout = route?.layout || "div";
        if (route.children) {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={<Component />}
            >
              {route.children.map((child) => {
                const ChildComponent = child.component;
                const routePermission = child.permissions;
                if (routePermission) {
                  return (
                    <Route
                      key={child.id}
                      path={child.path}
                      element={<ChildComponent />}
                    ></Route>
                  );
                }
                return (
                  <Route
                    key={child.id}
                    path={child.path}
                    element={<ChildComponent />}
                  >
                    {child.children?.map((childSub) => {
                      const ChildComponent = childSub.component;
                      const routePermission = childSub.permissions;
                      return (
                        <Route
                          key={childSub.id}
                          path={childSub.path}
                          element={<ChildComponent />}
                        ></Route>
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        } else {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={
                Layout ? (
                  <Layout>
                    <Component />
                  </Layout>
                ) : (
                  <Component />
                )
              }
            ></Route>
          );
        }
      })}
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/chats"
        element={<Home />}
      />
      <Route
        path="*"
        element={<Home />}
      />
      <Route
        path="/404"
        element={<NotFound />}
      />
    </Routes>
  );
};

export default memo(RoutesWrapper);
