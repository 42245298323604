export function extractUsername(email: string) {
  const username = email.split("@")[0];
  return username;
}

export function getSecretEmail(email?: string) {
  if (!email) {
    return "";
  }
  const prefixEmail = email.split("@")[0];
  const domain = email.split("@")[1];
  if (!prefixEmail) {
    return "";
  }
  if (prefixEmail.length <= 3) {
    return prefixEmail.substring(0, 1) + "********@" + domain;
  }
  return prefixEmail.substring(0, 4) + "********@" + domain;
}
