import api from "@/api/api_instance";
import { GetOtpResponse } from "@/features/auth/types/auth.types";
import { AppResponse } from "@/types/app.types";

import { UserMFAEnums } from "../constants/account.constants";
import { AccountEndpointsEnum } from "../constants/account.endpoints";
import {
  ChangePasswordParams,
  SendOtpResponse,
  UpdateEmailOrPhoneNumberParams,
  UpdateProfileParams,
  UploadPhotoParams,
  UploadPhotoResponse,
} from "../types/account.types";

export const updateProfile = (
  params: UpdateProfileParams
): Promise<AppResponse<true>> => {
  return api.put(AccountEndpointsEnum.UPDATE_PROFILE, params);
};

export const getPresignedUrlS3 = (
  params: UploadPhotoParams
): Promise<AppResponse<UploadPhotoResponse>> => {
  return api.post(AccountEndpointsEnum.UPLOAD_PHOTO, params);
};

export const uploadPhoto = (
  url: string,
  file: File,
  typeFile: string,
  fileLength: number
) => {
  return api.put(url, file, {
    headers: {
      "Content-Type": typeFile,
      "Content-Length": fileLength,
    },
  });
};

export const sendOtp = (
  email: string
): Promise<AppResponse<SendOtpResponse>> => {
  return api.post(AccountEndpointsEnum.OTP, {
    email,
  });
};

export const sendOtpToPhoneNumber = (
  phone: string
): Promise<AppResponse<SendOtpResponse>> => {
  return api.get(AccountEndpointsEnum.SEND_OTP_TO_PHONE_NUMBER, {
    params: {
      phoneNumber: phone,
    },
  });
};

export const login = (username: string, password: string) => {
  return api.post(AccountEndpointsEnum.LOGIN, { username, password });
};

export const getUserMFA = (): Promise<AppResponse<UserMFAEnums[]>> => {
  return api.get(AccountEndpointsEnum.MFA);
};

export const updateUserMFAType = (
  type: UserMFAEnums
): Promise<AppResponse<true>> => {
  return api.put(AccountEndpointsEnum.MFA, {
    userMFAType: type,
  });
};

export const changePassword = (params: ChangePasswordParams) => {
  const { newPassword, currentPassword, uid, otp } = params;
  return api.post(
    AccountEndpointsEnum.CHANGE_PASSWORD,
    {
      currentPassword,
      newPassword,
    },
    {
      headers: {
        "tg-code": otp,
        "tg-uuid": uid,
        xUuid: uid,
        xCode: otp,
      },
    }
  );
};

export const getUserOtp = (
  username: string,
  password: string
): Promise<GetOtpResponse> => {
  return api.post(AccountEndpointsEnum.USER_OTP, {
    username,
    password,
  });
};

export const checkExistForEmailOrPhoneNumber = (params: {
  email?: string;
  phoneNumber?: string;
}): Promise<AppResponse<boolean>> => {
  return api.post(AccountEndpointsEnum.CHECK_EXIST_EMAIL_OR_PHONENUMBER, {
    email: params.email || undefined,
    phoneNumber: params.phoneNumber || undefined,
  });
};

export const updateEmailOrPhoneNumberApi = (
  params: UpdateEmailOrPhoneNumberParams
) => {
  return api.put(
    AccountEndpointsEnum.UPDATE_PHONE_NUMBER_OR_EMAIL,
    {
      email: params.email || undefined,
      phoneNumber: params.phoneNumber || undefined,
    },
    {
      headers: {
        "tg-code": params.otp,
        "tg-uuid": params.uid,
      },
    }
  );
};

export const removeEmailOrPhoneNumber = (params: {
  email?: boolean;
  phoneNumber?: boolean;
}) => {
  return api.delete(AccountEndpointsEnum.REMOVE_EMAIL_OR_PHONE_NUMBER, {
    data: {
      email: params.email || undefined,
      phoneNumber: params.phoneNumber || undefined,
    },
  });
};
