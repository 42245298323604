export enum LibPathsEnum {
  CLOUD = "/cloud",
  RECENTLY = "/cloud/recently",
  SHARE_WITH_ME = "/cloud/share-with-me",
  FAVORITE = "/cloud/favorite",
  BLANK = "/cloud/blank",
  TRASH = "/cloud/trash",
  ORG_LIBRARY = "/cloud/org/:id",
  GROUP_LIBRARY = "/cloud/group/:id",
}
