import React, { useState, useEffect } from "react";

import {
  ChevronDown,
  InformationFilled,
  MailReply,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";

import { Empty } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { HoverCardUser } from "@/features/account/component/HoverCardUser";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";
import { getCustomMessage, getMessage } from "@/utils/message";

import {
  getRequestsOfGroup,
  updateStatusJoinRequest,
} from "../../api/group.api";
import {
  GroupUserRoleEnums,
  GroupUserStatusEnum,
} from "../../constants/group.constant";
import { useCheckRoleAndDevice } from "../../hooks/useCheckRoleAndDevice";
import { IGroupRequest } from "../../types/group.type";

const InviteRequestedScreen = () => {
  useCheckRoleAndDevice(
    [GroupUserRoleEnums.OWNER, GroupUserRoleEnums.ADMIN],
    true
  );

  const dispatch = useAppDispatch();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [openModal, setOpenModal] = useState(false);
  const [inviteRequests, setInviteRequests] = useState<IGroupRequest[] | []>(
    []
  );
  const [totalElement, setTotalElement] = useState(0);

  const fetchInfiniteScroll = async (pageScroll: number) => {
    try {
      if (groupInfo) {
        setIsLoadMore(true);
        const result = await getRequestsOfGroup({
          pageNumber: pageScroll,
          pageSize: pageSize,
          groupId: groupInfo.id,
        });
        if (!result.data.length || result.data.length < pageSize) {
          setIsLoadMore(false);
        }
        setInviteRequests(() => [...inviteRequests, ...result.data]);
      }
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };
  const fetchList = async (pageNumber: number) => {
    try {
      if (groupInfo) {
        setLoading(true);
        setIsLoadMore(true);
        const result = await getRequestsOfGroup({
          pageNumber: pageNumber,
          pageSize: pageSize,
          groupId: groupInfo.id,
        });
        if (!result.data.length || result.data.length < pageSize) {
          setIsLoadMore(false);
        }
        setTotalElement(result.totalElements);
        setInviteRequests(result.data);
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchList(page);
  }, []);

  const handelUpdateStatus = async (item: IGroupRequest, accept: boolean) => {
    try {
      await updateStatusJoinRequest({
        id: item.id,
        accept: accept,
        groupId: groupInfo?.id || 0,
      });
      if (accept) {
        toast({
          title: "Thành công",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage(
                  "MSG74",
                  String(
                    item.receiver.name ||
                      extractUsername(item.receiver.email || "")
                  )
                ),
              }}
            ></div>
          ),
        });
      } else {
        toast({
          title: "Thành công",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage(
                  "MSG75",
                  String(
                    item.receiver.name ||
                      extractUsername(item.receiver.email || "")
                  )
                ),
              }}
            ></div>
          ),
        });
      }
      setOpenModal(false);
      fetchList(page);
    } catch (error: any) {
      if (error.response?.data.errors[0]) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    }
  };
  return (
    <div className="pb-6">
      {groupInfo && (
        <>
          <div className="bg-white pt-5 pb-6 z-10 sticky top-0 border-b border-secondary-100">
            <div className="container">
              <p className="text-lg font-semibold text-secondary-900">
                Yêu cầu tham gia
              </p>
              <p className="text-xs text-secondary-600">
                Danh sách yêu cầu tham gia{" "}
                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn
              </p>
            </div>
          </div>
          {inviteRequests.length > 0 ? (
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-end-11">
                <div className="mt-6">
                  <div className=" h-6 justify-start items-center gap-1 inline-flex mb-1">
                    <div className="text-gray-900 text-base font-semibold">
                      Danh sách yêu cầu
                    </div>
                    <div className="text-gray-600 text-xs font-normal">・</div>
                    <div className="text-gray-600 text-sm font-semibold">
                      {totalElement}
                    </div>
                  </div>
                  <p className="text-xs text-secondary-600">
                    Yêu cầu tham gia tổ chức từ người dùng hoặc thông qua các
                    nhóm trong tổ chức.
                  </p>
                  <div className="p-3 pb-6 rounded-lg border border-secondary-200 bg-white mt-6 grid gap-3">
                    <InfiniteScroll
                      style={{
                        height: "unset",
                        overflow: "unset",
                      }}
                      className="px-0"
                      dataLength={inviteRequests.length}
                      next={() => {
                        setPage((prev) => prev + 1);
                        fetchInfiniteScroll(page + 1);
                      }}
                      hasMore={isLoadMore}
                      loader={
                        <div className="flex items-center justify-center mt-6">
                          <Loading3Dot
                            setWidth="30"
                            setHeight="30"
                          />
                        </div>
                      }
                    >
                      {inviteRequests.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`p-3 flex gap-3 items-start`}
                          >
                            <TGNAvatar
                              className={`${
                                item.groupUserStatus ===
                                GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                  ? "opacity-60"
                                  : ""
                              }`}
                              userName={
                                item.receiver.name ||
                                extractUsername(item.receiver.email || "")
                              }
                              userPhoto={item.receiver.photoUrl}
                            />
                            <div>
                              <div className="flex items-center">
                                <span
                                  className={`text-sm text-secondary-900 font-medium flex ${
                                    item.groupUserStatus ===
                                    GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                      ? "opacity-60"
                                      : ""
                                  }`}
                                >
                                  {item.receiver.name ||
                                    extractUsername(item.receiver.email || "")}
                                </span>
                                <span>
                                  {item.groupUserStatus ===
                                    GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE && (
                                    <TooltipProvider>
                                      <Tooltip>
                                        <TooltipTrigger>
                                          <Badge
                                            variant={"orange"}
                                            className="rounded uppercase pl-2 py-0.5 ml-1 opacity-100"
                                          >
                                            Đang xử lý
                                          </Badge>
                                        </TooltipTrigger>
                                        <TooltipContent className="opacity-100">
                                          <p>
                                            Lời mời này đã được chuyển tiếp lên
                                            Tổ chức và đang chờ phê duyệt.
                                          </p>
                                        </TooltipContent>
                                      </Tooltip>
                                    </TooltipProvider>
                                  )}
                                </span>
                              </div>
                              <p
                                className={`text-xs text-secondary-600 ${
                                  item.groupUserStatus ===
                                  GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                    ? "opacity-60"
                                    : ""
                                }`}
                              >
                                {item.receiver.email || item.receiver.phone}
                              </p>
                              {item.senders.map((sender, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`text-xs text-secondary-600 mt-2 ${
                                      item.groupUserStatus ===
                                      GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE
                                        ? "opacity-60"
                                        : ""
                                    }`}
                                  >
                                    Được tạo bởi{" "}
                                    <HoverCardUser
                                      user={sender.sender}
                                      button={
                                        <span className="font-medium underline cursor-pointer">
                                          {sender.sender.name ||
                                            extractUsername(
                                              sender.sender.email || ""
                                            )}
                                        </span>
                                      }
                                    />
                                    {sender.group &&
                                      sender.group.id !== groupInfo.id && (
                                        <span className="font-medium underline cursor-pointer">
                                          {", "}
                                          {sender.group.name || sender.group.id}
                                        </span>
                                      )}{" "}
                                    {formatDistance(sender.requestedAt)}
                                  </div>
                                );
                              })}
                            </div>
                            {item.groupUserStatus !==
                              GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE && (
                              <div className="ml-auto flex gap-3">
                                <Button
                                  disabled={groupInfo.groupLock}
                                  type="button"
                                  onClick={() => handelUpdateStatus(item, true)}
                                >
                                  Chấp nhận
                                </Button>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button
                                      type="button"
                                      className="px-3 py-2"
                                      variant={"text"}
                                    >
                                      <OverflowMenuVertical />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent className="p-0">
                                    {groupInfo.rootGroupId ? (
                                      <Button
                                        disabled={groupInfo.groupLock}
                                        onClick={() =>
                                          handelUpdateStatus(item, false)
                                        }
                                        className="pl-4"
                                        variant="ghost"
                                      >
                                        <TrashCan className="text-secondary-900 mr-1" />
                                        Từ chối yêu cầu
                                      </Button>
                                    ) : (
                                      <Dialog
                                        open={openModal}
                                        onOpenChange={setOpenModal}
                                      >
                                        <DialogTrigger asChild>
                                          <Button
                                            disabled={groupInfo.groupLock}
                                            onClick={() => setOpenModal(true)}
                                            className="pl-4"
                                            variant="ghost"
                                          >
                                            <TrashCan className="text-secondary-900 mr-1" />
                                            Từ chối yêu cầu
                                          </Button>
                                        </DialogTrigger>
                                        <DialogContent className="gap-10">
                                          <DialogHeader className="flex flex-row gap-4">
                                            <div>
                                              <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                                                <InformationFilled size={20} />
                                              </span>
                                            </div>
                                            <div className="flex-1">
                                              <DialogTitle className="mb-2">
                                                Từ chối yêu cầu
                                              </DialogTitle>
                                              <DialogDescription>
                                                <p>
                                                  Bạn chắc chắn muốn từ chối{" "}
                                                  <span className="font-medium">
                                                    {item.receiver.name ||
                                                      extractUsername(
                                                        item.receiver.email ||
                                                          ""
                                                      )}
                                                  </span>{" "}
                                                  tham gia
                                                  {groupInfo.rootGroupId
                                                    ? " Nhóm"
                                                    : " Tổ chức"}
                                                  ?
                                                </p>
                                                <p className="leading-3.5">
                                                  &nbsp;
                                                </p>
                                                <p>
                                                  Những yêu cầu tham gia từ
                                                  người này tới các nhóm thuộc
                                                  tổ chức của bạn sẽ bị huỷ bỏ.
                                                </p>
                                              </DialogDescription>
                                            </div>
                                          </DialogHeader>
                                          <DialogFooter>
                                            <DialogClose asChild>
                                              <Button
                                                size="sm"
                                                variant={"outline"}
                                                type="button"
                                              >
                                                Hủy bỏ
                                              </Button>
                                            </DialogClose>

                                            <Button
                                              size="sm"
                                              variant="secondary"
                                              onClick={() =>
                                                handelUpdateStatus(item, false)
                                              }
                                            >
                                              Xác nhận
                                            </Button>
                                          </DialogFooter>
                                        </DialogContent>
                                      </Dialog>
                                    )}
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
              <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                <Empty />
                <span className="text-secondary-600 text-xs mt-4 font-semibold">
                  Danh sách yêu cầu tham gia trống!
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InviteRequestedScreen;
