import React, { useEffect, useRef, useState } from "react";

import { Grid, List } from "@carbon/icons-react";
import { isAxiosError } from "axios";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Select, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import { GroupSortDateEnum } from "@/features/groups/constants/group.constant";
import { formatBytes, getContentName } from "@/utils/file";
import { getMessage } from "@/utils/message";

import { getGroupLibrary } from "../../api/lib.api";
import FileIcon from "../../components/FileIcon";
import { ContentTypeEnums } from "../../constants/lib.constants";
import { LibPathsEnum } from "../../constants/lib.paths";
import { IContent } from "../../types/lib.types";

const GroupLibrary = () => {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const containerRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const [contents, setContents] = useState<IContent[] | []>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const navigate = useNavigate();
  const fetchContents = async (page: number) => {
    setLoading(true);
    try {
      const result = await getGroupLibrary({
        id: Number(id),
        pageNumber: page,
        pageSize: PAGE_SIZE_DEFAULT,
      });
      if (result.data.length < PAGE_SIZE_DEFAULT) {
        setContents((prev) => [...prev, ...result.data]);
      }
      setContents(result.data);
      setTotalPage(result.totalElements / PAGE_SIZE_DEFAULT);
      setTotalElements(result.totalElements);
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate(LibPathsEnum.CLOUD);
        }
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchContents(page);
  }, [id]);

  return (
    <div className="container mx-auto">
      {contents.length > 0 && (
        <div className="mt-7 mb-4 flex justify-between items-center">
          <p className="text-sm font-semibold text-secondary-600">
            {/* {contents[0].group.name} */}
          </p>
          <div className="flex gap-x-4">
            <div className="flex items-center">
              <span className="text-secondary-600 text-xs">Sắp xếp theo:</span>
              <Select defaultValue={GroupSortDateEnum.NEWEST}>
                <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                  <SelectValue defaultValue={GroupSortDateEnum.NEWEST} />
                </SelectTrigger>
              </Select>
            </div>
            <div className="flex items-center">
              <span className="text-secondary-600 text-xs">Nhóm theo:</span>
              <Select defaultValue={GroupSortDateEnum.NEWEST}>
                <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                  <SelectValue defaultValue={GroupSortDateEnum.NEWEST} />
                </SelectTrigger>
              </Select>
            </div>
            <div className="w-6 h-6 bg-secondary-100 rounded-full flex items-center justify-center">
              <Grid />
            </div>
            <div className="w-6 h-6 rounded-full flex items-center justify-center">
              <List className="text-secondary-300" />
            </div>
          </div>
        </div>
      )}
      <div>
        <div ref={containerRef}>
          {loading ? (
            <div className="flex items-center justify-center mt-6">
              <TGNLoading size="medium" />
            </div>
          ) : (
            <InfiniteScroll
              style={{
                height: "unset",
                overflow: "unset",
              }}
              className="px-0"
              dataLength={contents.length}
              next={() => {
                fetchContents(page + 1);
                setPage((prev) => prev + 1);
              }}
              hasMore={page < totalPage}
              loader={null}
            >
              <div className={clsx("flex flex-wrap gap-y-9")}>
                {contents.map((content) => (
                  <div
                    key={content.id}
                    className={clsx("w-[180px] p-2 rounded h-full")}
                    onDoubleClick={() => {
                      if (content.type === ContentTypeEnums.FOLDER) {
                        // onMenuClick(FolderMenuActions.Open);
                      }
                    }}
                  >
                    <FileIcon
                      extension={content.extension}
                      size={64}
                    />
                    <p className="text-xs font-semibold mt-2 break-words">
                      {getContentName(content.name)}
                    </p>
                    {content.type === ContentTypeEnums.FILE && (
                      <p className="text-xs text-secondary-600">
                        {formatBytes(content.size)}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupLibrary;
