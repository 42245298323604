import { format, formatDistanceToNow, addHours } from "date-fns";
import { vi } from "date-fns/locale";

import { FormatDateEnums } from "@/constants/date.constants";

export const formatDate = (
  date: Date | string | number,
  type = FormatDateEnums.DATE_MONTH
) => {
  if (!date) {
    return "";
  }
  return format(typeof date === "string" ? new Date(date) : date, type, {
    locale: vi,
  });
};
export const formatDateDay = (
  date: Date | string | number,
  type = FormatDateEnums.FULL_DATE_DAY
) => {
  if (!date) {
    return "";
  }
  return format(typeof date === "string" ? new Date(date) : date, type, {
    locale: vi,
  });
};
export const formatViDate = (
  date: Date | string | number,
  type = FormatDateEnums.FULL_MONTH
) => {
  if (!date) {
    return "";
  }
  return format(typeof date === "string" ? new Date(date) : date, type, {
    locale: vi,
  });
};

export const formatDistanceToNowByDate = (
  date: Date | string | number,
  options?: {
    includeSeconds?: boolean | undefined;
    addSuffix?: boolean | undefined;
    locale?: Locale | undefined;
  }
) => {
  return formatDistanceToNow(typeof date === "string" ? new Date(date) : date, {
    locale: vi,
    ...options,
  });
};

export const formatDistance = (
  date: Date | string | number,
  options?: {
    includeSeconds?: boolean | undefined;
    addSuffix?: boolean | undefined;
    locale?: Locale | undefined;
  }
) => {
  const currentDate = new Date();

  // Get the time zone offset in minutes
  const timezoneOffsetMinutes = currentDate.getTimezoneOffset();

  // Convert minutes to hours and get the sign
  const offsetHours = Math.abs(timezoneOffsetMinutes / 60);

  const amount = timezoneOffsetMinutes > 0 ? -offsetHours : offsetHours;
  return formatDistanceToNowByDate(addHours(new Date(date), amount), {
    locale: vi,
    ...options,
  });
};

export const remainingTime = (
  date: Date | string | number,
  afterDate?: number
) => {
  const expiredDate = new Date(date);
  expiredDate.setDate(expiredDate.getDate() + (afterDate ?? 3));
  const currentDate = new Date();

  // Get the time zone offset in minutes
  const timezoneOffsetMinutes = currentDate.getTimezoneOffset();

  // Convert minutes to hours and get the sign
  const offsetHours = Math.abs(timezoneOffsetMinutes / 60);

  const amount = timezoneOffsetMinutes > 0 ? -offsetHours : offsetHours;
  if (currentDate < expiredDate) {
    return `sẽ hết hạn sau ${formatDistance(addHours(expiredDate, amount), {
      locale: vi,
    })}`;
  } else {
    return `đã quá hạn ${formatDistance(addHours(expiredDate, amount), {
      locale: vi,
    })}`;
  }
};
