import { GroupPathsEnum } from "@/features/groups/constants/group.paths";

const menuLinksConst = (_id: number) => {
  const id = String(_id);
  return [
    {
      url: GroupPathsEnum.GROUP_DETAIL.replace(/:id/, id),
      end: true,
      banner: true,
      label: "Giới thiệu",
    },
    {
      url: GroupPathsEnum.GROUP_DISCUSS.replace(/:id/, id),
      end: true,
      banner: true,
      label: "Thảo luận",
    },
    // {
    //   url: GroupPathsEnum.GROUP_ACTIVITY.replace(/:id/, id),
    //   end: false,
    //   banner: false,
    //   label: "Hoạt động",
    // },
    // {
    //   url: GroupPathsEnum.GROUP_TASKS.replace(/:id/, id),
    //   end: false,
    //   banner: false,
    //   label: "Công việc",
    // },
    // {
    //   url: GroupPathsEnum.GROUP_TREE.replace(/:id/, id),
    //   end: false,
    //   banner: false,
    //   label: "Cơ cấu tổ chức",
    // },
    // {
    //   url: `${GroupPathsEnum.GROUP_MEMBER.replace(":id", `${id}` || "")}`,
    //   end: false,
    //   banner: false,
    //   label: "Thành viên",
    // },
    // {
    //   url: GroupPathsEnum.GROUP_FILES.replace(/:id/, id),
    //   end: false,
    //   banner: false,
    //   label: "File",
    // },
  ];
};

export enum MemberTabEnums {
  ALL = "ALL",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  OWNER = "OWNER",
}

export enum FilterTimeTabEnums {
  ALL = "1",
  ONE_WEEK = "2",
  ONE_MONTH = "3",
}

export default menuLinksConst;
