import React, { useState, useEffect, Fragment, useRef } from "react";

import { isAxiosError } from "axios";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import ListEmpty from "@/assets/images/Search_empty.png";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
} from "@/components/ui/breadcrumb";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCustomMessage, getMessage } from "@/utils/message";

import ContentItem from "./ContentItem";
import {
  checkExistName,
  getListSoftDelete,
  hardDeleteContent,
  restoreContent,
} from "../../api/lib.api";
import DialogConfirmDeleteContent from "../../components/DialogConfirmDeleteContent";
import ExistNameModal from "../../components/ExistNameModal";
import {
  ContentTypeEnums,
  FolderTrashMenuActions,
} from "../../constants/lib.constants";
import { IContent } from "../../types/lib.types";

const TrashList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const containerRef = useRef<HTMLDivElement>(null);
  const { libraryId } = useAppSelector((state) => state.auth);

  const [contents, setContents] = useState<Partial<IContent>[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [type, setType] = useState<FolderTrashMenuActions>(
    FolderTrashMenuActions.Restore
  );

  const [openContentDetailPopup, setOpenContentDetailPopup] = useState(false);

  const [contentSelected, setContentSelected] = useState<Partial<IContent>>({});
  const [isShowConfirmDeleteContent, setIsShowConfirmDeleteContent] =
    useState<boolean>(false);
  const [openExistName, setOpenExistName] = useState(false);
  const [newName, setNewName] = useState<string | null>("");
  //delete
  const onDelete = async () => {
    try {
      if (!contentSelected.id || !contentSelected.name) return;
      await hardDeleteContent(contentSelected.id);
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage("MSGTV36", contentSelected.name),
            }}
          ></div>
        ),
      });
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        description: getMessage(e.data?.errors[0]),
      });
    } finally {
      setIsShowConfirmDeleteContent(false);
      fetchContents(pageIndex);
    }
  };
  const onDetail = async (content: Partial<IContent>) => {
    console.log("onDetail");
  };
  const onRestore = async () => {
    try {
      if (!contentSelected.id || !contentSelected.name) return;
      const result = await checkExistName(
        contentSelected.name,
        contentSelected.size ?? 0,
        contentSelected.type ?? ContentTypeEnums.FILE,
        libraryId
      );
      if (result.data.duplicate) {
        setOpenExistName(true);
        setNewName(result.data.new_name);
      } else {
        await restoreContent(contentSelected.id);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSGTV37", contentSelected.name),
              }}
            ></div>
          ),
        });
      }
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        description: getMessage(e.data?.errors[0]),
      });
    } finally {
      setIsShowConfirmDeleteContent(false);
      fetchContents(pageIndex);
    }
  };

  const onRestoreConfirm = async () => {
    setOpenExistName(false);
    try {
      if (!contentSelected.id || !contentSelected.name) return;
      await restoreContent(contentSelected.id);
      toast({
        title: "Thành công!",
        variant: "success",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: getCustomMessage(
                "MSGTV37",
                newName ?? contentSelected.name
              ),
            }}
          ></div>
        ),
      });
    } catch (e: any) {
      toast({
        title: "Thất bại!",
        description: getMessage(e.data?.errors[0]),
      });
    } finally {
      setIsShowConfirmDeleteContent(false);
      fetchContents(pageIndex);
    }
  };

  const onDeleteAll = async (content: Partial<IContent>) => {
    console.log("onDeleteAll");
  };
  //Check action
  const onMenuClick = (
    action: FolderTrashMenuActions,
    content: Partial<IContent>
  ) => {
    setContentSelected(content);
    switch (action) {
      case FolderTrashMenuActions.Detail:
        onDetail(content);
        break;
      case FolderTrashMenuActions.Restore:
        setType(FolderTrashMenuActions.Restore);
        setIsShowConfirmDeleteContent(true);
        break;
      case FolderTrashMenuActions.Delete:
        setType(FolderTrashMenuActions.Delete);
        setIsShowConfirmDeleteContent(true);
        break;
      case FolderTrashMenuActions.DeleteAll:
        onDeleteAll(content);
        break;
      default:
        break;
    }
  };
  const fetchContents = async (pageNumber: number) => {
    try {
      const res = await getListSoftDelete({
        id: libraryId,
        pageNumber: pageNumber,
        pageSize: PAGE_SIZE_DEFAULT,
      });
      setContents(res.data);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };
  useEffect(() => {
    libraryId && fetchContents(pageIndex);
  }, [libraryId]);

  if (!libraryId) {
    return <></>;
  }

  return (
    <div className="container mx-auto">
      <div>
        {contents.length === 0 ? (
          <div className="flex items-center justify-center h-[calc(100vh-64px)]">
            <div className="flex flex-col items-center justify-center gap-6 max-w-[350px]">
              <img
                className="w-[124px]"
                src={ListEmpty}
                alt=""
              />
              <div className="text-center">
                <p className="text-base font-semibold mb-2">
                  Oops! Lưu trữ trống.
                </p>
                <p className="text-xs text-secondary-600">
                  Danh sách nội dung đã xoá sẽ được hiển thị tại đây.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Breadcrumb className="px-6 mt-7 mb-4">
              <BreadcrumbList>
                <BreadcrumbItem className="cursor-pointer">
                  <p className="text-secondary-600 text-sm font-semibold">
                    Lưu trữ
                  </p>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
            <div ref={containerRef}>
              <InfiniteScroll
                style={{
                  height: "unset",
                  overflow: "unset",
                }}
                className="px-0"
                dataLength={contents.length}
                next={() => {
                  fetchContents(pageIndex + 1);
                }}
                hasMore={pageIndex < totalPage}
                loader={
                  <div className="flex items-center justify-center mt-6">
                    <TGNLoading size="medium" />
                  </div>
                }
              >
                <div
                  className={clsx(
                    "flex px-4 flex-wrap gap-x-2 gap-y-9",
                    openContentDetailPopup ? "w-[calc(100%-344px)]" : ""
                  )}
                >
                  {contents.map((content) => (
                    <ContentItem
                      key={content.id!}
                      content={content}
                      active={contentSelected?.id === content.id}
                      onMenuClick={(action) => onMenuClick(action, content)}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
      {contentSelected && (
        <DialogConfirmDeleteContent
          isShow={isShowConfirmDeleteContent}
          content={contentSelected}
          onAcceptContent={() => {
            switch (type) {
              case FolderTrashMenuActions.Restore:
                onRestore();
                break;
              case FolderTrashMenuActions.Delete:
                onDelete();
                break;
              default:
                break;
            }
          }}
          onRejectContent={() => setIsShowConfirmDeleteContent(false)}
          type={type}
        />
      )}
      <ExistNameModal
        open={openExistName}
        newName={newName}
        type="Restore"
        onSubmit={() => {
          onRestoreConfirm();
        }}
        onCancel={() => {
          setOpenExistName(false);
        }}
      />
    </div>
  );
};

export default TrashList;
