import React, { useEffect, useState } from "react";

import { Search } from "@carbon/icons-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";

import { Empty } from "@/assets/icons";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import { getMyGroups } from "../../api/group.api";
import CreateGroupModal from "../../components/CreateGroupModal/CreateGroupModal";
import GroupCard from "../../components/GroupCard/GroupCard";
import SearchModal from "../../components/SearchModal/SearchModal";
import {
  GroupRoleEnum,
  GroupSortEnum,
  GroupTypeEnum,
} from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { groupActions } from "../../group";
import { GroupInfoDef, GroupDef } from "../../types/group.type";

const GroupsScreen = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const loadings = useAppSelector((state) => state.group.loading);

  const [myGroups, setMyGroups] = useState<GroupDef[]>([]);
  const [rootGroups, setRootGroups] = useState<GroupDef[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const navigate = useNavigate();

  const flagList = useAppSelector((state) => state.group.flagList);
  const urlParams = new URLSearchParams(window.location.search);
  // const roleFilter = urlParams.get("roleFilter");
  const [roleFilter, setRoleFilter] = useState(urlParams.get("roleFilter"));

  // const rootGroupId = Number(urlParams.get("rootGroupId")) || undefined;
  const [rootGroupId, setRootGroupId] = useState(
    Number(urlParams.get("rootGroupId")) || undefined
  );
  // const sort = urlParams.get("sort") as GroupSortEnum;
  const [sort, setSort] = useState(urlParams.get("sort"));

  // const typeFilter = urlParams.get("typeFilter");
  const [typeFilter, setTypeFilter] = useState(urlParams.get("typeFilter"));
  let isFiltering = false;
  if (roleFilter || rootGroupId || sort || typeFilter) {
    isFiltering = true;
  }
  const fetchMyGroupsInfiniteScroll = async (pageScroll: number) => {
    try {
      setIsLoadMore(true);
      const result = await getMyGroups({
        pageNumber: pageScroll,
        pageSize: pageSize,
        typeFilter: typeFilter as GroupTypeEnum,
        roleFilter: roleFilter as GroupRoleEnum,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setMyGroups(() => [...myGroups, ...result.data]);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  const fetchMyGroups = async (pageNumber: number) => {
    try {
      setLoading(true);
      setIsLoadMore(true);
      const result = await getMyGroups({
        pageNumber: pageNumber,
        pageSize: pageSize,
        typeFilter: typeFilter as GroupTypeEnum,
        roleFilter: roleFilter as GroupRoleEnum,
        rootGroupId: rootGroupId,
        sort: sort as GroupSortEnum,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setTotalElement(result.totalElements);
      setMyGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchRootGroups = async () => {
    try {
      const result = await getMyGroups({
        typeFilter: GroupTypeEnum.ROOT,
      });
      setRootGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };
  const fetchFlagGroups = async () => {
    try {
      const result = await getMyGroups({});
      dispatch(
        groupActions.setFlagList(result.data.length == 0 ? true : false)
      );
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    }
  };
  useEffect(() => {
    fetchMyGroups(page);
    fetchRootGroups();
  }, [roleFilter, rootGroupId, sort, typeFilter, loadings]);

  useEffect(() => {
    fetchMyGroups(page);
    fetchFlagGroups();
  }, []);

  return (
    <>
      <div className="container sticky top-[70px] bg-white z-50">
        <div className="py-3 flex items-center justify-between">
          <p className="text-xs font-medium text-secondary-600">
            <span className="text-secondary-400">Nhóm</span> / Nhóm của tôi
          </p>
          <SearchModal />
        </div>
      </div>
      <div className="h-px w-full z-50 bg-secondary-100 sticky top-[70px]"></div>
      <div className=" z-50 bg-white sticky top-[70px]">
        <div className="container">
          <div className="flex justify-between">
            <div className="py-5 flex">
              <p className="font-semibold text-secondary-900 mr-2">
                Nhóm của tôi
              </p>
              <Badge>{totalElement} nhóm</Badge>
            </div>
            <div
              className={
                flagList
                  ? "hidden"
                  : "grid gap-4 auto-cols-max grid-flow-col items-center"
              }
            >
              <div className="text-xs flex items-center">
                <span className="text-secondary-600">Lọc theo:</span>
                <Select
                  defaultValue={typeFilter || "default"}
                  onValueChange={(value) => {
                    if (value === "default") {
                      setTypeFilter(null);
                      urlParams.delete("typeFilter");
                    } else {
                      urlParams.set("typeFilter", value);
                      setTypeFilter(value);
                      setPage(1);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                    <SelectValue
                      defaultValue={typeFilter || "default"}
                      className=""
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className=" ">
                      <SelectItem
                        value="default"
                        className={
                          typeFilter == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Mặc định
                      </SelectItem>
                      <SelectItem
                        value={GroupTypeEnum.ROOT}
                        className={
                          typeFilter == GroupTypeEnum.ROOT
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tổ chức
                      </SelectItem>
                      <SelectItem
                        value={GroupTypeEnum.GROUP}
                        className={
                          typeFilter == GroupTypeEnum.GROUP
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Nhóm
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="text-xs">
                <Select
                  defaultValue={roleFilter || "default"}
                  onValueChange={(value) => {
                    if (value === "default") {
                      setRoleFilter(null);
                      urlParams.delete("roleFilter");
                    } else {
                      urlParams.set("roleFilter", value);
                      setRoleFilter(value);
                      setPage(1);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                    <SelectValue defaultValue={roleFilter || "default"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value="default"
                        className={
                          roleFilter == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tất cả Vai trò
                      </SelectItem>
                      <SelectItem
                        value={GroupRoleEnum.MANAGER}
                        className={
                          roleFilter == GroupRoleEnum.MANAGER
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Quản trị
                      </SelectItem>
                      <SelectItem
                        value={GroupRoleEnum.MEMBER}
                        className={
                          roleFilter == GroupRoleEnum.MEMBER
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Đã tham gia
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              {rootGroups.length > 0 && (
                <div className="text-xs">
                  <Select
                    defaultValue={rootGroupId ? String(rootGroupId) : "default"}
                    onValueChange={(value) => {
                      if (value === "default") {
                        urlParams.delete("rootGroupId");
                        setRootGroupId(undefined);
                      } else {
                        urlParams.set("rootGroupId", value);
                        setRootGroupId(Number(value));
                        setPage(1);
                      }
                      navigate(
                        `${window.location.pathname}?${urlParams.toString()}`
                      );
                    }}
                  >
                    <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                      <SelectValue
                        defaultValue={
                          rootGroupId ? String(rootGroupId) : "default"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem
                          value="default"
                          className={
                            rootGroupId == undefined
                              ? "text-secondary-900 font-medium"
                              : "text-secondary-600  "
                          }
                        >
                          Tất cả Tổ chức
                        </SelectItem>
                        {rootGroups.length > 0 &&
                          rootGroups.map((group) => {
                            return (
                              <SelectItem
                                key={group.groupId}
                                value={String(group.groupId)}
                                className={
                                  rootGroupId == group.groupId
                                    ? "text-secondary-900 font-medium"
                                    : "text-secondary-600  "
                                }
                              >
                                {group.name}
                              </SelectItem>
                            );
                          })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}
              <div className="text-xs flex items-center">
                <span className="text-secondary-600">Sắp xếp theo:</span>
                <Select
                  defaultValue={sort || "default"}
                  onValueChange={(value) => {
                    if (value === "default") {
                      setSort(null);
                      urlParams.delete("sort");
                    } else {
                      setSort(value);
                      urlParams.set("sort", value);
                      setPage(1);
                    }
                    navigate(
                      `${window.location.pathname}?${urlParams.toString()}`
                    );
                  }}
                >
                  <SelectTrigger className="border-0 shadow-none min-w-0 w-auto text-xs p-1 text-secondary-900 font-medium">
                    <SelectValue defaultValue={sort || "default"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value="default"
                        className={
                          sort == null
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Mặc định
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.NAME}
                        className={
                          sort == GroupSortEnum.NAME
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Tên
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.UPDATED_DATE}
                        className={
                          sort == GroupSortEnum.UPDATED_DATE
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Ngày cập nhật
                      </SelectItem>
                      <SelectItem
                        value={GroupSortEnum.CREATED_DATE}
                        className={
                          sort == GroupSortEnum.CREATED_DATE
                            ? "text-secondary-900 font-medium"
                            : "text-secondary-600  "
                        }
                      >
                        Ngày tạo
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center mt-6">
          <TGNLoading
            size="large"
            isFullScreen
          />
        </div>
      ) : (
        <div className="container pb-6">
          <InfiniteScroll
            style={{
              height: "unset",
              overflow: "unset",
            }}
            className="px-0"
            dataLength={myGroups.length}
            next={() => {
              setPage((prev) => prev + 1);
              fetchMyGroupsInfiniteScroll(page + 1);
            }}
            hasMore={isLoadMore}
            loader={
              <div className="flex items-center justify-center mt-6">
                <Loading3Dot
                  setWidth="30"
                  setHeight="30"
                />
              </div>
            }
          >
            {myGroups.length === 0 ? (
              <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
                <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                  <Empty />
                  <span className="text-secondary-600 text-xs mt-4 font-semibold">
                    {isFiltering
                      ? "0 kết quả phù hợp"
                      : "Bạn chưa là thành viên của Tổ chức nào. Tạo nhóm hoặc khám phá thêm."}
                  </span>
                </div>
                {!isFiltering && (
                  <div className="mt-6 flex">
                    <CreateGroupModal haveSubGroup={true} />
                    <Link
                      to={GroupPathsEnum.GROUP_DISCOVER}
                      className="px-4 py-2.5 text-sm border border-primary-600 rounded text-primary-600 ml-3"
                    >
                      Khám phá
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className="grid gap-4">
                {/* <p className="text-sm font-semibold text-secondary-600">Others</p> */}
                <div className="grid gap-4 grid-cols-3">
                  {myGroups.map((group) => {
                    return (
                      <GroupCard
                        key={group.groupId}
                        group={group}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default GroupsScreen;
