import React, { useCallback, useEffect, useState } from "react";

import { Reset, ScanAlt } from "@carbon/icons-react";
import { getFingerprint } from "react-fingerprint";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Demo from "@/assets/images/Demo.png";
import Line from "@/assets/images/Lines.svg";
import TagaLogoColor from "@/assets/images/taga-logo-color.svg";
import { Button } from "@/components/ui/button";
import { QR_CODE_EXPIRE_BY_SECONDS } from "@/constants/app.constants";
import { fetchWebKey, saveTokenToStorage } from "@/features/auth/api/auth";
import { authAction } from "@/features/auth/redux/auth.slice";

import QRLogin from "../../components/QRLogin";
import { QRDef } from "../../types/auth.types";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [qrValue, setQRValue] = useState<QRDef | null>(null);
  const [countDown, setCountDown] = useState(QR_CODE_EXPIRE_BY_SECONDS);

  const query = new URLSearchParams(window.location.search);
  const token = query.get("continueTo");

  useEffect(() => {
    if (countDown === 0) {
      setQRValue(null);
    }
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  const listenerToken = useCallback(
    (webKey: string, fingerprint: string) => {
      const socket = new WebSocket(
        `wss://dev.taganow.vn/webapp-websocket?webkey=${webKey}&fingerprint=${fingerprint}`
      );
      socket.addEventListener("open", () => {
        console.log("connect success");
      });
      socket.addEventListener("message", (event) => {
        if (event.data) {
          const tokenObj = {
            ...JSON.parse(event.data),
            webKey,
            fingerprint,
          };
          saveTokenToStorage(JSON.stringify(tokenObj));
          dispatch(authAction.loginSuccess(true));
          const params = {
            ...tokenObj,
            callback: "/studio",
          };

          if (token == "studio") {
            window.location.href = `https://library.taganow.vn/token/${btoa(
              JSON.stringify(params)
            )}`;
          }
        }
        socket.close();
      });
      setTimeout(() => socket && socket.close(), 60 * 1000);
    },
    [dispatch]
  );
  const handleWebKey = () => {
    const getWebKey = async () => {
      const fingerprint = await getFingerprint();
      const res = await fetchWebKey(fingerprint);
      listenerToken(res.webKey, res.fingerprint);
      setQRValue({
        webKey: res.webKey,
        fingerprint: res.fingerprint,
        userAgent: navigator.userAgent,
      });
      localStorage.setItem("webKey", res.webKey);
      localStorage.setItem("fingerprint", fingerprint);
    };
    getWebKey();
  };
  useEffect(() => {
    handleWebKey();
  }, [listenerToken]);
  useEffect(() => {
    setCountDown(QR_CODE_EXPIRE_BY_SECONDS);
    handleWebKey();
  }, []);
  const handleReloadWebKey = () => {
    setCountDown(QR_CODE_EXPIRE_BY_SECONDS);
    handleWebKey();
  };
  return (
    <div className="w-full h-auto md:h-[100vh] relative bg-primary-100/10">
      <div className="flex flex-col-reverse md:grid  grid-cols-12 h-auto md:h-[100vh] ">
        <div className="col-span-3 justify-between bg-primary-100 p-5 flex flex-col relative">
          <div className="md:pt-[40%] text-center px-4">
            <img
              src={Demo}
              alt="Logo taga"
              className="mb-4 mx-auto"
            />
            <h1 className="text-[24px] font-bold mb-3">
              Nền tảng làm việc trọn gói của bạn
            </h1>
            <p className="text-gray-700">
              Quản lý thông tin, quy trình làm việc và con người, tất cả ở cùng
              một nơi.
            </p>
          </div>
          <div className="text-xs text-right">
            <p className="opacity-40">Developed by/ </p>
            <p className="opacity-60 uppercase">
              TAGA Joint Stock Company @2024
            </p>
          </div>
        </div>
        <div className="col-span-9 md:flex flex-col justify-center items-center relative p-4 md:p-0">
          <img
            className="absolute top-0 left-0 w-full h-full object-cover -z-[1]"
            src={Line}
            alt=""
          />
          <div className="md:absolute bottom-0 left-0 flex p-4 gap-3">
            <a
              className="text-secondary-700 text-sm"
              href="/"
            >
              Trang chủ
            </a>
            <a
              className="text-secondary-700 text-sm"
              href="/"
            >
              Trợ giúp
            </a>
          </div>

          <div className="z-[2] rounded-xl bg-white   shadow-xl  px-10 py-16 w-full max-w-[490px] mb-10">
            <div className="flex flex-col items-center">
              <img
                src={TagaLogoColor}
                alt="Logo taga"
                className="mb-4"
              />

              <p className="text-secondary-900 text-xl font-bold mb-10">
                Đăng nhập vào tài khoản
              </p>
              <div className="relative mb-10 p-3 rounded-md overflow-hidden">
                <QRLogin value={JSON.stringify(qrValue)} />
                {countDown === 0 && (
                  <div className="absolute left-0 top-0 right-0 bottom-0 bg-secondary-900/70">
                    <div
                      className="flex flex-col items-center justify-center h-full gap-2 cursor-pointer"
                      onClick={handleReloadWebKey}
                    >
                      <Reset
                        className="text-white font-semibold"
                        size={40}
                      />
                      <span className=" text-white font-semibold ">
                        Làm mới Qr code
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {/* <p className="mt-2 text-secondary-400 text-xs font-medium mb-4">
                Hết hạn sau:{" "}
                {countDown > 0 && (
                  <span className="font-semibold text-primary">
                    {countDown}s{" "}
                  </span>
                )}
              </p> */}

              <div className="text-xs text-secondary-600 mb-2 w-full md:flex items-center justify-center gap-1">
                <span>Truy cập ứng dụng TAGA trên di động chọn</span>{" "}
                <div className="inline-flex gap-1">
                  <ScanAlt /> <span className="font-medium"> Quét mã QR</span>
                </div>
              </div>
              <p className="text-primary font-medium text-xs">
                Làm cách nào để quét mã QR?
              </p>
              <div className="mt-10 w-full flex items-center justify-center relative after:absolute after:contents-[''] after:top-[50%] after:left-0 after:translate-y-[-50%] after:z-[1] after:w-full after:h-[1px] after:bg-secondary-200">
                <span className="text-secondary-400 font-medium text-xs relative z-[2] bg-white px-4">
                  Hoặc
                </span>
              </div>
              <div className="text-center mt-10">
                <Button
                  variant="secondary"
                  className="px-[40px]"
                  onClick={() => navigate("/")}
                >
                  Đăng ký tài khoản
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
