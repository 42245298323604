import React, { FC, memo, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import { Close, Eyedropper, Renew, View, ViewOff } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch } from "@/redux/store";

const TwoFAConfirm: FC = () => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [openModalOtp, setOpenModalOtp] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [viewPass, setViewPass] = useState(false);
  const [countDown, setCountDown] = useState(0);

  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={setOpenModal}
      >
        <DialogTrigger asChild>
          <Button onClick={() => setOpenModal(true)}>
            Thiết lập xác minh 2 bước
          </Button>
        </DialogTrigger>
        <DialogContent className={"max-w-[600px]  bg-white"}>
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center  text-lg mx-auto">
              Xác minh hai bước
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 pb-[12px] h-full">
            <div className=" flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-secondary-900 text-sm font-semibold">
                Thiết lập tính năng xác minh hai bước
              </div>
              <div className="self-stretch text-secondary-600 text-xs font-normal">
                Bắt đầu bằng cách nhập mật khẩu của bạn sao cho chúng tôi biết
                rằng đó là bạn. Sau đó, chúng tôi sẽ hướng dẫn bạn thực hiện qua
                hai bước đơn giản hơn.
              </div>
              <div className="relative">
                <Label className="text-sm text-secondary-900 mt-2">
                  {" "}
                  Mật khẩu tài khoản Taga
                </Label>
                <Input
                  type={viewPass ? "text" : "password"}
                  name="password"
                  placeholder={`********`}
                />
                <div
                  onClick={() => setViewPass(!viewPass)}
                  className="absolute right-2 bottom-3 text-secondary-600"
                >
                  {viewPass ? <View /> : <ViewOff />}
                </div>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              onClick={() => (setOpenModalOtp(true), setOpenModal(false))}
            >
              Tiếp tục
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={openModalOtp}>
        <DialogContent className="p-6 max-w-[600px] gap-2">
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModalOtp(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center text-lg mx-auto">
              Xác minh hai bước
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 pb-[12px] h-full">
            <div className=" flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-secondary-900 text-sm font-semibold">
                Thiết lập tính năng xác minh hai bước
              </div>
              <p className="text-sm text-secondary-600 font-normal">
                Nhập mã xác nhận bao gồm 6 chữ số chúng tôi vừa gửi đến email
                đăng ký tài khoản của bạn{" "}
                <span className="font-medium text-secondary-900">
                  Email.com
                </span>
                .
              </p>
              <div className="relative">
                <Input
                  type="text"
                  name="otp"
                  placeholder="Nhập mã"
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <div className="h-11 flex-col justify-center items-start gap-1 inline-flex mr-auto">
              <div className="text-gray-900 text-sm font-normal ">
                Không nhận được mã xác nhận?
              </div>

              <span className="flex gap-1 items-center text-primary font-medium text-sm ">
                <span
                  className={
                    countDown > 0
                      ? "flex opacity-50 items-center gap-1"
                      : "flex items-center gap-1 cursor-pointer"
                  }
                >
                  <Renew />
                  Gửi lại
                </span>
                {countDown > 0 && <span>sau {countDown}s</span>}
              </span>
            </div>
            <Button
              onClick={() => (
                setOpenModalConfirm(true), setOpenModalOtp(false)
              )}
            >
              Tiếp tục
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={openModalConfirm}>
        <DialogContent className="p-6 max-w-[600px] gap-2">
          <DialogHeader className="relative">
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-0 right-0 rounded-full w-[36px]"
              onClick={() => {
                setOpenModalConfirm(false);
              }}
            >
              <Close />
            </Button>
            <DialogTitle className="text-center text-lg mx-auto">
              Xác minh hai bước
            </DialogTitle>
          </DialogHeader>
          <div className=" pb-[12px] h-full">
            <div className="w-full h-14 px-3 py-2.5 bg-white rounded  border border-secondary-200 justify-start items-center gap-2 inline-flex mt-4">
              <div className="grow shrink h-5 justify-start items-center gap-2 flex">
                <div className="grow shrink text-secondary-900 text-sm font-medium ">
                  Bật tính năng xác minh 2 bước
                </div>
              </div>
              <Button className="min-w-20">Bật</Button>
            </div>
            <div>
              <p className="text-secondary-600 text-xs font-medium uppercase mt-6">
                Phương thức Xác nhận
              </p>
              <div className="w-full p-3 bg-slate-50 rounded-lg flex-col justify-start items-start gap-2 inline-flex mt-4">
                <div className="self-stretch text-secondary-900 text-sm font-medium">
                  Nhận mã OTP{" "}
                </div>
                <div className="justify-start items-start gap-1 inline-flex">
                  <div className="text-secondary-900 text-xs font-medium">
                    Taga@gmail.com
                  </div>
                  <div className="text-emerald-600 text-xs font-medium">
                    Đã xác minh
                  </div>
                </div>
                <div className="self-stretch text-secondary-600 text-xs font-normal">
                  Mã xác minh được gửi qua Email.
                </div>
              </div>
            </div>
            <div className=" flex-col justify-start items-start gap-2 inline-flex mt-6">
              <div className="text-secondary-900 text-sm font-semibold">
                Tính năng bảo mật 2 bước
              </div>
              <ul className="list-disc pl-4 text-secondary-600 text-xs font-normal">
                <li>
                  <span className="">
                    Bước thứ hai sau khi nhập mật khẩu sẽ giúp bạn xác minh danh
                    tính của mình khi đăng nhập
                  </span>
                </li>
                <li>
                  <span className="">
                    Bạn có thể bị đăng xuất khỏi các thiết bị khác của mình. Để
                    đăng nhập lại, bạn cần sử dụng mật khẩu và bước thứ hai.
                  </span>
                </li>
                <li>
                  <span className="">
                    Nếu bạn tắt tính năng xác minh hai bước, tài khoản của bạn
                    sẽ không còn được bảo vệ của bước đăng nhập thứ hai.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(TwoFAConfirm);
