import { HierarchyPointNode, hierarchy, tree } from "d3-hierarchy";
import { select } from "d3-selection";
import { zoom } from "d3-zoom";

import store from "../../../redux/store";
import { setSelectedBlock } from "../redux/workflow.slice";
import { BlockNodeDef, WorkflowItemDef } from "../types/operation";

export const renderTreemap = (
  elementId: string,
  treemapData: WorkflowItemDef
) => {
  const onClick = (node: any) => {
    if (node.data.type == "STEP") {
      store.dispatch(setSelectedBlock(node));
    } else store.dispatch(setSelectedBlock(null));
  };
  const margin = { top: 20, right: 90, bottom: 30, left: 90 },
    width = 960 - margin.left - margin.right + 200,
    height = 500 - margin.top - margin.bottom;
  select("#tree").remove();

  // Create SVG container
  const svg = select(elementId)
    .append("svg")
    .attr("id", "tree")
    .attr("width", "100%")
    .attr("height", "100%");
  svg.on("click", (e) => {
    if (e.target.nodeName === "svg") {
      store.dispatch(setSelectedBlock(null));
    }
  });
  // Create a group for transformations
  const svgGroup = svg.append("g");
  svgGroup.attr(
    "transform",
    "translate(" + margin.left + "," + margin.top + ")"
  );

  // Define the zoom behavior
  const zoomBehavior = zoom()
    .scaleExtent([0.1, 3]) // Set zoom limits
    .on("zoom", (event) => {
      svgGroup.attr("transform", event.transform.toString()); // Apply zoom transform
    });

  const svgWidth = parseInt(svg.style("width"));
  const svgHeight = parseInt(svg.style("height"));

  // Calculate centering offsets
  const centerX = (svgWidth - width) / 2;
  const centerY = (svgHeight - height) / 2;

  // Initial zoom configuration
  const initialScale = 0.8;

  // Apply initial transform to center and scale
  svgGroup.attr(
    "transform",
    `translate(${centerX + margin.left}, ${
      centerY + margin.top
    }) scale(${initialScale})`
  );
  zoomBehavior.scaleTo(svg as any, initialScale);
  zoomBehavior.translateBy(svg as any, centerX, centerY);

  // Attach zoom behavior to SVG
  svg.call(zoomBehavior as any).on("dblclick.zoom", null); // Disable double-click zoom
  // .on("wheel.zoom", null); // Disable wheel zoom if needed

  const duration = 750;
  let root: any;
  const rectWidth = 160; // Example width
  const rectHeight = 160; // Example height

  // declares a tree layout and assigns the size
  const treemap = tree()
    .nodeSize([rectWidth, rectHeight])
    .separation(function () {
      return 1;
    });
  // Assigns parent, children, height, depth
  // eslint-disable-next-line prefer-const
  root = hierarchy(treemapData, (d: any) => {
    return d.children;
  });
  root.x0 = height / 2;
  root.y0 = 0;

  update(root);

  function update(source: any) {
    // Assigns the x and y position for the nodes
    const treeData = treemap(root);

    // Compute the new tree layout.
    const nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach(function (d: any) {
      if (d.data) {
        let space = 300;
        if (d.data.type !== "STEP") {
          space = 300;
        }
        d.y = d.depth * space; // Horizontal spacing based on depth
        // eslint-disable-next-line no-self-assign
        d.x = d.x; // Vertical position
      }
    });

    // ****************** Nodes section ***************************

    // Update the nodes...
    const node = svgGroup.selectAll("g.node").data(nodes, function (d: any) {
      return d.id;
    });

    // Enter any new modes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append("g")
      .attr("id", (node) => {
        const { data } = node as any;
        return `g-${data.id}`;
      })
      .attr("class", "relative")
      .attr("transform", function (d) {
        return "translate(" + d.y + "," + d.x + ")";
      });

    nodeEnter
      .append("foreignObject")
      .attr("width", (node) => {
        const { data } = node as any;
        if (data.type === "CONDITION") return `160 `;
        if (data.type === "STEP") return `250`;
        return `${rectWidth}`;
      })
      .attr("height", (node) => {
        const { data } = node as any;
        return `${data.type === "CONDITION" ? 160 : rectHeight}`;
      })
      .attr("x", -rectWidth / 2) // Center the rectangle horizontally
      .attr("y", -rectHeight / 2) // Center the rectangle vertically
      .html((node) => {
        const { data } = node as any;
        return `<div class="w-full h-full flex items-center justify-center"> 
        <p class="text-[#F5F5F5]">.</span>
        ${renderNode(data)}
        </div>`;
      })
      .on("click", (event, _node) => {
        event.preventDefault(); // Prevent default browser behavior
        event.stopPropagation(); // Stop event propagation
        const node = _node as HierarchyPointNode<BlockNodeDef>;
        if (node) {
          onClick(node);
        }

        // Prevent focus or active effects on the clicked node
        const target = event.currentTarget as HTMLElement;
        if (target) {
          console.log(target);
          target.blur(); // Remove focus if it exists
        }
      });

    // UPDATE
    const nodeUpdate = nodeEnter.merge(node as any);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        // BEFORE ....
        return "translate(" + d.y + "," + d.x + ")";
        // AFTER ....
        // return "translate(" + d.x + "," + d.y + ")";
      });

    // Remove any exiting nodes
    const nodeExit = node
      .exit()
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        // BEFORE ....
        return "translate(" + source.y + "," + source.x + ")";
        // AFTER ....
        // return "translate(" + source.x + "," + source.y + ")";
      })
      .remove();

    // On exit reduce the opacity of text labels
    nodeExit.select("text").style("fill-opacity", 1e-6);

    // ****************** links section ***************************

    // Update the links...
    const link = svgGroup
      .selectAll("g.link-group")
      .data(links, function (d: any) {
        return d.id;
      });

    // Enter any new links at the parent's previous position.
    const linkEnter = link.enter().append("g").attr("class", "link-group");

    // Thêm định nghĩa marker vào SVG
    const defs = svg.append("defs");
    const markerColors = {
      RUNNING: "#008cff",
      COMPLETED: "#259450",
      STOPPED: "#E43F1E",
      DEFAULT: "#000000",
    };

    Object.entries(markerColors).forEach(([status, color]) => {
      defs
        .append("marker")
        .attr("id", `arrowhead-${status.toLowerCase()}`)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 10)
        .attr("refY", 0)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M10,-5L0,0L10,5")
        .attr("fill", color);
    });

    // Thêm path vào link group với marker
    linkEnter
      .append("path")
      .attr("class", "link")
      .attr("marker-start", function (d: any) {
        const status = d.data.status || "DEFAULT";
        return `url(#arrowhead-${status.toLowerCase()})`;
      });

    // Thêm circle vào link group
    linkEnter
      .append("circle")
      .attr("r", 2)
      .attr("fill", function (d: any) {
        const strokeColors: Record<string, string> = {
          RUNNING: "#008cff",
          COMPLETED: "#259450",
          STOPPED: "#E43F1E",
          DEFAULT: "#000000",
        };
        const status =
          (d.data.status as keyof typeof strokeColors) || "DEFAULT";
        return strokeColors[status];
      })
      .attr("cx", function (d) {
        const path = diagonal(d, d.parent);
        const points = path.split(/[ML]/).filter(Boolean);
        if (points.length >= 2) {
          const start = points[0].split(",").map(Number);
          const end = points[points.length - 1].split(",").map(Number);

          // Tính điểm giữa của đoạn thẳng dọc
          return end[0];
        }
        return 0;
      })
      .attr("cy", function (d) {
        const path = diagonal(d, d.parent);
        const points = path.split(/[ML]/).filter(Boolean);
        if (points.length >= 2) {
          const start = points[0].split(",").map(Number);
          const end = points[points.length - 1].split(",").map(Number);

          // Tính điểm giữa của đoạn thẳng dọc
          return (end[1] + end[1]) / 2;
        }
        return 0;
      });

    // UPDATE
    const linkUpdate = linkEnter.merge(link as any);

    // Cập nhật path và circle với transition
    linkUpdate
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + source.y + "," + source.x + ")";
      });

    // Cập nhật path
    linkUpdate
      .select("path")
      .transition()
      .duration(duration)
      .attr("marker-start", function (d: any) {
        const status = d.data.status || "DEFAULT";
        return `url(#arrowhead-${status.toLowerCase()})`;
      })
      .attr("style", function (d: any) {
        const strokeColors: Record<string, string> = {
          RUNNING: "#008cff",
          COMPLETED: "#259450",
          STOPPED: "#E43F1E",
          DEFAULT: "#000000",
        };
        const status =
          (d.data.status as keyof typeof strokeColors) || "DEFAULT";
        return `fill: none; stroke-width: 1px; stroke: ${strokeColors[status]};`;
      })
      .attr("d", (d) => diagonal(d, d.parent));

    // Remove any exiting links với transition
    const linkExit = link
      .exit()
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d: any) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s: any, d: any) {
      if (d.data) {
        let rectWidth = 160;
        const startX = s.x;
        let startY = s.y - rectWidth / 2 - 10;

        const endX = d.x;
        let endY = d.y + rectWidth / 2;

        if (d.data.type == "STEP") {
          rectWidth = 200;
          startY = s.y - rectWidth / 2 + 10;
          endY = d.y + rectWidth / 2 + 70;
        }

        // Tạo đường thẳng với điểm kiểm soát rõ ràng
        return `M${startY},${startX}
                L${(startY + endY) / 2},${startX}
                L${(startY + endY) / 2},${endX}
                L${endY},${endX}`;
      }
      return "";
    }
  }
};
const renderNode = (data: any) => {
  if (data.type === "START") {
    return ` 
    <div
        class="w-[160px] h-[80px] pr-[28px] flex items-center justify-center gap-3 bg-[#F5FAFE] rounded-lg border border-[#3C9CF0]"
      >
         <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="44" y="44" width="44" height="44" rx="22" transform="rotate(-180 44 44)" fill="#D8ECFD"/>
<rect x="36" y="36" width="28" height="28" rx="14" transform="rotate(-180 36 36)" fill="#1589EE"/>
<path d="M17.5 16C17.3674 16 17.2402 16.0526 17.1464 16.1464C17.0527 16.2402 17 16.3674 17 16.5V27.5C17 27.5869 17.0226 27.6722 17.0657 27.7477C17.1087 27.8232 17.1707 27.8862 17.2455 27.9304C17.3203 27.9746 17.4053 27.9986 17.4922 27.9999C17.5791 28.0013 17.6648 27.98 17.741 27.9381L27.7409 22.4381C27.8194 22.395 27.8848 22.3316 27.9304 22.2545C27.976 22.1774 28 22.0895 28 22C28 21.9105 27.976 21.8226 27.9304 21.7455C27.8848 21.6684 27.8194 21.605 27.7409 21.5619L17.741 16.0619C17.6671 16.0213 17.5842 16 17.5 16Z" fill="white"/>
</svg>
        <div class="text-[14px] font-[600] leading-[20px] text-[#18191C]">
           ${data.name}
        </div>
      
      </div>
   `;
  }
  if (data.type === "END") {
    return `
     <div
        class="w-[160px] h-[80px] flex items-center justify-center gap-3 bg-[#F1FCF5] rounded-lg border border-[#259450] opacity-40"
      >
        
        <div class="text-[14px] font-[600] leading-[20px] text-[#18191C]">
           ${data.name}
        </div>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="44" y="44" width="44" height="44" rx="22" transform="rotate(-180 44 44)" fill="#C1F1D3"/>
<rect x="36" y="36" width="28" height="28" rx="14" transform="rotate(-180 36 36)" fill="#34B766"/>
<path d="M22 15C20.6155 15 19.2622 15.4105 18.111 16.1797C16.9599 16.9489 16.0627 18.0421 15.5328 19.3212C15.003 20.6003 14.8644 22.0078 15.1345 23.3656C15.4046 24.7235 16.0713 25.9708 17.0503 26.9497C18.0292 27.9287 19.2765 28.5954 20.6344 28.8655C21.9922 29.1356 23.3997 28.997 24.6788 28.4672C25.9579 27.9373 27.0511 27.0401 27.8203 25.889C28.5895 24.7378 29 23.3845 29 22C29 20.1435 28.2625 18.363 26.9497 17.0503C25.637 15.7375 23.8565 15 22 15ZM21 24.7954L18.5 22.2954L19.2953 21.5L21 23.2046L24.705 19.5L25.5029 20.293L21 24.7954Z" fill="white"/>
</svg>

      </div>
    `;
  }
  if (data.type === "CONDITION") {
    return `
    <div class="w-[116px] h-[116px] bg-[#FFF8ED] border rotate-45 rounded-lg border-[#EA680C] flex items-center justify-center">
          <div class="rotate-[-45deg] text-[14px] font-[600] leading-[20px] text-[#18191C]">
              <p class="line-clamp-2 overflow-hidden">  ${data.name}</p>
          </div>
        </div>
    `;
  }
  type StatusKey = "RUNNING" | "COMPLETED" | "STOPPED" | "DEFAULT";

  const statusClasses: Record<StatusKey, string> = {
    RUNNING: "bg-[#008cff] border-[#3C9CF0]",
    COMPLETED: "!bg-[#F1FCF5] border-[#259450]",
    STOPPED: "bg-[#E43F1E]",
    DEFAULT: "bg-[#fff]",
  };

  const textClasses: Record<StatusKey, string> = {
    RUNNING: "text-[#ffffff]",
    COMPLETED: "text-[#259450]",
    STOPPED: "text-[#ffffff]",
    DEFAULT: "text-[#18191C]",
  };

  const badgeClasses: Record<StatusKey, string> = {
    RUNNING: "bg-white/40",
    COMPLETED: "bg-[#259450] text-[#ffffff]",
    STOPPED: "bg-white/40",
    DEFAULT: "",
  };

  // Explicitly type nodeStatus to ensure it matches StatusKey
  const nodeStatus: StatusKey = (data.status as StatusKey) || "DEFAULT";

  return `
    <div
    class="w-[300px] h-[80px] flex items-center p-3 gap-3 rounded-lg border ${
      statusClasses[nodeStatus] || statusClasses.DEFAULT
    }"
  >
    <div class="text-[14px] font-[600] leading-[20px] ${
      textClasses[nodeStatus] || textClasses.DEFAULT
    }">
      <span class="py-[2px] px-2 text-[10px] rounded-sm ${
        badgeClasses[nodeStatus] || badgeClasses.DEFAULT
      }">
          ${nodeStatus}
      </span>
      <p class="line-clamp-2 overflow-hidden">
        ${data.name}
      </p>
    </div>
  </div>
      `;
};
