import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { PAGE_DEFAULT } from "@/constants/app.constants";
import {
  AppResponse,
  PaginationParams,
  PaginationResponse,
} from "@/types/app.types";

import {
  getChildContent,
  getContentDetail,
  getDownloadFileUrl,
  getMySharedContent,
  searchContent,
} from "../api/lib.api";
import { DownloadContentStatusEnums } from "../constants/lib.constants";
import { IContent, MySharedContentDef } from "../types/lib.types";

interface LibraryState {
  contents: IContent[];
  contentDetail: IContent | null;
  parentContentDetail: IContent | null;
  pageIndex: number;
  totalPage: number;
  showCreateModal: boolean;
  loading: boolean;
  sharedContents: MySharedContentDef[];
  sharePageIndex: number;
  shareTotalPage: number;
  downloadContents: {
    content: IContent;
    status: DownloadContentStatusEnums;
  }[];
  showDownloadPopup: boolean;
}

const initialState = {
  contents: [],
  contentDetail: null,
  parentContentDetail: null,
  pageIndex: 1,
  totalPage: 1,
  showCreateModal: false,
  loading: false,
  sharedContents: [],
  sharePageIndex: 1,
  shareTotalPage: 1,
  downloadContents: [],
  showDownloadPopup: false,
} satisfies LibraryState as LibraryState;

export const getChildContentThunk = createAsyncThunk<
  PaginationResponse<IContent[]>,
  { contentId: number } & PaginationParams
>(
  "library/getChildContent",
  async ({ contentId, pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const result = await getChildContent({
        id: contentId,
        pageNumber,
        pageSize,
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMySharedContentThunk = createAsyncThunk<
  PaginationResponse<MySharedContentDef[]>,
  { contentId: number } & PaginationParams
>(
  "library/getMySharedContent",
  async ({ contentId, pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const result = await getMySharedContent({
        libraryId: contentId,
        pageNumber,
        pageSize,
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchContentThunk = createAsyncThunk<
  PaginationResponse<IContent[]>,
  { contentId: number; keyword: string } & PaginationParams
>(
  "library/searchContent",
  async ({ contentId, keyword, pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const result = await searchContent({
        id: contentId,
        keyword,
        pageNumber,
        pageSize,
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getParentContentDetailThunk = createAsyncThunk<
  AppResponse<IContent>,
  { contentId: number }
>("library/getParentContent", async ({ contentId }, { rejectWithValue }) => {
  try {
    const result = await getContentDetail(contentId);
    return result;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const downloadFileThunk = createAsyncThunk<
  {
    content: IContent;
    downloadUrl: string;
  },
  { content: IContent }
>("library/downloadFile", async ({ content }, { rejectWithValue }) => {
  try {
    const result = await getDownloadFileUrl({
      contentMaskingId: content.masking_id || "",
      contentSize: content.size,
    });
    return {
      content,
      downloadUrl: result.data.download_url,
    };
  } catch (error) {
    return rejectWithValue({
      content,
    });
  }
});

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    showCreateModal(state) {
      state.showCreateModal = true;
    },
    hideCreateModal(state) {
      state.showCreateModal = false;
    },
    setContentDetail(state, action: PayloadAction<IContent>) {
      state.contentDetail = action.payload;
    },
    addDownloadContent(
      state,
      action: PayloadAction<{
        content: IContent;
        status: DownloadContentStatusEnums;
      }>
    ) {
      state.downloadContents.unshift(action.payload);
    },
    setShowDownloadPopup(state, action: PayloadAction<boolean>) {
      state.showDownloadPopup = action.payload;
    },
    setDownloadContentStatus(
      state,
      action: PayloadAction<{
        contentId: number;
        status: DownloadContentStatusEnums;
      }>
    ) {
      const content = state.downloadContents.find(
        (item) => item.content.id === action.payload.contentId
      );
      if (content) {
        content.status = action.payload.status;
      }
    },
    resetDownloadContents(state) {
      state.showDownloadPopup = false;
      state.downloadContents = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChildContentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getChildContentThunk.fulfilled,
      (state, action: PayloadAction<PaginationResponse<IContent[]>>) => {
        if (action.payload.pageIndex === PAGE_DEFAULT) {
          state.contents = action.payload.data;
        } else {
          state.contents = [...state.contents, ...action.payload.data];
        }
        state.pageIndex = action.payload.pageIndex;
        state.totalPage = action.payload.totalPage;
        state.loading = false;
      }
    );
    builder.addCase(getChildContentThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getParentContentDetailThunk.fulfilled,
      (state, action: PayloadAction<AppResponse<IContent>>) => {
        state.parentContentDetail = action.payload.data;
      }
    );
    builder.addCase(searchContentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      searchContentThunk.fulfilled,
      (state, action: PayloadAction<PaginationResponse<IContent[]>>) => {
        if (action.payload.pageIndex === PAGE_DEFAULT) {
          state.contents = action.payload.data;
        } else {
          state.contents = [...state.contents, ...action.payload.data];
        }
        state.pageIndex = action.payload.pageIndex;
        state.totalPage = action.payload.totalPage;
        state.loading = false;
      }
    );
    builder.addCase(searchContentThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMySharedContentThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getMySharedContentThunk.fulfilled,
      (
        state,
        action: PayloadAction<PaginationResponse<MySharedContentDef[]>>
      ) => {
        if (action.payload.pageIndex === PAGE_DEFAULT) {
          state.sharedContents = action.payload.data;
        } else {
          state.sharedContents = [
            ...state.sharedContents,
            ...action.payload.data,
          ];
        }
        state.sharePageIndex = action.payload.pageIndex;
        state.shareTotalPage = action.payload.totalPage;
        state.loading = false;
      }
    );
    builder.addCase(getMySharedContentThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      downloadFileThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          content: IContent;
          downloadUrl: string;
        }>
      ) => {
        const content = state.downloadContents.find(
          (item) => item.content.id === action.payload.content.id
        );
        if (content) {
          content.status = DownloadContentStatusEnums.COMPLETED;
          window.open(action.payload.downloadUrl);
        }
      }
    );
    builder.addCase(
      downloadFileThunk.rejected,
      (state, action: PayloadAction<any>) => {
        const content = state.downloadContents.find(
          (item) => item.content.id === action.payload.content.id
        );
        if (content) {
          content.status = DownloadContentStatusEnums.ERROR;
        }
      }
    );
  },
});

export const libraryActions = librarySlice.actions;
export const libraryReducer = librarySlice.reducer;
