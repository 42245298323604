import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getCurrentUsername,
  getLibraryId,
  getTokenFromStorage,
} from "../api/auth";
import { AuthState, UserDef } from "../types/auth.types";

const initialState: AuthState = {
  loginSuccess: false,
  user: null,
  libraryId: 0,
  openSetting: false,
  accessToken: "",
};

export const getLibraryIdThunk = createAsyncThunk<number>(
  "auth/getLibraryId",
  async (_, { rejectWithValue }) => {
    try {
      const result = await getLibraryId();
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProfileThunk = createAsyncThunk<UserDef>(
  "auth/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const result = await getCurrentUsername();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.loginSuccess = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserDef | null>) => {
      if (action.payload == null) {
        state.loginSuccess = false;
        state.libraryId = 0;
      }
      state.user = action.payload;
      const token = JSON.parse(getTokenFromStorage() || "{}");
      state.accessToken = token && token?.webToken ? token.webToken : "";
    },
    hiddenSetting: (state) => {
      state.openSetting = false;
    },
    showSetting: (state) => {
      state.openSetting = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getLibraryIdThunk.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.libraryId = action.payload;
      }
    );
    builder.addCase(
      getProfileThunk.fulfilled,
      (state, action: PayloadAction<UserDef>) => {
        state.user = action.payload;
      }
    );
  },
});

export const authAction = authSlice.actions;

export default authSlice.reducer;
