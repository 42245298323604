import React, { FC, memo, useState } from "react";

import {
  CheckmarkFilled,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";

import GroupCover from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { GroupUserStatusEnum } from "@/features/groups/constants/group.constant";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { formatDistance } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";

import AcceptGroupModal from "./AcceptGroupModal";
import AcceptOrgModal from "./AcceptOrgModal";
import CancelConfirmModal from "./CancelConfirmModal";
import RejectOrgModal from "./RejectOrgModal";
import { IGroupInvitation } from "../../../types/group.type";

interface GroupCardProps {
  group: IGroupInvitation;
}

const GroupCardInvite: FC<GroupCardProps> = ({ group }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <>
      <div className="relative rounded-lg border border-secondary-200 hover:shadow-lg overflow-hidden">
        <img
          className="object-cover h-[140px] w-full"
          src={group.photoUrl ? group.photoUrl : GroupCover}
          alt=""
        />
        <div className="p-4">
          <p className="text-xs text-secondary-600 font-medium uppercase mb-2 truncate">
            Tổ chức
            {group.rootId != group.groupId && (
              <span className="">
                ・
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link
                        className="text-primary-600 hover:text-primary-800 truncate text-ellipsis"
                        to={GroupPathsEnum.GROUP_DETAIL.replace(
                          ":id",
                          String(group.rootId)
                        )}
                      >
                        {group.rootName}
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent>
                      <span className="text-xs"> {group.rootName}</span>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </span>
            )}
          </p>
          <div className="min-h-[48px]">
            <p>
              <Link
                className="font-semibold text-base leading-6 text-secondary-900 mb-2 line-clamp-2 hover:text-primary-600 inline"
                to={GroupPathsEnum.GROUP_DETAIL.replace(
                  ":id",
                  String(group.groupId)
                )}
              >
                {group.name}
              </Link>
            </p>
          </div>
          <div className="flex items-center">
            {group.sender && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      className="flex"
                      to={""}
                    >
                      <TGNAvatar
                        userPhoto={group.sender.photoUrl}
                        name={
                          group.sender.name ||
                          extractUsername(group.sender.email || "")
                        }
                        size={"24"}
                        className="block"
                      />
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>
                    <span className="text-xs">
                      {" "}
                      {group.sender.name || group.sender.email}
                    </span>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            <span className="text-sm text-secondary-600 ml-1">
              Đã gửi lời mời {formatDistance(group.invitedAt)}
            </span>
          </div>

          {group.status === GroupUserStatusEnum.WAIT_ORGANIZATION_APPROVE ? (
            <div className="flex mt-3 gap-3">
              <Button
                className="w-full me-2.5"
                disabled
              >
                Đang xử lý
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    className="px-3 py-2"
                    variant={"text"}
                  >
                    <OverflowMenuVertical />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <CancelConfirmModal
                    isDetail={false}
                    groupInfo={group}
                    button={
                      <Button
                        variant={"ghost"}
                        className="w-full"
                      >
                        <TrashCan className="me-1" />
                        Hủy tham gia
                      </Button>
                    }
                  />
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ) : (
            <>
              <div className="flex mt-3 gap-3">
                {group.rootId == group.groupId ? (
                  <AcceptOrgModal
                    inviteId={group.id}
                    groupId={group.groupId}
                    groupName={group.name}
                    isDetail={false}
                    rootGroupId={group.rootId}
                    groupLock={group.groupLock}
                    button={<Button className="w-full">Chấp nhận</Button>}
                  />
                ) : (
                  <AcceptGroupModal
                    rootGroupName={group.rootName}
                    inviteId={group.id}
                    groupId={group.groupId}
                    groupName={group.name}
                    isDetail={false}
                    rootGroupId={group.rootId}
                    groupLock={group.groupLock}
                    button={<Button className="w-full">Chấp nhận </Button>}
                  />
                )}

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      className="px-3 py-2"
                      variant={"text"}
                    >
                      <OverflowMenuVertical />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <RejectOrgModal
                      groupId={group.groupId}
                      groupName={group.name}
                      inviteId={group.id}
                      rootGroupId={group.rootId}
                      groupLock={group.groupLock}
                      isDetail={false}
                      button={
                        <Button
                          variant="ghost"
                          className="w-full"
                        >
                          <TrashCan className="me-1" />
                          Từ chối lời mời
                        </Button>
                      }
                    />
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </>
          )}
        </div>
      </div>
      <Dialog
        open={isSuccess}
        onOpenChange={setIsSuccess}
      >
        <DialogContent className="max-w-[464px]">
          <DialogHeader>
            <span className="flex items-center justify-center border-[6px] p-1 text-success-600 border-success-50 bg-success-100 rounded-full mx-auto">
              <CheckmarkFilled
                size={20}
                className="text-success-500"
              />
            </span>
            <DialogTitle className="text-center text-lg text-secondary-900">
              Thành công!
            </DialogTitle>
            <DialogDescription className="text-center text-secondary-600">
              Bạn đã là thành viên của Tổ chức{" "}
              <span className="font-semibold"> "{group?.name}"</span>. Giờ đây,
              bạn đã có thể truy cập và tham gia vào các hoạt động của tổ chức
              này.
            </DialogDescription>
          </DialogHeader>
          <div className="mx-auto">
            <Link
              className="px-2 py-3 text-sm font-medium text-primary-600 flex items-center"
              to={GroupPathsEnum.GROUP_DETAIL.replace(
                ":id",
                String(group.groupId)
              )}
            >
              Truy cập tổ chức
            </Link>
          </div>
          <DialogFooter className="sm:justify-center">
            <Button
              variant="text"
              onClick={() => {
                setIsSuccess(false);
              }}
            >
              Để sau
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(GroupCardInvite);
