import React, { FC, ReactNode, memo, useState } from "react";

import {
  ArrowUpRight,
  CheckmarkFilled,
  Close,
  InformationFilled,
  OverflowMenuVertical,
  TrashCan,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import { ArrowLeft } from "lucide-react";
import Carousel from "react-multi-carousel";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import {
  rejectGroupInvitation,
  settingGroup,
  userCancelRequestToGroup,
} from "@/features/groups/api/group.api";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { getGroupInfo, groupActions } from "@/features/groups/group";
import {
  GroupInfoDef,
  IGroupInvitation,
} from "@/features/groups/types/group.type";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getCustomMessage, getMessage } from "@/utils/message";

type CancelModalProps = {
  groupInfo: GroupInfoDef | IGroupInvitation;
  button: ReactNode;
  isDetail?: boolean;
};

const CancelModal: FC<CancelModalProps> = ({ groupInfo, button, isDetail }) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [haveInvites, setHaveInvites] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleReject = async (groupId: number) => {
    setOpenModal(false);
    dispatch(
      loadingAction.show({
        title: "Đang xử lý...",
        description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      if (groupId) {
        dispatch(groupActions.setFlagLoading(true));
        await userCancelRequestToGroup(groupId);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: getCustomMessage("MSG99", String(groupInfo.name)),
              }}
            ></div>
          ),
        });
        if (isDetail) {
          dispatch(
            getGroupInfo({
              group_id: groupInfo.id,
            })
          )
            .then(unwrapResult)
            .catch(() => {
              navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
            });
        }
      }
    } catch (error: any) {
      dispatch(loadingAction.hide());

      toast({
        title: "Thất bại!",
        variant: "error",
        description: COMMON_MESSAGES.MSG12,
      });
    } finally {
      dispatch(groupActions.setFlagLoading(false));
      dispatch(loadingAction.hide());
    }
  };
  return (
    <>
      {groupInfo && (
        <>
          <Dialog open={openModal}>
            <DialogTrigger asChild>
              <div
                className="w-full"
                onClick={() => setOpenModal(true)}
              >
                {button}
              </div>
            </DialogTrigger>
            <DialogContent className="max-w-[600px]">
              <DialogHeader>
                <div className="flex gap-4 grid-cols-2">
                  <div>
                    <span className="block border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full">
                      <InformationFilled size={24} />
                    </span>
                  </div>
                  <span className="w-[calc(100%_-_48px)]  text-sm">
                    <p className="text-secondary-900 text-lg font-semibold mb-2">
                      Huỷ tham gia nhóm
                    </p>
                    <span className="font-normal text-secondary-600 leading-tight">
                      Bạn chắc chắn muốn huỷ tham gia{" "}
                      {"userGroupRelation" in groupInfo
                        ? groupInfo.rootGroupId
                          ? "nhóm"
                          : "tổ chức"
                        : groupInfo.rootId
                        ? "nhóm"
                        : "tổ chức"}
                      <span className="font-semibold"> {groupInfo.name}</span>?
                    </span>
                    <p className="mt-2 font-normal text-secondary-600 leading-tight">
                      Lời mời đã được chuyển tiếp tới Tổ chức của nhóm này để xử
                      lý, bạn có thể chờ tới khi quá trình hoàn tất.
                    </p>
                  </span>
                </div>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant={"outline"}
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  size="sm"
                >
                  Hủy bỏ
                </Button>
                <Button
                  onClick={() => {
                    if ("userGroupRelation" in groupInfo) {
                      handleReject(groupInfo.userGroupRelation.id);
                    } else {
                      handleReject(groupInfo.id);
                    }
                  }}
                  variant="secondary"
                  size="sm"
                >
                  Xác nhận
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default memo(CancelModal);
