import { useEffect } from "react";

import { getTokenFromStorage } from "@/features/auth/api/auth";
import {
  DownloadContentStatusEnums,
  WebsocketResponseType,
} from "@/features/lib/constants/lib.constants";
import { libraryActions } from "@/features/lib/redux/lib.slice";
import { DownloadFolderResponse } from "@/features/lib/types/lib.types";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getWebSocketURL } from "@/utils/app";
import { getFingerprint, getWebkey } from "@/utils/local";

const useWebSocket = () => {
  const dispatch = useAppDispatch();
  const { user, accessToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!accessToken || !user) {
      return;
    }
    const url = getWebSocketURL({
      webkey: getWebkey(),
      fingerprint: getFingerprint(),
      token: accessToken,
    });
    const socket = new WebSocket(url);
    socket.addEventListener("open", () => {
      console.log("Connect Success");
    });
    socket.addEventListener("message", ({ data }) => {
      if (!data) return;
      try {
        const response: DownloadFolderResponse = JSON.parse(data);
        if (response.type === WebsocketResponseType.DOWNLOAD_FOLDER) {
          window.open(response.url);
          dispatch(
            libraryActions.setDownloadContentStatus({
              contentId: response.content.id,
              status: DownloadContentStatusEnums.COMPLETED,
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    });
    return () => {
      socket.close();
    };
  }, [user, accessToken]);
  return {};
};

export default useWebSocket;
