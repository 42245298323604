import React, { memo, FC } from "react";

import cx from "classnames";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Typography from "@/components/Typography/Typography";

import styles from "./SearchForm.module.scss";
import { SearchListConvertedType, SearchTypeEnum } from "../../mindmap";

type SearchItemProps = {
  item: SearchListConvertedType;
  active: boolean;
  onClick: () => void;
};

const SearchItem: FC<SearchItemProps> = ({ item, onClick, active }) => {
  const type =
    item.type === SearchTypeEnum.TITLE
      ? "Tiêu đề"
      : item.type === SearchTypeEnum.DESCRIPTION
      ? "Mô tả"
      : item.type === SearchTypeEnum.HASHTAG
      ? "Hashtag"
      : item.type === SearchTypeEnum.MEMBER
      ? "Thành viên"
      : "";
  const renderContent = () => {
    switch (item.type) {
      case SearchTypeEnum.TITLE:
        return item.value;
      case SearchTypeEnum.DESCRIPTION:
        return (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.value }}
          ></div>
        );
      case SearchTypeEnum.HASHTAG:
        return item.value;
      case SearchTypeEnum.MEMBER:
        return (
          <div className="d-flex align-items-center gap-2">
            <TGNAvatar
              size="20"
              src={item.value.user.photo}
            />
            <span className="fs-14">
              {item.value.user.name || item.value.user.username}
            </span>
          </div>
        );
      default:
        return "";
    }
  };
  return (
    <div
      className={cx(styles["search-item"], active && styles.active)}
      onClick={onClick}
    >
      <Typography className="d-flex align-items-center fs-12">
        <span className={cx("text-gray-400 text-truncate", styles.id)}>
          {item.id}
        </span>
        /<span className="text-gray-600">&nbsp; {type}</span>
      </Typography>
      {renderContent()}
    </div>
  );
};

export default memo(SearchItem);
