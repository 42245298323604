import React, { FC } from "react";

import QRCode from "react-qr-code";

type QRLoginProps = {
  value: string;
};

const QRLogin: FC<QRLoginProps> = ({ value }) => {
  return (
    <div>
      <QRCode
        style={{
          height: 220,
          width: 220,
        }}
        value={value}
      />
    </div>
  );
};

export default QRLogin;
