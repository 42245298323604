export enum AccessType {
  Publish = 0,
  Private = 1,
}

export enum ContentTypeEnums {
  FOLDER = 0,
  FILE = 1,
  LIBRARY = 2,
}

export enum GroupRoleCode {
  Admin = "ADMIN",
  Member = "MEMBER",
  Owner = "OWNER",
}

export enum GroupRoleLabel {
  Admin = "Admin",
  Member = "Member",
  Owner = "Owner",
}

export enum LibraryType {
  GROUP = "GROUP",
  PERSONAL = "PERSONAL",
}

export enum FolderMenuActions {
  Open = 0,
  Share = 1,
  Move = 2,
  Download = 3,
  Delete = 4,
  Detail = 5,
  DeSelect = 6,
  RemoveReference = 7,
  Rename = 8,
  RemoveShare = 9,
}

export enum IUploadDetailsStatus {
  Uploading = 1,
  Error = 2,
  Success = 3,
  SuccessWaitingList = 4,
}

export enum ResourceType {
  FOLDER = 0,
  FILE = 1,
  ROOT = 2,
}

export enum FolderTrashMenuActions {
  Restore = 0,
  Detail = 1,
  Delete = 2,
  DeleteAll = 3,
}

export enum DownloadContentStatusEnums {
  PENDING = "pending",
  COMPLETED = "completed",
  CANCELED = "canceled",
  ERROR = "error",
}

export enum WebsocketResponseType {
  DOWNLOAD_FOLDER = "download/folder",
}
