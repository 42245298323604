import React, { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { useToast } from "@/components/ui/use-toast";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import { getDiscoverGroups } from "../../api/group.api";
import GroupCard from "../../components/GroupCard/GroupCard";
import SearchModal from "../../components/SearchModal/SearchModal";
import { GroupDef } from "../../types/group.type";

const DiscoverScreen = () => {
  const { toast } = useToast();
  const [discoverGroups, setDiscoverGroups] = useState<GroupDef[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetchMyGroup = async (page: number) => {
    try {
      setLoading(true);
      setIsLoadMore(true);
      const result = await getDiscoverGroups({
        pageNumber: page,
        pageSize: pageSize,
      });

      setTotalElement(result.totalElements);
      setDiscoverGroups(result.data);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchMyGroupsScroll = async (page: number) => {
    try {
      setIsLoadMore(true);
      const result = await getDiscoverGroups({
        pageNumber: page,
        pageSize: pageSize,
      });
      if (!result.data.length || result.data.length < pageSize) {
        setIsLoadMore(false);
      }
      setDiscoverGroups(() => [...discoverGroups, ...result.data]);
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    }
  };
  useEffect(() => {
    fetchMyGroup(page);
  }, []);
  return (
    <div>
      <div className="container   bg-white z-50">
        <div className="py-3 flex items-center justify-between">
          <p className="text-xs font-medium text-secondary-600">
            <span className="text-secondary-400">Nhóm</span> / Khám phá
          </p>
          <SearchModal />
        </div>
      </div>
      <div className="h-px w-full z-50 bg-secondary-100 sticky top-[69px]"></div>
      <div className="flex justify-between z-50 bg-white sticky top-[70px]">
        <div className="container">
          <div className="py-5 flex">
            <p className="font-semibold text-secondary-900 mr-2">Khám phá</p>
          </div>
        </div>
      </div>
      <div className="container pb-4">
        <div className="grid gap-10">
          <div>
            <div className="flex justify-between items-end">
              <div>
                <p className="text-sm text-secondary-600 font-semibold">
                  Đề xuất
                </p>
              </div>
            </div>
            {loading ? (
              <TGNLoading
                size="large"
                isFullScreen
              />
            ) : (
              <InfiniteScroll
                style={{
                  height: "unset",
                  overflow: "unset",
                }}
                className="px-0 mb-12"
                dataLength={discoverGroups.length}
                next={() => {
                  setPage((prev) => prev + 1);
                  fetchMyGroupsScroll(page + 1);
                }}
                hasMore={isLoadMore}
                loader={
                  <div className="d-flex justify-content-center mt-8">
                    <TGNLoading size="large" />
                  </div>
                }
              >
                <div className="mt-4">
                  <div className="grid gap-4 grid-cols-3">
                    {discoverGroups.map((group) => {
                      return (
                        <GroupCard
                          type="discover"
                          key={group.groupId}
                          group={group}
                        />
                      );
                    })}
                  </div>
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverScreen;
