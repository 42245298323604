import React, { FC, memo, useEffect, useState } from "react";

import { Close, InformationFilled, Warning } from "@carbon/icons-react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { getMessage } from "@/utils/message";

import FileIcon from "./FileIcon";
import { checkContentName } from "../api/lib.api";
import { ContentTypeEnums } from "../constants/lib.constants";
import { IContent } from "../types/lib.types";

type RenameFolderDialogProps = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onOk: (name: string) => void;
  content?: IContent;
};

const RenameFolderDialog: FC<RenameFolderDialogProps> = ({
  open,
  loading,
  onClose,
  content,
  onOk,
}) => {
  const RenameFolderSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        255,
        getMessage(
          "MSG53",
          content?.type === ContentTypeEnums.FOLDER
            ? "Tên thư mục"
            : "Tên tệp tin"
        )
      )
      .trim()
      .required(
        getMessage(
          "MSG1",
          content?.type === ContentTypeEnums.FOLDER
            ? "Tên thư mục"
            : "Tên tệp tin"
        )
      ),
  });
  const [name, setName] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (content) {
      setName(content?.name);
    }
  }, [content]);
  useEffect(() => {
    setError("");
    setIsConfirm(false);
  }, [open]);
  const submitCheckContentName = async (name?: string) => {
    if (!content || !name) {
      return;
    }
    try {
      const result = await checkContentName({
        contentId: content.id,
        contentName: name,
      });
      if (result.data) {
        setError(
          getMessage(
            "MSG2",
            content.type === ContentTypeEnums.FOLDER
              ? "Tên thư mục"
              : "Tên tệp tin"
          )
        );
        return;
      }
      setIsConfirm(true);
    } catch (err) {
      setError(getMessage("MSG_ERROR"));
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
    >
      <DialogContent className={"sm:max-w-[696px] bg-white "}>
        {isConfirm ? (
          <div>
            <div className="flex gap-4">
              <div>
                <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                  <InformationFilled size={20} />
                </span>
              </div>
              <div>
                <p className="text-lg font-semibold mb-1">
                  Đổi tên{" "}
                  {content?.type === ContentTypeEnums.FILE
                    ? "tệp tin"
                    : "thư mục"}
                </p>
                <p className="text-secondary-600 text-sm">
                  Xác nhận đổi tên{" "}
                  {content?.type === ContentTypeEnums.FILE
                    ? "tệp tin"
                    : "thư mục"}{" "}
                  <span className="font-semibold">{content?.name}</span> thành{" "}
                  <span className="font-semibold">{name}</span>?
                </p>
              </div>
            </div>
            <div className="text-end mt-6">
              <Button
                variant="outline"
                className="mr-3"
                size="sm"
                type="button"
                onClick={onClose}
              >
                Huỷ bỏ
              </Button>
              <Button
                variant="secondary"
                onClick={() => onOk(name.trim())}
                size="sm"
                loading={loading}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <DialogHeader className="relative">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <FileIcon
                    extension={content?.extension}
                    size={24}
                  />{" "}
                  <span className="ml-2 text-lg font-semibold">
                    Đổi tên{" "}
                    {content?.type === ContentTypeEnums.FOLDER
                      ? "thư mục"
                      : "tệp tin"}
                  </span>
                </div>
                <Close
                  size={20}
                  className="cursor-pointer"
                  onClick={onClose}
                />
              </div>
            </DialogHeader>
            <Formik
              initialValues={{
                name: content?.name,
              }}
              validationSchema={RenameFolderSchema}
              onSubmit={(values) => submitCheckContentName(values.name)}
            >
              {({ handleChange, errors, touched, isSubmitting }) => (
                <Form>
                  <p className="text-xs font-medium mb-1 mt-6">
                    Tên mới <span className="text-error-600">*</span>
                  </p>
                  <Field
                    name="name"
                    as={Input}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setName(e.target.value);
                      setError("");
                    }}
                    error={errors.name}
                  ></Field>
                  {touched.name && errors.name && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {errors.name}
                    </span>
                  )}
                  {error && (
                    <span className="text-xs text-red-600 flex gap-1 mt-1">
                      <Warning />
                      {error}
                    </span>
                  )}
                  <div className="text-end mt-6">
                    <Button
                      variant="outline"
                      className="mr-3"
                      size="sm"
                      type="button"
                      onClick={onClose}
                    >
                      Huỷ bỏ
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      loading={isSubmitting}
                      disabled={content?.name === name}
                      type="submit"
                    >
                      Xác nhận
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(RenameFolderDialog);
