/* eslint-disable */
import styles from "@/features/mindmap/screens/mindmap/MindmapScreen.module.scss";
import {
  hierarchy,
  tree as createTree,
  HierarchyNode,
  HierarchyPointNode,
} from "d3-hierarchy";
import { Selection, select } from "d3-selection";
import { zoom } from "d3-zoom";
import { transition as d3Transition } from "d3-transition";
import { drag } from "d3-drag";
import {
  diagonal,
  getWidthNode,
  getHeightNode,
  findNearestNode,
  findNodeById,
  findParentNodeByChildId,
  renderTitle,
  renderAssignUser,
  updateHelperButtonPosition,
  renderDescription,
  flatNode,
  renderHashtags,
} from "../helpers/mindmap.helpers";
import {
  BaseTypeNode,
  MindmapItemDef,
  MindmapNodeDef,
  NodeUpdateDragDef,
  ZoomTransform,
} from "../types/mindmap.type";
select.prototype.transition = d3Transition;
import store from "redux/store";
import {
  setSelectedNode,
  setRoot,
  ROOT_ID,
  setCurrentZoomTransform,
  COMMON_WIDTH_NODE,
  openPeekRenderer,
  setZoomBehavior,
  addSubnode,
} from "../mindmap";

const nodeUpdateDrag: NodeUpdateDragDef = {
  source: null,
  target: null,
  type: "",
};

const d3Mindmap = (
  container: HTMLDivElement,
  options = {
    durationTime: 750,
  }
) => {
  const svg = select("#mindmap");

  const svgGroup = svg.append("g");
  const width = container.clientWidth;
  const tree = createTree()
    .nodeSize([50, 500])
    .separation(function (a: any, b: any) {
      const { scale } = store.getState().mindmap.currentSheet;
      return a.data.name.length > 30
        ? 4 * (scale < 1 ? 1 : scale)
        : 3 * (scale < 1 ? 1 : scale);
    });
  let currentZoomTransform: ZoomTransform | null = null;
  let listNode: MindmapNodeDef[] = [];
  let dragNode: Selection<
    SVGForeignObjectElement,
    unknown,
    HTMLElement,
    any
  > | null = null;

  function updatePositionButton() {
    const { selectedNode } = store.getState().mindmap.currentSheet;
    if (selectedNode && currentZoomTransform) {
      const selectedNodeInList = listNode.find(
        (node) => node.data.id === selectedNode.data.id
      );
      if (selectedNodeInList) {
        updateHelperButtonPosition({
          node: {
            ...selectedNode,
            x: selectedNodeInList.x,
            y: selectedNodeInList.y,
          },
          currentZoomTransform,
        });
      }
    }
  }

  const zoomBehavior = zoom()
    .on("zoom", (ev) => {
      select("#input-area").attr("style", `visibility: hidden;`);
      svgGroup.attr("transform", ev.transform);
      currentZoomTransform = ev.transform;
      store.dispatch(setCurrentZoomTransform(ev.transform));
      select("#btn-add-node").style("visibility", "hidden");
      select("#btn-toggle-node").style("visibility", "hidden");
    })
    .on("end", () => {
      updatePositionButton();
    });
  store.dispatch(setZoomBehavior(zoomBehavior));
  svg
    .call(zoomBehavior as any)
    .on("dblclick.zoom", null)
    .on("wheel.zoom", null);
  svg.on("click", (e) => {
    if (
      e.target.nodeName === "svg" &&
      !store.getState().mindmap.currentSheet.isShowPeekRenderer
    ) {
      store.dispatch(setSelectedNode(null));
    }
  });

  const render = (
    options = {
      durationTime: 750,
      isFirstRender: false,
    }
  ) => {
    const dataMindmap = store.getState().mindmap.currentSheet;
    const root = hierarchy(dataMindmap.root);
    // root.x0 = height / 2;
    // root.y0 = 0;
    if (dataMindmap.scale) {
      if (options.isFirstRender) {
        zoomBehavior.translateTo(
          svg as any,
          (dataMindmap.selectedNode?.y || 0) + 280,
          dataMindmap.selectedNode?.x || 0
        );
      }
      zoomBehavior.scaleTo(svg as any, dataMindmap.scale);
      if (currentZoomTransform) {
        currentZoomTransform.k = dataMindmap.scale;
      }
      svg
        .call(zoomBehavior as any)
        .on("dblclick.zoom", null)
        .on("wheel.zoom", null);
    }
    _beforeRender();
    _render(root, options);
  };

  const _beforeRender = () => {
    if (currentZoomTransform) {
      svgGroup.attr("transform", currentZoomTransform as any);
    }
  };

  const _render = (
    source: any,
    options = {
      durationTime: 750,
    }
  ) => {
    const { scale, selectedNode } = store.getState().mindmap.currentSheet;
    const treeData = tree(source as HierarchyNode<unknown>);
    const nodes = treeData.descendants();
    const links = treeData.links();

    nodes.forEach((d) => {
      d.y = d.depth * 390;
    });
    listNode = [];

    let i = 0;
    const node: Selection<
      any,
      HierarchyPointNode<unknown>,
      SVGGElement,
      unknown
    > = svgGroup.selectAll(".node").data(nodes, (d: any) => {
      return d.id || (d.id = ++i);
    });

    const nodeEnter = node
      .enter()
      .append("g")
      .attr(
        "id",
        (d: HierarchyPointNode<unknown>) =>
          `g${(d as HierarchyPointNode<MindmapItemDef>).data.id}`
      )
      .attr("class", "node");

    nodeEnter.append("line");

    const nodeUpdate = nodeEnter.merge(node);

    nodeUpdate
      .transition()
      .duration(options.durationTime)
      .attr("transform", (d) => {
        return `translate(${d.y}, ${d.x})`;
      });

    nodeUpdate.selectAll("foreignObject").remove();
    nodeUpdate
      .attr("id", (d: any) => {
        return `g${d.data.id}`;
      })
      .append("foreignObject")
      .style("overflow", "unset")
      .html(function (node: any) {
        const _node = node as HierarchyPointNode<MindmapItemDef>;
        const { description, id, name } = _node.data;
        if (id === ROOT_ID) {
          return `<div><div
          class='text-node ${styles["text-node"]} ${styles["text-node-root"]} ${
            id === selectedNode?.data?.id ? styles["text-node-active"] : ""
          }'
          style="background:white;font-size: ${description?.fontSize || 16}px;"
          ><div class="w-100"><div
          class="d-flex align-items-center">
          <div>${renderTitle(name)}</div>
          </div>${renderDescription(description.descriptionTask)}</div>`;
        }
        return `<div><div
          class='text-node ${styles["text-node"]}
          ${id === selectedNode?.data?.id ? styles["text-node-active"] : ""}'
          style="background:${
            description.fill
          };font-size: ${description?.fontSize || 16}px;"
          ><div class="w-100"><div
          class="d-flex align-items-center">
          <div>${renderTitle(
            name,
            description?.fontSize,
            description.color,
            description.decoration
          )}</div>
          </div>${renderDescription(
            description.descriptionTask
          )}${renderHashtags(description.hashtags)}<div
          class="d-flex justify-content-between gap-5">${renderAssignUser(
            node.data
          )}</div></div>`;
      })
      .style("width", function (node: any) {
        setTimeout(() => {
          listNode.push({
            ...node,
            x: node.x * currentZoomTransform!.k,
            y:
              node.y * currentZoomTransform!.k +
              (
                select(
                  `#g${node.data.id} foreignObject .text-node`
                )!.node() as BaseTypeNode
              )?.getBoundingClientRect().width,
          });
        }, 0);
        return `${COMMON_WIDTH_NODE}px`;
      })
      .style("transform", function (node: any) {
        return `translate(0,-${
          (
            select(this).select("foreignObject div").node() as BaseTypeNode
          )?.getBoundingClientRect()?.height /
          2 /
          currentZoomTransform!.k
        }px)`;
      })
      .style("height", function (node: any) {
        return getHeightNode(`#g${node.data.id}`) / scale;
      })
      .call(
        drag()
          .on("drag", function (event, el: any) {
            const node = select(el).node();
            const heightNode = getHeightNode(`#g${el.data.id}`);
            const widthNode = getWidthNode(`#g${el.data.id}`) + 30;
            if (dragNode) {
              dragNode.attr(
                "style",
                () =>
                  `width:${widthNode}px;height:136px;transform:translate(${
                    event.x - node.x
                  }px, ${event.y - node.y - heightNode / 2}px)`
              );
            } else {
              dragNode = select(`#g${el.data.id}`)
                .append("foreignObject")
                .attr("id", "drag-node")
                .attr("class", styles["drag-node"])
                .attr(
                  "style",
                  `width:${widthNode}px;height:136px;transform: translateY(${
                    -heightNode / 2
                  }px)`
                )
                .html(function () {
                  return `<div class=${
                    styles["drag-node-content"]
                  } style="background:${el.data.description.fill}"><div><div 
                  class='text-node ${styles["text-node"]}
                  ${
                    el.data.id === selectedNode?.data?.id
                      ? styles["text-node-active"]
                      : ""
                  }' 
                  style="background:white;font-size: ${
                    el.data.description?.fontSize || 16
                  }px;padding:${16 * scale}px"
                  ><div class="w-100"><div 
                  class="d-flex align-items-center">
                  <div>${renderTitle(
                    el.data.name,
                    el.data.description?.fontSize,
                    el.data.description.color,
                    el.data.description.decoration
                  )}</div>
                  </div>${renderDescription(
                    el.data.description.descriptionTask
                  )}${renderHashtags(el.data.description.hashtags)}<div
                  class="d-flex justify-content-between gap-5">${renderAssignUser(
                    node.data
                  )}</div></div>`;
                });
            }

            // update skeleton when drag
            const pointX = event.sourceEvent.x - (currentZoomTransform?.x || 0);
            const pointY = event.sourceEvent.y - (currentZoomTransform?.y || 0);
            const targetNode = findNearestNode(
              {
                x: pointY,
                y: pointX,
              },
              listNode
            );
            const checkSourceNodeIsParentNode = findNodeById(
              el.data,
              targetNode!.data.id
            );
            if (
              !targetNode ||
              el.data.id === targetNode.data.id ||
              checkSourceNodeIsParentNode
            ) {
              return;
            }
            nodeUpdateDrag.target = targetNode;
            const type =
              targetNode.x >
              event.sourceEvent.y - (currentZoomTransform?.y || 0)
                ? "up"
                : "down";
            if (el.data.id === targetNode.data.id) {
              return;
            }
            const { height: heightTarget, width: widthTarget } = (
              select(
                `#g${targetNode.data.id} foreignObject div`
              ).node() as BaseTypeNode
            ).getBoundingClientRect();

            const skeletonNode = select(".skeleton");

            // check right node
            if (
              pointX > targetNode.y &&
              targetNode.x - heightTarget / 2 < pointY &&
              pointY < targetNode.x + heightTarget / 2
            ) {
              // check node have child node
              if (targetNode.data.children.length) {
                const childNode = listNode.find(
                  (item) => item.data.id === targetNode.data.children[0].id
                );
                if (!childNode) {
                  return;
                }
                const { height: heightChildTarget, width: widthChildTarget } = (
                  select(
                    `#g${targetNode.data.id} foreignObject div`
                  ).node() as BaseTypeNode
                ).getBoundingClientRect();
                skeletonNode.attr(
                  "style",
                  `visibility: visible;top:${
                    childNode.x +
                    (currentZoomTransform?.y || 0) -
                    heightChildTarget -
                    20
                  }px;left:${
                    childNode.y +
                    (currentZoomTransform?.x || 0) -
                    widthChildTarget +
                    10
                  }px;`
                );
              } else {
                skeletonNode.attr(
                  "style",
                  `visibility: visible;top:${
                    targetNode.x + (currentZoomTransform?.y || 0)
                  }px;left:${
                    targetNode.y + (currentZoomTransform?.x || 0) + 20
                  }px;transform: translateY(-50%)`
                );
              }
              return;
            }

            // check node up and down
            if (type === "down") {
              skeletonNode.attr(
                "style",
                `visibility: visible;top:${
                  targetNode.x + (currentZoomTransform?.y || 0) + heightTarget
                }px;left:${
                  targetNode.y +
                  (currentZoomTransform?.x || 0) -
                  widthTarget +
                  10
                }px;`
              );
            } else {
              skeletonNode.attr(
                "style",
                `visibility: visible;top:${
                  targetNode.x +
                  (currentZoomTransform?.y || 0) -
                  heightTarget -
                  20
                }px;left:${
                  targetNode.y +
                  (currentZoomTransform?.x || 0) -
                  widthTarget +
                  10
                }px;`
              );
            }
          })
          .on("end", (event, el: any) => {
            if (event.x === el.x && event.y === el.y) {
              return;
            }
            const { mindmapIO } = store.getState().mindmap;
            dragNode = null;
            const skeletonNode = select(`.skeleton`);
            skeletonNode.attr("style", "visibility: hidden");
            select(`#drag-node`).remove();
            const node = select(el).node();
            const pointX = event.sourceEvent.x - (currentZoomTransform?.x || 0);
            const pointY = event.sourceEvent.y - (currentZoomTransform?.y || 0);
            const nearestNode = findNearestNode(
              {
                x: pointY,
                y: pointX,
              },
              listNode
            );
            if (!nearestNode) {
              return;
            }
            nodeUpdateDrag.source = node;
            nodeUpdateDrag.target = nearestNode;
            nodeUpdateDrag.type =
              nearestNode.x >
              event.sourceEvent.y - (currentZoomTransform?.y || 0)
                ? "up"
                : "down";
            if (
              nodeUpdateDrag?.source?.data?.id !==
              nodeUpdateDrag?.target?.data?.id
            ) {
              const { target, source, type } = nodeUpdateDrag;
              const { height = 0 } = (
                select(
                  `#g${target.data.id} foreignObject div`
                ).node() as BaseTypeNode
              ).getBoundingClientRect();
              const _dataRoot = JSON.parse(
                JSON.stringify(store.getState().mindmap.currentSheet.root)
              );
              const sourceNode = findNodeById(_dataRoot, source!.data.id);
              const targetNode = findNodeById(_dataRoot, target.data.id);

              if (!targetNode || !sourceNode || targetNode.id === "root") {
                return;
              }

              const parentSourceNode = findParentNodeByChildId(
                _dataRoot,
                source!.data.id
              );
              const parentTargetNode = findParentNodeByChildId(
                _dataRoot,
                target.data.id
              );
              if (!parentSourceNode) {
                return;
              }
              const checkSourceNodeIsParentNode = findNodeById(
                sourceNode,
                targetNode.id
              );

              if (checkSourceNodeIsParentNode) {
                return;
              }

              const sourceIndex = (parentSourceNode?.children || []).findIndex(
                (item: MindmapItemDef) => item.id === source!.data.id
              );
              const targetIndex = (parentTargetNode?.children || []).findIndex(
                (item: MindmapItemDef) => item.id === target.data.id
              );

              // check node is the child node: right node
              if (
                pointX > target.y &&
                target.x - height / 2 < pointY &&
                pointY < target.x + height / 2
              ) {
                parentSourceNode.children.splice(sourceIndex, 1);
                if (!targetNode?.children) {
                  targetNode.children.push(sourceNode);
                } else {
                  targetNode.children.unshift(sourceNode);
                }
                store.dispatch(
                  setRoot({
                    data: _dataRoot,
                    isMainRoot: true,
                  })
                );
                if (mindmapIO) {
                  mindmapIO.updateTasks(flatNode(_dataRoot, []));
                }
                return;
              }

              // check source and target is belong to the same parent
              if (parentSourceNode === parentTargetNode) {
                if (sourceIndex > targetIndex) {
                  parentSourceNode.children.splice(sourceIndex, 1);
                  parentTargetNode.children.splice(
                    type === "up" ? targetIndex : targetIndex + 1,
                    0,
                    sourceNode
                  );
                } else {
                  parentTargetNode.children.splice(
                    type === "up" ? targetIndex : targetIndex + 1,
                    0,
                    sourceNode
                  );
                  parentTargetNode.children.splice(sourceIndex, 1);
                }
                // source and target are difference
              } else {
                parentTargetNode?.children.splice(
                  type === "up" ? targetIndex : targetIndex + 1,
                  0,
                  sourceNode
                );
                parentSourceNode?.children.splice(sourceIndex, 1);
              }
              store.dispatch(
                setRoot({
                  data: _dataRoot,
                  isMainRoot: true,
                })
              );
              if (mindmapIO) {
                mindmapIO.updateTasks(flatNode(_dataRoot, []));
              }
              if (currentZoomTransform) {
                updatePositionButton();
              }
            }
          }) as any
      )
      .on("click", (event, _node) => {
        const node = _node as HierarchyPointNode<MindmapItemDef>;
        if (currentZoomTransform) {
          updatePositionButton();
        }
        store.dispatch(setSelectedNode(node as any));
      })
      .on("dblclick", function () {
        store.dispatch(openPeekRenderer());
      });

    nodeUpdate
      .append("foreignObject")
      .html(
        `<button
      class=${styles["btn-add"]}
      >
      <i class="tgn-outline-plus fs-16"></i>
      </button>`
      )
      .style("width", "20px")
      .style("height", "20px")
      .style("display", (_node) => {
        const node = _node as HierarchyPointNode<MindmapItemDef>;
        if (node.data.id === selectedNode?.data?.id) {
          return "block";
        }
        return "none";
      })
      .attr("transform", (_node: unknown) => {
        const node = _node as HierarchyPointNode<MindmapItemDef>;
        const height = getHeightNode(`#g${node.data.id}`);
        return `translate(24, -${
          height / 2 / (currentZoomTransform?.k || 1) + 10
        })`;
      })
      .on("click", () => {
        store.dispatch(addSubnode());
      });

    const nodeExit = node
      .exit()
      .transition()
      .duration(options.durationTime)
      .attr("transform", () => `translate(${source.y}, ${source.x})`)
      .remove();

    nodeExit.select("text").style("fill-opacity", 1e-6);

    const link = svgGroup
      .selectAll(".link")
      .data(links, (d: any) => d.target.id);

    const linkEnter = link
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .style("fill", "none")
      .style("stroke-width", "2px");

    const linkUpdate = linkEnter.merge(link as any) as any;

    linkUpdate
      .transition()
      .duration(options.durationTime)
      .attr("d", (d: any) => {
        const source = { ...d.source, y: d.source.y };
        const target = { ...d.target, y: d.target.y };

        return diagonal(source, target, currentZoomTransform?.k);
      })
      .style("stroke", (node: any) => {
        return node.target.data.description.lineColor;
      })
      .style("stroke-width", "2px");

    (link as any)
      .exit()
      .transition()
      .duration(options.durationTime)
      .attr("d", () => {
        const o = { x: source.x, y: source.y - 50 };
        return diagonal(o, o);
      })
      .remove();

    nodes.forEach((d: any) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    setTimeout(() => {
      updatePositionButton();
    }, 0);
  };

  return {
    render,
  };
};

export default d3Mindmap;

export type D3MindmapDef = ReturnType<typeof d3Mindmap>;
