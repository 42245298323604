export const getFileSize = (size: number) => {
  return size < 1024 ? size + "kb" : (size / (1024 * 1024)).toFixed(2) + "MB";
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "--";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getContentName = (name: string) => {
  const hashName = name.split(".");
  if (hashName.length === 1) {
    return hashName;
  }
  const extension = hashName[hashName.length - 1];
  hashName.pop();
  const fileName = hashName.join(".");
  if (fileName.length > 42) {
    return `${fileName.substring(0, 38)}...${fileName.substring(
      fileName.length - 4
    )}.${extension}`;
  }
  return `${fileName}.${extension}`;
};
