import React, { memo, useState } from "react";

import cx from "classnames";
import { toast } from "react-hot-toast";

import { setIsCancelUpload } from "@/features/mindmap/mindmap";
import { useAppDispatch } from "@/redux/store";

import styles from "./AttachFilePopup.module.scss";

const CancelAction = () => {
  const dispatch = useAppDispatch();
  const [active, setActive] = useState(false);
  return (
    <div
      className="d-flex gap-4"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={cx(styles.cancel, active && styles.active)}
        onMouseOver={() => setActive(true)}
        onMouseOut={() => setActive(false)}
        onClick={() => dispatch(setIsCancelUpload(true))}
      >
        <i className="tgn-outline-trash fs-18"></i>
        <span>Huỷ tải lên</span>
      </div>
      <div
        className={cx(styles.clear, active && styles.active)}
        onClick={(e) => {
          e.stopPropagation();
          toast.remove();
        }}
      >
        <span>Xóa tất cả</span>
        <i className="tgn-outline-remove fs-18"></i>
      </div>
    </div>
  );
};

export default memo(CancelAction);
