import React from "react";

import { ChevronDown, DotMark } from "@carbon/icons-react";

import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

import { TaskGroupDef } from "../types/task";

export const GroupTask: React.FC<{
  myTasks: TaskGroupDef;
  onSetId: (id: number) => void;
}> = ({ myTasks, onSetId }) => {
  return (
    <div>
      <Collapsible
        defaultOpen
        className="mt-2 p-5 rounded-lg bg-secondary-50"
      >
        <div className="flex items-center justify-between px-2 rounded-md w-full">
          <div className="flex items-center uppercase">
            {myTasks.rootGroupName}
          </div>
          <CollapsibleTrigger asChild>
            <Button
              variant="link"
              className="text-secondary-700"
            >
              <ChevronDown />
            </Button>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent>
          <div className="flex flex-col gap-3">
            {myTasks.tasks.map((item) => (
              <div
                key={item.stepId}
                className="p-4 bg-white border border-secondary-100 rounded-lg cursor-pointer hover:bg-slate-100"
                onClick={() => onSetId(item.stepId)}
              >
                <div>
                  {!Math.round(Math.random()) ? (
                    <Badge>Mobile App</Badge>
                  ) : (
                    <Badge>Dashboard</Badge>
                  )}
                </div>
                <div className="flex items-center gap-2 text-secondary-600">
                  <div className="flex items-center gap-2">
                    <img
                      className="object-cover w-4 h-4 rounded"
                      src={item.groupShortInfo.groupPhoto ?? GroupDetailCover}
                      alt=""
                    />
                    <h4>{item.groupShortInfo.groupName}</h4>
                  </div>
                  <DotMark
                    size={10}
                    className="text-secondary-600"
                  />
                  <h4>{item.workFlowName}</h4>
                </div>
                <p className="font-medium mt-3">{item.taskName}</p>
                <div className="flex gap-2">
                  {item.assignments.map((as) => (
                    <div key={as.id}>{as.user?.name}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export default GroupTask;
