import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INotification, NotificationState } from "../types/notification.type";

const initialState: NotificationState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action: PayloadAction<INotification>) => {
      state.notifications.unshift(action.payload);
    },
  },
});

export const notificationAction = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
