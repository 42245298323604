import React, { useState } from "react";

import {
  Close,
  Email,
  Information,
  InformationFilled,
  ReplyAll,
  Warning,
} from "@carbon/icons-react";
import * as Yup from "yup";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { createInvitation } from "../../api/group.api";

const emailSchema = Yup.object().shape({
  email: Yup.string().email(getMessage("MSG9")),
});

const InviteEmailScreen = () => {
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const [mailList, setMailList] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [countSuccessMails, setCountSuccessMails] = useState(0);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMails, setErrorMails] = useState({});

  const handleChange = async (email: string) => {
    setInputValue(email);
    if (mailList.includes(email)) {
      setErrorEmail(getMessage("MSG40"));
    } else {
      try {
        await emailSchema.validate({ email });
        setErrorEmail(null);
      } catch (error: any) {
        setErrorEmail(error.message);
      }
    }
  };
  const handleAddMail = () => {
    setMailList([...mailList, inputValue]);
    setInputValue("");
  };
  const handleRemoveEmail = (indexNumber: number) => {
    setMailList((mailList) => {
      return mailList.filter((mail, index) => index !== indexNumber);
    });
  };
  const handleInvite = async () => {
    try {
      setErrorMails({});
      dispatch(
        loadingAction.show({
          title: "Đang gửi lời mời đi...",
          description: "Vui lòng chờ tới khi tất cả lời mời được gửi đi.",
        })
      );
      if (groupInfo) {
        const result = await createInvitation(groupInfo.id, mailList);
        if (Object.keys(result.data.failedReasons).length > 0) {
          setShowModalError(true);
          setErrorMails(result.data.failedReasons);
        } else {
          if (result.data.successCounter === result.data.totalCounter) {
            toast({
              title: "Thành công!",
              variant: "success",
              description: getMessage("MSG22"),
            });
            setMailList([]);
          }
        }
      }
    } catch (error) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    } finally {
      dispatch(loadingAction.hide());
    }
  };
  return (
    <div className="pb-6">
      <Dialog
        open={showModalError}
        onOpenChange={() => (setShowModalError(false), setMailList([]))}
      >
        <DialogContent className="max-w-[650px]">
          <DialogHeader>
            <DialogTitle className="text-base">
              Gửi email tham gia {groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}
            </DialogTitle>
          </DialogHeader>
          {mailList.length - Object.keys(errorMails).length !== 0 ? (
            <p className="bg-blue-25 p-4 text-primary flex items-center rounded-lg font-normal text-sm">
              <InformationFilled className="text-base text-primary mr-1" />
              <span> Gửi lời mời thành công tới</span>
              <span className="font-medium ms-1">
                {Object.keys(errorMails).length}/{mailList.length} email
              </span>
            </p>
          ) : (
            <Label className="bg-error-25 p-4 text-error-600 flex items-center rounded-lg font-normal text-sm">
              <InformationFilled className="text-base text-error-600 mr-1" />
              Gửi lời mời thất bại
            </Label>
          )}
          <Table>
            <TableCaption className="caption-top text-left text-xs text-secondary-600 font-medium">
              Danh sách lời mời thất bại
            </TableCaption>
            <TableHeader>
              <TableRow className="border-secondary-200">
                <TableHead className="h-4 p-3 text-xs font-medium text-secondary-600 relative after:absolute after:h-[16px] after:w-[1px] after:bg-secondary-200 after:right-0 after:top-1/2 after:-translate-y-1/2 text-center">
                  STT
                </TableHead>
                <TableHead className="h-4 p-3 text-xs font-medium text-secondary-600 relative after:absolute after:h-[16px] after:w-[1px] after:bg-secondary-200 after:right-0 after:top-1/2 after:-translate-y-1/2">
                  Email
                </TableHead>
                <TableHead className="h-4 p-3 text-xs font-medium text-secondary-600">
                  Nguyên nhân lỗi
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="[&_tr:last-child]:border-1 [&_tr:border-secondary-200]">
              {Object.keys(errorMails).map((mail, index) => {
                return (
                  <TableRow
                    key={index}
                    className="border-secondary-200 [&_td]:p-3 [&_td]:text-sm"
                  >
                    <TableCell className="text-center">{index + 1}</TableCell>
                    <TableCell>{mail}</TableCell>
                    <TableCell className="text-error-600">
                      {errorMails[mail as keyof typeof errorMails]}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <DialogFooter className="text-right">
            <DialogClose>
              <Button size="sm">Xác nhận</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="bg-white pt-5 pb-6 z-10 sticky top-0 border-b border-secondary-100">
        <div className="container">
          <p className="text-lg font-semibold text-secondary-900">
            Mời tham gia qua Email
          </p>
          <p className="text-xs text-secondary-600">
            Hãy thêm các email khác để mời đến nhiều người cùng lúc
          </p>
        </div>
      </div>
      <div className="container mt-4">
        <div className="p-6 border border-secondary-200 rounded bg-white max-w-[620px] m-auto grid grid-flow-row gap-4">
          <div>
            <p className="text-secondary-900 text-sm font-medium mb-1">
              Nhập danh sách email
            </p>
            <p className="text-secondary-600 text-xs">
              Nhập địa chỉ email của người dùng mà bạn muốn mời họ vào{" "}
              {groupInfo?.rootGroupId ? "Nhóm" : "Tổ chức"}
            </p>
          </div>
          <div>
            <div className="flex gap-3">
              <Input
                placeholder="e.g., olivia@taganow.com"
                value={inputValue}
                onChange={(e) => handleChange(e.currentTarget.value)}
                error={!!errorEmail}
              />
              <Button
                disabled={!!errorEmail || !inputValue}
                variant="secondary"
                onClick={handleAddMail}
              >
                Thêm
              </Button>
            </div>
            {errorEmail && (
              <p className="text-error-400 text-xs flex mt-2">
                <Warning
                  className="text-error-400 mr-1"
                  size={16}
                />
                {errorEmail}
              </p>
            )}
          </div>
          {mailList.length > 0 && (
            <>
              <div>
                <p className="text-sm px-2 font-medium text-secondary-900">
                  Email được thêm vào・{mailList.length}
                </p>
                {mailList.map((mail, index) => {
                  return (
                    <div
                      key={index}
                      className="px-2 py-3 flex items-center gap-2"
                    >
                      <span className="bg-secondary-50 p-3 rounded-full">
                        <Email className="text-secondary-600" />
                      </span>
                      <span className="flex-1 text-sm font-medium">{mail}</span>
                      <Button
                        size="sm"
                        variant="text"
                        onClick={() => handleRemoveEmail(index)}
                      >
                        <Close />
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div>
                <Button
                  className="w-full"
                  onClick={handleInvite}
                >
                  <ReplyAll className="-scale-x-100 mr-2" /> Gửi lời mời
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InviteEmailScreen;
