import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import LogoTagaNow from "@/assets/images/taganow-logo-color.svg";
import { Button } from "@/components/ui/button";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-white dark:bg-gray-800">
      <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4">
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Về chúng tôi
            </h3>
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <a
                  href="#*"
                  className=" hover:underline"
                >
                  Giới thiệu
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Đối tác
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Công việc
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Tin tức
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Hỗ trợ
            </h3>
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Discord
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Twitter
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Facebook
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Liên hệ
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Điều khoản
            </h3>
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Chính sách bảo mật
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Cấp phép
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Điều khoản
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Tả về
            </h3>
            <ul className="text-gray-500 dark:text-gray-400">
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  iOS
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Android
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  Windows
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="#*"
                  className="hover:underline"
                >
                  MacOS
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="my-6 border-t border-gray-100 sm:mx-auto dark:border-gray-700 lg:my-8"></div>
        <div className="text-center">
          <a
            href="#*"
            className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              src={LogoTagaNow}
              className="h-6 mr-3 sm:h-9"
              alt="Taganow Logo"
            />
          </a>
          <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
            ©2024 Taganow™. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
