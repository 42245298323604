export enum GroupPathsEnum {
  GROUP = "/group",
  GROUP_NOT_AVAILABLE = "/group/not-available",
  GROUP_INVITE = "/group/invite",
  GROUP_DISCOVER = "/group/discover",
  USER_GROUP_DELETED = "/group/deleted",
  GROUP_REQUEST = "/group/request",
  GROUP_DEPARTMENT_USER = "/group/:id/department-and-user/",
  GROUP_USER_DETAIL = "/group/:id/department-and-user/user/:userId",
  GROUP_DEPARTMENT = "/group/:id/department-and-user/department",
  GROUP_DETAIL = "/group/:id",
  GROUP_DISCUSS = "/group/:id/discuss",
  CREATE = "/group/create",
  CREATE_CHILD = "/group/:id/create",
  GROUPS_SEARCH = "/group/search/:keyword",
  GROUP_SETTING = "/group/:id/setting",
  GROUP_SETTING_USER = "/group/:id/setting-user",
  GROUP_EDIT = "/group/:id/edit",
  GROUPS_DELETED = "/group/:id/deleted",
  GROUP_TREE = "/group/:id/department-and-user/tree",
  GROUP_LIBRARY = "/group/:id/library",
  GROUP_LIBRARY_SHARE_WITH_ME = "/group/:id/library/share-with-me",
  GROUP_FILES = "/group/files",
  GROUP_ACTIVITY = "/group/:id/activitys",
  GROUP_TASKS = "/group/:id/tasks",
  LEAVE_REQUEST = "/group/:id/leave-request",
  GROUP_JOIN_REQUEST = "/group/:id/request",
  GROUP_DEVICE = "/group/:id/device",
  GROUP_ROLE = "/group/:id/role",
  GROUP_TITLE = "/group/:id/title",
  INVITE_EMAIL = "/group/:id/invite-email",
  INVITE_FRIEND = "/group/:id/invite-friend",
  INVITE_MEMBER = "/group/:id/invite-member",
  INVITE_REQUESTED = "/group/:id/invite-requested",
  DEVICE_AUTHENTICATION = "/group/:id/device-authentication",
  REMOVE_GROUP_SUCCESS = "/group/remove-success",
}
