import React, { FC, ReactNode } from "react";

import { OverlayTriggerProps } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

type TGNTooltipProps = {
  children: ReactNode;
  label: ReactNode;
} & Omit<OverlayTriggerProps, "childrend" | "overlay">;

const TGNTooltip: FC<TGNTooltipProps> = ({ children, placement, label }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{children}</Tooltip>}
    >
      <div className="d-flex">{label}</div>
    </OverlayTrigger>
  );
};

export default TGNTooltip;
