import api from "@/api/api_instance";

import {
  ApiResponse,
  BlockResponse,
  IParamsGetProjectRef,
  IWorkflow,
} from "../types/operation";

export const getInstanceContent = (params: {
  instanceId: number;
  groupId: number;
}): Promise<ApiResponse> => {
  const { groupId, instanceId } = params;
  return api.get("/workflow/instance/content", {
    params: {
      instanceId,
      groupId,
    },
  });
};
export const getGroups = () => {
  return api.get(`/group/for-studio`);
};

export const getWorkflows = (
  params: IParamsGetProjectRef
): Promise<IWorkflow> => {
  return api.get(`/workflow/instance`, {
    params: { ...params },
  });
};
export const getInstanceBlockContent = (params: {
  blockId: number;
  groupId: number;
}): Promise<BlockResponse> => {
  const { groupId, blockId } = params;
  return api.get("/workflow/instance/block/content", {
    params: {
      blockId,
      groupId,
    },
  });
};

export const updateInstance = ({
  instanceId,
  groupId,
  status,
}: {
  instanceId: number;
  groupId: number;
  status: string;
}) => {
  return api.post(`/workflow/instance/update-status`, {
    groupId,
    instanceId,
    status,
  });
};

export const getTracklog = async (params: {
  groupId: number;
  workflowInstanceId: number;
}) => {
  const { groupId, workflowInstanceId } = params;
  return api.get(`/group/tracklog/${groupId}/${workflowInstanceId}`);
};
export const fetchListWorkflowInstance = async () => {
  return api.get("/workflow/instances-by-group");
};
