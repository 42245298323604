import React, { Children, useEffect, useState } from "react";

import { SearchLocate, CaretDown, ChevronDown } from "@carbon/icons-react";
import cx from "classnames";
import { active } from "d3-transition";
import { NavLink, Outlet, useSearchParams } from "react-router-dom";

import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import SubNav from "@/components/layout/SubNav/SubNav";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { getGroups } from "@/features/operation/api/operation.api";
import { IRootGroup } from "@/features/operation/types/operation";
import useToastError from "@/hooks/useToastError";
import { cn } from "@/lib/utils";

import { TaskPathsEnum } from "../constants/task.paths";

const navLinks = [
  {
    url: `${TaskPathsEnum.TASK}`,
    end: true,
    icon: (
      <SearchLocate
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Công việc",
    children: [
      {
        url: `${TaskPathsEnum.TASK}?role=TODO`,
        end: true,
        active: "TODO",
        label: "Công việc của bạn",
      },
      {
        url: `${TaskPathsEnum.TASK}?role=MANAGED`,
        active: "MANAGED",
        end: true,
        label: "Công việc đang quản lý",
      },
    ],
  },
  {
    url: TaskPathsEnum.TASK_DISCOVER,
    end: true,
    icon: (
      <SearchLocate
        className="text-base mr-2"
        size={16}
      />
    ),
    label: "Khám phá",
  },
];

const TaskLayout = () => {
  const [rootGroups, setRootGroups] = useState<IRootGroup[]>([]);
  const { showToastError } = useToastError();
  const [searchParams] = useSearchParams();

  const fetchMyGroupsInfiniteScroll = async () => {
    try {
      const result = await getGroups();
      setRootGroups(result.data);
    } catch (error) {
      showToastError(error);
    }
  };
  useEffect(() => {
    fetchMyGroupsInfiniteScroll();
  }, []);
  return (
    <div className="">
      <SubNav>
        <div className="w-full">
          <div className="p-4">
            <p className="text-xl font-semibold text-secondary-900 mb-0">
              Quản lý Vận hành
            </p>
          </div>
          <div className="w-full bg-secondary-100 h-px"></div>
          <div className="py-4 px-2">
            <p className="mb-1 text-sm text-secondary-700">Cá nhân</p>
            <ul>
              {navLinks.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="mb-1"
                  >
                    {item?.children ? (
                      <Collapsible
                        defaultOpen
                        className="mt-2"
                      >
                        <div className="flex items-center justify-between px-2   rounded-md w-full">
                          <div className="flex items-center text-sm text-gray-700">
                            {item.icon}
                            {item.label}
                          </div>
                          <CollapsibleTrigger asChild>
                            <Button
                              variant="link"
                              className="text-secondary-700"
                            >
                              <ChevronDown />
                            </Button>
                          </CollapsibleTrigger>
                        </div>
                        <CollapsibleContent>
                          <div className="pl-4">
                            {item.children.map((navLink, j) => (
                              <NavLink
                                key={j}
                                to={navLink.url}
                                className={({ isActive }) =>
                                  cn(
                                    "flex items-center px-2 py-2 pl-4 rounded-md text-sm",
                                    isActive &&
                                      searchParams.get("role") == navLink.active
                                      ? "bg-primary/5 text-primary"
                                      : "hover:bg-gray-50 text-gray-700"
                                  )
                                }
                              >
                                {navLink.label}
                              </NavLink>
                            ))}
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    ) : (
                      <NavLink
                        to={item.url}
                        className={({ isActive }) =>
                          cn(
                            "flex items-center px-2 py-2 rounded-md text-sm",
                            isActive
                              ? "bg-primary/5 text-primary"
                              : "hover:bg-gray-50 text-gray-700"
                          )
                        }
                      >
                        {({ isActive }) => (
                          <div className="flex items-center">
                            {item.icon}
                            {item.label}
                          </div>
                        )}
                      </NavLink>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      </SubNav>
      <div className="content pl-[280px] pt-[70px] ">
        <Outlet />
      </div>
    </div>
  );
};

export default TaskLayout;
