import React, { FC, memo, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import {
  ArrowLeft,
  Close,
  InformationFilled,
  ListChecked,
  ReplyAll,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import LockOrg from "@/assets/images/lockorg.png";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { settingGroup } from "@/features/groups/api/group.api";
import { getGroupInfo } from "@/features/groups/group";
import { useAppDispatch } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import { GroupPathsEnum } from "../../../constants/group.paths";
import { GroupInfoDef } from "../../../types/group.type";

type LockOrgModalProps = {
  groupInfo?: GroupInfoDef;
  disable: boolean;
};

const LockOrgModal: FC<LockOrgModalProps> = ({ groupInfo, disable }) => {
  const { toast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLock = async () => {
    try {
      setOpenConfirmModal(false);
      setLoading(true);
      if (groupInfo) {
        await settingGroup({
          deviceRequireAccept: true,
          groupId: groupInfo.id,
          lock: true,
          mode: groupInfo.mode,
          requireApprove: groupInfo.requireRequestApprove,
        });
        navigate(`/group/${groupInfo.id}`);
        toast({
          title: "Thành công!",
          variant: "success",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: COMMON_MESSAGES.MSG61.replaceAll(
                  "${0}",
                  `${
                    groupInfo.rootGroupId ? "nhóm" : "tổ chức"
                  } <span class='font-semibold'>${groupInfo.name}</span>`
                ),
              }}
            ></div>
          ),
        });
        dispatch(
          getGroupInfo({
            group_id: groupInfo.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error: any) {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage(error.response?.data.errors[0]),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {groupInfo && (
        <>
          <Dialog
            open={openModal}
            onOpenChange={setOpenModal}
          >
            <DialogTrigger asChild>
              <Button
                variant="secondary"
                onClick={() => setOpenModal(true)}
                disabled={disable}
              >
                Khoá {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
              </Button>
            </DialogTrigger>
            <DialogContent
              className={"sm:max-w-[600px] bg-white gap-6 text-center"}
            >
              <DialogHeader className="relative">
                <Button
                  variant="ghost"
                  size="sm"
                  className="absolute top-0 right-0 rounded-full w-[36px]"
                  onClick={() => {
                    setOpenModal(false);
                    setOpenConfirmModal(false);
                  }}
                >
                  <Close />
                </Button>
                <DialogTitle className="text-center max-w-[460PX] text-lg mx-auto">
                  Khoá {groupInfo.rootGroupId ? "Nhóm " : "Tổ chức "}
                  {groupInfo?.name}
                </DialogTitle>
              </DialogHeader>
              <div className="grid gap-4 pb-[12px]">
                <img
                  src={LockOrg}
                  alt=""
                  className="w-[64px] h-[64px] mx-auto"
                />
                <DialogDescription className="text-xl font-semibold text-secondary-900">
                  {groupInfo.rootGroupId
                    ? "Bạn có chắc muốn cô lập nhóm của mình với Tổ chức và người dùng trên TAGA?"
                    : " Bạn có chắc muốn cô lập tổ chức của mình với người dùng trên TAGA?"}
                </DialogDescription>
                <p className="  text-secondary-900">
                  {groupInfo.rootGroupId
                    ? "Nhóm và chuỗi các nhóm trực thuộc của bạn sẽ tạm thời ở trạng thái cô lập. Tất cả danh sách Lời mời tham gia/Yêu cầu tham gia Nhóm của bạn sẽ tạm thời không khả dụng. "
                    : "Hệ thống Tổ chức của bạn sẽ tạm thời ở trạng thái cô lập. Tất cả danh sách Lời mời tham gia/Yêu cầu tham gia Tổ chức của bạn sẽ tạm thời không khả dụng."}
                </p>
                <p className="  text-secondary-900">
                  Bạn có thể thực hiện chỉnh sửa trước khi tiến hành khoá{" "}
                  {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}.
                </p>
                <div className="flex">
                  <Button
                    className="w-full mr-4"
                    variant="secondaryColor"
                    iconBefore={<ListChecked />}
                    disabled
                  >
                    Lời mời đã gửi
                  </Button>
                  <Button
                    className="w-full"
                    variant="secondaryColor"
                    disabled
                    iconBefore={<ReplyAll />}
                  >
                    Yêu cầu tham gia
                  </Button>
                </div>
              </div>
              <DialogFooter>
                <Button
                  className="w-full"
                  onClick={() => {
                    setOpenModal(false);
                    setOpenConfirmModal(true);
                  }}
                >
                  Tiếp theo
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog open={openConfirmModal}>
            <DialogContent className="max-w-[600px] gap-10">
              <DialogHeader>
                <DialogTitle className="text-center flex justify-between text-lg">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="rounded-full w-[36px]"
                    onClick={() => {
                      setOpenModal(true);
                      setOpenConfirmModal(false);
                    }}
                  >
                    <ArrowLeft />
                  </Button>
                  Khoá {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}{" "}
                  {groupInfo?.name}
                  <Button
                    variant="ghost"
                    size="sm"
                    className="rounded-full w-[36px]"
                    onClick={() => {
                      setOpenModal(false);
                      setOpenConfirmModal(false);
                    }}
                  >
                    <Close />
                  </Button>
                </DialogTitle>
                <DialogDescription className="font-xs text-secondary-900 font-medium text-center mt-4">
                  Bạn chắc chắn muốn cô lập{" "}
                  {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                  <span className="font-semibold">“{groupInfo?.name}”</span>{" "}
                  {groupInfo.rootGroupId ? (
                    <>
                      và{" "}
                      <span className="font-semibold">
                        chuỗi nhóm liên quan
                      </span>{" "}
                      với Tổ chức và người dùng trên TAGA?
                    </>
                  ) : (
                    "với người dùng trên TAGA?"
                  )}
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant="secondary"
                  className="w-full"
                  onClick={() => {
                    handleLock();
                  }}
                >
                  Khóa {groupInfo.rootGroupId ? "nhóm" : "tổ chức"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog
            open={loading}
            onOpenChange={setLoading}
          >
            <DialogContent className="p-6 text-center max-w-[400px] gap-2">
              <LoadingIcon className="animate-spin duration-[3000ms] mx-auto" />
              <p className="text-secondary-900 text-lg font-semibold mt-2">
                Đang cô lập {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"}
              </p>

              <p className="text-secondary-600 text-sm">
                Vui lòng chờ tới khi thiết lập hoàn tất.
              </p>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default memo(LockOrgModal);
