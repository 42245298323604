import * as React from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex min-h-[80px] w-full text-secondary-900 rounded-md border border-input bg-background px-3 py-2.5 shadow-sm text-sm ring-offset-background placeholder:text-secondary-400 hover:border-primary-600 focus:shadow-active focus:border-primary-600 focus-visible:outline-none read-only:bg-secondary-100 read-only:cursor-not-allowed read-only:focus:border-input read-only:focus:shadow-none read-only:hover:border-input disabled:bg-secondary-100 disabled:text-secondary-300 disabled:hover:border-secondary-200 disabled:cursor-not-allowed",
          error
            ? "border-error-600 hover:border-error-600 focus:border-error-600 focus:shadow-error"
            : "",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
