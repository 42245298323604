import React, { memo } from "react";

import ListEmpty from "@/assets/images/Search_empty.png";

const SearchEmpty = () => {
  return (
    <div className="flex items-center justify-center h-[calc(100vh-64px)]">
      <div className="flex flex-col items-center justify-center gap-6 max-w-[350px]">
        <img
          className="w-[124px]"
          src={ListEmpty}
          alt=""
        />
        <p className="text-black font-semibold text-base">
          Oops! Không có kết quả tìm kiếm phù hợp.
        </p>
      </div>
    </div>
  );
};

export default memo(SearchEmpty);
