import * as React from "react";

import { DocumentUnknown, Image, Pdf, Txt, Zip } from "@carbon/icons-react";

import { Icons } from "./icon";

export enum FileTypes {
  Excel = 1,
  World = 2,
  Powerpoint = 3,
  OneNote = 4,
  Image = 5,
  Zip = 6,
  Text = 7,
  Pdf = 8,
  Unknown = 9,
  Folder = 10,
}

export const getFileTypeByExtension = (
  extension: string | null | undefined
) => {
  if (extension === null) return FileTypes.Folder;
  if (!extension) return FileTypes.Unknown;
  let strCheck = extension;
  const strCheckArr = extension.split("/");
  if (strCheckArr.length) {
    strCheck = strCheckArr[strCheckArr.length - 1];
  }
  if (
    [
      "xlsx",
      "xlsm",
      "xls",
      "csv",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(strCheck)
  )
    return FileTypes.Excel;
  else if (
    [
      "doc",
      "docx",
      "msword",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(strCheck)
  )
    return FileTypes.World;
  else if (["pptx", "ppt"].includes(strCheck)) return FileTypes.Powerpoint;
  else if (["one"].includes(strCheck)) return FileTypes.OneNote;
  else if (["jpg", "png", "jpeg", "gif"].includes(strCheck))
    return FileTypes.Image;
  else if (["zip"].includes(strCheck)) return FileTypes.Zip;
  else if (["txt"].includes(strCheck)) return FileTypes.Text;
  else if (["pdf"].includes(strCheck)) return FileTypes.Pdf;
  else return FileTypes.Unknown;
};

export interface IFileIconProps {
  extension: string | null | undefined;
  size?: number;
}

export default function FileIcon({ extension, size = 22 }: IFileIconProps) {
  switch (getFileTypeByExtension(extension)) {
    case FileTypes.Excel:
      return (
        <Icons.TGExcel
          size={size}
          aria-label="Excel"
        ></Icons.TGExcel>
      );
    case FileTypes.World:
      return (
        <Icons.TGWorld
          size={size}
          aria-label="World"
        ></Icons.TGWorld>
      );
    case FileTypes.Powerpoint:
      return (
        <Icons.TGPowerpoint
          size={size}
          aria-label="Powerpoint"
        ></Icons.TGPowerpoint>
      );
    case FileTypes.OneNote:
      return (
        <Icons.TGOneNote
          size={size}
          aria-label="OneNote"
        ></Icons.TGOneNote>
      );
    case FileTypes.Folder:
      return (
        <Icons.TGFolder
          size={size}
          aria-label="Folder"
        ></Icons.TGFolder>
      );
    case FileTypes.Image:
      return (
        <Image
          size={size}
          aria-label="Image"
        ></Image>
      );
    case FileTypes.Zip:
      return (
        <Zip
          size={size}
          aria-label="Zip"
        ></Zip>
      );
    case FileTypes.Text:
      return (
        <Txt
          size={size}
          aria-label="Text"
        ></Txt>
      );
    case FileTypes.Pdf:
      return (
        <Pdf
          size={size}
          aria-label="Pdf"
        ></Pdf>
      );
    default:
      return (
        <DocumentUnknown
          size={size}
          aria-label="Unknown"
        ></DocumentUnknown>
      );
  }
}
