import React, { FC, memo, useState } from "react";

import cx from "classnames";

import { Excel } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { FormatDateEnums } from "@/constants/date.constants";
import { useAppDispatch } from "@/redux/store";
import { formatDate } from "@/utils/date";

import styles from "./ChatBox.module.scss";
import {
  MessageDef,
  RevisionsFileType,
  requestDownloadFile,
} from "../../mindmap";

type CommentProps = {
  message: MessageDef;
  files: RevisionsFileType[];
};

const Comment: FC<CommentProps> = ({ message, files }) => {
  const dispatch = useAppDispatch();
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      key={message.issueAt}
      className={styles.comment}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <div>
        <TGNAvatar
          name="Nguyen Son"
          status="active"
          size="24"
          round
          className="d-flex"
        />
      </div>
      <div className="flex-1">
        <div className="d-flex flex-wrap align-items-center fs-12 text-tertiary-color-gray-5 mb-2">
          <span className="fw-semibold text-primary-color-gray-10">
            {message.userId}
          </span>
          &nbsp;
          <div className={styles["chip-owner"]}>
            <i className="tgn-outline-user-polygon fs-14"></i>
            <span className="fs-12 fw-semibold">Kiểm duyệt viên</span>
          </div>
          &nbsp;
          {formatDate(Number(message.issueAt), FormatDateEnums.FULL_DATE)}
        </div>
        {message.value.message && (
          <div className={styles.content}>
            <div
              className={cx("fs-14 text-primary-color-gray-10", styles.message)}
              style={{
                maxWidth: 356,
              }}
              dangerouslySetInnerHTML={{
                __html: message.value.message.replaceAll("\n", "<br />"),
              }}
            ></div>
            <br />
          </div>
        )}
        {(message.files.length ? message.files : files).map((file) => {
          const compareFile = files.find(
            (item) => item.extensionData.chatId === file.extensionData.chatId
          );
          return (
            <div
              key={file.issuedAt}
              className={styles.file}
              onClick={() => dispatch(requestDownloadFile(compareFile || file))}
            >
              {!compareFile && <div className={styles.loader}></div>}
              <Excel />
              <div className="d-flex flex-column">
                <span className="fs-12 fw-semibold">{file.fileName}</span>
                <span className="fs-12">25 MB</span>
              </div>
            </div>
          );
        })}
      </div>
      {isHover && (
        <div className={styles.action}>
          <i className="tgn-outline-smile-ellipse"></i>
          <i className="tgn-solid-reply-rectangle"></i>
          <i className="tgn-outline-more-horizontal"></i>
        </div>
      )}
    </div>
  );
};

export default memo(Comment);
