import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BlockNodeDef, WorkflowState } from "../types/operation";

const initialState: WorkflowState = {
  group: "",
  workflowData: "",
  currentBlock: null,
  blockInstanceContent: null,
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<string>) => {
      state.group = action.payload;
    },
    setSelectedBlock: (state, action: PayloadAction<BlockNodeDef | null>) => {
      state.currentBlock = action.payload;
    },
  },
});

export const { setSelectedBlock, setGroup } = workflowSlice.actions;

export const workflowReducer = workflowSlice.reducer;
