import axios from "axios";

import api from "@/api/api_instance";

export const getTasks = (params: { type: string }) => {
  const { type } = params;
  return api.get("/task/me", {
    params: {
      type,
    },
  });
};

export const getTaskDetail = (id: number) => {
  console.log(id);
  return api.get("/task/:id/detail".replace(":id", String(id)));
};
