import React from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppSelector } from "@/redux/store";

import AccessManagement from "./components/AccessManagement";
import AdvanceSetting from "./components/AdvanceSetting";
import GeneralSetting from "./components/GeneralSetting";
import GroupPermission from "../../components/GroupPermission";
import { GroupUserRoleEnums } from "../../constants/group.constant";

const SettingScreen = () => {
  const { data: groupInfo } = useAppSelector((state) => state.group);
  return (
    <>
      {groupInfo && (
        <div className="pb-6">
          <div className="bg-white pt-5 pb-6 z-10 sticky top-0">
            <div className="container">
              <p className="text-lg font-semibold text-secondary-900">
                Cài đặt
              </p>
              <p className="text-xs text-secondary-600">
                Thiết lập và quản lý{" "}
                {groupInfo.rootGroupId ? "Nhóm" : "Tổ chức"} của bạn
              </p>
            </div>
          </div>
          <Tabs defaultValue="general">
            <div className="bg-white z-10 sticky top-[88px] border-secondary-100 border-b">
              <div className="container">
                <TabsList className="p-0 flex justify-start">
                  <TabsTrigger value="general">Thông tin chung</TabsTrigger>
                  <GroupPermission codes={[GroupUserRoleEnums.OWNER]}>
                    <TabsTrigger value="manager">Quản lý Truy cập</TabsTrigger>
                    <TabsTrigger value="advance">
                      Thiết lập Nâng cao
                    </TabsTrigger>
                  </GroupPermission>
                </TabsList>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-end-11">
                <TabsContent value="general">
                  <GeneralSetting />
                </TabsContent>
                <GroupPermission codes={[GroupUserRoleEnums.OWNER]}>
                  <TabsContent value="manager">
                    <AccessManagement />
                  </TabsContent>
                  <TabsContent value="advance">
                    <AdvanceSetting />
                  </TabsContent>
                </GroupPermission>
              </div>
            </div>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default SettingScreen;
