export enum LibEndPointEnum {
  CREATE_PERSONAL_CONTENT = "/content/me/create",
  SHARE_WITH_ME = "/content/me/:id/my-shared",
  CHILD = "/content/me/:id/child",
  PERSONAL_UPLOAD = "/content/me/upload",
  PERSONAL_UPLOAD_FILE = "/content/me/upload",
  PERSONAL_UPLOAD_FOLDER = "/content/me/upload-folder",
  CHECK_EXIST_NAME = "/content/me/upload/check-exits-name",
  FRIEND = "/content/me/invite-friend",
  SHARED_USER = "/content/me/:id/user-share",
  CONTENT_DETAIL = "/content/me/:id",
  SHARED_CONTENT = "/content/me/:id/share",
  REMOVE_MEMBER_CONTENT = "/content/me/:id/permission",
  SEARCH = "/content/me/:id/search",
  CHECK_CONTENT_NAME = "/content/me/check-content-name",
  SOFT_DELETE = "/content/me/soft-delete-content?contentId=:id",
  LIST_SOFT_DELETE = "content/me/soft-delete-content?contentId=:id",
  RENAME = "/content/me/accept-content-name",
  RESTORE = "/content/me/recover",
  HARD_DELETE = "/content/me/hard-delete-content?contentId=:id",
  CHECK_DUPLICATE_CONTENT_NAME = "/content/me/:id/check-exist",
  MOVE_LOCATION = "/content/me/:id/move",
  RECENT = "/content/me/:id/recent",
  MY_SHARED = "/content/me/:id/my-shared",
  DELETE_MY_SHARED_CONTENT = "/content/me/:id/my-share/permission",
  DOWNLOAD_FILE = "/content/me/download",
  DOWNLOAD_FOLDER = "/content/me/download-folder/:id",
  CAPACITY_DETAIL = "/content/me/capacity-detail",
  LIBRARY_LIST = "/content/me/library",
  ORG_LIBRARY = "/content/group/:id/library",
  GROUP_LIBRARY = "/content/:id/child/",
  ACCEPT_SHARE_CONTENT = "/content/request/:id/accept",
}
