import React, { useEffect, useRef, useState } from "react";

import { Workflow } from "@/assets/icons";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { renderStatus } from "@/features/mindmap/helpers/mindmap.helpers";
import { formatDateDay } from "@/utils/date";

import {
  fetchListWorkflowInstance,
  getInstanceContent,
} from "../api/operation.api";
import { renderTreemap } from "../lib/tree";
import {
  ApiResponse,
  Block,
  IWorkflowContent,
  WorkflowItemDef,
} from "../types/operation";

const OperationScreen = () => {
  const [myWorkflows, setMyWorkflows] = useState<any[]>([]);
  console.log(myWorkflows);
  useEffect(() => {
    fetchMyWorkflows();
  }, []);
  const fetchMyWorkflows = async () => {
    try {
      const result = await fetchListWorkflowInstance();

      setMyWorkflows(result.data);
    } catch (error: any) {
      console.log(error);
    }
  };
  return (
    <div className="container mt-10">
      Quy trình của bạn
      <div className="h-full overflow-y-auto pb-8">
        <table className="min-w-full  text-sm text-left relative">
          <thead className=" border-b border-secondary-200">
            <tr>
              <th className="py-4 text-secondary-600 font-medium">
                Tên tệp tin
              </th>
              <th className="py-4 text-secondary-600 font-medium ">
                <div className="border-l border-secondary-200 pl-2">
                  Trạng thái
                </div>
              </th>
              <th className="py-4 text-secondary-600 font-medium ">
                <div className="border-l border-secondary-200 pl-2">
                  Ngày khởi chạy
                </div>
              </th>
              <th className="py-4 text-secondary-600 font-medium ">
                <div className="border-l border-secondary-200 pl-2">
                  Nhóm khởi chạy
                </div>
              </th>
              <th className="py-4 text-secondary-600 font-medium ">
                <div className="border-l border-secondary-200 pl-2">
                  Người khởi chạy
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {myWorkflows.length > 0 &&
              myWorkflows.map((file, index) => (
                <tr
                  key={index}
                  className="hover:bg-secondary-100 border-b border-secondary-200"
                >
                  <td className="py-4 px-2 flex items-center gap-2">
                    <div className="w-7 h-7 bg-secondary-200 flex items-center justify-center rounded-md">
                      <Workflow />
                    </div>
                    <div className="flex flex-col cursor-pointer">
                      <p className="text-sm font-semibold">
                        {file.instanceName}
                      </p>
                    </div>
                  </td>
                  <td className="py-4 px-2"> {file.status}</td>
                  <td className="py-4 px-2">
                    <div className="flex flex-col">
                      <p className="text-sm font-semibold">
                        {formatDateDay(file.runDate)}
                      </p>
                    </div>
                  </td>
                  <td className="py-4 px-2">
                    <div className="flex items-center gap-2">
                      {" "}
                      {file.groupName}
                    </div>
                  </td>
                  <td className="py-4 px-2">
                    <div className="flex items-center"></div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OperationScreen;
