import React, { FC, Fragment, useState } from "react";

import {
  Download,
  Edit,
  FolderMoveTo,
  Information,
  ReplyAll,
  TrashCan,
} from "@carbon/icons-react";

import Divider from "@/components/Divider/Divider";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { cn } from "@/lib/utils";
import { formatDistance } from "@/utils/date";
import { formatBytes, getContentName } from "@/utils/file";

import FileIcon, { FileTypes, getFileTypeByExtension } from "./FileIcon";
import {
  ContentTypeEnums,
  FolderMenuActions,
} from "../constants/lib.constants";
import { RecentContentDef } from "../types/lib.types";

function getBGColor(extension: string | null) {
  switch (getFileTypeByExtension(extension)) {
    case FileTypes.Excel:
      return "bg-[#E3ECE6]";
    case FileTypes.World:
      return "bg-[#EAF1F6]";
    case FileTypes.Powerpoint:
      return "bg-[#F6F0ED]";
    case FileTypes.OneNote:
      return "bg-[#F3ECF9]";
    case FileTypes.Folder:
      return "bg-[#faedd1]";
    default:
      return "bg-[#F2F5F9]";
  }
}

type RecentContentItemProps = {
  recentContent: RecentContentDef;
  onMenuClick: (action: FolderMenuActions) => void;
};

const RecentContentItem: FC<RecentContentItemProps> = ({
  recentContent,
  onMenuClick,
}) => {
  const [open, setOpen] = useState(false);
  const actionBarList = [
    {
      label: "Đổi tên",
      icon: <Edit />,
      action: FolderMenuActions.Rename,
    },
    {
      label: "Chia sẻ",
      icon: <ReplyAll />,
      action: FolderMenuActions.Share,
    },
    {
      label: "Chuyển đến",
      icon: <FolderMoveTo />,
      action: FolderMenuActions.Move,
    },
    {
      label: "Tải xuống",
      icon: <Download />,
      action: 1,
    },
    {
      label: "Thông tin",
      icon: <Information />,
      action: FolderMenuActions.Detail,
    },
    {
      label: "Xoá",
      icon: <TrashCan />,
      action: FolderMenuActions.Delete,
    },
  ];
  return (
    <ContextMenu onOpenChange={(o) => setOpen(o)}>
      <ContextMenuTrigger>
        <div
          key={recentContent.content.id}
          className={cn(
            "p-4 pr-2 rounded-xl w-[246px] h-[148px] flex flex-col justify-between bg-secondary cursor-pointer",
            getBGColor(recentContent.content.extension)
          )}
        >
          <FileIcon
            size={40}
            extension={recentContent.content.extension}
          />
          <div>
            <div className="text-xs font-semibold">
              {recentContent.content.name}
            </div>
            <div className="text-xs text-tg-text-secondary">
              Chỉnh sửa {formatDistance(recentContent.last_modified)}
            </div>
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="bg-white p-1">
        {actionBarList.map((item) => (
          <Fragment key={item.label}>
            <ContextMenuItem className="p-0 min-w-[240px]">
              <div
                className={cn(
                  "flex gap-1 items-center hover:bg-secondary-100 w-full px-2 py-[10px] rounded-sm cursor-pointer",
                  [
                    FolderMenuActions.Delete,
                    FolderMenuActions.RemoveShare,
                  ].includes(item.action)
                    ? "text-error-600"
                    : ""
                )}
                onClick={() => onMenuClick(item.action)}
              >
                <div className="mr-1">{item.icon}</div>
                {item.label}
              </div>
            </ContextMenuItem>
            {["Tải xuống", "Thông tin"].includes(item.label) && (
              <Divider
                className="my-1 !w-[calc(100%-16px)] mx-auto"
                height={1}
              />
            )}
          </Fragment>
        ))}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default RecentContentItem;
