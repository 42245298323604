export enum NotificationStatus {
  READ = "READ",
  UNREAD = "UNREAD",
}

export const NOTIFICATION_ACTION_TYPES = [
  "NT_1",
  "NT_11",
  "NT_13",
  "NT_16",
  "NT_19",
];
