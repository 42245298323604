import React from "react";

type TaskDataProps = {
  taskData: any; // Replace with a proper type if available
};

export const TaskDetailRenderer: React.FC<TaskDataProps> = ({ taskData }) => {
  if (!taskData) {
    return <p>No data available.</p>;
  }

  const { taskName, stepBlockId, section, screens, dataFormContents } =
    taskData;

  return (
    <div className="p-4">
      {/* Task General Information */}
      <div className="mb-4">
        <h2 className="text-xl font-bold">Task Details</h2>
        <p>
          <strong>Task Name:</strong> {taskName}
        </p>
        <p>
          <strong>Step Block ID:</strong> {stepBlockId}
        </p>
      </div>

      {/* Section Details */}
      {section && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Section</h3>
          <p>
            <strong>Section ID:</strong> {section.sectionId}
          </p>
          <p>
            <strong>Assigned Role:</strong> {section.assignRole}
          </p>
          {section.screenLogicComponents &&
            section.screenLogicComponents.length > 0 && (
              <div>
                <h4 className="font-semibold">Screen Logic Components:</h4>
                {section.screenLogicComponents.map(
                  (component: any, index: number) => (
                    <div
                      key={index}
                      className="border p-2 mb-2"
                    >
                      <p>
                        <strong>Component ID:</strong> {component.id}
                      </p>
                      {component.logics && component.logics.length > 0 && (
                        <div>
                          <h5>Logics:</h5>
                          {component.logics.map((logic: any, idx: number) => (
                            <div
                              key={idx}
                              className="ml-4"
                            >
                              <p>
                                <strong>Logic ID:</strong> {logic.logicId}
                              </p>
                              <p>
                                <strong>Logic Type:</strong> {logic.logicType}
                              </p>
                              <p>
                                <strong>Interaction Type:</strong>{" "}
                                {logic.interactionType}
                              </p>
                              {logic.subLogics &&
                                logic.subLogics.length > 0 && (
                                  <div className="ml-4">
                                    <h6>Sub Logics:</h6>
                                    {logic.subLogics.map(
                                      (subLogic: any, subIdx: number) => (
                                        <p key={subIdx}>
                                          <strong>Sub Logic ID:</strong>{" "}
                                          {subLogic.logicId},{" "}
                                          <strong>Type:</strong>{" "}
                                          {subLogic.logicType}
                                        </p>
                                      )
                                    )}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            )}
        </div>
      )}

      {/* Screens */}
      {screens && screens.length > 0 && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Screens</h3>
          {screens.map((screen: any, index: number) => (
            <div
              key={index}
              className="border p-2 mb-2"
            >
              <p>
                <strong>Screen ID:</strong> {screen.screenId}
              </p>
              {screen.elements && screen.elements.length > 0 && (
                <div>
                  <h4>Elements:</h4>
                  {screen.elements.map((element: any, idx: number) => (
                    <p key={idx}>
                      <strong>Element ID:</strong> {element.elementId},{" "}
                      <strong>Type:</strong> {element.type},{" "}
                      <strong>Label:</strong> {element.config.label}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Data Form Contents */}
      {dataFormContents && dataFormContents.length > 0 && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Data Form Contents</h3>
          {dataFormContents.map((content: any, index: number) => (
            <div
              key={index}
              className="border p-2 mb-2"
            >
              <p>
                <strong>File ID:</strong> {content.fileId}
              </p>
              {content.dataform && content.dataform.length > 0 && (
                <table className="table-auto border-collapse border border-gray-300">
                  <thead>
                    <tr>
                      <th className="border px-2 py-1">X Axis</th>
                      <th className="border px-2 py-1">Y Axis</th>
                      <th className="border px-2 py-1">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.dataform.map((item: any, idx: number) => (
                      <tr key={idx}>
                        <td className="border px-2 py-1">{item.xAxis}</td>
                        <td className="border px-2 py-1">{item.yAxis}</td>
                        <td className="border px-2 py-1">{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
