import React, { useEffect, useState } from "react";

import { Search, Add, Connect, Pause, Information } from "@carbon/icons-react";
import delay from "lodash/delay";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";

import { Empty, Extended, Workflow } from "@/assets/icons";
import GroupDetailCover from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import Loading3Dot from "@/components/TGNLoading/Loading3Dot";
import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { getGroupById } from "@/features/groups/api/group.api";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDateDay } from "@/utils/date";
import { extractUsername } from "@/utils/extractUsername";
import { getMessage } from "@/utils/message";

import { getWorkflows, updateInstance } from "../api/operation.api";
import { FiMenuContent } from "../components/FileMenu";
import { WorkflowDetail } from "../components/WorkflowDetail";
import { WorkflowStatus } from "../constants/operation.constant";
import { workflowSlice } from "../redux/workflow.slice";
import { IWorkflowContent } from "../types/operation";

const OperationGroup = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const workflow = useAppSelector((state) => state.workflow);
  const [myWorkflows, setMyWorkflows] = useState<IWorkflowContent[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [idWorkflow, setIdWorkflow] = useState<number | null>(
    Number(urlParams.get("idWorkflow"))
  );
  const [groupId, setGroupId] = useState(Number(id));
  useEffect(() => {
    setGroupId(Number(id));
  }, [id]);

  const fetchMyWorkflowsInfiniteScroll = async (pageScroll: number) => {
    if (groupId) {
      try {
        setIsLoadMore(true);
        const result = await getWorkflows({
          pageNumber: pageScroll,
          pageSize: pageSize,
          groupId: groupId,
        });
        if (!result.data.length || result.data.length < pageSize) {
          setIsLoadMore(false);
        }
        setMyWorkflows(() => [...myWorkflows, ...result.data]);
      } catch (error: any) {
        setIsLoadMore(false);
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data.errors[0]),
        });
      }
    }
  };
  const fetchMyWorkflows = async (pageNumber: number) => {
    if (groupId) {
      try {
        setLoading(true);
        setIsLoadMore(true);
        const result = await getWorkflows({
          groupId: groupId,
          pageNumber: pageNumber,
          pageSize: pageSize,
        });
        if (!result.data.length || result.data.length < pageSize) {
          setIsLoadMore(false);
        }
        setMyWorkflows(result.data);
      } catch (error: any) {
        setIsLoadMore(false);
        setLoading(false);
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      } finally {
        setLoading(false);
      }
    }
  };
  const fetchGroupById = async (groupId: number) => {
    const result = await getGroupById(groupId);
    dispatch(workflowSlice.actions.setGroup(result.data.name));
  };

  useEffect(() => {
    fetchMyWorkflows(page);
    fetchGroupById(groupId);
    setPage(1);
  }, [groupId]);

  useEffect(() => {
    fetchMyWorkflows(page);
  }, []);
  //handel

  const actions = [
    { key: "open", label: "Mở luồng", icon: <Extended /> },
    { key: "stop", label: "Dừng luồng", icon: <Pause /> },
    { key: "line" },
    { key: "details", label: "Chi tiết", icon: <Information /> },
  ];
  const [fileSelected, setFileSelected] = useState<IWorkflowContent | null>(
    null
  );

  const [contextMenu, setContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
    file: IWorkflowContent | null;
  }>({
    visible: false,
    x: 0,
    y: 0,
    file: null,
  });

  const handleContextMenu = (
    event: React.MouseEvent,
    file: IWorkflowContent
  ) => {
    event.preventDefault();
    setFileSelected(file);
    const { pageX, pageY } = event;
    delay(
      () =>
        setContextMenu({
          visible: true,
          x: pageX - 200,
          y: pageY - 100,
          file,
        }),
      200
    );
  };

  const closeMenu = () => setContextMenu({ ...contextMenu, visible: false });

  const handleAction = (key: string | number, file: IWorkflowContent) => {
    switch (key) {
      case "open":
        handelSetId(file);
        break;
      case "stop":
        updateInstanceFn(file);
        break;
    }
    setContextMenu({ ...contextMenu, visible: false });
  };

  const onFileDoubleClick = (file: IWorkflowContent) => {
    handelSetId(file);
  };

  const handelSetId = (file: IWorkflowContent) => {
    setIdWorkflow(file.id);
    urlParams.set("idWorkflow", String(file.id));
    navigate(`${window.location.pathname}?${urlParams.toString()}`);
  };
  const updateInstanceFn = async (file: IWorkflowContent) => {
    try {
      setLoading(true);
      await updateInstance({
        groupId: groupId,
        status: WorkflowStatus.STOPPED,
        instanceId: file.id,
      });
      setPage(1);
      fetchMyWorkflows(1);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: "Thất bại!",
        variant: "error",
        description: getMessage("MSG12"),
      });
    } finally {
      setLoading(false);
    }
  };
  const renderStatus = (status: WorkflowStatus) => {
    switch (status) {
      case WorkflowStatus.RUNNING:
        return <Badge className="">Đang chạy</Badge>;
      case WorkflowStatus.STOPPED:
        return <Badge variant="destructive">Đã dừng</Badge>;
      default:
        return (
          <Badge
            size="md"
            variant="ghost"
          >
            <Connect />
            Đang kết nối ...
          </Badge>
        );
    }
  };
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center mt-6">
          <TGNLoading
            size="large"
            isFullScreen
          />
        </div>
      ) : (
        <div className="container relative">
          <InfiniteScroll
            style={{
              height: "unset",
              overflow: "unset",
            }}
            className="px-0"
            dataLength={myWorkflows.length}
            next={() => {
              setPage((prev) => prev + 1);
              fetchMyWorkflowsInfiniteScroll(page + 1);
            }}
            hasMore={isLoadMore}
            loader={
              <div className="flex items-center justify-center mt-6">
                <Loading3Dot
                  setWidth="30"
                  setHeight="30"
                />
              </div>
            }
          >
            {myWorkflows.length === 0 ? (
              <div className="flex flex-col h-[calc(100vh-131px)] -mt-10 items-center justify-center flex-1">
                <div className="flex flex-col items-center justify-center max-w-[229px] text-center">
                  <Empty />
                  <h4 className="w-[350px] h-8 text-center text-[#252526] text-base font-semibold mt-4">
                    Oops! Danh sách trống.
                  </h4>
                  <p className="w-[350px] text-center text-[#6d6b70] text-xs ">
                    Truy cập Studio và tạo mới dự án để tiến hành thực hiện công
                    việc trong tổ chức của bạn
                  </p>
                  <Button className="mt-4">Truy cập Studio</Button>
                </div>
              </div>
            ) : (
              <div className="grid ">
                <div className="py-3 flex gap-3">
                  <form className="relative flex-1">
                    <Search className="absolute left-3 top-3 text-secondary-600" />
                    <Input
                      className="bg-secondary-100 rounded-[384px] pl-9"
                      placeholder="Tìm kiếm tệp tin, thư mục"
                    />
                  </form>
                  <Button
                    variant="secondary"
                    className="rounded-[100%] p-3"
                  >
                    <Add />
                  </Button>
                </div>
                <div>
                  <h4 className="text-secondary-600 font-medium my-4">
                    {workflow.group}
                  </h4>
                </div>
                <div className="h-full overflow-y-auto pb-8">
                  <table className="min-w-full  text-sm text-left relative">
                    <thead className=" border-b border-secondary-200">
                      <tr>
                        <th className="py-4 text-secondary-600 font-medium">
                          Tên tệp tin
                        </th>
                        <th className="py-4 text-secondary-600 font-medium ">
                          <div className="border-l border-secondary-200 pl-2">
                            Trạng thái
                          </div>
                        </th>
                        <th className="py-4 text-secondary-600 font-medium ">
                          <div className="border-l border-secondary-200 pl-2">
                            Ngày khởi chạy
                          </div>
                        </th>
                        <th className="py-4 text-secondary-600 font-medium ">
                          <div className="border-l border-secondary-200 pl-2">
                            Nhóm khởi chạy
                          </div>
                        </th>
                        <th className="py-4 text-secondary-600 font-medium ">
                          <div className="border-l border-secondary-200 pl-2">
                            Người khởi chạy
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {myWorkflows.map((file) => (
                        <tr
                          key={file.id}
                          className="hover:bg-secondary-100 border-b border-secondary-200"
                          onDoubleClick={() => onFileDoubleClick(file)}
                          onContextMenu={(event) =>
                            handleContextMenu(event, file)
                          }
                        >
                          <td className="py-4 px-2 flex items-center gap-2">
                            <div className="w-7 h-7 bg-secondary-200 flex items-center justify-center rounded-md">
                              <Workflow />
                            </div>
                            <div
                              className="flex flex-col cursor-pointer"
                              onClick={() => handelSetId(file)}
                            >
                              <p className="text-sm font-semibold">
                                {file.fileName}
                              </p>
                            </div>
                          </td>
                          <td className="py-4 px-2">
                            <div className=" ">{renderStatus(file.status)}</div>
                          </td>
                          <td className="py-4 px-2">
                            <div className="flex flex-col">
                              <p className="text-sm font-semibold">
                                {formatDateDay(file.runDate)}
                              </p>
                            </div>
                          </td>
                          <td className="py-4 px-2">
                            <div className="flex items-center gap-2">
                              <img
                                className="object-cover w-4 h-4 rounded"
                                src={
                                  file.startGroup.groupPhoto ?? GroupDetailCover
                                }
                                alt=""
                              />
                              <span className="text-sm font-semibold text-ellipsis truncate">
                                {file.startGroup.groupName}
                              </span>
                            </div>
                          </td>
                          <td className="py-4 px-2">
                            <div className="flex items-center">
                              <TGNAvatar
                                className="cursor-pointer"
                                src={file.startUser.photo}
                                name={
                                  file.startUser.name ||
                                  extractUsername(file.startUser.email || "")
                                }
                                size="24"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </InfiniteScroll>
          {contextMenu.visible && fileSelected && (
            <div
              className="absolute z-10"
              style={{
                top: `${contextMenu.y}px`, // Correct Y position
                left: `${contextMenu.x}px`, // Correct X position
              }}
            >
              <FiMenuContent
                actions={actions}
                file={fileSelected}
                handleAction={handleAction}
                onClose={closeMenu}
              />
            </div>
          )}
        </div>
      )}
      {(idWorkflow != null || idWorkflow != 0) && (
        <WorkflowDetail
          groupId={groupId}
          workflowId={idWorkflow}
          close={() => (
            setIdWorkflow(null),
            urlParams.delete("idWorkflow"),
            navigate(`${window.location.pathname}?${urlParams.toString()}`)
          )}
        />
      )}
    </>
  );
};

export default OperationGroup;
