import React, { memo, useState } from "react";

import {
  Copy,
  InformationFilled,
  Logout,
  OverflowMenuVertical,
  Pin,
  Reply,
} from "@carbon/icons-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import { createLeaveRequest } from "../../api/group.api";
import {
  GroupUserRequestStatusEnum,
  GroupUserRoleEnums,
} from "../../constants/group.constant";
import menuLinksConst from "../../constants/group.menu";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo } from "../../group";

const GroupHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: groupData } = useAppSelector((state) => state.group);
  const { id } = useParams();
  const menuLinks = menuLinksConst(Number(id));
  const [openLeaveGroup, setOpenLeaveGroup] = useState(false);
  const { toast } = useToast();
  const handleLeaveGroup = async () => {
    dispatch(
      loadingAction.show({
        title: "Đang xử lý...",
        description: "Vui lòng chờ tới khi xử lý hoàn tất.",
      })
    );
    try {
      if (groupData) {
        await createLeaveRequest(
          groupData.id,
          groupData.rootGroupId ?? groupData.id
        );
        toast({
          title: "Thành công!",
          variant: "success",
          description: `Gửi yêu cầu rời ${
            groupData.rootGroupId ? "nhóm" : "tổ chức"
          } thành công.`,
        });
        setOpenLeaveGroup(false);
        dispatch(
          getGroupInfo({
            group_id: groupData.id,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            navigate(GroupPathsEnum.GROUP_NOT_AVAILABLE);
          });
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(error.response?.data?.errors?.[0]),
        });
      } else {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage("MSG12"),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
    }
  };

  return (
    <div className="container">
      <div className="flex justify-between items-center">
        <div>
          <p className="text-xl font-bold leading-7.5 mb-4 text-secondary-900">
            {groupData?.name}
          </p>
          <ul className="flex gap-6">
            {menuLinks.map((menuLink, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={menuLink.url}
                    end
                    className={({ isActive, isPending }) =>
                      isPending
                        ? ``
                        : isActive
                        ? `px-2 pb-4 border-b-2 text-sm font-semibold leading-5 border-primary-600 text-primary-600 inline-block`
                        : "px-2 pb-4 border-b-2 border-transparent text-secondary-600 text-sm font-medium leading-5 inline-block"
                    }
                  >
                    {menuLink.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="grid grid-flow-col gap-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                iconBefore={<Reply className="-scale-x-100" />}
              >
                <span className="font-medium">Chia sẻ</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="min-w-[389px]">
              <p className="font-semibold text-secondary-900 mb-1">
                Chia sẻ nhóm
              </p>
              <p className="text-xs leading-4 text-secondary-600 mb-4">
                Bất kỳ ai có liên kết này và tài khoản TagaNow đều có thể xem
                được.
              </p>
              <div className="flex">
                <Input
                  className="flex-1 mr-2 py-[7px]"
                  readOnly
                  value={GroupPathsEnum.GROUP_DETAIL.replace(
                    ":id",
                    String(groupData?.id)
                  )}
                />
                <Button
                  variant="secondary"
                  size="sm"
                  className="px-2.5"
                >
                  <Copy color="#FAFAFA" />
                </Button>
              </div>
            </PopoverContent>
          </Popover>
          {groupData?.role && groupData?.deviceAccepted && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  className="px-3 py-2"
                  variant="ghost"
                >
                  <OverflowMenuVertical />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>
                  <Pin className="mr-2" />
                  Ghim
                </DropdownMenuItem>
                {groupData?.role?.code !== GroupUserRoleEnums.OWNER &&
                  groupData?.userRequest?.status !==
                    GroupUserRequestStatusEnum.WAITING && (
                    <DropdownMenuItem onClick={() => setOpenLeaveGroup(true)}>
                      <Logout className="mr-2" />
                      Rời khỏi {groupData?.rootGroupId ? "nhóm" : "tổ chức"}
                    </DropdownMenuItem>
                  )}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      <Dialog open={openLeaveGroup}>
        <DialogContent>
          <DialogHeader className="flex flex-row gap-4">
            <div>
              <span className="border-[6px] p-1 text-error-600 border-error-50 bg-error-100 rounded-full block">
                <InformationFilled size={20} />
              </span>
            </div>
            <div className="flex-1">
              <DialogTitle className="text-lg">
                Rời khỏi {groupData?.rootGroupId ? "Nhóm" : "Tổ chức"}
              </DialogTitle>
              <DialogDescription className="text-secondary-600 mt-2">
                Bạn chắc chắn rời khỏi{" "}
                {groupData?.rootGroupId ? "Nhóm" : "Tổ chức"}
                <span className="font-semibold"> {groupData?.name}</span>?
                <p className="mt-5">
                  Yêu cầu rời khỏi {groupData?.rootGroupId ? "nhóm" : "tổ chức"}{" "}
                  cần chờ chủ sở hữu{" "}
                  {groupData?.rootGroupId ? "Nhóm" : "Tổ chức"} phê duyệt. Bạn
                  sẽ không thể truy cập và sử dụng tài sản trong tổ chức và các
                  nhóm đã tham gia thuộc tổ chức này.{" "}
                  <span className="font-semibold">Tìm hiểu thêm↗</span>
                </p>
              </DialogDescription>
            </div>
          </DialogHeader>
          <DialogFooter className="justify-end gap-2">
            <Button
              size="sm"
              variant="text"
              onClick={() => setOpenLeaveGroup(false)}
            >
              Huỷ bỏ
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={handleLeaveGroup}
            >
              Xác nhận
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(GroupHeader);
