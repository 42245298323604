import React, { ReactNode, memo, useEffect, useMemo, useState } from "react";

import {
  CheckmarkOutline,
  CloudServiceManagement,
  Renew,
  Upgrade,
} from "@carbon/icons-react";
import { isAxiosError } from "axios";
import cx from "classnames";
import Carousel from "react-multi-carousel";
import { useNavigate, useParams } from "react-router-dom";

import NoConnection from "@/assets/images/slide/NoConnection.png";
import DeviceImage from "@/assets/images/slide/auth.png";
import UpgradeImg from "@/assets/images/slide/upgrade.png";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { loadingAction } from "@/redux/common.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import DeviceVerify from "./DeviceVerify";
import { cancelDeviceAuthenticationRequest } from "../../api/group.api";
import { GroupDeviceStatus, GroupSlide } from "../../constants/group.constant";
import { GroupPathsEnum } from "../../constants/group.paths";
import { getGroupInfo } from "../../group";
import RemoveDeviceRequestDialog from "../../screens/SettingUserScreen/components/RemoveDeviceRequestDialog";

type Item = {
  code: string;
  classN: string;
  title: string;
  img: string;
  des: ReactNode;
  button: ReactNode;
  bg?: ReactNode;
};

const Slide = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [openRemoveDeviceRequestDialog, setOpenRemoveDeviceRequestDialog] =
    useState(false);

  const upgrade: Item = useMemo(() => {
    return {
      code: GroupSlide.upgrade,
      classN: "bg-[#F5FAFE]",
      title: "Used Capacity",
      img: UpgradeImg,
      des: (
        <span className="text-gray-600 text-xs font-medium z-10">
          Tổ chức của bạn đã sử dụng được 60% gói dung lượng lưu trữ.
        </span>
      ),
      button: (
        <Button
          size="sm"
          className="w-full bg-gradient-to-l to-[#19F1FF] from-[#1948C1] z-10 mt-3"
          iconBefore={<Upgrade />}
        >
          Upgrade plan
        </Button>
      ),
      bg: (
        <>
          <div className="w-[360px] h-[360px] left-[-136px] top-[-136px] absolute bg-[#EEF7FF] rounded-full" />
          <div className="w-[220px] h-[220px] left-[-66px] top-[-66px] absolute bg-[#E7F3FF] rounded-full" />
          <div className="w-[120px] h-[120px] left-[-16px] top-[-16px] absolute bg-[#DEEDFF] rounded-full" />
        </>
      ),
    };
  }, []);
  const submitRemoveDeviceRequest = async () => {
    if (!device || !groupInfo || !user) {
      return;
    }
    try {
      dispatch(
        loadingAction.show({
          title: "Đang xử lý...",
          description: "Vui lòng chờ tới khi xử lý hoàn tất.",
        })
      );
      await cancelDeviceAuthenticationRequest({
        deviceUniqueId: groupInfo.deviceName,
        groupId: groupInfo.rootGroupId || groupInfo.id,
        userId: user.id,
      });
      toast({
        title: "Thành công!",
        variant: "success",
        description: <p>Đã gỡ yêu cầu xác thực thiết bị</p>,
      });
      setOpenRemoveDeviceRequestDialog(false);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    } finally {
      dispatch(loadingAction.hide());
      await dispatch(
        getGroupInfo({
          group_id: groupInfo.id,
        })
      );
    }
  };
  const device: Item = useMemo(() => {
    return groupInfo?.deviceAccepted
      ? {
          code: GroupSlide.deviceAccepted,
          classN: "bg-gradient-to-br from-violet-50 to-blue-100",
          title: "Thiết bị đã xác thực!",
          img: DeviceImage,
          des: (
            <span className="text-gray-600 text-xs font-medium z-10">
              Bạn đang đăng nhập tài khoản trên thiết bị
              <span className="text-gray-900 text-xs font-semibold ">
                {" "}
                {groupInfo?.deviceName}
              </span>
            </span>
          ),
          button: (
            <Button
              variant={"secondary"}
              size="sm"
              className="w-full z-10 mt-3 text-xs"
              iconBefore={<CloudServiceManagement />}
              onClick={() =>
                navigate(
                  `${GroupPathsEnum.GROUP_SETTING_USER.replace(
                    ":id",
                    id
                  )}?tabActive=advance`
                )
              }
            >
              Quản lý thiết bị
            </Button>
          ),
        }
      : groupInfo?.deviceStatus === GroupDeviceStatus.REQUESTED
      ? {
          code: GroupSlide.deviceAccepted,
          classN: "bg-gradient-to-br from-violet-50 to-blue-100",
          title: "Đang chờ xác thực!",
          img: NoConnection,
          des: (
            <span className="text-gray-600 text-xs font-medium z-10">
              Thiết bị này đang chờ Chủ sở hữu của Tổ chức phê duyệt.
            </span>
          ),
          button: (
            <DeviceVerify
              isRemove={false}
              button={
                <Button
                  variant="secondary"
                  size="sm"
                  className="w-full z-10 mt-3 text-xs"
                  iconBefore={<Renew />}
                  onClick={() => setOpenRemoveDeviceRequestDialog(true)}
                >
                  Huỷ xác thực
                </Button>
              }
            />
          ),
        }
      : {
          code: GroupSlide.deviceAccepted,
          classN: "bg-gradient-to-br from-violet-50 to-blue-100",
          title: "Thiết bị chưa xác thực!",
          img: NoConnection,
          des: (
            <span className="text-gray-600 text-xs font-medium z-10">
              Vui lòng tiến hành xác thực thông tin để mở khoá
              <span className="text-primary ml-1 text-xs font-semibold ">
                tính năng nâng cao ↗
              </span>
            </span>
          ),
          button: (
            <DeviceVerify
              isRemove={false}
              button={
                <Button
                  size="sm"
                  className="w-full z-10 mt-3 text-xs"
                  iconBefore={<CheckmarkOutline />}
                >
                  Tiến hành xác thực
                </Button>
              }
            />
          ),
        };
  }, [groupInfo, id, navigate]);
  const [listItem, setListItem] = useState<Item[]>([device]);
  useEffect(() => {
    setListItem([device, upgrade]);
  }, [groupInfo]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
  };
  return (
    <div className="py-4">
      <Carousel
        responsive={responsive}
        autoPlay
        autoPlaySpeed={3000}
        arrows={false}
        keyBoardControl={true}
        showDots={listItem.length > 1 ? true : false}
        shouldResetAutoplay
        infinite={listItem.length > 1 ? true : false}
        draggable={true}
        partialVisible={false}
      >
        {listItem.map((item, index) => {
          return (
            <div
              key={index}
              className="px-4 pb-6"
            >
              <div
                className={cx(
                  item.classN,
                  "p-4 flex-col justify-start gap-2 inline-flex rounded-lg relative overflow-hidden"
                )}
              >
                <div className="h-16 flex-col justify-start inline-flex z-10">
                  <img
                    className="w-16 h-16 z-2"
                    src={item.img}
                    alt=""
                  />
                </div>
                <div className="text-gray-900 text-sm font-semibold z-10">
                  {item.title}
                </div>
                {item.des}
                {item.button}
                {item.bg}
              </div>
            </div>
          );
        })}
      </Carousel>
      <RemoveDeviceRequestDialog
        open={openRemoveDeviceRequestDialog}
        onClose={() => setOpenRemoveDeviceRequestDialog(false)}
        onSubmit={submitRemoveDeviceRequest}
        device={null}
        loading={false}
        currentDevice={true}
      />
    </div>
  );
};

export default memo(Slide);
