import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface LoadingState {
  loading: {
    show: boolean;
    title: string;
    description: string;
  };
}

const initialLoadingState = {
  loading: {
    show: false,
    title: "",
    description: "",
  },
} as LoadingState;

const loadingSlice = createSlice({
  name: "counter",
  initialState: initialLoadingState,
  reducers: {
    show(state, action: PayloadAction<{ title: string; description: string }>) {
      state.loading.show = true;
      state.loading.title = action.payload.title;
      state.loading.description = action.payload.description;
    },
    hide(state) {
      state.loading.show = false;
      state.loading.title = "";
      state.loading.description = "";
    },
  },
});

export const loadingAction = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
